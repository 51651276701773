import React, { useCallback, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { IconButton } from '../buttons';

import {
  ArrowLeftImg,
  ArrowRightImg,
  LargeBinImg,
  PlusImg,
  WandImg,
} from '../../assets/icons';
import { NavigationBarContainer } from './styles';
import { useEditor } from '../../hooks/useEditor';
import { DocumentFormat } from '../../types/DocumentSettings';
import WarnPageNumberDialog from '../dialogs/WarnPageNumberDialog';
import { NavigationBarProps } from './types';
import LayoutDialog from '../dialogs/LayoutDialog';
import { useDocumentStore } from '../../hooks/zustand/documents';
import { lang } from '../../lang';

function NavigationBar({ anchor, onWandClick }: NavigationBarProps) {
  const tabletMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const currentDocument = useDocumentStore((state) => state.currentDocument);
  const prevPage = useDocumentStore((state) => state.prevPage);
  const nextPage = useDocumentStore((state) => state.nextPage);
  const addPage = useDocumentStore((state) => state.addPage);
  const deletePage = useDocumentStore((state) => state.deletePage);

  const {
    selectedPage,
    selectedPageIndex,
    isViewMode,
    handleSelectEl,
    setIsRefreshing,
    updatePage,
  } = useEditor();

  const [isWarnPageDialogOpen, setIsWarnPageDialogOpen] = useState(false);
  const [isWarnPageDeleteDialogOpen, setIsWarnPageDeleteDialogOpen] = useState(false);
  const [isLayoutDialogOpen, setIsLayoutDialogOpen] = useState(false);

  const handleDeletePage = useCallback(async () => {
    setIsWarnPageDeleteDialogOpen(false);

    if (!currentDocument) return;

    deletePage(currentDocument.id, currentDocument.version.id, selectedPage!.id);
  }, [currentDocument, selectedPage]);

  const handleDeleteWarn = useCallback(() => {
    if (!currentDocument) return;
    const { format, version: { pages } } = currentDocument;
    if (format === DocumentFormat.BOOKLET && pages.length === 8) {
      setIsWarnPageDeleteDialogOpen(true);
    } else {
      handleDeletePage();
    }
  }, [currentDocument, handleDeletePage]);

  const handleAddPageWarn = useCallback(() => {
    if (!currentDocument) return;
    const { format, version: { pages } } = currentDocument;
    if (format === DocumentFormat.BOOKLET && pages.length === 8) {
      setIsWarnPageDialogOpen(true);
    } else {
      setIsLayoutDialogOpen(true);
    }
  }, [currentDocument]);

  return (
    <>
      <NavigationBarContainer anchor={anchor} id="navigation-bar">
        <div className="left-container">
          <div className="page-button" />
          <div>
            <IconButton
              src={WandImg}
              alt="magic wand"
              onClick={onWandClick}
              label={tabletMQ ? undefined : lang('document.artboard.change_layout')}
              disabled={isViewMode}
              style={{ visibility: isViewMode ? 'hidden' : 'visible', width: '100%', paddingLeft: !tabletMQ ? '1rem' : undefined }}
            />
          </div>
          <div>
            <IconButton
              src={LargeBinImg}
              alt="trash bin"
              disabled={currentDocument?.version?.pages.length === 1 || isViewMode}
              onClick={handleDeleteWarn}
              style={{ visibility: isViewMode ? 'hidden' : 'visible' }}
            />
          </div>
        </div>
        <div className="double-button">
          <div>
            <IconButton
              src={ArrowLeftImg}
              alt="left arrow"
              onClick={() => {
                if (prevPage()) setIsRefreshing(true);
                if (selectedPage) updatePage(selectedPage);
              }}
              disabled={selectedPageIndex === 0}
            />
          </div>
          <div>
            {
              !isViewMode
                && currentDocument
                && selectedPageIndex === (currentDocument?.version?.pages.length || 0) - 1
                ? (
                  <IconButton
                    src={PlusImg}
                    alt="add page"
                    onClick={handleAddPageWarn}
                  />
                ) : (
                  <IconButton
                    src={ArrowRightImg}
                    alt="right arrow"
                    onClick={() => {
                      if (nextPage()) setIsRefreshing(true);
                      if (selectedPage) updatePage(selectedPage);
                    }}
                    disabled={
                      selectedPageIndex === (currentDocument?.version?.pages.length || 0) - 1
                    }
                  />
                )
            }
          </div>
        </div>
        <div className="right-container">
          <div className="feedback-button" />
        </div>
      </NavigationBarContainer>
      <WarnPageNumberDialog
        isOpen={isWarnPageDeleteDialogOpen}
        onClose={() => setIsWarnPageDeleteDialogOpen(false)}
        isDelete
        onSubmit={handleDeletePage}
      />
      <LayoutDialog
        handleLayoutSelect={(layout, template) => addPage(layout, template)}
        isOpen={isLayoutDialogOpen}
        onOpen={() => handleSelectEl(null)}
        onClose={() => setIsLayoutDialogOpen(false)}
      />
      <WarnPageNumberDialog
        isOpen={isWarnPageDialogOpen}
        onClose={() => setIsWarnPageDialogOpen(false)}
        isDelete={false}
        onSubmit={() => { setIsWarnPageDialogOpen(false); setIsLayoutDialogOpen(true); }}
      />
    </>
  );
}

export default NavigationBar;

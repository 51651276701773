import React, { useState } from 'react';
import {
  Box, ClickAwayListener, Input, useTheme,
} from '@mui/material';
import { OperationState } from 'src/hooks/store/document/operations/abstract';
import { Document } from 'src/types/models';
import * as Styles from './styles';

export default function DocumentTitle(props: {
  document: Document;
  state: OperationState | undefined;
  onSubmit: (data: { name: string }) => Promise<void>;
}) {
  const { document, state, onSubmit } = props;
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(document.name);
  const [isSaving, setIsSaving] = useState(false);

  return !isEditing ? (
    <Styles.DocumentInformation>
      <Box
        onClick={() => setIsEditing(true)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            setIsEditing(true);
          }
        }}
        tabIndex={0}
        sx={{
          cursor: 'pointer',
        }}
      >
        <h1>{document.name}</h1>
      </Box>
      {state && (
        <small>
          {
            {
              queued: 'Saved •',
              merged: 'Saved •',
              saving: 'Saving',
              saved: [
                'Saved',
                <span key="saved" style={{ color: 'green' }}>
                  {' '}
                  ✓
                </span>,
              ],
              failed: (
                <span key="failed" style={{ color: theme.palette.error.main }}>
                  Error
                </span>
              ),
            }[state]
          }
        </small>
      )}
    </Styles.DocumentInformation>
  ) : (
    <ClickAwayListener
      onClickAway={() => {
        setIsEditing(false);
        onSubmit({ name });
      }}
    >
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          event.stopPropagation();
          setIsSaving(true);
          try {
            await onSubmit({ name });
          } finally {
            setIsSaving(false);
          }
          setIsEditing(false);
        }}
      >
        <Input
          type="text"
          name="name"
          disabled={isSaving}
          onChange={(e) => setName(e.target.value)}
          autoFocus
          onFocus={(e) => e.target.select()}
          value={name}
          onKeyUp={(e) => {
            if (e.key === 'Escape') {
              setIsEditing(false);
            }
          }}
        />
      </form>
    </ClickAwayListener>
  );
}

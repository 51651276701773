import {
  Card,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Input,
  ThemeProvider,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import * as AssignmentsAPI from 'src/api/Assignments';
import * as DocumentsAPI from 'src/api/Documents';
import {
  CopyImg, Eye, Quill, StarImg,
} from 'src/assets/icons';
import BackButton from 'src/components/BackButton';
import SaveStatus from 'src/components/SaveStatus';
import StatusTag from 'src/components/StatusTag';
import { IconButton } from 'src/components/buttons';
import Button from 'src/components/buttons/Button';
import AssignmentAssistantDialog from 'src/components/dialogs/AssignmentAssistantDialog';
import AssignmentLinkDialog from 'src/components/dialogs/AssignmentLinkDialog';
import SelectClassroomsAndStudentsDialog from 'src/components/dialogs/SelectClassroomsAndStudentsDialog';
import TopBar from 'src/components/shared/TopBar';
import {
  useApplyDocumentOperation,
  useCreateDocument,
  useDocument,
} from 'src/hooks/document';
import {
  SetImportantWordsOperation,
  SetWritingPlanOperation,
} from 'src/hooks/store/document/operations';
import { useResourceStore } from 'src/hooks/store/resources';
import {
  DocumentFormat,
  DocumentTemplateSystem,
} from 'src/types/DocumentSettings';
import * as models from 'src/types/models';
import { debounce, theme } from 'src/utils';
import { useUserStore } from '../../hooks/zustand/user';
import * as Styles from './styles';

import { Logo } from '../../components';
import BaseDocumentForm from './BaseDocumentForm';

function getStoreAssignment(assignmentId: string) {
  return useResourceStore.getState().assignmentById[assignmentId];
}

export default function EditAssignment() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const { id: assignmentId } = useParams();

  const titleInputRef = useRef<HTMLInputElement>(null);

  const assignment = useResourceStore(
    (state) => (assignmentId ? state.assignmentById[assignmentId] : undefined),
  );
  const setAssignment = useResourceStore((state) => state.setAssignment);

  // The base document is managed as its own resource. This takes advantage
  // of the sophisticated hooks and state management already in place for
  // the editor.
  const createDocument = useCreateDocument();
  const applyDocumentOperation = useApplyDocumentOperation();

  // This will be undefined until the assignment has a document id.
  const document = useDocument(assignment?.baseDocument?.id) || assignment?.baseDocument;

  // A document may be provided as a starting point, for example, if the user
  // arrives at the assignment page from the editor. Since this is a client-side
  // route, the document will probably be in the store. This document
  // will be shown until the document is duplicated in the background.
  const documentIdToDuplicate = search.get('document') || undefined;
  const documentToDuplicate = useDocument(documentIdToDuplicate);

  // The following state variables manage display of dialogs and status.
  const [saveStatus, setSaveStatus] = useState<'saving' | 'saved' | 'failed' | null>(null);
  const [
    isSelectClassroomsAndStudentsOpen,
    setIsSelectClassroomsAndStudentsOpen,
  ] = useState(false);
  const [isAssignmentLinkOpen, setIsAssignmentLinkOpen] = useState(false);
  const [isAssistantOpen, setIsAssistantOpen] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const save = async (newAssignment: typeof assignment) => {
    if (!newAssignment || saveStatus === 'saving') {
      return;
    }

    setSaveStatus('saving');
    if (!newAssignment.id || newAssignment.id === 'new') {
      try {
        // First, create the base document on which the assignment depends.
        // There are two cases to consider:
        let newDocumentId: string;
        if (documentIdToDuplicate) {
          // If the user is coming from the editor or another assignment,
          // there may be a document to act as the starting point.
          // Duplicate the document and use it as the base document.
          const response = await DocumentsAPI.duplicateDocument(
            documentIdToDuplicate,
          );

          // Since we're detached from document state management machinery
          // for the time being, I'll just let this document ID cycle back
          // around and get loaded with useDocument() on the next refresh.
          newDocumentId = response.data.data.id;
        } else {
          // If there's nothing to duplicate, create a new document with default settings.
          // If any other changes have been made to the temporary baseDocument object,
          // such as through state/override parameters, then incorporate them.
          const { baseDocument } = newAssignment;
          const newDocument = await createDocument(
            baseDocument?.name || 'Untitled',
            baseDocument?.format || DocumentFormat.BOOKLET,
            baseDocument?.meta || {},
          );
          newDocumentId = newDocument.id;
        }

        // Setting the assignment here should trigger the useDocument hook
        // and get a head start on loading the document into the store.
        const storeAssignment = getStoreAssignment(newAssignment.id);
        setAssignment({
          ...storeAssignment!,
          baseDocument: {
            ...(storeAssignment?.baseDocument || {}),
            id: newDocumentId,
          },
        });

        // Create the assignment. It will receive an ID from the server that
        // should be reflected in the URL.
        const response = await AssignmentsAPI.create({
          name: newAssignment.name,
          instructions: newAssignment.instructions,
          document_id: newDocumentId,
          classrooms: newAssignment.classrooms.map((c) => ({ id: c.id })),
          students: newAssignment.students.map((s) => ({ id: s.id })),
        });

        const newAssignmentId = response.data.data.id;
        setAssignment({
          // Only update fields that are set by the server. This is an asynch
          // operation and the assignment may have been updated by the user
          // while it is happening.
          ...getStoreAssignment(newAssignment.id)!,
          id: newAssignmentId,
        });
        setSaveStatus('saved');

        // Set the Assignment's ID in the URL.
        // To be honest, I do not fully understand the implications for component
        // state by changing the URL. The assignment is loaded only on the first
        // component render, so it should not trigger a reload that would overwrite
        // data. However, this could be a source of a race condition of some kind.
        navigate(
          (
            window.document.location.pathname + window.document.location.search
          ).replace(
            /\bassignment([/=])(\w+)/,
            `assignment$1${newAssignmentId}`,
          ),
          { replace: true },
        );
      } catch (error) {
        setSaveStatus('failed');
      }
    } else {
      // Save the assignment. Do not use this mechanism to save the
      // base document. Apply document operations instead.
      try {
        await AssignmentsAPI.update(newAssignment.id, {
          name: newAssignment.name,
          instructions: newAssignment.instructions,
          classrooms: newAssignment.classrooms,
          students: newAssignment.students,
        });
        setSaveStatus('saved');
      } catch (error) {
        setSaveStatus('failed');
      }
    }
  };

  const changeAssignment = (
    newAssignment: models.Assignment,
    timeout: number = 1000,
  ) => {
    // Set the assignment in the store,
    setAssignment(newAssignment);

    // and then queue a save to the server. Cancel queued requests if a new one
    // comes in before the timeout.
    return new Promise((resolve, reject) => {
      debounce(
        () => {
          save(newAssignment).then(resolve).catch(reject);
        },
        timeout,
        'save-assignment',
      );
    });
  };

  useEffect(() => {
    if (!assignmentId) {
      navigate('/gallery');
      return;
    }

    let postLoginState = {};
    try {
      postLoginState = JSON.parse(
        localStorage.getItem('postLoginState') || '{}',
      );
      localStorage.removeItem('postLoginState');
    } catch (error) {
      // Ignore errors.
    }

    const testParam = (value: any) => {
      if (value === 'true') {
        return true;
      }
      if (value === 'false') {
        return false;
      }
      return undefined;
    };

    const overrides = {
      name: search.get('name') || '',
      importantWords:
        location.state?.importantWords
        || (search.get('importantWords')
          && search.get('importantWords')!.split(',')),
      writingPlanId: search.get('writingPlan'),
      prompt: location.state?.prompt,
      hasSignalWords: testParam(search.get('hasSignalWords')),
      hasImportantWords: testParam(search.get('hasImportantWords')),
      hasWordCount: testParam(search.get('hasImportantWords')),
      ...postLoginState,
    };

    if (user.role === 'teacher' && assignmentId === 'new') {
      // Initialize with a default assignment.
      const initialAssignment: models.Assignment = {
        // TODO: What are the implications of putting 'new' in the shared store?
        id: 'new',
        name: overrides.name || '',
        instructions: overrides.prompt || '',
        teacher: {
          id: user.id!,
          name: user.name,
          surname: user.surname,
        },
        classrooms: [],
        students: [],
        submission: null,
        createdAt: new Date(),
        updatedAt: new Date(),

        // This is broken out into its own resource upon interaction with the API.
        // Assign it a temporary ID so that it can be manipulated in the store.
        // When the assignment is saved, it will receive a server ID.
        baseDocument: documentToDuplicate
          ? {
            // Use the original document, but lop off its ID so that we don't
            // accidentally overwrite it from this page. All of its block and
            // page IDs will be the same as the original until, so be careful.
            ...documentToDuplicate,
            id: undefined,
          }
          : {
            // Otherwise, initialize an empty document.
            name: overrides.name || 'Untitled',
            templateSystem: DocumentTemplateSystem.BLOCKS,
            meta: {
              writingPlan: overrides.writingPlanId,
              importantWords: overrides.importantWords || [],
              hasImportantWords: overrides.hasImportantWords !== false,
              hasWordCount: overrides.hasWordCount !== false,
              hasSignalWords: overrides.hasSignalWords !== false,
            },
          },

        // Using notes for assignment instructions is deprecated in
        // favor of the `instructions` field.
        notes: [],
      };

      setAssignment(initialAssignment);
      save(initialAssignment);
    } else if (assignmentId && assignment === undefined) {
      // Fetch the Assignment from the server and map to client-side
      // naming convention.
      AssignmentsAPI.getById(assignmentId)
        .then((response) => {
          const { data } = response.data;
          setAssignment({
            id: data.id,
            name: overrides.name || data.name,
            classrooms: data.classrooms,
            students: data.students,
            baseDocument: data['base-document']
              ? {
                id: data['base-document'].id,
              }
              : null,
            teacher: {
              id: data.teacher.id,
              name: data.teacher.name,
              surname: data.teacher.surname,
            },
            createdAt: new Date(data.created_at),
            updatedAt: new Date(data.updated_at),

            // Assignment instructions are no longer stored as Notes.
            // Update the representation to instructions.
            // The note will be ignored if `instructions` is set.
            instructions:
              overrides.prompt
              || data.instructions
              || (data.notes?.length ? data.notes[0].text : ''),
            notes: [],

            // These fields are specific to students:
            submission: data.submission
              ? {
                id: data.submission?.id,
                documentId: data.submission?.document_id,
                status: data.submission?.status,
              }
              : null,
          });
        })
        .catch(() => {
          // Pattern in this repository is to use null to represent a loading error.
          // Add specific error handling here if it should be displayed to the user.
          // setAssignment(null);
        });
    } else if (assignment) {
      // The assignment has been loaded from the store.
      // Just check for overrides.
      if (overrides.name || overrides.prompt) {
        changeAssignment(
          {
            ...assignment,
            name: overrides.name || assignment.name,
            instructions: overrides.prompt || assignment.instructions,
          },
          100,
        );
      }
      if (document?.id) {
        if (overrides.importantWords) {
          applyDocumentOperation(
            new SetImportantWordsOperation(
              document.id,
              overrides.importantWords,
            ),
          );
        }
        if (overrides.writingPlanId) {
          applyDocumentOperation(
            new SetWritingPlanOperation(document.id, overrides.writingPlanId),
          );
        }
      }
    }
  }, []);

  const isAssigned = !!(
    assignment?.classrooms.length || assignment?.students.length
  );

  let status = 'New';
  if (assignment?.id) {
    if (isAssigned) {
      status = 'Assigned';
    } else {
      // The assignment has been saved but has not been assigned to a class.
      status = 'Draft';
    }
  }

  // Redirect students to start the assignment.
  useEffect(() => {
    if (user.role === 'student' && assignment?.id) {
      if (!assignment.submission?.id) {
        AssignmentsAPI.createSubmission(assignment.id).then((response) => {
          navigate(
            `/document/${response.data.data.document_id}?${
              assignment.instructions ? 'notes=true' : ''
            }`,
          );
        });
      } else if (assignment.submission?.documentId) {
        navigate(`/document/${assignment.submission?.documentId}`);
      } else {
        // 404?
        navigate('/gallery');
      }
    }
  }, [user.role, assignment]);

  useEffect(() => {
    if (assignment?.name) {
      window.document.title = `${assignment.name}`;
    } else {
      window.document.title = 'Assignment';
    }
  }, [assignment?.name]);

  if (user.role !== 'teacher') {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <TopBar>
        <TopBar.Section>
          <Logo height="2.25rem" variant="responsive" to="/gallery" />
        </TopBar.Section>
        <TopBar.Spacer />
        <TopBar.Section>
          {assignment && (
            <IconButton
              src={Eye}
              alt=""
              label="Enter Student Preview"
              disabled={!document?.id || !assignment?.id}
              onClick={() => {
                // Open a new tab
                navigate({
                  pathname: `/document/${document?.id}`,
                  search: `?assignmentPreview=${assignment.id}&notes=1`,
                });
              }}
            />
          )}
        </TopBar.Section>
      </TopBar>

      {assignment && (
        <>
          <Container
            maxWidth="xl"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'background.default',
              position: {
                xs: 'sticky',
                sm: 'static',
              },
              top: {
                xs: '48px',
                sm: 'auto',
              },
              paddingBottom: {
                xs: '1rem',
                sm: '0',
              },
              zIndex: theme.zIndex.mobileStepper,
            }}
          >
            <BackButton
              fallbackTo="/gallery"
              fallbackState={{ tab: 1 }}
              label="Go back to all assignments"
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                marginTop: '1rem',
                minHeight: '3rem',
              }}
            >
              {!isAssigned && (
                <Button
                  label="Assign"
                  disabled={!assignment.name}
                  onClick={() => {
                    // Simple form validation.
                    if (!assignment.name) {
                      setShowValidation(true);
                      titleInputRef.current?.focus();
                      return;
                    }

                    setIsSelectClassroomsAndStudentsOpen(true);
                  }}
                />
              )}
              {isAssigned && (
                <IconButton
                  src={CopyImg}
                  alt=""
                  label="Make a copy"
                  onClick={() => {
                    navigate(`/assignment/${assignment.id}/duplicate`);
                  }}
                />
              )}
            </div>
          </Container>

          <Container>
            <Grid container columnSpacing={8}>
              <Grid item display="flex" flexDirection="column" sm={6} gap={3}>
                <div>
                  <Styles.AssignmentSubTitle>
                    Create an
                  </Styles.AssignmentSubTitle>
                  <Styles.AssignmentTitle
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'end',
                      gap: 3,
                    }}
                  >
                    Assignment
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        gap: '6px',

                        // About the height of a descending 'g'.
                        marginBottom: '.2em',
                      }}
                    >
                      <StatusTag
                        status={status}
                        icon={status === 'Assigned' ? StarImg : undefined}
                      />
                      <SaveStatus status={saveStatus} />
                    </div>
                  </Styles.AssignmentTitle>
                </div>

                <FormControl
                  required
                  error={showValidation && !assignment.name}
                >
                  <FormLabel>Title</FormLabel>
                  <Input
                    inputRef={titleInputRef}
                    type="text"
                    value={assignment.name}
                    placeholder="Add a title"
                    onChange={(e) => {
                      changeAssignment({ ...assignment, name: e.target.value });
                    }}
                    onBlur={() => setShowValidation(true)}
                    autoFocus
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '2em',
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Writing Prompt</FormLabel>
                  <Input
                    type="text"
                    multiline
                    value={assignment.instructions}
                    onChange={(e) => changeAssignment({
                      ...assignment,
                      instructions: e.target.value,
                    })}
                    placeholder="Add a writing prompt"
                  />
                </FormControl>

                <Card
                  elevation={16}
                  onClick={() => {
                    setIsAssistantOpen(true);
                  }}
                  sx={{
                    backgroundColor: '#f6f6fa',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    padding: '1rem',
                    margin: '2rem 1rem 1rem 0',
                    border: `1px solid ${theme.palette.primary.main}`,

                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#f0f0f4',
                    },
                  }}
                >
                  <img src={Quill} alt="quill icon" />
                  <div>
                    <small>
                      Engage students with AI-powered writing prompts
                    </small>
                    <p
                      style={{
                        marginTop: '.2em',
                        color: theme.palette.primary.main,
                      }}
                    >
                      Try the Assignment Assistant
                    </p>
                  </div>
                </Card>
              </Grid>

              <Grid item display="flex" flexDirection="column" sm={6} gap={3}>
                <BaseDocumentForm
                  document={document}
                  assignment={assignment}
                  isEditable
                />
              </Grid>
            </Grid>
          </Container>
        </>
      )}

      {assignment && (
        <SelectClassroomsAndStudentsDialog
          isOpen={isSelectClassroomsAndStudentsOpen}
          onClose={() => setIsSelectClassroomsAndStudentsOpen(false)}
          selectedClassrooms={assignment.classrooms}
          selectedStudents={assignment.students}
          openClassroomsSelection={() => setIsSelectClassroomsAndStudentsOpen(true)}
          onSelect={async (classrooms, students) => {
            await changeAssignment(
              {
                ...assignment!,
                classrooms,
                students,
              },
              0,
            );
            setIsSelectClassroomsAndStudentsOpen(false);
            setIsAssignmentLinkOpen(true);
          }}
        />
      )}

      {assignment && (
        <AssignmentLinkDialog
          isOpen={isAssignmentLinkOpen}
          onClose={() => setIsAssignmentLinkOpen(false)}
          assignment={assignment}
        />
      )}

      <AssignmentAssistantDialog
        showControls
        open={isAssistantOpen}
        onClose={() => setIsAssistantOpen(false)}
        onSelect={({
          topic, prompt, writingPlanId, importantWords,
        }) => {
          const newAssignment = {
            ...assignment!,

            // Always overwrite the prompt.
            instructions: prompt,

            // Only set the topic if it has not been set.
            name: assignment?.name || topic,
          };

          if (writingPlanId && !document?.meta?.writingPlan) {
            // A writing plan was chosen in the assistant and there is
            // not currently a writing plan set.
            if (document?.id) {
              applyDocumentOperation(
                new SetWritingPlanOperation(document?.id, writingPlanId),
              );
            } else {
              // TODO: It's bad design to have two different ways of setting
              // the assignment's base document values.
              newAssignment.baseDocument = {
                ...newAssignment.baseDocument,
                meta: {
                  ...newAssignment.baseDocument?.meta,
                  writingPlan: writingPlanId,
                },
              };
            }
          }

          if (importantWords) {
            const currentWords = document?.meta?.importantWords || [];
            const mergedWords = new Set([
              ...currentWords,
              ...importantWords,
            ]).values();

            // Overwrite the important words.
            const isEnabled = document?.meta?.hasImportantWords !== false;
            if (document?.id) {
              applyDocumentOperation(
                new SetImportantWordsOperation(
                  document.id,
                  Array.from(mergedWords),
                  isEnabled,
                ),
              );
            } else {
              newAssignment.baseDocument = {
                ...assignment!.baseDocument,
                meta: {
                  ...assignment!.baseDocument?.meta,
                  importantWords,
                },
              };
            }
          }

          changeAssignment(newAssignment);
          setIsAssistantOpen(false);
        }}
      />
    </ThemeProvider>
  );
}

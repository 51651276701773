import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DraftInlineStyleType } from 'draft-js';

import {
  BoardDefinition,
  Element, ImageBoxType, TextBoxType,
} from '../types/LayoutEditor';
import { LayoutPage as PageType } from '../types/models';
import {
  EditorContextData,
  EditorCurrentPage,
  EditorProviderProps,
  HistoryItem,
} from './types';
import {
  debounce, findWord, getPredominantColor, getWordCount,
} from '../utils';

import { DocumentFormatSettings } from '../configuration/documents';
import WRITING_PLANS_LIST from '../configuration/writing-plans';
import { getTextFromPage } from '../components/elements/TextBox/utils';
import { ContentBlock } from '../components/elements/TextBox/types';
import { parsePageToState, parseStateToPage } from './utils';
import { useDocumentStore } from './zustand/documents';

const EditorContext = createContext<EditorContextData>({} as EditorContextData);

const IGNORE_LOADING = ['Label'];

let updateTimeout: NodeJS.Timeout;

export function EditorProvider({ children }: EditorProviderProps) {
  const currentDocument = useDocumentStore((state) => state.currentDocument);
  const page = useDocumentStore((state) => state.selectedPage);
  const changeSelectedPage = useDocumentStore((state) => state.changeSelectedPage);
  const updateDocumentSelector = useDocumentStore((state) => state.updateDocument);
  const getPage = useDocumentStore((state) => state.getPage);
  const updatePages = useDocumentStore((state) => state.updatePages);
  const updatePageSelector = useDocumentStore((state) => state.updatePage);

  const board = useMemo(() => {
    if (!currentDocument) return null;

    const format = currentDocument.format?.toLowerCase();

    if (!format) return null;

    return {
      ...DocumentFormatSettings[format as keyof typeof DocumentFormatSettings],
      key: 'board',
      type: 'Board',
      ref: null,
    } as BoardDefinition;
  }, [currentDocument]);

  const selectedPageIndex = useMemo(
    () => currentDocument?.version?.pages?.findIndex((el) => el.id === page?.id) ?? -1,
    [currentDocument, page],
  );

  const [isViewMode, setIsViewMode] = useState(false);

  const [hasImportantWords, setHasImportantWords] = useState(false);
  const [importantWords, setImportantWords] = useState<{ [key: string]: boolean }>({});
  const [hasNewWords, setHasNewWords] = useState(false);

  const [hasWritingPlan, setHasWritingPlan] = useState(false);
  const [writingPlanId, setWritingPlanId] = useState('');
  const [signalWords, setSignalWords] = useState<{ [key: string]: boolean }>({});

  const [currentPage, setCurrentPage] = useState<EditorCurrentPage | null>(page ?? null);
  const [thereAreChanges, setThereAreChanges] = useState(false);

  const [selectedKey, setSelectedKey] = useState('');

  const [isBusy, setIsBusy] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [titleLength, setTitleLength] = useState(0);

  const { numberOfImages, numberOfImageSlots } = useMemo(() => {
    if (!currentPage?.content) return { numberOfImages: 0, numberOfImageSlots: 0 };

    const imageSlots = currentPage.content?.filter((el) => el.type === 'ImageBox') as ImageBoxType[] ?? [];
    const filledImage = imageSlots.filter((el) => !!el.originalImage);

    return { numberOfImages: filledImage.length, numberOfImageSlots: imageSlots.length };
  }, [currentPage]);

  const [allContentsLoaded, setAllContentsLoaded] = useState(false);
  const [historyPast, setHistoryPast] = useState<HistoryItem[]>([]);
  const [historyFuture, setHistoryFuture] = useState<HistoryItem[]>([]);

  const [selectedString, setSelectedString] = useState('');

  const clearData = () => {
    setHistoryPast([]);
    setHistoryFuture([]);
    setHasImportantWords(false);
    setImportantWords({});
    setHasWritingPlan(false);
    setWritingPlanId('');
    setSignalWords({});
    setIsRefreshing(false);
    setTitleLength(0);
  };

  useEffect(() => { if (!currentDocument) clearData(); }, [currentDocument]);

  useEffect(() => () => clearData(), []);

  useEffect(() => {
    if (currentDocument && !allContentsLoaded && currentDocument.version?.pages?.length > 0) {
      setAllContentsLoaded(!currentDocument.version.pages.find((pg) => !pg.content));
    }
  }, [currentDocument]);

  const content = useMemo(
    () => currentPage?.content ?? [],
    [currentPage?.content],
  );

  const pageElements = useMemo(
    () => currentPage?.content?.map(
      (e) => (e.type === 'TextBox' ? (e as TextBoxType).defaultFont : e.type),
    ) || [],
    [currentPage?.content],
  );

  const selectedEl = useMemo(() => {
    if (!content || isViewMode) return null;
    if (selectedKey === 'board') return board;
    return content.find((el) => el.key === selectedKey) || null;
  }, [selectedKey, content, board, isViewMode]);

  const documentWordCount = useMemo(
    () => {
      if (!currentDocument) return 0;

      if (isViewMode) {
        return currentDocument.meta && typeof currentDocument.meta === 'string' ? JSON.parse(currentDocument.meta).wordCount ?? 0 : 0;
      }

      return currentDocument?.version?.pages?.reduce((prev, el) => {
        if (!el || !currentPage) return prev;

        if (el.id === currentPage?.id && currentPage.wordCount) return prev + currentPage.wordCount;

        if (!el.meta) return prev;

        return prev + (el.meta && typeof el.meta === 'string' ? JSON.parse(el.meta).wordCount ?? 0 : 0);
      }, 0) ?? 0;
    },
    [currentDocument, currentPage],
  );

  const updateDocument = useCallback(async (
    signalWrds: { [key: string]: boolean },
    importantWrds: { [key: string]: boolean },
  ) => {
    if (
      isViewMode
      || !currentDocument
      || !currentDocument.isOwner
      || (hasWritingPlan && !signalWrds)
      || (hasImportantWords && !importantWrds)
    ) {
      return;
    }

    debounce(() => {
      updateDocumentSelector(
        currentDocument.id,
        currentDocument.name,
        JSON.stringify({
          writingPlan: writingPlanId,
          signalWords: signalWrds,
          importantWords: importantWrds,
          wordCount: documentWordCount,
        }),
      );
    }, 1000, 'update-document');
  }, [
    currentDocument,
    isViewMode,
    hasWritingPlan,
    writingPlanId,
    hasImportantWords,
    documentWordCount,
  ]);

  const setDocumentMeta = useCallback((meta: any, update = true) => {
    if (!currentDocument || !meta) {
      setHasWritingPlan(false);
      setWritingPlanId('');
      setSignalWords({});
      setHasImportantWords(false);
      setImportantWords({});
      return;
    }

    if (!('importantWords' in meta)) {
      setHasImportantWords(false);
      setImportantWords({});
    } else {
      setHasImportantWords(Object.keys(meta.importantWords).length !== 0);
      setImportantWords(meta.importantWords);
      setHasNewWords(true);
    }

    let words;
    if (!('writingPlan' in meta)) {
      setHasWritingPlan(false);
      setWritingPlanId('');
      setSignalWords({});
    } else {
      words = 'signalWords' in meta ? meta.signalWords : WRITING_PLANS_LIST.find((el) => el.id === meta.writingPlan)?.signalWords;
      setHasWritingPlan(!!meta.writingPlan);
      setWritingPlanId(meta.writingPlan);

      // Signal words as two representations: object-based and array-based.
      // The Array-based representation is newer. Within this context provider,
      // transform the newer format to the older format for backward-compatibility.
      if (Array.isArray(words)) {
        const signalWordsObject: Record<string, boolean> = {};
        words.forEach((word: string) => {
          signalWordsObject[word] = false;
        });
        setSignalWords(signalWordsObject);
      } else if (words) {
        setSignalWords(words);
      } else {
        setSignalWords({});
      }
    }

    if (('writingPlan' in meta || 'importantWords' in meta) && update) {
      updateDocument(words, meta.importantWords);
    }
  }, [currentDocument]);

  useEffect(() => {
    if (currentDocument?.meta) {
      setDocumentMeta(JSON.parse(currentDocument.meta), false);
    }
  }, [currentDocument?.id]);

  const updatePage = useCallback((newPage: PageType, fromState = false) => {
    if (
      isViewMode
      || !currentDocument
      || !currentDocument.isOwner
      || !board
      || (!thereAreChanges && !currentDocument.isTryIt)
    ) return;
    updatePageSelector(
      currentDocument.id,
      currentDocument.version.id,
      fromState ? newPage : parseStateToPage(newPage, board)!,
    );
  }, [currentDocument, board, isViewMode, thereAreChanges]);

  useEffect(() => {
    // Force artboard to re-render when changing selected page
    if (page?.id) setIsRefreshing(true);
  }, [page?.id]);

  useEffect(() => {
    async function loadContent() {
      if (currentDocument?.isTryIt) {
        setIsPageLoaded(true);
      }

      if (isRefreshing || !currentDocument || !page || page.content) return;

      const responseStatus = await getPage(
        currentDocument.id,
        currentDocument.version.id,
        page.id,
      );

      setIsPageLoaded(responseStatus === 200);
    }

    loadContent();
  }, [currentDocument, page]);

  useEffect(() => {
    if (currentPage && page?.meta && !('wordCount' in JSON.parse(page.meta))) {
      updatePage(currentPage);
    }
  }, [currentPage?.wordCount]);

  const toggleViewMode = useCallback((value?: boolean) => {
    if (value !== undefined) {
      setIsViewMode(value);
    } else {
      setIsViewMode((prev) => !prev);
    }
    setSelectedKey('');
  }, []);

  // After load page from api, fill it's content to every
  // entry of the history array that's empty
  const fillHistoryContent = useCallback((newPage: PageType) => {
    if (isViewMode) return;
    setHistoryPast((prev) => prev.map((h) => {
      const prevHistory = h.pages.slice();
      const pageIndex = prevHistory.findIndex((p) => p.id === newPage?.id);
      if (!prevHistory[pageIndex]?.content) {
        prevHistory.splice(pageIndex, 1, newPage);
      }
      return { id: h.id, pages: prevHistory };
    }));
  }, []);

  // For every change made in any page, check if the content changed
  // If the content changed, the pages array will be pushed to history
  const handleUpdateHistory = useCallback((newPage: PageType) => {
    if (!currentDocument || !page) return;
    const auxPages = [...currentDocument.version.pages];
    setHistoryPast(
      (previousHistory) => {
        if (isViewMode) return previousHistory;

        const previous = previousHistory[previousHistory.length - 1];
        if (!previous?.id) return [{ id: newPage.id, pages: auxPages }];
        auxPages[newPage.position] = newPage;
        return [...previousHistory, { id: newPage.id, pages: auxPages }];
      },
    );
  }, [currentDocument, page]);

  useEffect(
    () => {
      if (!page) return;

      if (currentPage && currentPage.id !== page.id) {
        clearTimeout(updateTimeout);
      }

      setCurrentPage((prev) => parsePageToState(prev, page));
      setThereAreChanges(false);
    },
    [page],
  );

  useEffect(() => {
    clearTimeout(updateTimeout);
    if (!currentPage) return;
    updateTimeout = setTimeout(() => {
      if (thereAreChanges) {
        updatePage(currentPage);
        handleUpdateHistory(currentPage);
        setThereAreChanges(false);
      }
    }, 1000);
  }, [currentPage]);

  const undo = useCallback(() => {
    if (historyPast.length > 1 && board) {
      const lastItem = historyPast.pop()!;

      const currentNew = historyPast[historyPast.length - 1].pages;
      updatePages(() => currentNew);
      setHistoryFuture(
        (futureHistory) => [
          ...futureHistory,
          lastItem,
        ],
      );

      changeSelectedPage(lastItem.id);
      const lastPage = currentNew.find((e) => e.id === lastItem.id);
      if (lastPage) updatePage(lastPage);
      setIsRefreshing(true);
    }
  }, [board, historyPast]);

  const redo = useCallback(() => {
    if (historyFuture.length > 0 && board) {
      const lastItem = historyFuture.pop()!;
      updatePages(() => lastItem.pages);
      setHistoryPast(
        (pastHistory) => [
          ...pastHistory,
          lastItem,
        ],
      );
      changeSelectedPage(lastItem.id);
      const lastPage = lastItem.pages.find((e) => e.id === lastItem.id);
      if (lastPage) updatePage(lastPage);
      setIsRefreshing(true);
    }
  }, [board, historyFuture]);

  const updateSignalWordsOnPage = useCallback((
    oldText: string,
    newText: string,
    prevState?: { [key: string]: boolean },
  ) => {
    if (!hasWritingPlan) return null;
    return Object.entries(prevState ?? signalWords).reduce((prev, [key, value]) => {
      const oldTextHad = findWord(key, oldText);
      const newTextHas = findWord(key, newText);
      if (value) {
        if (oldTextHad && !newTextHas) {
          return { ...prev, [key]: false };
        }
      }

      if (newTextHas && !value) {
        return { ...prev, [key]: true };
      }

      if (!newTextHas) {
        return { ...prev, [key]: false };
      }

      return prev;
    }, prevState ?? signalWords);
  }, [hasWritingPlan, signalWords]);

  const updateImportantWordsOnPage = useCallback((
    oldText: string,
    newText: string,
    prevState?: { [key: string]: boolean } | null,
  ) => {
    if (!hasImportantWords) return null;
    return Object.entries(prevState ?? importantWords).reduce((prev, [key, value]) => {
      const oldTextHad = findWord(key.toLowerCase(), oldText);
      const newTextHas = findWord(key.toLowerCase(), newText);

      if (value) {
        if (oldTextHad && !newTextHas) {
          return { ...prev, [key]: false };
        }
      }

      if (newTextHas && !value) {
        return { ...prev, [key]: true };
      }

      if (!newTextHas) {
        return { ...prev, [key]: false };
      }

      return prev;
    }, prevState ?? importantWords);
  }, [hasImportantWords, importantWords]);

  const updateSignalWords = useCallback((newValues: { [key: string]: boolean }) => {
    if (!hasWritingPlan || !currentDocument || !page) return;
    setSignalWords(
      (prevState) => {
        const newWords = Object.keys(prevState).reduce(
          (prev, key) => {
            const newValue = currentDocument.version.pages.some(
              (el) => {
                if (el.id === page.id || !el.meta) return false;
                const pageSignalWords = JSON.parse(el.meta as string).signalWords;
                if (!pageSignalWords) return false;
                return pageSignalWords[key] ?? false;
              },
            ) || newValues[key];
            return {
              ...prev,
              [key]: newValue,
            };
          },
          prevState,
        );
        return newWords;
      },
    );
  }, [hasWritingPlan, currentDocument, page, updateDocument]);

  const updateImportantWords = useCallback((newValues: { [key: string]: boolean }) => {
    if (!hasImportantWords || !currentDocument || !page) return;
    setImportantWords(
      (prevState) => {
        const newWords = Object.keys(prevState).reduce(
          (prev, key) => {
            const newValue = currentDocument.version.pages.some(
              (el) => {
                if (el.id === page.id || !el.meta) return false;
                const pageImportantWords = JSON.parse(el.meta as string).importantWords;
                if (!pageImportantWords) return false;
                return pageImportantWords[key] ?? false;
              },
            ) || newValues[key];
            return {
              ...prev,
              [key]: newValue,
            };
          },
          prevState,
        );
        return newWords;
      },
    );
  }, [hasImportantWords, currentDocument, page, updateDocument]);

  useEffect(() => {
    updateDocument(signalWords, importantWords);
  }, [signalWords, importantWords]);

  useEffect(() => {
    if (!hasNewWords || !hasImportantWords || !currentDocument) return;

    const newValues = currentDocument.version.pages.reduce(
      (prevState, p) => {
        if (!p.content) return prevState;

        const pageText = getTextFromPage(p);
        const changes = updateImportantWordsOnPage('', pageText);

        if (!changes) return prevState;
        updatePage({ ...p, importantWords: changes });

        const nextState = Object.keys(prevState).reduce(
          (prev, key) => {
            if (prev[key]) return prev;
            const newValue = prev[key] || changes[key];
            return { ...prev, [key]: newValue };
          },
          prevState,
        );

        return nextState;
      },
      importantWords,
    );

    updateImportantWords(newValues);

    setHasNewWords(false);
  }, [hasNewWords]);

  useEffect(() => {
    if (!hasWritingPlan || !currentDocument) return;

    const newValues = currentDocument.version.pages.reduce(
      (prevState, p) => {
        if (!p.content) return prevState;

        const pageText = getTextFromPage(p);
        const changes = updateSignalWordsOnPage('', pageText);

        if (!changes) return prevState;
        updatePage({ ...p, signalWords: changes });

        const nextState = Object.keys(prevState).reduce(
          (prev, key) => {
            if (prev[key]) return prev;
            const newValue = prev[key] || changes[key];
            return { ...prev, [key]: newValue };
          },
          prevState,
        );

        return nextState;
      },
      signalWords,
    );

    updateSignalWords(newValues);
  }, [writingPlanId]);

  const handleElChange = useCallback(
    (key: string, prop: string, value: unknown) => {
      if (!currentPage?.content) return;
      const element = currentPage.content.find((el) => el.key === key);

      let changesSignalWords = null as { [key: string]: boolean } | null;
      let changesImportantWords = null as { [key: string]: boolean } | null;

      setCurrentPage((prev) => {
        if (!prev?.content) return prev;
        const newPage = { ...prev };

        const prevText = getTextFromPage(newPage);

        newPage.content = newPage.content?.map((el) => {
          if (el.key !== key) return el;
          const newContent = {
            ...el,
            [prop]: value,
          };

          if (prop === 'originalImage') {
            (newContent as ImageBoxType).cropArea = null;
            (newContent as ImageBoxType).rotation = 0;
            (newContent as ImageBoxType).zoom = 1;
          }

          return newContent;
        });

        if (prop === 'value' && element?.type === 'TextBox') {
          newPage.wordCount = getWordCount(newPage);

          const newText = getTextFromPage(newPage);

          changesSignalWords = updateSignalWordsOnPage(prevText ?? '', newText);
          changesImportantWords = updateImportantWordsOnPage(prevText ?? '', newText);

          setTitleLength(getTextFromPage(newPage, 'TITLE').replaceAll(' ', '').length);
          newPage.signalWords = changesSignalWords;
          newPage.importantWords = changesImportantWords;
        }

        if (prop === 'value' && newPage.content?.some((el: Element) => el?.type === 'TextBox')) {
          newPage.predominantColor = getPredominantColor(
            newPage.content?.filter((el: Element) => el.type === 'TextBox') as TextBoxType[],
          );
        }

        // When load a new page from api, check if the history array
        // has some entry without this page's content
        if (newPage && ['loaded'].includes(prop) && !allContentsLoaded) {
          if (historyPast.length === 0) handleUpdateHistory(newPage);
          else fillHistoryContent(newPage);
        }

        if (!element?.loaded) return newPage;

        if ((newPage && !['ref', 'loaded', 'html'].includes(prop))) {
          setThereAreChanges(true);

          debounce(() => {
            if (changesSignalWords) {
              updateSignalWords(changesSignalWords);
            }
            if (changesImportantWords) {
              updateImportantWords(changesImportantWords);
            }
          }, 750);
        }

        return newPage;
      });
    },
    [currentPage, updateSignalWords, updateImportantWords],
  );

  const handleSelectEl = useCallback(
    (key: string | null) => {
      if (isViewMode || (selectedKey && isBusy)) return;
      if (selectedEl?.type === 'TextBox' && selectedEl.ref?.isFocused) {
        selectedEl.ref?.setIsFocused(false);
        return;
      }
      setSelectedKey(key ?? '');
      setIsBusy(false);
    },
    [selectedEl, selectedKey, isBusy],
  );

  const changeLayout = useCallback(
    (layout: string, template: Element[]) => {
      let newPage = null as (PageType | null);
      setCurrentPage((p) => {
        if (!p) return p;
        const oldContent = [...p.content];

        newPage = {
          ...p,
          template: layout,
          content: template.map((element) => {
            const newElement = {
              ...element,
              loaded: IGNORE_LOADING.includes(element.type),
              ref: null,
            };

            const oldElementContentIndex = oldContent.findIndex((el) => el.type === element.type);

            if (oldElementContentIndex >= 0) {
              newElement.value = oldContent[oldElementContentIndex].value;

              if (element.type === 'ImageBox') {
                const { originalImage } = oldContent[oldElementContentIndex] as ImageBoxType;
                (newElement as ImageBoxType).originalImage = originalImage;
                (newElement as ImageBoxType).cropArea = null;
                (newElement as ImageBoxType).rotation = 0;
                (newElement as ImageBoxType).zoom = 1;
              }

              if (element.type === 'TextBox') {
                const {
                  defaultBackground,
                  defaultColor,
                  defaultTextBackground,
                } = oldContent[oldElementContentIndex] as TextBoxType;
                (newElement as TextBoxType).defaultBackground = defaultBackground;
                (newElement as TextBoxType).defaultColor = defaultColor;
                (newElement as TextBoxType).defaultTextBackground = defaultTextBackground;

                const { defaultTextAlign } = newElement as TextBoxType;
                if (newElement.value.blocks) {
                  newElement.value.blocks = newElement.value.blocks.map(
                    (block: ContentBlock) => {
                      const alignStyleIndex = block.inlineStyleRanges.findIndex((el) => el.style.startsWith('ALIGN_'));
                      const newStyles = [...block.inlineStyleRanges];
                      if (alignStyleIndex >= 0) {
                        newStyles[alignStyleIndex].style = (defaultTextAlign ?? 'ALIGN_LEFT') as DraftInlineStyleType;
                      }
                      return { ...block, inlineStyleRanges: newStyles };
                    },
                  );
                }
              }

              oldContent.splice(oldElementContentIndex, 1);
            }

            return newElement;
          }),
        };

        return newPage;
      });

      if (newPage) {
        setThereAreChanges(true);
      }

      setIsRefreshing(true);
    },
    [],
  );

  const changeBackgroundColor = useCallback(
    (color: string) => {
      setCurrentPage((p) => {
        if (!p || p.id !== page?.id) return p;
        return { ...p, backgroundColor: color };
      });

      if (currentPage?.backgroundColor !== color) {
        setThereAreChanges(true);
      }
    },
    [currentPage],
  );

  useEffect(() => {
    if (isRefreshing) {
      debounce(() => {
        if (isPageLoaded) setIsRefreshing(false);
      }, 200, 'refreshing-disable');
    }
  }, [isRefreshing, isPageLoaded]);

  const boardLoading = useMemo(
    () => currentPage?.content?.some((el) => !el.loaded) ?? true,
    [currentPage],
  );

  return (
    <EditorContext.Provider
      value={{
        board,
        selectedPage: currentPage,
        content,
        selectedEl,
        handleSelectEl,
        handleElChange,
        isBusy,
        setIsBusy,
        isRefreshing,
        setIsRefreshing,
        boardLoading,
        changeBackgroundColor,
        changeLayout,
        selectedPageIndex,
        isViewMode,
        toggleViewMode,
        undo,
        redo,
        documentWordCount,
        setDocumentMeta,
        hasImportantWords,
        importantWords,
        hasWritingPlan,
        writingPlanId,
        signalWords,
        titleLength,
        pageElements,
        numberOfImages,
        numberOfImageSlots,
        clearData,
        updatePage,
        selectedString,
        setSelectedString,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
}

export function useEditor(): EditorContextData {
  const context = useContext(EditorContext);

  return context;
}

import { Card, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { DocumentFormatSettings } from 'src/configuration/documents';
import { DOCUMENT_TEMPLATES } from 'src/configuration/templates';

export default function TemplateCard(props: {
  template: typeof DOCUMENT_TEMPLATES[number],
}) {
  const { template } = props;
  const theme = useTheme();

  const Icon = template.svg;
  const formatSettings = DocumentFormatSettings[template.format];

  return (
    <Link
      style={{
        textDecoration: 'none',
      }}
      to={`/document/new?template=${template.documentId}`}
    >
      <Card
        elevation={16}
        sx={{
          cursor: 'pointer',
          border: 'none',
          position: 'relative',
          borderRight: `1px solid ${theme.palette.primary.light}`,
          borderBottom: `1px solid ${theme.palette.primary.light}`,
          borderLeft: `1px solid ${theme.palette.primary.light}`,
          backgroundColor: 'unset',
          transition: 'all .2s ease-in-out',

          '&:hover': {
            backgroundColor: 'white',
          },
        }}
      >
        <Icon
          style={{
            opacity: 0.2,
            color: template.color,
            position: 'absolute',
            left: '16px',
            top: '70px',
            width: '5rem',
            height: '5rem',
          }}
        />
        <div
          style={{
            position: 'absolute',
            right: '12px',
            bottom: '8px',
            display: 'flex',
            alignItems: 'end',
            color: theme.palette.grey[600],
            gap: '4px',
            fontSize: '0.8rem',
          }}
        >
          <small>{formatSettings.title}</small>
          <img
            style={{
              width: '40px',
            }}
            src={formatSettings.image}
            alt="icon"
          />
        </div>
        <div
          style={{
            borderTop: `14px solid ${template.color}`,
            padding: '2px 60px 16px 16px',
            position: 'relative',
            height: '132px',
            fontWeight: 600,
            fontSize: '18px',
          }}
        >
          <span
            style={{
              color: theme.palette.primary.main,
              fontSize: '36px',
              fontWeight: 300,
            }}
          >
            +
          </span>
          <br />
          {template.name}
        </div>
      </Card>
    </Link>
  );
}

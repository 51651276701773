import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import BlockTypeLabel from 'src/components/BlockLabel';
import { getBlockType } from 'src/configuration/blocks';
import {
  useApplyDocumentOperation,
  useHoveredBlock,
  usePreviewDocumentOperation,
  useSelectedBlock,
} from 'src/hooks/document';
import {
  Block,
  BlockPage,
  Document,
  Page,
  isBlockPage,
} from 'src/types/models';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import {
  AddPageOperation,
  ReorderPageOperation,
} from 'src/hooks/store/document/operations';
import { DocumentFormatSettings } from 'src/configuration/documents';
import { IconButton } from 'src/components/buttons';
import { PlusImg } from 'src/assets/icons';
import PageDropTarget from './PageDropTarget';
import PageThumbnail from './PageThumbnail';

/**
 * Given a page, return a Rows x Columns array in which each position
 * is either:
 * - a block reference, if a block begins at that coordinate,
 * - null, if a block occupies that position but does not fill it,
 * - undefined, if no block occupies that position (is empty space)
 *
 * This is the view-model for the block outline, which needs to handle
 * empty space.
 */
function denseGrid(page: BlockPage) {
  // Initialize a full two-dimensional array of undefined.
  const matrix: (Block | null | undefined)[][] = Array.from(
    Array(page.grid.rows),
    () => new Array(page.grid.columns).fill(undefined),
  );

  // Block X and Y are 1-indexed, but the returned grid is 0-indexed.
  page.grid.blocks.forEach((block) => {
    const x = block.x - 1;
    const y = block.y - 1;
    matrix[y][x] = block;

    // Fill nulls for the rest of the positions occupied by this block.
    // Do not overwrite the block itself.
    for (let dy = 0; dy < block.height && dy < matrix.length; dy += 1) {
      for (
        let dx = 0;
        dx < block.width && dx < matrix[y + dy].length;
        dx += 1
      ) {
        if (dx > 0 || dy > 0) {
          matrix[y + dy][x + dx] = null;
        }
      }
    }
  });

  return matrix;
}

export default function BlocksList(props: {
  document: Document;
  selectedPage?: Page | null;
  isEditable?: boolean;
}) {
  const { document, selectedPage, isEditable = false } = props;
  const { version } = document;
  const location = useLocation();
  const navigate = useNavigate();
  const previewDocumentOperation = usePreviewDocumentOperation();
  const applyDocumentOperation = useApplyDocumentOperation();

  const [selectedBlock, setSelectedBlockId] = useSelectedBlock();
  const [hoveredBlock, setHoveredBlockId] = useHoveredBlock();
  const [hoveredPage, setHoveredPage] = useState<Page | null>(null);

  // Distinguish between the actual document version and the one being
  // previewed during drag-and-drop operations. Once dropped, the global
  // store is updated, but not before.
  const [renderedVersion, setRenderedVersion] = useState(version);
  useEffect(() => {
    // Keep the rendered version in sync with the global store if the store
    // changes. This will interrupt the drag preview.
    setRenderedVersion(version);
  }, [version]);

  // Preview the change to be made if the page is dragged into a new position.
  const onPageOver = useCallback((draggedPage: Page, newIndex: number) => {
    setRenderedVersion(
      previewDocumentOperation(
        new ReorderPageOperation(
          draggedPage.documentId!,
          draggedPage.id,
          newIndex,
        ),
      )!.version,
    );
  }, []);

  const onPageDrop = useCallback(
    (draggedPage: Page, newIndex: number) => {
      applyDocumentOperation(
        new ReorderPageOperation(
          draggedPage.documentId!,
          draggedPage.id,
          newIndex,
        ),
      );

      // Navigate to the new page.
      navigate({
        ...location,
        pathname: `/document/${draggedPage.documentId}/pages/${newIndex + 1}`,
      });
    },
    [location],
  );

  // Booklets have a fixed number of pages.
  const canAddPage = isEditable && DocumentFormatSettings[document.format].canChangePageCount;

  return (
    <div>
      {renderedVersion.pages.filter(isBlockPage).map((page, position) => (
        <PageDropTarget
          key={page.id}
          index={position}
          onPageDrop={onPageDrop}
          onPageOver={onPageOver}
        >
          <Grid
            container
            sx={{
              paddingBottom: 2,
            }}
          >
            <Grid item container flexDirection="column" xs={8}>
              {denseGrid(page).map((row, i) => row.map((block, j) => (block ? (
                <Grid
                  item
                  key={block.id}
                  flexGrow={1}
                  onClick={() => setSelectedBlockId(block.id)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setSelectedBlockId(block.id);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  style={{
                    cursor: 'pointer',
                    paddingBottom: '.5em',
                  }}
                  onMouseEnter={() => setHoveredBlockId(block.id)}
                  onMouseLeave={() => setHoveredBlockId(null)}
                >
                  <BlockTypeLabel
                    blockType={getBlockType(block)}
                    isBold={isEditable && selectedBlock?.id === block.id}
                    isDarkened={hoveredBlock?.id === block.id}
                  />
                </Grid>
              ) : (
              // Empty or occupied space.
              // eslint-disable-next-line react/no-array-index-key
                <Grid key={`${i}-${j}`} item flexGrow={1} />
              ))))}
            </Grid>

            {DocumentFormatSettings[document.format].pageNavigationStyle
              !== 'continuous' && (
              // Show the page thumbnail when pages are distinct.
              <>
                <Grid
                  item
                  xs="auto"
                  sx={{
                    color:
                      page.id === selectedPage?.id
                        ? 'primary.main'
                        : 'primary.light',
                  }}
                >
                  <Link
                    to={{
                      ...location,
                      pathname: `/document/${page.documentId}/pages/${
                        position + 1
                      }`,
                    }}
                    replace
                    style={{
                      textDecoration: 'none',
                    }}
                    onMouseEnter={() => setHoveredPage(page)}
                    onMouseLeave={() => setHoveredPage(null)}
                  >
                    <PageThumbnail
                      page={page}
                      format={document.format}
                      isSelected={page.id === selectedPage?.id}
                      isHovered={page.id === hoveredPage?.id}
                    />
                  </Link>
                </Grid>
                <Grid item xs={1}>
                  <small
                    style={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                      marginLeft: '6px',
                    }}
                  >
                    {page.position + 1}
                  </small>
                </Grid>
              </>
            )}
          </Grid>
        </PageDropTarget>
      ))}

      {canAddPage && (
        <IconButton
          src={PlusImg}
          label="Add Page"
          onClick={() => {
            applyDocumentOperation(new AddPageOperation(document.id));
            navigate({
              ...location,
              pathname: `/document/${document.id}/pages/${
                version.pages.length + 1
              }`,
            });
          }}
          alt="+"
          style={{
            border: 'none',
          }}
        />
      )}
    </div>
  );
}

import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { PLACEHOLDERS } from 'src/assets/images/placeholders';
import { DocumentFormatSettings } from 'src/configuration/documents';
import { useDocument } from 'src/hooks/document';
import UploadDialog from '../../../../views/document-v2/dialogs/UploadDialog';
import ImageEditorContainer from './ImageEditorContainer';
import { ImageProps } from './types';
import { stringCharCodeSum } from './utils';

function Image({
  isEditable,
  onChange,
  block,
  blockType,
  showPlaceholder = false,
  isSelected,
  blockRef,
}: ImageProps) {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const placeholderIndex = useMemo(
    () => {
      const seed = stringCharCodeSum(block.documentId);
      return (block.x + block.y + seed) % PLACEHOLDERS.length;
    },
    [block.id],
  );

  const document = useDocument(block.documentId);
  if (!document) {
    return null;
  }

  const formatSettings = DocumentFormatSettings[document.format];
  const gridStyle = formatSettings.grid.style;

  let { imageUrl } = block.properties;
  if (showPlaceholder && !imageUrl) {
    imageUrl = PLACEHOLDERS[placeholderIndex];
  }

  if (!blockType.configuration.hasImage) {
    return null;
  }

  return (
    <Box
      sx={[
        {
          width: '100%',
        },
        gridStyle === 'fluid' && {
          // In a fluid grid, blocks can set their own height, just as text
          // boxes do. But, unlike text boxes, images scale to any height and
          // can be resized. This property is the user-set height.
          height: '100%', // block.properties.preferredHeight,
          position: 'static',
        },
        gridStyle === 'fixed' && {
          height: '100%',
          position: 'absolute',
        },
      ]}
    >
      {!block.properties.imageUrl && (
        <Box
          onClick={() => {
            if (isEditable && !block.properties.imageUrl && showPlaceholder) {
              setIsUploadDialogOpen(true);
            }
          }}
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            backgroundImage: `url('${imageUrl}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      )}

      {block.properties.imageUrl && (
        <ImageEditorContainer
          blockRef={blockRef}
          block={block}
          isSelected={!!isSelected}
          onChange={onChange}
          isEditable={isEditable}
        />
      )}

      {isEditable && onChange && (
        <UploadDialog
          isOpen={isUploadDialogOpen}
          onClose={() => setIsUploadDialogOpen(false)}
          id={`image-${block.id}`}
          onUpload={(url, orientation) => {
            onChange({
              imageUrl: url,
              orientation,
              positionX: 0,
              positionY: 0,
              zoom: 1.0,
              rotation: 0,
            });
          }}
          documentId={block.documentId}
        />
      )}
    </Box>
  );
}

export default Image;

import { Box } from '@mui/system';
import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import PlaceholderImage, { PLACEHOLDERS } from '../../../assets/images/placeholders';
import { useEditor } from '../../../hooks/useEditor';
import { stringCharCodeSum } from './utils';
import Loader from '../../Artboard/Loader';
import { ImageBoxContainer } from './styles';
import { useDocumentStore } from '../../../hooks/zustand/documents';
import { ImageBoxProps } from './types';
import CropComponent from './CropComponent';

function ImageBox(props: ImageBoxProps) {
  const {
    name,
    originalImage,
    width,
    height,
    x,
    y,
    noImagePosition,
    handleUploadImage,
    boardDims,
  } = props;

  const currentDocument = useDocumentStore((state) => state.currentDocument);

  const {
    handleElChange,
    selectedEl,
    handleSelectEl,
    isViewMode,
    content,
    selectedPageIndex,
    setIsBusy,
  } = useEditor();

  const [isSettingUp, setIsSettingUp] = useState(!!originalImage);

  const [isCropping, setIsCropping] = useState(true);
  const [isRotating, setIsRotating] = useState(false);

  const [croppedImage, setCroppedImage] = useState<string>('');

  const [loadStep, setLoadStep] = useState(0);

  const placeholderIndex = useMemo(
    () => {
      if (!currentDocument) return 0;
      const seed = stringCharCodeSum(currentDocument.id);
      const contentIndex = content.findIndex((el) => el.key === name);
      return (selectedPageIndex + contentIndex + seed) % PLACEHOLDERS.length;
    },
    [currentDocument],
  );

  const onResize = useCallback(() => {
    setIsBusy(false);
    setLoadStep(1);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (isRotating) {
      setTimeout(() => {
        setIsCropping(false);
        setIsRotating(false);
      }, 200);
    }
  }, [isRotating]);

  const handleSelectImage = () => {
    handleSelectEl(name);
    setIsSettingUp(false);
  };

  useEffect(() => {
    if (!originalImage) {
      handleElChange(name, 'loaded', true);
    }
  }, [originalImage]);

  const renderImageComponent = () => (
    <>
      {originalImage && (
        <CropComponent
          {...props}
          loadStep={loadStep}
          setLoadStep={setLoadStep}
          isCropping={isCropping}
          setIsCropping={setIsCropping}
          isRotating={isRotating}
          setIsRotating={setIsRotating}
          isSettingUp={isSettingUp}
          setIsSettingUp={setIsSettingUp}
          onImageLoadComplete={setCroppedImage}
        />
      )}
      {(!isCropping || isRotating) && (
        <Box
          onClick={handleSelectImage}
          style={{
            position: 'absolute', height: '100%', width: '100%', overflow: 'hidden',
          }}
        >
          <img
            style={loadStep !== 2 ? { display: 'none' } : {}}
            src={croppedImage}
            alt="Loading"
            height={height}
            width={width}
          />
        </Box>
      )}
      {loadStep !== 2 && (
        <div style={{ position: 'relative', height: '100%' }}>
          <div style={{
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -70%)',
          }}
          >
            <Loader animate size={Math.min(height, width) / 2} />
          </div>
        </div>
      )}
    </>
  );

  return (
    <ImageBoxContainer
      className="imagebox-container"
      id={name}
      hasImage={!!originalImage}
      noImagePosition={noImagePosition}
      selected={selectedEl?.key === name}
      isViewMode={isViewMode}
      onClick={() => !originalImage && !isViewMode && handleUploadImage(name)}
      dims={{
        height: `${height}px !important`,
        width: `${width}px !important`,
        left: x,
        top: y,
        offsetX: x - ((boardDims.width - width) / 2),
      }}
    >
      {originalImage ? renderImageComponent() : !isViewMode && (
        <Box className="no-image-container">
          <PlaceholderImage index={placeholderIndex} height={height} width={width} />
        </Box>
      )}
    </ImageBoxContainer>
  );
}

export default ImageBox;

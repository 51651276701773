import {
  Document,
} from '../../../../types/models';
import SynchronousDocumentOperation from './synchronous';

export default class SetDocumentNameOperation extends SynchronousDocumentOperation {
  readonly type = 'set-document-name';

  name: string;

  constructor(documentId: string, name: string) {
    super(documentId);
    this.name = name;
  }

  apply(document: Document): Document {
    return {
      ...document,
      name: this.name,
    };
  }
}

import { useUserStore } from './zustand/user';

/**
 * Return the currently logged in user, or a "default user", which is the
 * user that is not logged in. Is `undefined` if the user state is loading.
 * This user may be anonymous if requestAnonymousUser has been called.
 */
export function useAuthenticatedUser() {
  const userHasLoaded = useUserStore((state) => state.hasLoaded);
  const user = useUserStore((state) => state.user);

  if (!userHasLoaded) {
    return undefined;
  }

  if (!user.id) {
    return null;
  }

  // Assert that the user has an ID in the type.
  return user as typeof user & { id: string };
}

/*
This file is a central location for tracking user activity and sending
it to different destinations for analysis.

All analytic data (that is, data that is not part of the application
database but is, instead, collected for the purpose of business
decision-making) should move through this file. It ensures that
there is one clear place where all of our data collection is documented.
*/
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserStore } from 'src/hooks/zustand/user';
import { WebApplicationEvent } from 'src/types/WebApplicationEvent';

const {
  REACT_APP_INBOUND_DATA_COLLECTOR_URL,
} = process.env;

let timezone: string = '';
try {
  // This next line is supported in most modern browsers.
  // https://caniuse.com/?search=DateTimeFormat
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
} catch { }

// The event sequence is a client-maintained counter that records the order in
// which events are sent. Since events might be generated, sent, and received
// in the same moment or out of order, this counter can clarify their intended
// order. It is reset every time the page reload, so it must be used in
// combination with another timestamp to globally order events.
let eventSequence = 0;

// A data type that can be used to represent a JSON-serializable value.
type ExtraProperties = {
  [key: string]: ExtraProperties | ExtraProperties[] | string | number | boolean | null;
};

/**
 * Record events to local storage when an event is posted.
 * This does two things. One, it throttles events by placing them in a queue
 * instead of sending them as rapidly as they occur. Two, it allows for events
 * to be stored when the user is offline or when an event occurs as the user is
 * navigating away from the page.
 */
function trackEvent(
  user: {
    // The user's role informs whether we track certain events. In general,
    // student data is not sent to external systems.
    role?: string;
  },
  event: Omit<WebApplicationEvent, 'clientEventSequence' | 'sentAt'>,
  extraProperties?: ExtraProperties,
) {
  try {
    const pendingEvents = JSON.parse(localStorage.getItem('pendingEvents') || '[]');

    eventSequence += 1;
    pendingEvents.push({
      ...event,
      extraProperties,
      clientEventSequence: eventSequence,
    });
    localStorage.setItem('pendingEvents', JSON.stringify(pendingEvents));

    // In addition to posting the event to our own servers, alert the
    // Google Tag Manager. This allows other systems to respond.
    // Do not send page views manually. GTM will track page views automatically.
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    if (user.role !== 'student' && event.eventName !== 'page-viewed') {
      window.dataLayer.push({
        event: event.eventName.replace('-', '_'),
        ...(extraProperties || {}),
      });
    }
  } catch (error) { }
}

/**
 * Use this hook to track events from a component.
 */
export function useTrackEvent() {
  const location = useLocation();
  const user = useUserStore((state) => state.user);
  const sessionId = useUserStore((state) => state.sessionId);

  return useCallback(
    (
      eventName: WebApplicationEvent['eventName'],
      extraProperties?: ExtraProperties,
    ) => {
      trackEvent(
        user,
        {
          type: 'track',
          eventName,
          createdAt: new Date().toISOString(),
          anonymousId: sessionId,
          user: user.id
            ? {
              id: user.id,
              masqueradingAdminId: user.masqueradingAdminId,
            }
            : null,
          context: {
            page: {
              path: location.pathname,
              domain: window.location.origin,
              protocol: window.location.protocol,
              search: location.search,
              title: window.document.title,
            },
            app: {
              name: 'write-app',
              environment: process.env.NODE_ENV,
            },
            campaign: {},
            locale: window.navigator.language,
            referrer: {
              url: window.document.referrer,
            },
            screen: {
              width: window.screen.width,
              height: window.screen.height,
              density: window.devicePixelRatio,
            },
            timezone,
            userAgent: window.navigator.userAgent,
          },
        },
        extraProperties,
      );
    },
    [location, user, sessionId, sessionId],
  );
}

// Check for queued events every second. If there are events, send them to the
// data collector and clear local storage. Only allow one request to be in
// flight at a time. If the request succeeds, clear the queue.
let queueInterval = 1000;
function processEventQueue() {
  if (!REACT_APP_INBOUND_DATA_COLLECTOR_URL) {
    return;
  }

  const pendingEventsJSON = localStorage.getItem('pendingEvents');
  if (pendingEventsJSON) {
    const pendingEvents = JSON.parse(pendingEventsJSON);
    const sentAt = new Date().toISOString();
    fetch(`${REACT_APP_INBOUND_DATA_COLLECTOR_URL}/events/web-application-event-v1`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pendingEvents.map((event: Omit<WebApplicationEvent, 'sentAt'>) => ({
        ...event,
        sentAt,
      }))),
    }).then(() => {
      localStorage.removeItem('pendingEvents');
      queueInterval = 1000;
      setTimeout(processEventQueue, queueInterval);
    }).catch(() => {
      // Back off processing the queue if there is a failure.
      queueInterval *= 2;
      setTimeout(processEventQueue, queueInterval);
    });
  } else {
    setTimeout(processEventQueue, queueInterval);
  }
}
setTimeout(processEventQueue, queueInterval);

export function setUserProperties(properties: Record<string, string | undefined>) {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push(properties);
}

// The following declaration prevents the compiler from complaining that
// `dataLayer` is not a property of `window`.
declare global {
  interface Window {
    dataLayer?: any[];
    lintrk?: (command: string, data: any) => void;
  }
}

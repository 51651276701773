import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Align from './Align';
import Edit from './Edit';
import New from './New';

function DocumentScreen() {
  const { id: documentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  if (documentId === 'new') {
    return (
      <New />
    );
  }

  if (documentId === 'align') {
    return (
      <Align />
    );
  }

  if (location.pathname === '/try-it') {
    return (
      <Edit
        documentId="try-it"
        isPublishedView={location.pathname.startsWith('/publish/')}
      />
    );
  }

  if (documentId) {
    return (
      <Edit
        documentId={documentId}
        isPublishedView={location.pathname.startsWith('/publish/')}
      />
    );
  }

  navigate('/gallery');
  return null;
}

export default DocumentScreen;

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as AssignmentAPI from 'src/api/Assignments';
import * as AssistantAPI from 'src/api/Assistant';
import QuillAnimated from 'src/assets/icons/animated/quill';
import { DOCUMENT_TEMPLATES } from 'src/configuration/templates';
import {
  SetBlockOperation,
  SetDocumentNameOperation,
  SetImportantWordsOperation,
} from 'src/hooks/store/document/operations';
import { useResourceStore } from 'src/hooks/store/resources';
import { useAuthenticatedUser } from 'src/hooks/user';
import { Assignment, Document } from 'src/types/models';
import {
  useApplyDocumentOperation,
  useCreateDocumentFromTemplate,
} from '../../hooks/document';
import { DocumentFormat, isValidDocumentFormat } from '../../types/DocumentSettings';

function postAssignment(assignment: Assignment) {
  return AssignmentAPI.update(assignment.id, assignment);
}

async function createAlignedAssignment(document: Document, user: {
  id: string,
  name: string,
  surname: string,
}): Promise<Assignment> {
  const { data: { data } } = await AssignmentAPI.create({
    name: document.name,
    document_id: document.id,
  });

  return {
    // Reusing the document ID represents a one-to-one relationship.
    id: data.id,
    name: '',
    instructions: '',
    classrooms: [],
    students: [],
    teacher: user,
    baseDocument: document,
    createdAt: new Date(),
    updatedAt: new Date(),
    notes: [],
  };
}

export default function Align() {
  const navigate = useNavigate();
  const createDocumentFromTemplate = useCreateDocumentFromTemplate();
  const setAssignment = useResourceStore((state) => state.setAssignment);
  const apply = useApplyDocumentOperation();
  const user = useAuthenticatedUser();
  const [isCreating, setIsCreating] = React.useState(false);
  const search = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const topic = search.get('topic') || '';
    const formatParam = search.get('format');
    const format = isValidDocumentFormat(formatParam) ? formatParam : DocumentFormat.DOC;
    const gradeLevel = search.get('gradeLevel');
    const relevantTemplates = DOCUMENT_TEMPLATES.filter(
      (t) => t.format === format,
    );

    // Document will be undefined until it is created, at which point,
    // redirect the user to the first page.
    if (!isCreating) {
      setIsCreating(true);
      // Run the assistant to generate parts of the document.
      const create = async () => {
        // If the user uploaded an image, we need to fetch it.
        const objectDataUrl = search.get('objectDataUrl');
        let file: Blob | undefined;
        if (objectDataUrl) {
          file = (await (await fetch(objectDataUrl)).blob());
        }

        const partialTemplateSelection = AssistantAPI.selectDocumentTemplate(
          topic,
          relevantTemplates,
          file,
        );

        // The result of the assistant streams in. At each step, the whole
        // JSON object is returned, and we will act on each part as it becomes
        // available.
        let template: typeof DOCUMENT_TEMPLATES[0] | undefined;
        let document: Document | undefined;
        let assignment: Assignment | undefined;
        let text = '';
        for await (const result of partialTemplateSelection) {
          text = result.text;

          if (!template) {
            // Check to see if we have a complete template.
            template = relevantTemplates.find(
              (t) => t.documentId === result.templateId,
            );
            if (template) {
              // Once the template becomes available, create the document.
              document = await createDocumentFromTemplate('Untitled', template.documentId);

              // The document may have been created for an anonymous user,
              // in which case, use the user that was created with the document.
              assignment = await createAlignedAssignment(document, user || {
                id: document.user?.id || '',
                name: '',
                surname: '',
              });

              // This will navigate away from the quill loading animation,
              // but the rest of the function will continue executing in the
              // background.
              navigate(`/document/${document.id}?assignment=${assignment.id}`);
            }
          }

          if (document) {
            if (result.title) {
              apply(new SetDocumentNameOperation(document.id, result.title));

              if (assignment) {
                assignment.name = result.title;
              }
            }

            if (result.writingPrompt) {
              if (assignment) {
                assignment.instructions = result.writingPrompt;
                setAssignment(assignment);
              }
            }
          }
        }

        if (!(document && assignment)) {
          // This is unlikely, and would constitute an error.
          navigate('/');
          return;
        }

        postAssignment(assignment);

        // At this point, the quill loading screen will disappear in favor
        // of the document editor, but we can continue queuing up modifications
        // to the document.
        AssistantAPI.suggestImportantWordsForPrompt(
          assignment?.instructions || 'Free write',
          text,
          template?.writingPlanId || 'free-write',
          gradeLevel || '6th',
        ).then((response) => {
          const { data } = response.data;
          apply(new SetImportantWordsOperation(
            document!.id,
            data[0].words,
          ));
        });

        const partialStarters = AssistantAPI.generateSentenceCompletions(
          text,
          document,
          assignment,
        );
        for await (const starters of partialStarters) {
          for (const { blockId, starter } of starters) {
            apply(new SetBlockOperation(
              document.id,
              {
                id: blockId,
                properties: {
                  placeholder: starter,
                },
              },
            ));
          }
        }
      };
      create();
    }
  }, [isCreating, user]);

  // Center quill on screen, about 20% of the width
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <div style={{ width: '20%', paddingBottom: '15vh' }}>
        <QuillAnimated />
      </div>
    </div>
  );
}

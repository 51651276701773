import React, { useMemo, useState } from 'react';
import { CheckedOrangeImg } from 'src/assets/icons';
import { ImportantWordsImg } from 'src/assets/images';
import { useUserStore } from 'src/hooks/zustand/user';
import { Document, isBlockPage } from 'src/types/models';
import { Chip } from '@mui/material';
import ImportantWordsDialog from '../../dialogs/ImportantWordsSelectionDialog';
import * as actions from '../../actions';
import FeedbackCard, { CommonFeedbackCardProps } from '.';

function ImportantWordsCard(props: CommonFeedbackCardProps & {
  document: Document;
  isEditable?: boolean;
}) {
  const {
    document,
    isEditable = false,
  } = props;
  const user = useUserStore((state) => state.user);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const importantWords = (document.meta.importantWords || []) as string[];
  const hasImportantWords = Array.isArray(importantWords) && importantWords.length;

  // Important words can be set by teachers who own the document.
  const canSet = isEditable && user.id === document.user?.id && user.role === 'teacher';

  const importantWordsPresence = useMemo(() => {
    const presence: Record<string, boolean> = {};

    if (hasImportantWords && importantWords.length) {
      const allBlocks = document.version.pages.filter(
        isBlockPage,
      ).flatMap(
        (page) => page.grid.blocks,
      );
      const text = allBlocks.map((b) => b.properties.plainText || '').join(' ').toLowerCase();

      importantWords.forEach((word) => {
        presence[word] = text.includes(word.toLowerCase());
      });
    }
    return presence;
  }, [document.version.pages, importantWords]);

  if (!canSet && !hasImportantWords) {
    return null;
  }

  const renderWordDetector = () => {
    if (hasImportantWords && importantWords) {
      return (
        <div className="important-words-content">
          <div className="words-wrapper">
            {importantWords.map((word) => (
              <Chip
                key={word}
                label={word}
                icon={importantWordsPresence[word] ? <img src={CheckedOrangeImg} alt="Checked" /> : undefined}
              />
            ))}
          </div>
          <div className="signal-words-description" />
        </div>
      );
    }

    return null;
  };

  return (
    <FeedbackCard
      {...props}
      accentColor="#E88F25"
      title="Important Words"
      icon={ImportantWordsImg}
    >
      {renderWordDetector()}

      {canSet && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          onClick={() => {
            setIsDialogOpen(true);
          }}
          style={{
            color: '#163567',
            textDecoration: 'none',
            fontWeight: 'bold',
            fontSize: '.8rem',
            display: 'inline-block',
            margin: '8px 4px',
          }}
        >
          Edit
        </a>
      )}
      <ImportantWordsDialog
        open={isDialogOpen}
        initialImportantWords={importantWords.join(', ')}
        onAdd={(words) => {
          let wordList: string[];
          if (words.trim().length) {
            wordList = words.split(/,\s*/);
          } else {
            wordList = [];
          }
          actions.saveDocument(document.id, {
            meta: {
              ...document.meta,
              importantWords: wordList,
            },
          });
          setIsDialogOpen(false);
        }}
        onClose={() => setIsDialogOpen(false)}
      />
    </FeedbackCard>
  );
}

export default ImportantWordsCard;

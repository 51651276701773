import {
  Theme, useMediaQuery, useTheme, Box, IconButton, Paper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InviteCoteacherDialog from 'src/components/dialogs/InviteCoteacherDialog';
import { GoogleClassroomImg } from '../../assets/icons';
import { Button } from '../../components/buttons';
import ExtendableDialog from '../../components/ExtendableDialog';
import GalleryTopBar from '../../components/GalleryTopBar';
import { Description } from '../../components/typography';
import StudentsTable from '../../components/StudentsTable';
import TeachersTable from '../../components/TeachersTable';
import ClassroomPageContainer from './styles';
import DeleteConfirmationDialog from '../../components/dialogs/DeleteConfirmationDialog';
import { Chevron } from '../../assets/icons/variantComponents';
import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg';
import * as ClassroomAPI from '../../api/Classrooms';
import { useClassroomStore } from '../../hooks/zustand/classrooms';
import { lang } from '../../lang';
import { useUserStore } from '../../hooks/zustand/user';
import JoinCodeDialog from '../../components/dialogs/CreateClassroomDialog/JoinCodeDialog';

function ClassroomPage() {
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);
  const [isJoinClassDialogOpen, setIsJoinClassDialogOpen] = useState(false);
  const [isCoteacherInvitationDialogOpen, setIsCoteacherInvitationDialogOpen] = useState(false);
  const [coteacherInvitationId, setCoteacherInvitationId] = useState('');

  const { cid } = useParams();

  const tabletMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const theme = useTheme();

  const [syncing, setSyncing] = useState(false);

  const navigate = useNavigate();

  const getClassroomData = useClassroomStore((state) => state.getClassroomData);
  const data = useClassroomStore((state) => state.currentClassroom);
  const setCurrentClassroom = useClassroomStore((state) => state.setCurrentClassroom);
  const user = useUserStore((state) => state.user);

  const loadClassroom = async () => {
    const response = await getClassroomData(cid!);
    if (!response) navigate('/gallery', { state: { tab: 2 } });
  };

  useEffect(() => {
    if (cid && user.role === 'teacher') loadClassroom();

    return () => {
      setCurrentClassroom(undefined);
    };
  }, [cid]);

  const handleDeleteClassroom = async () => {
    try {
      await ClassroomAPI.deleteClassroom(data?.id!);
      navigate('/gallery', { state: { tab: 2 } });
    } catch {
      // This action doesn't have any specific error exibition
    }
  };

  const handleGoogleSync = async () => {
    setSyncing(true);
    try {
      await ClassroomAPI.syncWithGoogle(data?.id!);
      setTimeout(() => {
        loadClassroom();
      }, 2000);
    } finally {
      setSyncing(false);
    }
  };

  const canInviteCoteachers = !!(user.id && data?.owner_teacher_encoded_ids.includes(user.id));

  return (
    <>
      <GalleryTopBar tab="classrooms" />
      <ClassroomPageContainer>
        {tabletMQ && !mobileMQ && (
          <div>
            <span
              className="classroom-back-button"
              role="button"
              tabIndex={-1}
              onClick={() => { navigate('/gallery', { state: { tab: 2 } }); }}
              onKeyDown={() => { navigate('/gallery', { state: { tab: 2 } }); }}
            >
              <BackArrow height={16} width={16} stroke="#999999" />
              <Description size="small">{lang('classroom.classroom_page.go_back')}</Description>
            </span>
          </div>
        )}
        {!tabletMQ && (
          <div className="left-column">
            <div>
              <span
                className="classroom-back-button"
                role="button"
                tabIndex={-1}
                onClick={() => { navigate('/gallery', { state: { tab: 2 } }); }}
                onKeyDown={() => { navigate('/gallery', { state: { tab: 2 } }); }}
              >
                <BackArrow height={16} width={16} stroke="#999999" />
                <Description size="small">{lang('classroom.classroom_page.go_back')}</Description>
              </span>
            </div>
            <Paper
              style={{
                border: `1px solid ${theme.palette.primary.main}`,
                boxShadow: `4px 4px 0 ${theme.palette.primary.main}`,
                borderRadius: '0px',
              }}
            >
              <div className="classroom-name-info">
                <Description className="classroom-type">
                  {data?.provider === 'google' ? (
                    <>
                      <img src={GoogleClassroomImg} alt="google classroom" />
                      {lang('general.provider.google')}
                    </>
                  ) : lang('general.provider.pressto')}
                </Description>
                <Description className="classroom-name">
                  {data?.name}
                </Description>
              </div>
            </Paper>
            {data?.provider === 'google' ? (
              <Button
                onClick={handleGoogleSync}
                large
                outline
                label={lang(`classroom.classroom_page.sync${syncing ? 'ing' : ''}`)}
                style={{ marginTop: '10px' }}
              />
            ) : (
              <Paper
                style={{
                  border: `1px solid ${theme.palette.primary.main}`,
                  boxShadow: `4px 4px 0 ${theme.palette.primary.main}`,
                  borderRadius: '0px',
                  borderTop: 'none',
                }}
              >
                <div className="classroom-name-info">
                  <Description className="classroom-type">
                    {lang('classroom.classroom_page.joincode')}
                  </Description>
                  <div style={{ display: 'flex', gap: '1.5rem' }}>
                    <Description className="classroom-code">
                      {data?.join_code}
                    </Description>
                  </div>
                </div>
              </Paper>
            )}
            <Box
              className="classroom-delete-button"
              onClick={() => setIsDeleteConfirmationDialogOpen(true)}
            >
              {lang('classroom.classroom_page.delete')}
            </Box>
          </div>
        )}
        <div className="right-column">
          {data?.teachers && (
            <TeachersTable
              classroomId={data.id}
              data={data.teachers}
              provider={data.provider}
              setIsCoteacherInvitationDialogOpen={setIsCoteacherInvitationDialogOpen}
              setCoteacherInvitationId={setCoteacherInvitationId}
              canInviteCoteachers={canInviteCoteachers}
            />
          )}
          {data?.students && (
            <StudentsTable
              data={data.students}
              provider={data.provider}
              setIsJoinClassDialogOpen={() => setIsJoinClassDialogOpen(true)}
            />
          )}
        </div>
        {tabletMQ && (
          <ExtendableDialog>
            {({ extended, toggle }) => (
              <div className="classroom-name-info">
                <Description className="classroom-type">
                  {data?.provider === 'google' ? (
                    <>
                      <img src={GoogleClassroomImg} alt="google classroom" />
                      {lang('general.provider.google')}
                    </>
                  ) : lang('general.provider.pressto')}
                </Description>
                <Description className="classroom-name">
                  {data?.name}
                </Description>
                <IconButton onClick={toggle} style={{ position: 'absolute', top: '2.5rem', right: '2.5rem' }}>
                  <Chevron
                    height="2rem"
                    width="2rem"
                    stroke="#163567"
                    direction={extended ? 'up' : 'down'}
                  />
                </IconButton>
                {extended && (
                  <div className="extended-container">
                    {data?.provider === 'google' ? (
                      <Button
                        onClick={handleGoogleSync}
                        large
                        outline
                        disabled={syncing}
                        label={lang(`classroom.classroom_page.sync${syncing ? 'ing' : ''}`)}
                      />
                    ) : (
                      <div style={{ width: '100%' }}>
                        <Description className="classroom-type">
                          {lang('classroom.classroom_page.joincode')}
                        </Description>
                        <div style={{ display: 'flex', gap: '1.5rem' }}>
                          <Description className="classroom-code">
                            {data?.join_code}
                          </Description>
                        </div>
                      </div>
                    )}
                    <Box
                      className="classroom-delete-button"
                      onClick={() => setIsDeleteConfirmationDialogOpen(true)}
                    >
                      {lang('classroom.classroom_page.delete')}
                    </Box>
                  </div>
                )}
              </div>
            )}
          </ExtendableDialog>
        )}
      </ClassroomPageContainer>
      <DeleteConfirmationDialog
        title={lang('classroom.classroom_page.title')}
        description={lang('classroom.classroom_page.subtitle', { name: data?.name })}
        onSubmit={handleDeleteClassroom}
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
      {data && (
        <JoinCodeDialog
          onClose={() => setIsJoinClassDialogOpen(false)}
          isOpen={isJoinClassDialogOpen}
          code={data.join_code!}
          buttonLabel={lang('classroom.create_classroom_dialog.step_2.submit.from_classroom')}
          classroomId={data.id}
          showBottomAction={false}
        />
      )}
      {data && (
        <InviteCoteacherDialog
          onClose={() => setIsCoteacherInvitationDialogOpen(false)}
          isOpen={isCoteacherInvitationDialogOpen}
          coteacherInvitationId={coteacherInvitationId}
        />
      )}
    </>
  );
}

export default ClassroomPage;

import AssignmentDuplicate from 'src/views/assignment/Duplicate';
import AssignmentReview from 'src/views/assignment/Review';
import AssignmentAssistantScreen from 'src/views/lesson';
import PublishedPressto from 'src/views/publish';
import CoteacherInvitation from 'src/views/register/CoteacherInvitation';
import JoinCode from 'src/views/register/JoinCode';
import AssignmentScreen from '../views/assignment';
import ClassroomPage from '../views/classroom';
import GoogleClassroomRedirect from '../views/classroom/GoogleRedirect';
import StudentWorks from '../views/classroom/StudentWorks';
import DocumentScreenV1 from '../views/document-v1';
import NewDocumentScreenV1 from '../views/document-v1/CreateDocument';
import DocumentScreenV2 from '../views/document-v2';
import GalleryScreen from '../views/gallery';
import ProfileScreen from '../views/profile';
import SchoolForm from '../views/register/SchoolForm';

const PRIVATE_ROUTES = [
  {
    path: '/document/:id/',
    name: 'Document',
    component: DocumentScreenV2,
  },
  {
    path: '/document/:id/pages/:pageIndex',
    name: 'Document Page',
    component: DocumentScreenV2,
  },
  {
    path: '/document-l/new',
    name: 'New Layout Document',
    component: NewDocumentScreenV1,
  },
  {
    path: '/document-l/:id',
    name: 'Layout Document',
    component: DocumentScreenV1,
  },
  {
    path: '/publish-l/:id',
    name: 'Published Pressto',
    component: PublishedPressto,
  },
  {
    path: '/gallery/:tab?',
    name: 'Gallery',
    component: GalleryScreen,
  },
  {
    path: '/assignment/:id',
    name: 'Assignment',
    component: AssignmentScreen,
  },
  {
    path: '/assignment/:id/review',
    name: 'Assignment Review',
    component: AssignmentReview,
  },
  {
    path: '/assignment/:id/duplicate',
    name: 'Assignment',
    component: AssignmentDuplicate,
  },
  {
    // This is the URL used for sharing assignments with others. It may become
    // more distinct from the assignment route in the future, but for now it
    // shares the same code as AssignmentScreen.
    path: '/templates/:id',
    name: 'Assignment Template',
    component: AssignmentScreen,
  },
  {
    path: '/classroom/:cid',
    name: 'Classroom',
    component: ClassroomPage,
  },
  {
    path: '/classroom/:cid/student/:id',
    name: 'Student Profile',
    component: ProfileScreen,
  },
  {
    path: '/classroom/:cid/student-work/:id',
    name: 'Student Works',
    component: StudentWorks,
  },
  {
    path: '/assistant/',
    name: 'Lesson Plan',
    component: AssignmentAssistantScreen,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileScreen,
  },
  {
    path: '/publish/:id',
    name: 'Published Document',
    component: DocumentScreenV2,
  },
  {
    path: '/school-information-form',
    name: 'School Information Form',
    component: SchoolForm,
  },
  {
    path: '/classrooms/providers/oauth/google',
    name: 'Google Classroom Redirect',
    component: GoogleClassroomRedirect,
  },
  {
    path: '/code/:joinCode',
    name: 'Join Class with Code',
    component: JoinCode,
  },
  {
    path: '/code',
    name: 'Join Class without Code',
    component: JoinCode,
  },
  {
    path: '/coteacher-invitation/:coteacherInvitationId',
    name: 'Co-Teacher Invitation',
    component: CoteacherInvitation,
  },
];

export default PRIVATE_ROUTES;

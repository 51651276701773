import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import BlocksItem from 'src/components/BlockType';
import { getBlockType } from 'src/configuration/blocks';
import { BlockTypesEnum, WritingPlan } from 'src/types/models';
import { DialogCloseImg } from '../../../assets/icons';
import { lang } from '../../../lang';
import { DialogProps } from '../types';
import { Container, Title } from './styles';

export default function SelectBlockTypeDialog({
  isOpen,
  onClose,
  onSelect,
  availablePlans,
}: DialogProps & {
  onSelect: (blockTypeId: BlockTypesEnum) => void
  availablePlans: WritingPlan[]
}) {
  // TODO: Add basic plan (text + image).
  return (
    <Container
      onClose={onClose}
      open={isOpen}
    >
      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
      <Grid container sx={{ width: '100%', height: '100%' }} flexWrap="nowrap">
        <Grid item sx={{ py: 4, px: 6 }} sm="auto">
          <Title>
            <div>
              <p>{lang('document.choose_blocktype_dialog.title.line_1')}</p>
              <p>{lang('document.choose_blocktype_dialog.title.line_2')}</p>
            </div>
          </Title>
        </Grid>

        <Grid
          item
          sx={{
            padding: 4, overflowY: 'auto', py: 6, paddingRight: 16,
          }}
        >
          <div>
            {availablePlans.map((plan) => (
              <div key={plan.id}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: 2,
                    marginTop: 4,
                  }}
                >
                  {plan.title}
                </Typography>
                <Box sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  columnGap: 3,
                  rowGap: 1,
                  alignItems: 'flex-start',
                }}
                >
                  {plan.blockTypeIds.map(getBlockType).map((blockType) => (
                    <Button
                      key={blockType.title}
                      onClick={() => {
                        onSelect(blockType.id);
                        onClose();
                      }}
                      sx={{
                        padding: 0,
                        textTransform: 'unset',
                        opacity: 0.8,
                        cursor: 'pointer',

                        '&:hover': {
                          transform: 'scale(1.1)',
                          backgroundColor: 'unset',
                          fontWeight: 'bold',
                          opacity: 1.0,
                        },
                      }}
                    >
                      <BlocksItem
                        blockType={blockType}
                      />
                    </Button>
                  ))}
                </Box>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

import { EditorState } from 'draft-js';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Block, isBlockPage,
} from 'src/types/models';
import { getPageContainingBlock } from 'src/utils/documents';
import { useDocumentStore } from '../store/document';

/**
 * There is only ever one block selected at a time. Thus, it is part of the
 * global state. Access that state via this hook, which is responsible
 * for determining which block is selected at any given time.
 */
// TODO: Scroll to a block when it is selected. Right now, we navigate by page.
// This will have to change when there is one long page and the page number is irrelevant.
// 1H
export function useSelectedBlock(): [Block | null, (blockId: string | number | null) => void] {
  const selectedBlockId = useDocumentStore((state) => state.selectedBlockId);
  const blocksById = useDocumentStore((state) => state.blocksById);
  const selectedBlock = blocksById[selectedBlockId || ''];
  const selectBlockId = useDocumentStore((state) => state.setSelectedBlockId);

  const document = useDocumentStore((state) => state.documentsById[selectedBlock?.documentId]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (document) {
      const page = getPageContainingBlock(document.version, selectedBlockId || '');
      if (page) {
        // Navigate to the page that the block is on.
        const pathname = `/document/${document.id}/pages/${page.position + 1}`;
        if (location.pathname.startsWith(`/document/${document.id}/`) && location.pathname !== pathname) {
          navigate({
            ...location,
            pathname,
          });
        }
      }
    }
  }, [selectedBlock]);

  const selectBlock = useCallback((block: Block | string | number | null) => {
    if (typeof block === 'object' && block?.id) {
      selectBlockId(block.id);
    } else if (typeof block === 'string') {
      selectBlockId(block);
    } else if (document && typeof block === 'number') {
      const adjustment = block;
      const page = getPageContainingBlock(document.version, selectedBlockId || '');
      if (page && isBlockPage(page)) {
        // Get the index of the block.
        const index = page.grid.blocks.findIndex((b) => b.id === selectedBlockId);

        // Increment or decrement by adjustment within the bounds of the page.
        const newIndex = Math.min(Math.max(index + adjustment, 0), page.grid.blocks.length - 1);

        selectBlockId(page.grid.blocks[newIndex].id);
      }
    } else {
      selectBlockId(null);
    }
  }, [selectBlockId, selectedBlock, document]);

  return [
    selectedBlock || null,
    selectBlock,
  ];
}

/**
 * Ditto for hovering.
 */
export function useHoveredBlock(): [Block | null, (blockId: string | null) => void] {
  const hoveredBlockId = useDocumentStore((state) => state.hoveredBlockId);
  const setHoveredBlockId = useDocumentStore((state) => state.setHoveredBlockId);
  const hoveredBlock = useDocumentStore((state) => state.blocksById[hoveredBlockId || '']);

  return [
    hoveredBlock || null,
    setHoveredBlockId,
  ];
}

type EditorStateSetter = (
  newEditorState: EditorState | ((editorState: EditorState) => EditorState)
) => void;

// If the incoming ID is a string, then an initial value is required
// and the hook will never return an undefined value.
export function useBlockEditorState(
  blockId: string, initialValue: EditorState
): [EditorState, EditorStateSetter];

// If the incoming ID may be undefined, then an initial value does not
// make sense and the hook may return an undefined value. Setting the editor state
// may be a no-op.
export function useBlockEditorState(
  blockId: string | undefined
): [EditorState | undefined, EditorStateSetter];

// The hook implementation handles both cases.
export function useBlockEditorState(
  blockId: string | undefined,
  initialValue?: EditorState,
): [EditorState | undefined, EditorStateSetter] {
  const editorState = useDocumentStore((state) => state.draftEditorStateById[blockId || '']);
  const setStoreEditorState = useDocumentStore((state) => state.setDraftEditorState);

  const setEditorState = useCallback((
    newEditorState: EditorState | ((editorState: EditorState) => EditorState),
  ) => {
    setStoreEditorState(blockId!, newEditorState);
  }, [blockId]);

  useEffect(() => {
    if (blockId && !editorState && initialValue) {
      setStoreEditorState(blockId, initialValue);
    }
  }, [editorState, blockId]);

  if (blockId === undefined) {
    return [
      undefined,
      (() => {}) as EditorStateSetter,
    ];
  }

  return [
    editorState || initialValue,
    setEditorState,
  ];
}

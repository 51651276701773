import { Box, Typography } from '@mui/material';
import React from 'react';
import * as models from 'src/types/models';
import { useUserStore } from 'src/hooks/zustand/user';
// import { useUserStore } from 'src/zustand/user';
import Note from '../Note';
import { CreatableNote } from '../types';

const PADDING = 3;

export default function NotesList(props: {
  notes: models.Note[];
  showCreateForm?: boolean;
  onSave?: (note: CreatableNote) => Promise<void>;
  onDelete?: (note: models.Note) => Promise<void>;
}) {
  const {
    notes, showCreateForm, onSave, onDelete,
  } = props;
  const user = useUserStore((state) => state.user);
  const newNote: CreatableNote = {
    text: '',
    user: {
      id: '1',
      name: user.name,
      surname: user.surname,
      fullName: `${user.name} ${user.surname}`,
    },
  };

  return (
    <Box sx={{
      px: PADDING,
      pt: 3,

      // This allows the textarea to appear to touch the
      // bottom of the container.
      // TODO: This absence of bottom padding feels like it burdens
      // other elements with extra style considerations. Is there a cleaner way?
      pb: 0,
    }}
    >
      <Typography
        variant="h6"
        sx={{
          color: 'primary.main',
          '&:last-child': {
            mb: 3,
          },
        }}
      >
        Notes & Instructions
      </Typography>
      {notes.map((note) => (
        <Note
          key={note.id}
          note={note}
          onSave={onSave}
          onDelete={onDelete}
          spacingOffset={PADDING}
        />
      ))}
      {showCreateForm && (
        <Note
          note={newNote}
          onSave={onSave}
          spacingOffset={PADDING}
        />
      )}
    </Box>
  );
}

import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useAuthenticatedUser } from 'src/hooks/user';
import { CheckedOrangeImg } from '../../../assets/icons';
import { ImportantWordsImg } from '../../../assets/images';
import { useEditor } from '../../../hooks/useEditor';
import { lang } from '../../../lang';
import CreatePresstoDialog from '../../dialogs/CreatePresstoDialog';
import { Description } from '../../typography';
import { CardContainer, SignalWord } from '../styles';
import { CardProps } from '../types';

function ImportantWordsCard({ expanded }: CardProps) {
  const {
    importantWords, hasImportantWords, setDocumentMeta,
  } = useEditor();

  const user = useAuthenticatedUser();

  const [isImportantWordsDialogOpen, setIsImportantWordsDialogOpen] = useState(false);

  const renderWordDetector = () => {
    if (hasImportantWords && importantWords) {
      return (
        <div className="important-words-content">
          <div className="words-wrapper">
            {Object.keys(importantWords as { [key: string]: boolean }).map((word: string) => (
              <SignalWord
                activated={importantWords[word]}
                key={word}
                lightColor="#E88F2580"
                mainColor="#E88F25"
              >
                {word}
                {importantWords[word] && <img src={CheckedOrangeImg} alt={`${word} used`} />}
              </SignalWord>
            ))}
          </div>
          <div className="signal-words-description" />
        </div>
      );
    }

    return <Description className="description">{lang('document.side_bar.important_words.description')}</Description>;
  };

  return (
    <>
      <CardContainer
        backgroundColor="#FFFCF8"
        mainTextColor="#E88F25"
        onClick={() => {
          if (!hasImportantWords && user?.role === 'teacher') setIsImportantWordsDialogOpen(true);
        }}
      >
        <div className="header">
          <img
            className={`card-icon ${expanded ? 'expanded' : 'visible'}`}
            src={ImportantWordsImg}
            alt="Important Words"
          />
          <Box
            className={expanded ? 'title-open' : ''}
            onClick={() => {
              if (hasImportantWords && user?.role === 'teacher') {
                setIsImportantWordsDialogOpen(true);
              }
            }}
          >
            {lang('document.side_bar.important_words.title')}
            {
              user?.role === 'teacher' && (
                <div className="subtitle customize orange">
                  {lang('document.side_bar.important_words.customize')}
                </div>
              )
            }
          </Box>
        </div>
        {expanded && renderWordDetector()}
      </CardContainer>
      <CreatePresstoDialog
        onSubmit={(format: string, meta: any) => {
          if (!meta) return;
          setDocumentMeta(meta);
        }}
        initialTab={2}
        isOpen={isImportantWordsDialogOpen}
        onClose={() => setIsImportantWordsDialogOpen(false)}
      />
    </>
  );
}

export default ImportantWordsCard;

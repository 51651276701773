import { Button, Grid, Input } from '@mui/material';
import React, { useId, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';

export default function AlignmentInput() {
  const [topic, setTopic] = useState('');
  const [objectUrl, setObjectUrl] = useState('');
  const inputId = useId();
  const navigate = useNavigate();

  const [{ isOver, isDragging }, drop] = useDrop<{
    files: File[], dataTransfer: DataTransfer
  }, void, {
    isOver: boolean,
    canDrop: boolean,
    isDragging: boolean,
  } >(
    () => ({
      accept: [NativeTypes.FILE],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        isDragging: monitor.getItem() !== null,
      }),
      drop(item: { files: File[] }) {
        const file = item.files?.[0];
        if (file) {
          setObjectUrl(URL.createObjectURL(file));
        }
      },
    }),
    [],
  );

  return (
    <Grid
      container
      sx={[
      ]}
    >
      <Grid
        item
        container
        md={10}
        sx={[{
          alignItems: 'end',
          paddingBottom: '2px',
          borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
        },
        ]}
        ref={drop}
      >
        {objectUrl && (
        <img
          src={objectUrl}
          alt="object"
          style={{
            width: 'auto',
            alignSelf: 'top',
            height: '2.8rem',
            objectFit: 'contain',
            marginRight: '20px',
            marginBottom: '5px',
            borderRadius: '5px',
          }}
        />
        )}

        <Grid item sx={{ flex: 1 }}>
          <Input
            type="text"
            multiline
            fullWidth
            placeholder={
              isDragging ? 'Drop image here'
                : 'Enter a topic, text, URL, or image'
            }
            value={topic}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            onKeyUp={(event) => {
              event.stopPropagation();
            }}
            onChange={(event) => {
              setTopic(event.target.value);
            }}
            autoFocus
            sx={[{
              fontSize: topic.length <= 150 ? '2rem' : '1rem',
              color: 'black',
              fontWeight: 'bold',
              fontFamily: 'Inter',
              borderBottom: 'none',
              borderRadius: '5px',

              'textarea::placeholder': {
                color: 'rgba(0, 0, 0, 0.6)',
              },
            },
            {
              borderColor: 'transparent',
              borderWidth: '1px',
              borderStyle: 'dashed',
            },
            // isDragging && {
            //   borderColor: 'gray',
            // },
            isOver && {
              borderColor: 'gray',
            },
            ]}
          />
        </Grid>
        <Grid item>
          <label htmlFor={inputId}>
            <Button
              variant="text"
              component="span"
              style={{
                textTransform: 'none',
                lineHeight: '2rem',
              }}
            >
              Upload Image
            </Button>
            <input
              id={inputId}
              type="file"
              accept="image/*"
              style={{
                display: 'none',
              }}
              onChange={(event) => {
                const file = event.target.files?.[0];
                if (file) {
                  setObjectUrl(URL.createObjectURL(file));
                }
              }}
            />
          </label>
        </Grid>
      </Grid>
      <Grid item md={2} alignSelf="end">
        <Button
          variant="contained"
          color="primary"
          style={{
            height: '100%',
            marginBottom: '5px',
            textTransform: 'none',
            marginLeft: '1em',
          }}
          onClick={() => {
            navigate(`/document/align?topic=${topic}&objectDataUrl=${objectUrl}`);
          }}
        >
          Start Activity
        </Button>
      </Grid>
    </Grid>
  );
}

import { useMemo } from 'react';
import { useUserStore } from 'src/hooks/zustand/user';
import WRITING_PLANS from 'src/configuration/writing-plans';

/**
 * Return the list of writing plans available to this user.
 * Custom writing plans are only available to certain users.
 */
export function useAvailableWritingPlans() {
  const user = useUserStore((state) => state.user);

  return useMemo(() => {
    const plans = WRITING_PLANS.filter((plan) => {
      if (user.email) {
        // Users who are logged in with an email address may have
        // custom writing plans available.
        const domain = user.email.split('@')[1];

        if (plan.showForEmailDomains) {
          return plan.showForEmailDomains.includes(domain);
        }
        if (plan.hideForEmailDomains) {
          return !plan.hideForEmailDomains.includes(domain);
        }
      }

      // Do not show custom writing plans to users without an email.
      return !plan.showForEmailDomains;
    });

    // Place 'Basic' at the top of the list, then custom plans.
    // Otherwise, show in the order they are defined in the configuration.
    plans.sort((a, b) => {
      if (a.id === 'basic') {
        return -1;
      }
      if (b.id === 'basic') {
        return 1;
      }
      if (a.showForEmailDomains && !b.showForEmailDomains) {
        return -1;
      }
      if (!a.showForEmailDomains && b.showForEmailDomains) {
        return 1;
      }
      return 0;
    });

    return plans;
  }, [user]);
}

/**
 * Return the list of writing plans selectable by this user.
 * Custom writing plans are selectable by only certain users.
 * The 'Basic' plan is not selectable.
 */
export function useSelectableWritingPlans() {
  const plans = useAvailableWritingPlans();
  return useMemo(() => plans.filter((plan) => plan.id !== 'basic'), [plans]);
}

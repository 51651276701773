import {
  Box, Divider, Paper, useTheme,
} from '@mui/material';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import {
  Cycle as CycleIcon,
  MoveArrow,
  SmallBin as SmallBinIcon,
} from 'src/assets/icons';
import BlockTypeLabel from 'src/components/BlockLabel';
import SelectBlockTypeDialog from 'src/components/dialogs/SelectBlockTypeDialog';
import { getBlockType } from 'src/configuration/blocks';
import { getWritingPlan } from 'src/configuration/writing-plans';
import {
  useApplyDocumentOperation,
  useDocument,
  useHoveredBlock,
  useSelectedBlock,
} from 'src/hooks/document';
import {
  DeleteBlockOperation,
  SetBlockOperation,
} from 'src/hooks/store/document/operations';
import { useAvailableWritingPlans } from 'src/hooks/writing-plans';
import * as models from 'src/types/models';
import { BlockProperties, BlockTypesEnum } from 'src/types/models';
import Image from './Features/Image';
import Text from './Features/Text';
import ResizeHandle from './ResizeHandle';
import IconButton from './Toolbar/ToolbarComponents/IconButton';
import { FONT_SIZES, findFontSizeIndex } from './Toolbar/utils';

/**
  Use the canvas method to get an accurate measure in pixels of a string
  rendered in a particular font.
  This code is taken from:
  https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
*/
const getTextWidth: any = (text: string, font: string) => {
  const canvas = getTextWidth.canvas
    || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  // The +10 will prevent a small amount of cases where the measuse is not precise and the
  // element starts flickering between two font sizes (e.g. the string "But ...Really"
  // using Alfa Slab, which was used as a test case to fix this bug ).
  // Not ideal, but it should work.
  return metrics.width + 10;
};

export default function Block({
  block,
  blockType,
  isEditable = false,
  isResizable = false,
  isFixedSize = false,
  alwaysShowLabels = false,
  showOutline = false,
  labelPosition = 'left',
  labelOffset = '16px',
  neighbors,
  dragHandleProps,
}: {
  block: models.Block;
  blockType: models.BlockType;
  isEditable?: boolean;
  isResizable?: boolean;
  isFixedSize?: boolean;
  alwaysShowLabels?: boolean;
  showOutline?: boolean;
  labelPosition?: 'top' | 'left';
  labelOffset?: string;
  neighbors?: {
    above: models.Block | null;
    below: models.Block | null;
  };
  dragHandleProps?: DraggableProvidedDragHandleProps;
}) {
  // TODO: I need the primary color from the theme, but the sx.outlineColor
  // property does not seem to pick up on it.
  const theme = useTheme();

  const presstoDocument = useDocument(block.documentId);
  const applyOperation = useApplyDocumentOperation();
  const [selectedBlock, setSelectedBlockId] = useSelectedBlock();
  const [hoveredBlock, setHoveredBlockId] = useHoveredBlock();
  const [isChangeTypeDialogOpen, setIsChangeTypeDialogOpen] = useState(false);

  // An active block may trigger additional block funcionality,
  // this would be a click on the already selected block (AKA second click).
  // Main use (only current one) is to focus on text where ever you click on the block.
  const [isTextAutoFocused, setIsTextAutoFocused] = useState(false);

  // Use this to keep track of the size of the content area and respond to changes.
  const contentRef = useRef<HTMLDivElement>(null);

  // TODO: We should be able to do a reference equality check here.
  // But it's not working. This indicates the store is getting out of sync
  // with the block object. Investigate.
  const isSelected = block.id === selectedBlock?.id;
  const isHovered = block.id === hoveredBlock?.id;

  const updateProperties = useCallback(
    (properties: BlockProperties) => {
      applyOperation(
        new SetBlockOperation(block.documentId, {
          id: block.id,
          properties: {
            ...block.properties,
            ...properties,
          },
        }),
      );
    },
    [block.id, block.properties],
  );

  // Any time an element within this block is focused, use that as a
  // signal to mark the block as selected in the store. This will
  // automatically clear any other block selection.
  const captureFocus = useCallback(
    (event: React.SyntheticEvent) => {
      if (!isEditable) {
        return;
      }
      if (!event.isDefaultPrevented()) {
        // If already selected block, focus on text.
        if (isSelected) {
          setIsTextAutoFocused(true);
        } else {
          setSelectedBlockId(block.id);
          setIsTextAutoFocused(false);
        }
      }
      event.stopPropagation();
    },
    [block, isEditable, isSelected],
  );

  // Inversely, when the block is selected, place the cursor into the text.
  useEffect(() => {
    if (isSelected && isEditable) {
      // If the block is not visible on the screen, then scroll it into view.
      if (contentRef?.current) {
        // Check whether the element is visible.
        const buffer = 150;
        const rect = contentRef.current.getBoundingClientRect();
        if (rect.top < buffer || rect.top > window.innerHeight - buffer) {
          window.scrollTo({
            top: rect.top + window.scrollY - buffer,
            behavior: 'smooth',
          });
        }
      }

      if (!isTextAutoFocused) {
        setIsTextAutoFocused(true);
      }
    } else {
      setIsTextAutoFocused(false);
    }
  }, [contentRef?.current, isSelected, isTextAutoFocused, isEditable]);

  // If there is a writing plan assigned to the document, then limit block
  // selection to those in that plan. Otherwise, allow selection of any block
  // in any plan.
  let writingPlans = useAvailableWritingPlans();
  if (presstoDocument?.meta.writingPlan) {
    const documentPlan = getWritingPlan(presstoDocument.meta.writingPlan);
    if (documentPlan) {
      writingPlans = [documentPlan];
    }
  }
  const onChangeBlockType = useCallback(
    (newBlockTypeId: BlockTypesEnum) => {
      // Reset any properties that still have their default values.
      const changedProperties: BlockProperties = {};
      const newBlockType = getBlockType(newBlockTypeId);
      Object.entries(blockType.defaultProperties).forEach(
        ([key, defaultValue]) => {
          const property = key as keyof BlockProperties;
          if (block.properties[property] !== defaultValue) {
            changedProperties[property] = block.properties[property] as any;
          }
        },
      );

      applyOperation(
        new SetBlockOperation(block.documentId, {
          id: block.id,
          typeId: newBlockTypeId,
          properties: {
            ...newBlockType.defaultProperties,
            ...changedProperties,
          },
        }),
      );
    },
    [block, blockType],
  );

  const onDeleteBlock = useCallback(() => {
    setSelectedBlockId(null);
    setHoveredBlockId(null);
    applyOperation(new DeleteBlockOperation(block.documentId, block.id));
  }, [block]);

  const onImageChange = useCallback(
    (
      data: Pick<
      models.BlockProperties,
      | 'imageUrl'
      | 'rotation'
      | 'positionX'
      | 'positionY'
      | 'zoom'
      | 'orientation'
      >,
    ) => {
      updateProperties(data);
    },
    [block],
  );

  const onTextChange = useCallback(
    (
      data: Pick<
      models.BlockProperties,
      'plainText' | 'htmlText' | 'rawEditorContent'
      >,
    ) => {
      // Operations might trigger API calls and other heavy side effect.
      // The Text component might signal changes more often than the text
      // materially changes. Check if the text has actually changed.
      // Use the HTML representation as the definitive representation here.
      if (data.htmlText !== block.properties.htmlText) {
        updateProperties(data);
      }
    },
    [block],
  );

  const onTextOverflow = useCallback(() => {
    // Fires when the editor reports that the text has overflowed its container.
    if (isFixedSize) {
      // Make the font smaller.
      const [, value] = block.properties.fontSize?.match(/([.\d]+)(\D+)/) ?? [
        '',
        '1',
        'em',
      ];
      const currentIndex = findFontSizeIndex(Number(value));
      if (currentIndex !== 0) {
        updateProperties({
          fontSize: `${FONT_SIZES[currentIndex - 1].em}em`,
          fitFontSize: true,
          maximumFontSize: `${FONT_SIZES[currentIndex - 1].em}em`,
        });
      }
    }
  }, [block, isFixedSize]);

  // Calculate biggest font size that fits the block height.
  const getMaximumFontSizeAllowed = useCallback(
    (text: string) => {
      let maxFontSize = FONT_SIZES[FONT_SIZES.length - 1].em;

      if (!isFixedSize) {
        // If the block height is not fixed, then it can grow up to the maximum.
        return maxFontSize;
      }

      if (!contentRef.current) {
        return maxFontSize;
      }
      const editorElement = contentRef.current.querySelector(
        '.public-DraftEditor-content',
      );
      const blockElement = contentRef.current.querySelector(
        '.rdw-editor-wrapper',
      );

      if (!editorElement || !blockElement) {
        return maxFontSize;
      }
      const editorFontFamily = window
        .getComputedStyle(editorElement, null)
        .getPropertyValue('font-family');
      const editorFontSize = window
        .getComputedStyle(editorElement, null)
        .getPropertyValue('font-size');
      const [, currentFontSize] = block.properties.fontSize?.match(
        /([.\d]+)(\D+)/,
      ) ?? ['', '1', 'em'];

      // Otherwise, figure out the maximum font size that fits the block height.
      for (let index = 0; index < FONT_SIZES.length; index += 1) {
        // Get pixel size from the font 'em' value
        const font = FONT_SIZES[index];
        const nextSize = font.em;
        const fontSizeInPx = (nextSize * Number(editorFontSize.replace('px', '')))
          / Number(currentFontSize);

        // This is an estimation, but it seems to be accurate enough.
        const lineHeight = Math.floor(fontSizeInPx * 1.5);

        // Calculate the maximum number of lines the block can render
        // given the line height of the text with a larger font size.
        const maxNumberOfLines = Math.ceil(
          blockElement!.clientHeight / lineHeight,
        );

        // Split text into words and measure the size of each one to get number
        // of lines it occupies.
        const words: string[] = (text || '').split(' ');
        let lineWidth = 0;
        let lineCount = 1;
        words.forEach((word) => {
          const numberOfBreakLines = word.split('').reduce((total, letter) => {
            if (letter === '\n') {
              total += 1;
            }
            return total;
          }, 0);
          lineCount += numberOfBreakLines;
          word.replaceAll('\n', '');

          const wordWidth = getTextWidth(
            `${word} `,
            `${fontSizeInPx}px ${editorFontFamily}`,
          );
          const linesOccupiedByWord = wordWidth / blockElement!.clientWidth;

          if (linesOccupiedByWord >= 1) {
            lineCount += Math.floor(linesOccupiedByWord);
          }

          // If the word occupies multiple lines, this will get the last letters
          // to use on the next line.
          const pieceOfLastWord = wordWidth
            - Math.floor(linesOccupiedByWord) * blockElement!.clientWidth;
          lineWidth += pieceOfLastWord;

          if (blockElement!.clientWidth < lineWidth) {
            // The last word of the current line goes to the next line.
            lineCount += 1;
            lineWidth = pieceOfLastWord;
          }
        });

        if (lineCount < maxNumberOfLines) {
          maxFontSize = font.em;
        } else {
          // If text has more lines than maximum allowed for the block,
          // set the maximum to the smallest size available.
          if (font.label === FONT_SIZES[0].label) {
            maxFontSize = font.em;
          }
          break;
        }
      }

      updateProperties({ maximumFontSize: `${maxFontSize}em` });
      return maxFontSize;
    },
    [block, contentRef.current, isFixedSize],
  );

  // Fires when the editor changes and font size is setted to 'FIT'
  const handleFitFontSize = useCallback(() => {
    if (!isEditable) return;

    const [, currentFontSize] = block.properties.fontSize?.match(
      /([.\d]+)(\D+)/,
    ) ?? ['', '1', 'em'];
    const currentFontSizeIndex = findFontSizeIndex(Number(currentFontSize));

    const { plainText } = block.properties;

    const maximumFontSize = getMaximumFontSizeAllowed(plainText || '');
    if (
      FONT_SIZES[currentFontSizeIndex].em !== maximumFontSize
      && block.properties.fitFontSize
    ) {
      updateProperties({ fontSize: `${maximumFontSize}em` });
    }
  }, [block]);

  // If the block height increases, calculate if text size can increase
  // If the height decreases, there is no need to call handleFitFontSize here
  // since the text overflow function already does it
  useEffect(() => {
    if (block.height === 2 && block.properties.fontSize) {
      handleFitFontSize();
    }
  }, [block.height]);

  // Create a ref for the container of the toolbox.
  const toolboxRef = useRef<HTMLDivElement>(null);
  const isLabelVisible = alwaysShowLabels || isSelected;

  return (
    <Box
      ref={contentRef}
      sx={[
        {
          height: '100%',
          position: 'relative',
          whiteSpace: 'break-spaces',
          touchAction: 'none',
          backgroundColor: theme.palette.background.default,

          '.public-DraftStyleDefault-block': {
            margin: 0,
          },
        },
        showOutline && {
          outlineWidth: '2px',
          outlineStyle: 'solid',
          outlineColor: 'transparent',
        },
        isHovered && {
          outlineColor: theme.palette.primary.light,
          zIndex: 1,
        },
        isEditable
          && isSelected && {
          outlineColor: theme.palette.primary.main,
          zIndex: 2,
        },
      ]}
      onMouseEnter={isEditable ? () => setHoveredBlockId(block.id) : undefined}
      onMouseLeave={isEditable ? () => setHoveredBlockId(null) : undefined}
      onFocus={captureFocus}
      onMouseUp={captureFocus}
      // TODO: Bluntly stopping propogation of clicks and keypresses feels like
      // it could have some unintended consequences. The goal is to
      // deselect the block when the user clicks anywhere else on the
      // artboard.
      onKeyDown={(event) => {
        // Avoid keyboard events within the block from triggering page-level
        // keyboard shortcuts.
        event.stopPropagation();
      }}
    >
      {/* This sticks out above the block. */}
      {labelPosition === 'top' && isLabelVisible && (
        <Box
          sx={{
            position: 'absolute',
            display: isSelected ? 'flex' : 'none',
            gap: '8px',
            userSelect: 'none',
            zIndex: 8,
            top: '-24px',
            // Align to the outline or the start of text.
            marginLeft: showOutline ? '-2px' : '0.5em',
          }}
        >
          <div {...dragHandleProps}>
            <BlockTypeLabel
              blockType={blockType}
              isHighlighted={!(isEditable && isSelected) && isHovered}
              isSelected={isEditable && isSelected}
            />
          </div>
        </Box>
      )}

      {/* This sticks out to the left or bottom of the block. */}
      <Box
        sx={[
          {
            // Jogging this element to the left by the width of its parent
            // container causes it to hug the left edge of the parent, regardless
            // of the width of its content.
            position: 'absolute',
            display: 'flex',
            gap: '8px',
            userSelect: 'none',
            zIndex: 8,
            marginLeft: showOutline ? '-2px' : '0.5em',
          },
          labelPosition === 'left' && {
            right: '100%',
            px: labelOffset,
            marginTop: '0.5em',
            flexDirection: 'column',
            alignItems: 'flex-end',
          },
          labelPosition === 'top' && {
            bottom: '-48px',
            py: '8px',
            flexDirection: 'row',
          },
        ]}
      >
        {labelPosition === 'left' && isLabelVisible && (
          <Box
            sx={{
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <div {...dragHandleProps}>
              <BlockTypeLabel
                blockType={blockType}
                isHighlighted={!(isEditable && isSelected) && isHovered}
                isSelected={isEditable && isSelected}
              />
            </div>
          </Box>
        )}

        {!isLabelVisible && (
          // React Beautiful DnD requires a drag handle to be a child of the
          // draggable element. If labels are not visible, then create a dummy
          // drag handle to silence warnings.
          <div {...dragHandleProps} style={{ display: 'none' }} />
        )}

        {isEditable && isSelected && (
          <div
            style={{
              overflow: 'hidden',
              padding: '0 14px 8px 0',
              marginRight: '-14px',
            }}
            ref={toolboxRef}
          >
            <Paper
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  lg: 'row',
                },
                justifyItems: 'left',
              }}
              elevation={2}
            >
              {isResizable && (
                <>
                  <IconButton
                    src={MoveArrow}
                    noBorder
                    isSmall
                    alt="Move"
                    onClick={() => {
                      // Am I at the top?
                      if (block.y === 1 && block.height === 1) {
                        // Do I have space underneath me?
                        if (!neighbors?.below) {
                          // I can grow down.
                          applyOperation(
                            new SetBlockOperation(block.documentId, {
                              id: block.id,
                              height: block.height + 1,
                            }),
                          );
                        } else {
                          // Swap positions with the block below me.
                          applyOperation(
                            new SetBlockOperation(block.documentId, {
                              id: block.id,
                              y: block.y + 1,
                            }),
                          );
                          applyOperation(
                            new SetBlockOperation(block.documentId, {
                              id: neighbors.below.id,
                              y: neighbors.below.y - 1,
                            }),
                          );
                        }
                      } else if (block.y === 2 && block.height === 1) {
                        // I'm at the bottom.
                        // Is there space above me?
                        if (!neighbors?.above) {
                          // I can grow up.
                          applyOperation(
                            new SetBlockOperation(block.documentId, {
                              id: block.id,
                              height: block.height + 1,
                              y: block.y - 1,
                            }),
                          );
                        } else {
                          // Swap positions with the block above me.
                          applyOperation(
                            new SetBlockOperation(block.documentId, {
                              id: block.id,
                              y: block.y - 1,
                            }),
                          );
                          applyOperation(
                            new SetBlockOperation(block.documentId, {
                              id: neighbors.above.id,
                              y: neighbors.above.y + 1,
                            }),
                          );
                        }
                      } else if (block.y === 1 && block.height === 2) {
                        // I'm big. I can shrink.
                        applyOperation(
                          new SetBlockOperation(block.documentId, {
                            id: block.id,
                            height: block.height - 1,
                          }),
                        );
                      }
                    }}
                  />
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ borderColor: 'primary.light' }}
                  />
                </>
              )}

              <IconButton
                src={CycleIcon}
                noBorder
                isSmall
                alt="Change Block Type"
                onClick={() => {
                  setIsChangeTypeDialogOpen(true);
                }}
              />
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: 'primary.light' }}
              />
              <IconButton
                src={SmallBinIcon}
                noBorder
                isSmall
                alt="Delete"
                onClick={onDeleteBlock}
              />
            </Paper>
          </div>
        )}
      </Box>

      {blockType.configuration.hasImage && (
        <Image
          blockRef={contentRef}
          isEditable={isEditable}
          isSelected={isSelected}
          block={block}
          blockType={blockType}
          onChange={onImageChange}
          showPlaceholder={isEditable && !blockType.configuration.hasText}
        />
      )}
      {blockType.configuration.hasText && (
        <Text
          block={block}
          blockType={blockType}
          onChange={onTextChange}
          onOverflow={onTextOverflow}
          handleFitFontSize={handleFitFontSize}
          isEditable={isEditable}
          autoFocus={isTextAutoFocused}
          isSelected={isSelected}
        />
      )}

      <SelectBlockTypeDialog
        isOpen={isChangeTypeDialogOpen}
        onClose={() => setIsChangeTypeDialogOpen(false)}
        onSelect={onChangeBlockType}
        availablePlans={writingPlans}
      />
      {isSelected
        && isEditable
        && isResizable
        && ((block.y === 2 && !neighbors?.above) || block.height === 2) && (
          <ResizeHandle
            anchor="top"
            contentRef={contentRef}
            block={block}
            neighbors={neighbors}
          />
      )}
      {isSelected
        && isEditable
        && isResizable
        && ((block.y === 1 && !neighbors?.below) || block.height === 2) && (
          <ResizeHandle
            anchor="bottom"
            contentRef={contentRef}
            block={block}
            neighbors={neighbors}
          />
      )}
    </Box>
  );
}

import React, { useMemo, useState } from 'react';
import { CheckedImg } from 'src/assets/icons';
import { SignalWordsImg } from 'src/assets/images';
import { lang } from 'src/lang';
import { isBlockPage, Document, WritingPlan } from 'src/types/models';
import { Chip } from '@mui/material';
import { useApplyDocumentOperation } from 'src/hooks/document';
import {
  SetSignalWordsOperation,
  SetWritingPlanOperation,
} from 'src/hooks/store/document/operations';
import FeedbackCard, { CommonFeedbackCardProps } from '.';
import WritingPlanDialog from '../../dialogs/WritingPlanDialog';

function WritingPlanCard(
  props: CommonFeedbackCardProps & {
    document: Document;
    writingPlan: WritingPlan | null;
    isEditable?: boolean;
  },
) {
  const { document, writingPlan, isEditable = false } = props;

  const [isWritingPlanDialogOpen, setIsWritingPlanDialogOpen] = useState(false);
  const applyOperation = useApplyDocumentOperation();

  const signalWordsPresence = useMemo(() => {
    const presence: Record<string, boolean> = {};

    if (writingPlan?.signalWords.length) {
      const allBlocks = document.version.pages
        .filter(isBlockPage)
        .flatMap((page) => page.grid.blocks);
      const text = allBlocks
        .map((b) => b.properties.plainText || '')
        .join(' ')
        .toLowerCase();

      writingPlan.signalWords.forEach((word) => {
        presence[word] = text.includes(word.toLowerCase());
      });
    }
    return presence;
  }, [document.version.pages, writingPlan?.signalWords]);

  return (
    <FeedbackCard
      {...props}
      accentColor="#2E2C75"
      icon={SignalWordsImg}
      title={lang('document.side_bar.writing_plan.title')}
    >
      {writingPlan
        ? Object.keys(signalWordsPresence).map((word: string) => (
          <Chip
              // activated={importantWordsPresence[word]}
            key={word}
            label={word}
            icon={
                signalWordsPresence[word] ? (
                  <img src={CheckedImg} alt="Checked" />
                ) : undefined
              }
          />
        ))
        : isEditable && <p>Add a writing plan to see signal words.</p>}
      {isEditable && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          onClick={() => {
            setIsWritingPlanDialogOpen(true);
          }}
          style={{
            color: '#163567',
            textDecoration: 'none',
            fontWeight: 'bold',
            fontSize: '.8rem',
            display: 'inline-block',
            margin: '8px 4px',
          }}
        >
          Edit
        </a>
      )}
      <WritingPlanDialog
        open={isWritingPlanDialogOpen}
        initialWritingPlan={writingPlan?.id}
        onClose={() => setIsWritingPlanDialogOpen(false)}
        onAdd={async (id: string) => {
          applyOperation(new SetWritingPlanOperation(document.id, id));
          applyOperation(new SetSignalWordsOperation(document.id, true));
          setIsWritingPlanDialogOpen(false);
        }}
      />
    </FeedbackCard>
  );
}

export default WritingPlanCard;

import React, { useEffect } from 'react';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { useAssignment } from 'src/hooks/assignment';
import { useUserStore } from 'src/hooks/zustand/user';
import NotFound from '../notFound';
import EditAssignment from './Edit';
import NotAuthorized from './NotAuthorized';
import ViewAssignment from './View';

/**
 * Switch between the view and edit mode of the Assignment page.
 */
export default function AssignmentView() {
  const {
    id: assignmentId,
  } = useParams();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const [assignment] = useAssignment(assignmentId === 'new' ? undefined : assignmentId);

  // At this time, the route for sharing an assignment with others as a template
  // uses the same View as editing an assignment you own. This may change
  // in the future if Assignment Templates become more distinct. For now,
  // check the path to distinguish between the two. Behavior should only differ
  // in the way they handle access control.
  const isPublicTemplate = location.pathname.startsWith('/templates/');

  useEffect(() => {
    if (!user.id && assignmentId === 'new') {
      // A user who is not logged in is trying to create a new assignment.
      // Redirect them to the login page, but remember the assignment parameters.
      const overrides = {
        name: search.get('name') || '',
        importantWords: search.get('importantWords') && search.get('importantWords')!.split(','),
        writingPlanId: search.get('writingPlan'),
        prompt: location.state?.prompt,
      };

      const next = '/assignment/new?via=unauth';
      const postLoginState = { ...overrides, next };
      localStorage.setItem('postLoginState', JSON.stringify(postLoginState));

      // Redirect the user to login.
      navigate({
        ...location,
        pathname: '/register-teacher',
        search: new URLSearchParams({
          ...search,
          via: 'assistant',
        }).toString(),
      });
    }
  }, [user.id, assignmentId]);

  if (!user.id && assignmentId === 'new') {
    // The user is not logged in and is trying to create a new assignment.
    // This case is handled by the effect above.
    return null;
  }

  if (user.role === 'teacher' && assignmentId === 'new') {
    // A teacher is creating a new assignment.
    return <EditAssignment />;
  }

  if (user.id && user.role !== 'teacher' && assignmentId === 'new') {
    // Only teachers can create assignments.
    return (
      <NotAuthorized
        title="Cannot create assignment"
        message={(
          <>
            <p>Log in to Pressto as a teacher to create an assignment.</p>
            <br />
            <br />
            <Link to="/gallery">Return to gallery</Link>
          </>
        )}
      />
    );
  }

  if (assignment === undefined) {
    // The assignment is loading.
    return null;
  }

  if (user.role === 'student') {
    // Determine whether the student can see the assignment.
    // Note: The API current returns an assignment even if it is not assigned
    // to the student. This is because assignments are public (they can be
    // viewed by unauthenticated users). If this behavior changes in the future,
    // then the assignment will be `null`, representing a load error.
    const isAssignedDirectlyToStudent = assignment?.students?.some((s) => s.id === user.id);
    const isAssignedToStudentThroughClassroom = (
      assignment?.classrooms
      && user.classrooms?.some((c1) => (
        assignment?.classrooms?.some((c2) => c1.id === c2.id)
      ))
    );
    const isAssigned = isAssignedDirectlyToStudent || isAssignedToStudentThroughClassroom;

    if (assignment === null || !isAssigned) {
      return (
        <NotAuthorized
          title="Unavailable"
          message={(
            <>
              <p>This is a link to an assignment that was not assigned to you on Pressto.</p>
              <br />
              <br />
              <Link to="/gallery">Return to your assignments</Link>
            </>
          )}
        />
      );
    }

    if (assignment && isAssigned) {
      // The view component will display appropriate information to a student.
      return <ViewAssignment />;
    }
  }

  if (assignment === null) {
    // The assignment is not found or there is another error.
    return null;
  }

  // If this teacher teachers a classroom to which the assignment is assigned,
  // or the teacher is the person who created the assignment,
  // then the assignment is editable.
  if (
    user.teacherClassrooms?.some((tc) => (
      assignment?.classrooms.find((ac) => ac.id === tc.id)
    ))
    || user.id === assignment.teacher.id
  ) {
    // The user is the owner of this assignment. If the teacher can access
    // the assignment and it's not a template, we can infer that they are
    // able to edit it. If this isn't true, worst case is that the API
    // rejects the changes.
    return <EditAssignment />;
  }

  if (isPublicTemplate) {
    // The person viewing the assignment is not the owner and they are not
    // a student and the assignment is not a public template.
    return <ViewAssignment />;
  }

  return <NotFound />;
}

import { ButtonUnstyled } from '@mui/base';
import { Theme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useAuthenticatedUser } from 'src/hooks/user';
import { FeedbackImg } from '../../assets/icons';
import { Chevron } from '../../assets/icons/variantComponents';
import { useEditor } from '../../hooks/useEditor';
import { lang } from '../../lang';
import DraggableContainer from '../DraggableContainer';
import IconButton from '../buttons/IconButton';
import { Description } from '../typography';
import ImageFeedbackCard from './Cards/ImageFeedbackCard';
import ImportantWordsCard from './Cards/ImportantWordsCard';
import TitleLengthCard from './Cards/TitleLengthCard';
import WordCountCard from './Cards/WordCountCard';
import WritingPlanCard from './Cards/WritingPlanCard';
import { Wrapper } from './styles';

function FeedbackSideBar() {
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const user = useAuthenticatedUser();

  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    setExpanded(!mobileMQ);
  }, [mobileMQ]);

  const {
    pageElements, hasWritingPlan, hasImportantWords, isViewMode,
  } = useEditor();

  const renderWritingPlan = () => {
    if (isViewMode && !hasWritingPlan) return null;

    return <WritingPlanCard expanded={expanded} />;
  };

  const renderImportantWords = () => {
    if (isViewMode && !hasImportantWords) return null;

    if (user?.role !== 'teacher' && !hasImportantWords) return null;

    return <ImportantWordsCard expanded={expanded} />;
  };

  return (
    <>
      {(!mobileMQ || expanded) && (
        <Wrapper expanded={expanded}>
          <DraggableContainer>
            <>
              <ButtonUnstyled onClick={() => setExpanded((prev) => !prev)} className="header-button">
                <div>
                  <img src={FeedbackImg} alt="feedback" />
                  {expanded && <Description highlight size="small">{lang('general.feedback')}</Description>}
                </div>
                <Chevron direction={expanded ? 'right' : 'left'} />
              </ButtonUnstyled>
              <div className="cards-list">
                <WordCountCard expanded={expanded} />
                {pageElements?.some((el) => el.includes('TITLE')) && <TitleLengthCard expanded={expanded} />}
                {pageElements?.includes('ImageBox') && <ImageFeedbackCard expanded={expanded} />}
                {renderWritingPlan()}
                {renderImportantWords()}
              </div>
            </>
          </DraggableContainer>
        </Wrapper>
      )}
      {mobileMQ && !expanded && (
        <>
          {ReactDOM.createPortal(
            <IconButton
              src={FeedbackImg}
              alt="pages"
              onClick={() => setExpanded(true)}
            />,
            document.querySelector('#navigation-bar > .right-container > .feedback-button')!,
          )}
        </>
      )}
    </>
  );
}

export default FeedbackSideBar;

import {
  Container, FormGroup, Grid, InputLabel, MenuItem, Select,
} from '@mui/material';
import React, { useState } from 'react';
import { Form, Input } from 'src/components';
import { Button } from 'src/components/buttons';
import Logo from 'src/components/Logo';
import TopBar from 'src/components/shared/TopBar';
import { useUserStore } from 'src/hooks/zustand/user';
import * as UserAPI from 'src/api/User';
import { useLocation } from 'react-router-dom';

/**
 * An administrative form for logging in as another user.
 */
export default function Masquerade() {
  const authCheck = useUserStore((state) => state.authCheck);
  const search = new URLSearchParams(useLocation().search);
  const [usernameOrEmail, setUsernameOrEmail] = useState(search.get('username') || '');
  const [loginReason, setLoginReason] = useState(search.get('loginReason') || '');
  const [isLoading, setIsLoading] = useState(false);

  const masquerade = async () => {
    setIsLoading(true);
    try {
      await UserAPI.masquerade({
        usernameOrEmail,
        loginReason: 'Customer Support',
      });
      await authCheck();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main>
      <TopBar>
        <Logo height="2.25rem" variant="responsive" />
        <TopBar.Spacer />
      </TopBar>

      <Container sx={{ paddingTop: 4 }}>
        <Grid container>
          <Grid item md={6}>
            <h2>Masquerade as a user</h2>

            <Form
              onSubmit={(event) => {
                event.preventDefault();
                masquerade();
              }}
              sx={{ marginTop: 2 }}
            >
              <FormGroup>
                <InputLabel id="user">Username or email</InputLabel>
                <Input
                  required
                  id="user"
                  type="text"
                  name="user"
                  value={usernameOrEmail}
                  onChange={(e) => setUsernameOrEmail(e.target.value)}
                  placeholder="Email or username"
                />
              </FormGroup>

              <FormGroup>
                <InputLabel id="loginReason">Login reason</InputLabel>
                <Select
                  id="loginReason"
                  name="loginReason"
                  label="Login reason"
                  value={loginReason}
                  onChange={(e) => setLoginReason(e.target.value)}
                >
                  <MenuItem value="Customer Support">Customer Support</MenuItem>
                  <MenuItem value="Troubleshoot Bug">Troubleshoot Bug</MenuItem>
                  <MenuItem value="Investigate Abuse">Investigate Abuse</MenuItem>
                  <MenuItem value="User Research">User Research</MenuItem>
                </Select>
              </FormGroup>

              <Button
                label="Log in as user"
                type="submit"
                disabled={isLoading}
                large
              />
            </Form>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}

import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

import {
  FlexBox, Form, GradientContainer, Input, InputPassword, Logo,
} from '../../components';
import {
  Title, Description, Link,
} from '../../components/typography';
import { theme } from '../../utils';
import { Button } from '../../components/buttons';
import * as UserAPI from '../../api/User';
import { useUserStore } from '../../hooks/zustand/user';
import { lang } from '../../lang';

const SuccessMessage: any = styled(Typography)(() => ({
  color: theme.palette.success.light,
  fontSize: '12px',
  fontWeight: '700',
}));

function ResetPassword() {
  const setUser = useUserStore((state) => state.setUser);

  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);

  const [{ error, message }, setMessage] = useState({ error: false, message: '' });

  const handleChangeEmail = (e: any) => setEmail(e.target.value);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await UserAPI.resetPasswordLink({ email });
      setMessage({ error: false, message: 'An email was sent to you. Follow it\'s instructions to create a new password.' });
    } catch (err: any) {
      setMessage({ error: true, message: err.response.data.message });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitNewPass = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await UserAPI.setNewPassword({ email, password, token });
      setMessage({ error: false, message: 'Your password has been successfully updated!' });
    } catch (err: any) {
      setMessage({ error: true, message: err.response.data.message });
    } finally {
      setLoading(false);
    }
  };

  const login = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const response = await UserAPI.signIn({ username_or_email: email, password });
    setUser(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setToken(urlParams.get('token') || '');
    setEmail(urlParams.get('email') || '');
  }, []);

  const renderNewPasswordForm = () => (
    <FlexBox textAlign="center">
      <FlexBox textAlign="center">
        <Title>{lang('auth.reset_password.enter_password')}</Title>
      </FlexBox>

      <FlexBox marginTop={5} width={310} alignSelf="center">
        <Form
          onSubmit={(e: any) => {
            if (message) login(e);
            else handleSubmitNewPass(e);
          }}
        >
          <Description>{lang('auth.reset_password.password_rules')}</Description>
          <InputPassword
            placeholder="Password"
            aria-label="password"
            error={error}
            value={password}
            invalidMessage={error ? message : ''}
            onChange={(e: any) => setPassword(e.target.value)}
          />
          {message && !error && <SuccessMessage>{message}</SuccessMessage>}
          <Button
            type="submit"
            aria-label="submit"
            label={lang(`auth.reset_password.${message ? 'login' : 'reset'}`)}
            disabled={!password}
            large
          />
        </Form>
      </FlexBox>
    </FlexBox>
  );

  const renderSendEmail = () => (
    <FlexBox textAlign="center">
      <FlexBox textAlign="center">
        <Title>Reset password</Title>
        <Description align="center">Enter your e-mail to reset your password!</Description>
      </FlexBox>

      <FlexBox marginTop={5} width={310}>
        <Form onSubmit={handleSubmit}>
          <Input
            placeholder="E-mail"
            aria-label="email"
            type="email"
            error={error}
            invalidMessage={error ? message : ''}
            value={email}
            onChange={handleChangeEmail}
          />
          <Button type="submit" aria-label="submit" label="Reset password" disabled={!email || loading} large />
        </Form>
        <Description>
          {!error && message}
        </Description>
      </FlexBox>
    </FlexBox>
  );

  return (
    <GradientContainer>
      <Logo />

      {token && renderNewPasswordForm()}
      {!token && renderSendEmail()}

      <Description size="small">
        <Link underline href="/register">Create an account</Link>
      </Description>
    </GradientContainer>
  );
}

export default ResetPassword;

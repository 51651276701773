import { createTheme, Shadows } from '@mui/material/styles';
import { alpha, darken } from '@mui/system';
import { ContentBlock } from '../components/elements/TextBox/types';
import { DateFilter, PresstoDefinition } from '../types/Gallery';
import { TextBoxType } from '../types/LayoutEditor';
import {
  BlockTypesEnum, Document, isBlockPage, LayoutPage as PageType,
} from '../types/models';
import COLORS from './colors';

const primaryLight = '#445D85';
const primaryDark = darken('#163567', 0.2);
const primaryMain = '#163567';

export const theme = createTheme({
  palette: {
    background: {
      default: '#FFFFFF',
    },
    primary: {
      light: primaryLight,
      main: primaryMain,
      dark: primaryDark,
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#E3E3E3',
      dark: '#2C2C2C',
      main: '#999',
      contrastText: '#000',
    },
    grey: {
      // TODO: Extrapolate this slightly cool gray to the rest of the shades.
      A100: '#F3F6FA',
    },
    divider: '#F6F6F6',
    error: {
      main: '#FF5252',
      dark: '#dd2222',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: `1px solid ${primaryMain}`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          borderColor: primaryMain,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${primaryLight}`,

          'textarea::placeholder, input::placeholder': {
            opacity: 0.2,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          elevation: 16,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          transition: 'none',
          boxShadow: 'none',
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  shadows: [
    'none',
    ...Array(12).fill('').map((_, i) => `${i + 1}px ${i + 1}px 0 ${alpha('#163567', 0.4)}`),
    ...Array(12).fill('').map((_, i) => `${i + 1}px ${i + 1}px 0 ${alpha('#163567', 1.0)}`),
  ] as Shadows,
});

export const verifyPassword = (password: string) => password.length >= 6;

const timeoutId = {} as { [key: string]: NodeJS.Timeout };

export const debounce = (cb: () => void, timeout: number, id?: string) => {
  if (timeoutId[id ?? 'DEFAULT']) clearTimeout(timeoutId[id ?? 'DEFAULT']);
  timeoutId[id ?? 'DEFAULT'] = setTimeout(cb, timeout);
};

export const capitalize = (str: string) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

export const kebabize = (str: string) => str.split('').map((letter, idx) => (letter.toUpperCase() === letter
  ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
  : letter)).join('');

export const filterPresstosByDate = (presstos: PresstoDefinition[], dateFilter: string) => {
  switch (dateFilter) {
    case DateFilter.LASTWEEK:
      return presstos.filter((pressto) => {
        const diff = Math.abs(new Date().getTime() - new Date(pressto.updated_at).getTime());
        return diff < 7 * 24 * 60 * 60 * 1000;
      });
    case DateFilter.LASTTWOWEEKS:
      return presstos.filter((pressto) => {
        const diff = Math.abs(new Date().getTime() - new Date(pressto.updated_at).getTime());
        return diff < 14 * 24 * 60 * 60 * 1000;
      });
    case DateFilter.LASTMONTH:
      return presstos.filter((pressto) => {
        const diff = Math.abs(new Date().getTime() - new Date(pressto.updated_at).getTime());
        return diff < 30 * 24 * 60 * 60 * 1000;
      });
    case DateFilter.LASTTHREEMONTHS:
      return presstos.filter((pressto) => {
        const diff = Math.abs(new Date().getTime() - new Date(pressto.updated_at).getTime());
        return diff < 90 * 24 * 60 * 60 * 1000;
      });
    case DateFilter.LASTSEMESTER:
      return presstos.filter((pressto) => {
        const diff = Math.abs(new Date().getTime() - new Date(pressto.updated_at).getTime());
        return diff < 180 * 24 * 60 * 60 * 1000;
      });
    case DateFilter.LASTYEAR:
      return presstos.filter((pressto) => {
        const diff = Math.abs(new Date().getTime() - new Date(pressto.updated_at).getTime());
        return diff < 365 * 24 * 60 * 60 * 1000;
      });
    default:
      return presstos;
  }
};

export const sortByKey = (a: any, b: any, key: string) => {
  if (!b[key]) return -1;
  if (!a[key]) return 1;
  if (a[key]! < b[key]!) return -1;
  if (a[key]! > b[key]!) return 1;
  return 0;
};

export const formatDiff = (diff: number, expired = false) => {
  if (expired || diff < 0) {
    return 'Expired';
  }

  if (diff === 0) {
    return 'Today';
  }

  if (diff === 1) {
    return 'Tomorrow';
  }

  if (diff < 30) {
    return `${diff} days`;
  }

  if (diff < 365) {
    return `${Math.floor(diff / 30)} months`;
  }

  if (diff < 365 * 2) {
    return 'Last year';
  }

  return `${Math.floor(diff / 365)} years`;
};

export const formatNumber = (number: number) => number.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

export const generateRandomCode = (length = 6) => {
  const DIGITS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let code = '';
  for (let i = 0; i < length; i += 1) {
    code += DIGITS[Math.floor(Math.random() * DIGITS.length)];
  }
  return code;
};

/** Return the teacher's first initial and last name */
export const teacherDisplayName = (user: {
  name?: string | null,
  surname?: string | null,
}) => {
  let displayName = '';
  if (user.name && user.name.length >= 1) {
    // Display the teacher's first initial.
    displayName += `${user.name[0]}. `;
  }
  if (user.surname) {
    displayName += user.surname;
  }

  return displayName;
};

export const array2dict = (array: { id: string, name: string }[]) => array.reduce((dict, item) => {
  const next = { ...dict };
  next[item.id] = item.name;
  return next;
}, {} as { [key: string]: string });

export const getWordCount = (p: PageType) => {
  let pageCount = 0;

  if (p?.content) {
    for (let i = 0; i < p.content.length; i += 1) {
      const element = p.content[i];
      if (element.type === 'TextBox') {
        for (let j = 0; j < element.value.blocks.length; j += 1) {
          const el = element.value.blocks[j];
          const words = el.text.replace(/\uFEFF/g, '').split(/\s+/).filter((w: string) => w !== '');
          pageCount += words.length;
        }
      }
    }
  }

  return pageCount;
};

export const getPrefix = (str: string) => {
  if (!str) return '';
  const parts = str.split('_');
  if (parts.length === 1) return '';
  return parts.slice(0, parts.length - 1).join('_');
};

export const getSufix = (str: string) => {
  if (!str) return '';
  const parts = str.split('_');
  if (parts.length === 1) return '';
  return parts[parts.length - 1];
};

export const getPredominantColor = (content: TextBoxType[]) => {
  if (content.length === 0) return '#000000';

  const colorsCount = content.reduce((prev1, el) => {
    const elBlocks = el.value.blocks as ContentBlock[];
    const next1 = elBlocks.reduce((prev2, block) => block.inlineStyleRanges
      .reduce((prev3, style) => {
        if (!style.style.startsWith('TEXT_COLOR')) return prev3;

        if (style.style in prev3) {
          return { ...prev3, [style.style as string]: prev3[style.style] + style.length };
        }

        return { ...prev3, [style.style as string]: style.length };
      }, prev2), prev1);
    return next1;
  }, {} as { [key: string]: number });

  const sortedCount = Object.entries(colorsCount).sort((x, y) => y[1] - x[1]);
  if (sortedCount.length > 0) {
    const maxValue = getSufix(sortedCount[0][0]);
    return maxValue in COLORS ? COLORS[maxValue as keyof typeof COLORS] : '#000000';
  }
  return '#000000';
};

export const getPresstoCardPredominantColor = (version: any) => {
  try {
    if (!version) return '#A8A7CC40';
    const { pages: [firstPage] } = version;
    const pageMeta = firstPage?.meta && typeof firstPage?.meta === 'string' ? JSON.parse(firstPage.meta) : {};
    return pageMeta.predominantColor ? `${pageMeta.predominantColor}80` : '#A8A7CC40';
  } catch {
    return '#A8A7CC60';
  }
};

export const findWord = (word: string, text: string) => {
  const regex = new RegExp(`([^a-zA-Z0-9]|$)${word}([^a-zA-Z0-9]|$)`);
  return regex.test(text);
};

/**
 * Iterate over the document's pages. Use the title in the first Title block
 * found, if any.
 */
export const inferDocumentTitleFromBlock = (document: Document) => {
  for (let i = 0; i < document.version.pages.length; i += 1) {
    const page = document.version.pages[i];
    if (isBlockPage(page)) {
      for (let j = 0; j < page.grid.blocks.length; j += 1) {
        const block = page.grid.blocks[j];
        if (block.typeId === BlockTypesEnum.Title && block.properties.plainText) {
          return block.properties.plainText;
        }
      }
    }
  }

  return '';
};

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { debounce } from 'src/utils';
import { useUserStore } from 'src/hooks/zustand/user';

declare global {
  interface Window {
    Intercom: (command: string, data?: any) => void;
  }
}

// Treat Intercom as a singleton. Don't boot multiple times.
let intercomStatus: 'booted' | 'shutdown' | 'not-booted' = 'not-booted';

/**
 * Invoke the Intercom integration.
 * Intercom is a customer messaging platform. This function was implemented using
 * the documentation at:
 *
 * https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
 */
export function useIntercom() {
  const user = useUserStore((state) => state.user);
  const location = useLocation();

  useEffect(() => {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      hide_default_launcher: true,
    });
    intercomStatus = 'booted';
  }, []);

  useEffect(() => {
    if (user.id) {
      const intercomUser = {
        hide_default_launcher: user.role !== 'teacher',
        user_id: user.id,
        email: user.role !== 'student' ? user.email : '',
        first_name: user.name,
        last_name: user.surname,
        email_domain: user.email ? `@${user.email.split('@')[1]}` : '',
        role: user.role,
        created_at: user.createdAt ? Math.floor(Number(user.createdAt) / 1000) : null,
        flags: user.flags.join(', '),
        last_request_at: Math.floor(Date.now() / 1000),
      };

      if (intercomStatus === 'booted') {
        debounce(() => {
          window.Intercom('update', intercomUser);
        }, 100, 'update-intercom');
      } else {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          ...intercomUser,
        });
        intercomStatus = 'booted';
      }
    } else if (intercomStatus === 'booted') {
      window.Intercom('shutdown');
      intercomStatus = 'shutdown';
    }
  }, [user, location]);
}

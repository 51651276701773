import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '@mui/material';
import { Create } from '../../assets/icons';
import ClassroomButton from '../../components/ClassroomButton';
import { ClassroomsTabProps } from './types';
import { useClassroomStore } from '../../hooks/zustand/classrooms';
import { ClassroomGrid } from './styles';
import { lang } from '../../lang';
import { useUserStore } from '../../hooks/zustand/user';

function ClassroomsTab({ onCreateClassroomButtonClick }: ClassroomsTabProps) {
  const classrooms = useClassroomStore((state) => state.classrooms);
  const loadClassrooms = useClassroomStore((state) => state.loadClassrooms);
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    if (user.role === 'teacher') loadClassrooms();
  }, []);

  const navigate = useNavigate();

  window.document.title = 'Classrooms';
  return (
    <div>
      <ClassroomGrid>
        <div className="pressto-count-row">
          <div style={{ marginTop: '.5rem' }} className="pressto-count">
            {lang('general.found')}
            {' '}
            <strong>
              {lang('classroom.classroom_tab.classroom_count', { count: classrooms.length })}
            </strong>
          </div>
        </div>
        <div className="content">
          <Card
            className="create pressto-button classroom-button"
            onClick={() => onCreateClassroomButtonClick()}
            elevation={16}
            sx={{
              backgroundColor: 'unset',
            }}
          >
            <img src={Create} alt="+" />
            {lang('classroom.classroom_tab.create')}
          </Card>
          {classrooms.map((classroom) => (
            <ClassroomButton
              key={classroom.id}
              classroom={classroom}
              onClick={(id) => navigate(`/classroom/${id}`)}
            />
          ))}
        </div>
      </ClassroomGrid>
    </div>
  );
}

export default ClassroomsTab;

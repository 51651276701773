import { useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonContainer } from 'src/components/AssignmentButton/styles';

export default function NewAssignmentCard() {
  const theme = useTheme();

  return (
    <Link
      to="/assignment/new"
      style={{
        textDecoration: 'none',
      }}
    >
      <ButtonContainer
        elevation={16}
        className="create"
        sx={{
          '&:hover .info-container': {
            backgroundColor: '#F0F0F477',
          },
        }}
      >
        <div className="info-container">
          <div>
            {/* Spacer. Info container is justify-between. */}
          </div>
          <div
            style={{
              fontSize: '1em',
            }}
          >
            <span
              style={{
                color: theme.palette.primary.main,
                fontSize: '36px',
                fontWeight: 300,
              }}
            >
              +
            </span>
            <br />
            New
            <br />
            Assignment
          </div>
          <div />
        </div>
      </ButtonContainer>
    </Link>
  );
}

import AssignmentAssistantScreen from 'src/views/lesson';
import AssignmentDuplicate from 'src/views/assignment/Duplicate';
import CoteacherInvitation from 'src/views/register/CoteacherInvitation';
import AssignmentScreen from '../views/assignment';
import Register from '../views/register';
import StudentSignUp from '../views/register/StudentSignUp';
import TeacherSignUp from '../views/register/TeacherSignUp';
import SchoolDetected from '../views/register/SchoolDetected';
import FindSchool from '../views/register/FindSchool';
import SchoolInformation from '../views/register/SchoolInformation';
import SchoolValidation from '../views/register/SchoolValidation';
import SignIn from '../views/signIn';
import ResetPassword from '../views/resetPassword';
import BirthdayForm from '../views/register/BirthdayForm';
import GoogleEmail from '../views/register/GoogleEmail';
import JoinCode from '../views/register/JoinCode';
import DocumentScreenV2 from '../views/document-v2';
import PublishedPressto from '../views/publish';
import GoogleRedirect from '../views/register/GoogleRedirect';

const PUBLIC_ROUTES = [
  {
    path: '/',
    name: '',
    component: SignIn,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/code/:joinCode?',
    name: 'Join Code Shortened',
    component: JoinCode,
  },
  {
    path: '/coteacher-invitation/:coteacherInvitationId?',
    name: 'Co-Teacher Invitation',
    component: CoteacherInvitation,
  },
  {
    path: '/register-student',
    name: 'Register Student',
    component: StudentSignUp,
  },
  {
    path: '/register-teacher',
    name: 'Register Teacher',
    component: TeacherSignUp,
  },
  {
    path: '/school-detected',
    name: 'School Detected',
    component: SchoolDetected,
  },
  {
    path: '/find-school',
    name: 'Find School',
    component: FindSchool,
  },
  {
    path: '/school-information',
    name: 'School Information',
    component: SchoolInformation,
  },
  {
    path: '/school-validation',
    name: 'School Validation',
    component: SchoolValidation,
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
  },
  {
    path: '/birthday-form',
    name: 'Birthday Form',
    component: BirthdayForm,
  },
  {
    path: '/document/:id/',
    name: 'Document',
    component: DocumentScreenV2,
  },
  {
    path: '/document/:id/pages/:pageIndex',
    name: 'Document Page',
    component: DocumentScreenV2,
  },
  {
    path: '/publish/:id',
    name: 'Published Pressto',
    component: DocumentScreenV2,
  },
  {
    path: '/publish-l/:id',
    name: 'Published Pressto',
    component: PublishedPressto,
  },
  {
    path: '/document/:id/',
    name: 'Document',
    component: DocumentScreenV2,
  },
  {
    path: '/assignment/:id/duplicate',
    name: 'Assignment',
    component: AssignmentDuplicate,
  },
  {
    path: '/assignment/:id',
    name: 'Assignment',
    component: AssignmentScreen,
  },
  {
    // This is the URL used for sharing assignments with others. It may become
    // more distinct from the assignment route in the future, but for now it
    // shares the same code as AssignmentScreen.
    path: '/templates/:id',
    name: 'Assignment Template',
    component: AssignmentScreen,
  },
  {
    path: '/assistant',
    name: 'AssignmentAssistant',
    component: AssignmentAssistantScreen,
  },
  {
    path: '/document/:id/pages/:pageIndex',
    name: 'Document',
    component: DocumentScreenV2,
  },
  {
    path: '/google-email',
    name: 'Google Email',
    component: GoogleEmail,
  },

  // React router v6 doesn't support optional parameter,
  // so the solution is to create two similar routes
  // https://github.com/remix-run/react-router/issues/7285#issuecomment-620071853
  {
    path: '/oauth/google',
    name: 'Google Redirect',
    component: GoogleRedirect,
  },
  {
    path: '/oauth/google/:type',
    name: 'Google Redirect',
    component: GoogleRedirect,
  },
  {
    path: '/try-it',
    name: 'Try It',
    component: DocumentScreenV2,
  },
];

export default PUBLIC_ROUTES;

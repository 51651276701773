/* eslint-disable no-underscore-dangle */
// TODO: Implement Hubspot integration as a copy of the functionality in
// ./intercom.ts. Invoke the hook from the router, as Intercom.
// Don't over-consolidate the integrations too much. It should be easy to
// add, remove, and change them separately.
// 1h.
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { debounce } from 'src/utils';
import { useUserStore } from 'src/hooks/zustand/user';

declare global {
  interface Window {
    _hsq: [string, any?][];
  }
}

const HUBSPOT_ID = process.env.REACT_APP_HUBSPOT_ID;

window._hsq = window._hsq || [];
const hsq = window._hsq;

export function useHubspot() {
  if (!HUBSPOT_ID) {
    // Generally, conditionals shouldn't go before hooks. In this case,
    // we are checking a constant that is set at compile time, so there
    // will be no variation from invocation to invocation.
    return;
  }

  const user = useUserStore((state) => state.user);
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'hs-script-loader';
    script.async = true;
    script.src = `//js.hs-scripts.com/${HUBSPOT_ID}.js`;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (user.id && user.role === 'teacher') {
      hsq.push(['identify', {
        email: user.email,
        first_name: user.name,
        last_name: user.surname,
        role: user.role,
        created_at: user.createdAt?.toISOString(),
        flags: user.flags,
      }]);
    }
  }, [user]);

  useEffect(() => {
    debounce(() => {
      hsq.push(['setPath', location.pathname]);
      hsq.push(['trackPageView']);
    }, 100, 'hubspot-page-view');
  }, [location]);
}

import { Theme, useMediaQuery } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';

import { useAuthenticatedUser } from 'src/hooks/user';
import { DocumentTemplateSystem } from 'src/types/DocumentSettings';
import {
  PlayImg, PrintImg, ShareImg, SquareImg,
} from '../../assets/icons';
import DropdownMenu from '../DropdownMenu';
import { MenuItemType, MenuRefType } from '../DropdownMenu/types';
import IconButton from '../buttons/IconButton';

import * as DocumentsAPI from '../../api/Documents';
import { useEditor } from '../../hooks/useEditor';
import { useDocumentStore } from '../../hooks/zustand/documents';
import { lang } from '../../lang';
import { Button } from '../buttons';
import CreateAssignmentDialog from '../dialogs/CreateAssignmentDialog';
import PublishDialog from '../dialogs/PublishDialog';
import { TopBarButtonContainer } from './styles';

function ExportButtonGroup({ viewOnlyMode }: { viewOnlyMode: boolean }) {
  const tabletMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const currentDocument = useDocumentStore((state) => state.currentDocument);
  const user = useAuthenticatedUser();

  const {
    toggleViewMode, isViewMode,
  } = useEditor();

  const showPlayButton = !tabletMQ || !(mobileMQ && !viewOnlyMode);

  const [isPrinting, setIsPrinting] = useState(false);
  const [timeMessage, setTimeMessage] = useState('');

  const menuRef = useRef<MenuRefType>(null);

  const [isPublishDialogOpen, setIsPublishDialogOpen] = useState(false);
  const [isCreateAssignmentDialogOpen, setIsCreateAssignmentDialogOpen] = useState(false);

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    menuRef.current?.open(e);
  };

  const getDocumentPdf = async () => {
    setTimeout(() => {
      setTimeMessage(lang('document.top_bar.export.take_a_while'));
    }, 5000);
    setIsPrinting(true);
    if (!currentDocument) return;
    const { id, version, name } = currentDocument;
    const response = await DocumentsAPI.print(id, version.id);
    if (response.status >= 200 && response.status < 300) {
      const link = window.document.createElement('a');
      link.href = response.data.file;
      link.download = `${name}.pdf`;
      link.target = '_blank';
      link.click();
    }
    setIsPrinting(false);
    setTimeMessage('');
  };

  const handleDialogMenu = () => {
    menuRef.current?.close();
    setIsPublishDialogOpen(true);
  };

  const menuItems = useMemo<MenuItemType[]>(() => {
    const items = [];

    // Layout legacy: Disable publish button
    if (currentDocument?.template_system !== 'layout') {
      items.push({
        key: 'publish',
        label: lang('document.top_bar.export.publish'),
        disabled: isPrinting,
        onClick: handleDialogMenu,
      });
    }

    items.push({
      key: 'print',
      label: lang(`document.top_bar.export.${isPrinting ? 'printing' : 'print'}`),
      disabled: isPrinting,
      onClick: getDocumentPdf,
      description: timeMessage,
    });

    if (!showPlayButton) {
      return [
        {
          key: 'view',
          label: lang(`document.top_bar.export.${isViewMode ? 'stop_view_mode' : 'view_mode'}`),
          onClick: toggleViewMode,
        },
        ...items,
      ];
    }
    return items;
  }, [showPlayButton, isViewMode, handleDialogMenu, isPrinting]);

  return (
    <TopBarButtonContainer className={viewOnlyMode ? 'center-container' : ''} style={!mobileMQ ? { gap: '.5rem' } : {}}>
      {mobileMQ ? (
        <>
          {showPlayButton && currentDocument?.isOwner && <IconButton src={isViewMode ? SquareImg : PlayImg} alt="play" onClick={() => toggleViewMode()} />}
          {currentDocument?.isOwner ? (
            <IconButton
              src={ShareImg}
              alt="share"
              onClick={handleOpenMenu}
              // BUTTON PROPS
              id="share-menu"
              aria-controls={menuRef.current?.isOpen ? 'avatar-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={menuRef.current?.isOpen ? 'true' : undefined}
            />
          ) : (
            <IconButton
              src={ShareImg}
              alt="Print"
              onClick={getDocumentPdf}
            />
          )}
        </>
      ) : (
        <>
          <div>
            {
              // Layout legacy: Disable assign button
              currentDocument?.template_system !== DocumentTemplateSystem.LAYOUT
              && currentDocument?.isOwner
              && user?.role === 'teacher'
              && (
                <Button
                  onClick={() => setIsCreateAssignmentDialogOpen(true)}
                  label={lang('document.top_bar.export.assign')}
                  style={{ height: '2.5rem' }}
                />
              )
            }
          </div>
          <div>
            {currentDocument?.isOwner ? (
              <IconButton
                src={ShareImg}
                alt="share"
                onClick={handleOpenMenu}
                label={lang('document.top_bar.export.share')}
                // BUTTON PROPS
                id="share-menu"
                aria-controls={menuRef.current?.isOpen ? 'avatar-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={menuRef.current?.isOpen ? 'true' : undefined}
              />
            ) : (
              <IconButton
                src={PrintImg}
                alt="Print"
                onClick={getDocumentPdf}
                label={lang('document.top_bar.export.print')}
              />
            )}
          </div>
          <div>
            {showPlayButton && !isViewMode && (
              <IconButton
                src={isViewMode ? SquareImg : PlayImg}
                alt="play"
                onClick={() => toggleViewMode()}
                label={lang(`document.top_bar.export.${isViewMode ? 'stop' : 'view'}`)}
                style={{ minWidth: '5.625rem' }}
              />
            )}
          </div>
        </>
      )}
      <DropdownMenu ref={menuRef} items={menuItems} />
      <PublishDialog isOpen={isPublishDialogOpen} onClose={() => setIsPublishDialogOpen(false)} />
      {user?.role === 'teacher' && (
        <CreateAssignmentDialog
          isOpen={isCreateAssignmentDialogOpen}
          onClose={() => setIsCreateAssignmentDialogOpen(false)}
          onOpen={() => setIsCreateAssignmentDialogOpen(true)}
        />
      )}
    </TopBarButtonContainer>
  );
}

export default ExportButtonGroup;

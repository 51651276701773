import React, { useEffect } from 'react';
import {
  useSearchParams, useNavigate,
} from 'react-router-dom';
import * as UserAPI from '../../api/User';
import { useUserStore } from '../../hooks/zustand/user';

function GoogleRedirect() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const setUser = useUserStore((state) => state.setUser);
  const setUserError = useUserStore((state) => state.setUserError);

  const handleCredentialResponse = async () => {
    const data: any = {
      code: searchParams.get('code') || '',
    };

    // We send state through Google authentication from GoogleButton.tsx
    // and receive it here. It is a URL-encoded string in the form of
    // query parameters.
    const state = new URLSearchParams(searchParams.get('state') || '');
    const role = state.get('role') || 'individual';

    try {
      if (localStorage.getItem('registerFlow') === 'signUp') {
        data.join_code = localStorage.getItem('joinCode') || undefined;

        const response: any = await UserAPI.googleSignUp(data, ['teacher'].includes(role));
        if (response.status !== 200 && response.status !== 201) {
          throw response.data;
        }
        setUser(response.data.data);
        return;
      }

      const response: any = await UserAPI.googleSignIn({ code: data.code });
      if (response.status !== 200 && response.status !== 201) throw response.data;
      setUser(response.data.data);
    } catch (error: any) {
      localStorage.removeItem('joinCode');
      setUserError({ error: true, message: error?.response?.data?.message || '' });
      if (localStorage.getItem('registerFlow') === 'signUp') {
        navigate(`/register-${role}`);
      } else {
        navigate('/');
      }
    }
  };

  useEffect(() => {
    handleCredentialResponse();

    return () => {
      localStorage.removeItem('registerFlow');
    };
  }, []);

  return (
    <div />
  );
}

export default GoogleRedirect;

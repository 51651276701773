import React, { useCallback, useMemo, useState } from 'react';
import * as models from 'src/types/models';
import { useApplyDocumentOperation } from 'src/hooks/document';
import { SetBlockOperation } from 'src/hooks/store/document/operations';
import { HandleElement, HandleIcon } from './styles';
import { usePageContext } from '../../../views/document-v2/PageContext';

function ResizeHandle({
  contentRef,
  block,
  neighbors,
  anchor,
}: {
  contentRef: React.RefObject<HTMLDivElement>;
  block: models.Block;
  neighbors?: {
    above: models.Block | null;
    below: models.Block | null;
  };
  anchor: 'top' | 'bottom';
}) {
  const { pageRef } = usePageContext();

  const [handlePosition, setHandlePosition] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const applyOperation = useApplyDocumentOperation();

  const handleResizeBlock = useCallback(
    (event: any) => {
      if (!isDragging || !pageRef?.current) return;

      const { clientY } = event;
      setHandlePosition(clientY - 250);

      // These are the distances from top of the screen to each part of the page
      const { top } = pageRef.current.getBoundingClientRect();
      const middle = top + pageRef.current.getBoundingClientRect().height / 2;
      const bottom = top + pageRef.current.getBoundingClientRect().height;

      if (anchor === 'top') {
        if (Math.abs(clientY - middle) < Math.abs(clientY - top)) {
          // Cursor is closer to middle of the page than top
          if (block.height === 2) {
            applyOperation(
              new SetBlockOperation(block.documentId, {
                id: block.id,
                height: 1,
                y: 2,
              }),
            );
          }
        } else {
          // Cursor is closer to top of the page than middle
          applyOperation(
            new SetBlockOperation(block.documentId, {
              id: block.id,
              height: 2,
              y: 1,
            }),
          );
        }
      } else if (Math.abs(clientY - middle) < Math.abs(clientY - bottom)) {
        // Cursor is closer to middle of the page than bottom and anchor is 'bottom'
        if (block.height === 2) {
          applyOperation(
            new SetBlockOperation(block.documentId, {
              id: block.id,
              height: 1,
            }),
          );
        }
      } else if (block.y === 1 && block.height === 1 && !neighbors?.below) {
        // Cursor is closer to bottom of the page than middle and anchor is 'bottom'
        applyOperation(
          new SetBlockOperation(block.documentId, {
            id: block.id,
            height: 2,
          }),
        );
      }
    },
    [isDragging],
  );

  const onMouseDown = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(true);

      const { clientY } = event;
      setHandlePosition(clientY - 250);
    },
    [isDragging],
  );

  const onMouseUp = () => {
    setHandlePosition(null);
    setIsDragging(false);
  };

  const containerPosition = useMemo(() => {
    let style: Record<string, string | number> = {};
    if (isDragging && handlePosition) {
      style = {
        position: 'fixed',
        width: contentRef.current!.offsetWidth,
        height: 500,
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        top: handlePosition,
      };
    } else {
      style = {
        position: 'absolute',
        bottom: anchor === 'bottom' ? -2 : 'auto',
        top: anchor === 'top' ? -2 : 'auto',
        width: '100%',
      };
    }
    return style;
  }, [isDragging, handlePosition, anchor, contentRef.current]);

  return (
    // Creates a space around the handle so the mouse can move
    // without losing focus of the element while holding down the button
    <span
      style={containerPosition}
      tabIndex={-1}
      role="button"
      onPointerMove={handleResizeBlock}
      onPointerUp={onMouseUp}
      onPointerLeave={onMouseUp}
    >
      <HandleElement
        onPointerDown={onMouseDown}
        aria-label="handle"
        role="button"
        isDragging={isDragging}
      >
        <HandleIcon isDragging={isDragging} />
      </HandleElement>
    </span>
  );
}

export default ResizeHandle;

import * as models from 'src/types/models';
import create from 'zustand';

type Index<T extends { 'id': string | number }> = Record<T['id'], T | null>;

/**
 * A unified, global store for resources. A "resource" is an object that
 * maps to a database record and to an API endpoint. Certain conventions
 * are observed across resources, such as the use of an `id` field.
 *
 * The Document resource is not managed by this store. It is updated using
 * operations instead.
 */
export const useResourceStore = create<{
  assignmentById: Index<models.Assignment & { 'id': string }>;
  setAssignment:(assignment: models.Assignment & { 'id': string }) => void;
}>()((set) => ({
  assignmentById: {},
  setAssignment: (assignment) => {
    set((state) => ({
      assignmentById: {
        ...state.assignmentById,
        [assignment.id]: assignment,
      },
    }));
  },
}));

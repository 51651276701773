import { getPageContainingBlock } from 'src/utils/documents';
import * as models from '../../../../types/models';
import SynchronousDocumentOperation from './synchronous';

/**
 * This operation is designed for fluid grids that can have any number of
 * blocks and do no have empty cells. As such, this operation only supports
 * moving a block within a page. If the old and the new page differ,
 * the operation will be a no-op.
 */
export default class MoveBlockOperation extends SynchronousDocumentOperation {
  readonly type = 'move-block';

  blockId: models.Block['id'];

  newPageId: models.Page['id'];

  newPositionX: number;

  newPositionY: number;

  constructor(
    documentId: string,
    blockId: models.Block['id'],
    newPageId: models.Page['id'],
    newPositionX: number,
    newPositionY: number,
  ) {
    super(documentId);
    this.blockId = blockId;
    this.newPageId = newPageId;
    this.newPositionX = newPositionX;
    this.newPositionY = newPositionY;
  }

  apply(document: models.Document): models.Document {
    // Move page with ID to new position.
    const { version } = document;

    // This operation only supports moving blocks within a page at this time.
    const page = getPageContainingBlock(version, this.blockId);
    if (!page || page.id !== this.newPageId) {
      return document;
    }

    const movingBlock = page.grid.blocks.find((b) => b.id === this.blockId);
    if (!movingBlock) {
      return document;
    }

    // The following is based on the assumption of a fluid grid. Moving blocks
    // in a fixed grid is not accounted for. Fixed grids differ in that they
    // can have two dimensions and empty cells.
    const newBlocks = page.grid.blocks.map((block) => {
      if (this.newPositionY > movingBlock.y) {
        // Moving forward.
        if (block.y > movingBlock.y && block.y <= this.newPositionY) {
          return {
            ...block,
            y: block.y - 1,
          };
        }
      }
      if (this.newPositionY < movingBlock.y) {
        // Moving backward.
        if (block.y >= this.newPositionY && block.y < movingBlock.y) {
          // The block is affected by the move, increment its position.
          return {
            ...block,
            y: block.y + 1,
          };
        }
      }
      if (block.id === movingBlock.id) {
        // The block is being moved, set its position.
        return {
          ...block,
          y: this.newPositionY,
        };
      }
      return block;
    });

    const newPages = version.pages.map((p) => {
      if (p.id === page.id) {
        return {
          ...p,
          grid: models.isBlockPage(p) ? {
            ...p.grid,
            blocks: newBlocks,
          } : undefined,
        };
      }
      return p;
    });

    return {
      ...document,
      version: {
        ...version,
        pages: newPages,
      },
    };
  }
}

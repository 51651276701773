import {
  Block, BlockType,
  Document, isBlockPage,
} from 'src/types/models';
import SynchronousDocumentOperation from './synchronous';

type BlockPosition = Pick<Block, 'x' | 'y' | 'width' | 'height'>;

export default class AddBlockOperation extends SynchronousDocumentOperation {
  readonly type = 'add-block';

  pageId: string;

  blockId: string;

  position: BlockPosition;

  // Fully specify the block type instead of just the ID.
  // This keeps a record of the block type as it was at the time the document
  // was being edited, permitting schema drift at the cost of storing
  // a little more information. It also sets up the ability for users to
  // specify their own block types.
  blockType: BlockType;

  constructor(
    documentId: string,
    pageId: string,
    blockId: string,
    blockType: BlockType,
    position: BlockPosition,
  ) {
    super(documentId);
    this.pageId = pageId;
    this.blockId = blockId;
    this.position = position;
    this.blockType = blockType;
  }

  apply(document: Document): Document {
    // Return a new document version with this block updated.
    const { version } = document;

    return {
      ...document,
      version: {
        ...version,
        pages: version.pages.map((page) => {
          if (page.id === this.pageId && isBlockPage(page)) {
            let { rows } = page.grid;
            if (page.grid.style === 'fluid') {
              rows = Math.max(rows, this.position.y + this.position.height - 1);
            }
            return {
              ...page,
              grid: {
                ...page.grid,
                rows,
                blocks: [
                  ...page.grid.blocks,
                  {
                    id: this.blockId,
                    typeId: this.blockType.id,
                    documentId: document.id,
                    ...this.position,
                    properties: this.blockType.defaultProperties,
                  },
                ],
              },
            };
          }
          return page;
        }),
      },
    };
  }
}

export enum BlockTypesEnum {
  Text = 'Text',
  Title = 'Title',
  Image = 'Image',

  Introduction = 'Introduction',
  Lead = 'Lead',
  Quote = 'Quote',
  CallOut = 'CallOut',
  Conclusion = 'Conclusion',
  ImageCaption = 'ImageCaption',
  Solution = 'Solution',
  StorySolution = 'StorySolution',
  SupportingDetails = 'SupportingDetails',

  MainIdea = 'MainIdea',
  Details = 'Details',

  SettingAndCharacters = 'SettingAndCharacters',
  ProblemOrGoal = 'ProblemOrGoal',
  Attempts = 'Attempts',
  ConsequenceOrResolution = 'ConsequenceOrResolution',
  Consequence = 'Consequence',
  Resolution = 'Resolution',

  PointOfView = 'PointOfView',
  PeopleAndSetting = 'PeopleAndSetting',
  ClaimOrOpinion = 'ClaimOrOpinion',
  Evidence = 'Evidence',

  Event = 'Event',

  Cause = 'Cause',
  Effect = 'Effect',

  Compare = 'Compare',
  Contrast = 'Contrast',

  Problem = 'Problem',

  // These are extra blocks that are not included in default
  // writing plans but which can be added to future custom
  // writing plans.
  Thesis = 'Thesis',
  TopicSentence = 'TopicSentence',
  EvidenceAndLinks = 'EvidenceAndLinks',
  Hook = 'Hook',
  ConcludingSentence = 'ConcludingSentence',
  ConcludingParagraph = 'ConcludingParagraph',
  Background = 'Background',
  Body = 'Body',
  Closing = 'Closing',
  Claim = 'Claim',
  Grounds = 'Grounds',
  Warrant = 'Warrant',
  Qualifier = 'Qualifier',
  Rebuttal = 'Rebuttal',
  Backing = 'Backing',
}

export interface Block {
  id: string,
  typeId: BlockTypesEnum,
  documentId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  properties: BlockProperties;
}

// Block sizing must be specified in a unit that is relative to the
// font size: em. For example, '1em', or '2.5em'. The type system will complain if
// this is not the case.
type BlockSizing = `${number}em`;

export interface BlockType {
  id: BlockTypesEnum;
  title: string;
  label: string;
  icon: string;
  preferredWidth: number;
  preferredHeight: number;
  defaultProperties: BlockProperties;
  configuration: BlockConfiguration;
}

export type BlockProperties = Partial<{
  plainText: string;
  htmlText: string;
  html: string;
  rawEditorContent: string;
  fitFontSize: boolean;
  fontSize: BlockSizing;
  maximumFontSize: BlockSizing;
  fontStyle: string;
  fontFamily: string;
  color: string;
  backgroundColor: string | null;
  fontColor: string;
  orientation: string | null;
  imageUrl: string | null;
  preferredHeight: BlockSizing;
  rotation: 0 | 90 | 180 | 270;
  positionX: number;
  positionY: number;
  zoom: number;
  placeholder: string;
}>;

type BlockConfiguration = Partial<{
  /** The block has editable text. */
  hasText: boolean;
  /** The placeholder text to use for the text editor when content is empty. */
  placeholder: string;
  /** The block has an editable image. */
  hasImage: boolean;
  /** In fluid grids, the block can be resized to an arbitrary height. */
  hasAdjustableHeight: boolean;
}>;

import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import update from 'immutability-helper';

import { BinImg, CopyImg } from '../../assets/icons';
import { useEditor } from '../../hooks/useEditor';
import { ContextMenuItemProps } from '../ContextMenu/types';
import { LayoutPage as PageType } from '../../types/models';
import { DocumentFormat } from '../../types/DocumentSettings';
import ContextMenu from '../ContextMenu';
import WarnPageNumberDialog from '../dialogs/WarnPageNumberDialog';
import { Description } from '../typography';
import { ThumbnailContainer } from './styles';
import { PageThumbnailProps } from './types';
import { useDocumentStore } from '../../hooks/zustand/documents';
import { lang } from '../../lang';
import { reorderPage } from '../../api/Documents';
import PagesContext from './Context';

function PageThumbnail({
  name, pageNo, layoutImg, disabled, index, defaultLayoutImg,
}: PageThumbnailProps) {
  const {
    board,
    selectedPage,
    selectedEl,
    updatePage,
    setIsRefreshing,
  } = useEditor();

  const changeSelectedPage = useDocumentStore((state) => state.changeSelectedPage);
  const updatePages = useDocumentStore((state) => state.updatePages);
  const deletePage = useDocumentStore((state) => state.deletePage);
  const duplicatePage = useDocumentStore((state) => state.duplicatePage);
  const currentDocument = useDocumentStore((state) => state.currentDocument);

  const { pages, setPages } = useContext(PagesContext);

  const [isWarnPageDialogOpen, setIsWarnPageDialogOpen] = useState(false);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setPages(
      (prevPages: PageType[]) => update(prevPages, {
        $splice: [
          [dragIndex, 1], [hoverIndex, 0, prevPages[dragIndex]],
        ],
      }).map((el, i) => ({ ...el, position: i })),
    );
  }, []);

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item: any, monitor) => {
      const { index: newIndex, originalIndex } = item;
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        if (originalIndex) moveCard(originalIndex, newIndex);
        reorderPage(name, newIndex);
      }
    },
  });

  useEffect(() => {
    if (pages) {
      updatePages(() => pages);
    }
  }, [pages]);

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<any, void, { handlerId: Identifier | null }>({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: (item: any, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index!;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item) {
      reorderPage(pages[item.index].id, item.index);
    },
  });

  drag(drop(ref));

  const handleDeletePage = useCallback(async () => {
    setIsWarnPageDialogOpen(false);
    deletePage(
      currentDocument!.id,
      currentDocument!.version.id,
      name,
    );
  }, [currentDocument, name]);

  const handleDeleteWarn = useCallback(() => {
    if (board?.format === DocumentFormat.BOOKLET && pages?.length === 8) {
      setIsWarnPageDialogOpen(true);
    } else {
      handleDeletePage();
    }
  }, [board, pages, handleDeletePage]);

  const menuItems = useMemo<ContextMenuItemProps[]>(() => ([
    { label: lang('general.duplicate'), icon: CopyImg, onClick: () => duplicatePage(name, currentDocument?.isTryIt) },
    {
      label: lang('general.delete'),
      icon: BinImg,
      onClick: handleDeleteWarn,
      style: { color: '#FF5252 !important' },
      disabled: pages?.length === 1,
    },
  ]), [pageNo, handleDeleteWarn]);

  const handleOnError = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = defaultLayoutImg!;
  };

  return (
    <div
      data-handler-id={handlerId}
      ref={ref}
      style={{ opacity: isDragging ? 0.8 : 1 }}
    >
      <ContextMenu menuItems={menuItems} disabled={disabled}>
        <ThumbnailContainer
          active={selectedPage?.id === name}
          onClick={() => {
            if (!selectedEl) {
              if (changeSelectedPage(name)) setIsRefreshing(true);
              if (selectedPage) updatePage(selectedPage);
            }
          }}
        >
          <div className="page-number">
            <Description size="small">{index + 1}</Description>
          </div>
          <img
            onError={handleOnError}
            src={layoutImg || ''}
            alt={`page ${pageNo + 1}`}
          />
        </ThumbnailContainer>
      </ContextMenu>
      <WarnPageNumberDialog
        isOpen={isWarnPageDialogOpen}
        isDelete
        onClose={() => setIsWarnPageDialogOpen(false)}
        onSubmit={handleDeletePage}
      />
    </div>
  );
}

export default PageThumbnail;

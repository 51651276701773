import React, {
  useEffect,
  useState,
} from 'react';

import * as ImageAPI from 'src/api/Image';
import { getImage, getImageData } from 'src/api/Image';
import { lang } from 'src/lang';
import { debounce, theme } from 'src/utils';

import BaseDialog, {
  DialogContent,
  DialogSubTitle,
  DialogTitle,
} from 'src/components/dialogs/BaseDialog';
import ImageGrid from 'src/components/dialogs/UploadDialog/ImageGrid';
import UploadFileMessage from 'src/components/dialogs/UploadDialog/UploadFileMessage';
import SearchInput from 'src/components/inputs/SearchInput';

function UploadDialog({
  isOpen,
  onClose,
  documentId,
  onUpload,
  id,
}: {
  isOpen: boolean;
  onClose: (...args: any[]) => void;
  documentId: string;
  onUpload: (url: string, orientation: string) => void;
  id: string;
}) {
  const [searchValue, setSearchValue] = useState('');
  const [imagesArray, setImagesArray] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');

  const loadPageData = async () => {
    // if (!currentDocument?.id && !currentDocument?.isTryIt) return;
    const { data } = await getImage('try-it', {
      text: searchValue,
      page: currentPage,
    });
    if (data?.data) {
      setImagesArray((prev) => prev
        .concat(data?.data)
        .filter((v, i, a) => a.findIndex((v2) => v2.provider_id === v.provider_id) === i));
      setCurrentPage((prev) => prev + 1);
      setTimeout(() => setIsLoading(false), 200);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (isProcessing) return;
      if (imagesArray.length === 0 || isLoading) loadPageData();
    }
  }, [isOpen, isLoading]);

  useEffect(() => {
    debounce(() => {
      setIsLoading(true);
      setCurrentPage(1);
      setImagesArray([]);
    }, 500);
  }, [searchValue]);

  const handleClose = () => {
    setSearchValue('');
    setIsProcessing(false);

    if (searchValue) {
      setImagesArray([]);
    }

    onClose();
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    let orientation = 'portrait';
    const image = new Image();
    image.src = window.URL.createObjectURL(file);
    image.onload = () => { orientation = image.width > image.height ? 'landscape' : 'portrait'; };

    try {
      const response = await ImageAPI.upload(
        documentId,
        formData,
      );

      onUpload(response.data.data.url, orientation);
      handleClose();
    } catch (err: any) {
      setError(err.response.data.errors?.image || err.response.data.message);
    }
  };

  const handleImageChosen = async (image: string, orientation: string) => {
    setIsProcessing(true);
    try {
      const { data } = await getImageData(documentId, { image_id: image });
      if (data.data) {
        onUpload(data.data.url, orientation);
        handleClose();
      } else {
        setIsProcessing(false);
      }
    } catch {
      setIsProcessing(false);
    }
  };

  const handleScroll = (e: any) => {
    const section = e.target.firstChild;
    const scrollHeight = e.target.clientHeight + e.target.scrollTop;
    if (isLoading || scrollHeight < section.clientHeight * 0.9) return;
    setIsLoading(true);
  };

  return (
    <BaseDialog size="medium" open={isOpen} onClose={handleClose}>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DialogSubTitle>{lang('document.upload_dialog.title.line_1')}</DialogSubTitle>
        <DialogTitle>{lang('document.upload_dialog.title.line_2')}</DialogTitle>
        <SearchInput
          value={searchValue}
          onChange={setSearchValue}
          placeholder={lang('document.upload_dialog.title.search_placeholder')}
          disabled={isProcessing}
        />
        <UploadFileMessage
          id={id}
          error={error}
          isProcessing={isProcessing}
          handleSubmit={handleSubmit}
        />
        <ImageGrid
          images={imagesArray}
          isProcessing={isProcessing}
          onScroll={handleScroll}
          onImageChosen={(provider_id) => {
            const imageElement = document.getElementById(provider_id);
            const orientation = imageElement!.offsetHeight > imageElement!.offsetWidth ? 'portrait' : 'landscape';
            handleImageChosen(provider_id, orientation);
          }}
          sx={{
            marginTop: '24px',
            [theme.breakpoints.up('sm')]: {
              marginTop: '40px',
            },
            minHeight: '50vh',
          }}
        />
      </DialogContent>
    </BaseDialog>
  );
}

export default UploadDialog;

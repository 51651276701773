import * as models from '../../../../types/models';
import SynchronousDocumentOperation from './synchronous';

export default class UnpublishOperation extends SynchronousDocumentOperation {
  readonly type = 'unpublish';

  // eslint-disable-next-line class-methods-use-this
  apply(document: models.Document): models.Document {
    const { version } = document;
    return {
      ...document,
      version: {
        ...version,
        status: 'DRAFT',
      },
    };
  }
}

import {
  Document,
} from '../../../../types/models';
import SynchronousDocumentOperation from './synchronous';

export default class SetWordCountOperation extends SynchronousDocumentOperation {
  readonly type = 'set-word-count';

  isEnabled: boolean;

  constructor(documentId: string, isEnabled: boolean = true) {
    super(documentId);
    this.isEnabled = isEnabled;
  }

  apply(document: Document): Document {
    return {
      ...document,
      meta: {
        ...document.meta,
        hasWordCount: this.isEnabled,
      },
    };
  }
}

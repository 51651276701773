/*
TODO: This file must be moved into views/document
*/
import { ButtonUnstyled } from '@mui/base';
import { Theme, useMediaQuery } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  LargeBackArrowImg, PagesImg, PlusImg,
} from '../../assets/icons';
import DraggableContainer from '../DraggableContainer';
import IconButton from '../buttons/IconButton';
import { Description } from '../typography';
import PageThumbnail from './PageThumbnail';
import { AddPageButton, StyledDialog, Wrapper } from './styles';
import { useEditor } from '../../hooks/useEditor';
import LayoutDialog from '../dialogs/LayoutDialog';
import { LayoutPage as PageType } from '../../types/models';
import { DocumentFormat } from '../../types/DocumentSettings';
import WarnPageNumberDialog from '../dialogs/WarnPageNumberDialog';
import { Chevron } from '../../assets/icons/variantComponents';
import { layoutsByFormat } from '../../configuration/layout-templates';
import { useDocumentStore } from '../../hooks/zustand/documents';
import { lang } from '../../lang';
import PagesContext from './Context';

function SideBar() {
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const {
    board,
    isViewMode,
    handleSelectEl,
  } = useEditor();

  const [isLayoutDialogOpen, setIsLayoutDialogOpen] = useState(false);
  const [isWarnPageDialogOpen, setIsWarnPageDialogOpen] = useState(false);

  const [expanded, setExpanded] = useState(!mobileMQ);

  const currentDocument = useDocumentStore((state) => state.currentDocument);
  const addPage = useDocumentStore((state) => state.addPage);
  const [pages, setPages] = useState<PageType[]>([]);

  useEffect(() => {
    setPages(currentDocument?.version?.pages || []);
  }, [currentDocument?.version?.pages]);

  useEffect(() => {
    setExpanded(!mobileMQ);
  }, [mobileMQ]);

  const layouts = useMemo(() => {
    if (!board) return [];
    const formatted = { '': '' } as any;
    layoutsByFormat[board.format].forEach((el) => { formatted[el.key.toLowerCase()] = el.img; });
    return formatted;
  }, [board?.format]);

  const handleAddPage = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentDocument?.format === DocumentFormat.BOOKLET && pages?.length === 8) {
      setIsWarnPageDialogOpen(true);
    } else {
      setIsLayoutDialogOpen(true);
    }
  }, [currentDocument]);

  const renderMobileVersion = () => (
    <>
      <StyledDialog open={expanded} fullScreen>
        <div className="dialog-header">
          <IconButton src={LargeBackArrowImg} alt="back button" onClick={() => setExpanded(false)} />
          <Description>{lang('general.pages')}</Description>
        </div>
        <DraggableContainer>
          <div className="dialog-pages-grid">
            <DndProvider backend={HTML5Backend}>
              {
                pages?.map((
                  {
                    id: key,
                    position,
                    template: layout,
                    thumbnail_url,
                  }: any,
                  index: number,
                ) => (
                  <PageThumbnail
                    key={key}
                    name={key}
                    pageNo={position}
                    layoutImg={thumbnail_url}
                    defaultLayoutImg={layouts[layout?.toLowerCase()]}
                    disabled={isViewMode}
                    index={index}
                  />
                ))
              }
            </DndProvider>
            {!isViewMode && (
              <div className="add-button-container">
                <AddPageButton onClick={handleAddPage}><img src={PlusImg} alt="plus sign" /></AddPageButton>
              </div>
            )}
          </div>
        </DraggableContainer>
      </StyledDialog>
      {ReactDOM.createPortal(<IconButton
        src={PagesImg}
        alt="pages"
        onClick={() => setExpanded(true)}
      />, document.querySelector('#navigation-bar > .left-container > .page-button')!)}
    </>
  );

  const renderDefaultVersion = () => (
    <Wrapper expanded={expanded}>
      <DraggableContainer>
        <>
          <ButtonUnstyled onClick={() => setExpanded((prev) => !prev)} className="pages-button">
            <div>
              <img src={PagesImg} alt="pages" />
              {expanded && <Description highlight size="small">{lang('general.pages')}</Description>}
            </div>
            <Chevron direction={expanded ? 'left' : 'right'} />
          </ButtonUnstyled>
          <div className="pages-list">
            <DndProvider backend={HTML5Backend}>
              {
                pages?.map((
                  {
                    id: key,
                    position,
                    template: layout,
                    thumbnail_url,
                  }: any,
                  index: number,
                ) => (
                  <PageThumbnail
                    key={key}
                    name={key}
                    index={index}
                    pageNo={position}
                    layoutImg={thumbnail_url}
                    defaultLayoutImg={layouts[layout?.toLowerCase()]}
                    disabled={isViewMode}
                  />
                ))
              }
            </DndProvider>
            {!isViewMode && (
              <AddPageButton onClick={handleAddPage}>
                <img src={PlusImg} alt="plus sign" />
              </AddPageButton>
            )}
          </div>
        </>
      </DraggableContainer>
    </Wrapper>
  );

  return (
    <PagesContext.Provider value={{ pages, setPages }}>
      {mobileMQ ? renderMobileVersion() : renderDefaultVersion()}
      <LayoutDialog
        isOpen={isLayoutDialogOpen}
        onOpen={() => handleSelectEl(null)}
        onClose={() => setIsLayoutDialogOpen(false)}
        handleLayoutSelect={(layout, template) => addPage(layout, template)}
      />
      <WarnPageNumberDialog
        isOpen={isWarnPageDialogOpen}
        onClose={() => setIsWarnPageDialogOpen(false)}
        isDelete={false}
        onSubmit={() => { setIsWarnPageDialogOpen(false); setIsLayoutDialogOpen(true); }}
      />
    </PagesContext.Provider>
  );
}

export default SideBar;

import { Theme, useMediaQuery } from '@mui/material';
import React, {
  useEffect, useState,
} from 'react';
import {
  useNavigate, useParams, Link as RouterLink, useLocation,
} from 'react-router-dom';
import { Box } from '@mui/system';
import BackButton from 'src/components/BackButton';
import { APIResourceType } from 'src/api';
import GalleryTopBar from '../../components/GalleryTopBar';
import AssignmentPageContainer from './styles';
import { Description } from '../../components/typography';
import DeleteConfirmationDialog from '../../components/dialogs/DeleteConfirmationDialog';
import { GoogleClassroomImg } from '../../assets/images';
import * as AssignmentsAPI from '../../api/Assignments';
import * as ClassroomsAPI from '../../api/Classrooms';
import { useUserStore } from '../../hooks/zustand/user';
import { lang } from '../../lang';
import AssignmentDocumentButton from '../../components/AssignmentDocumentButton';
import { PresstoDefinition } from '../../types/Gallery';

const BACK_BUTTON_BEHAVIOR = {
  label: lang('assignment.assignment_tab.go_back_to_assignemnts'),
  fallbackTo: {
    pathname: '/gallery',
  },
  fallbackState: { tab: 1 },
};

function AssignmentReview() {
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);

  const user = useUserStore((state) => state.user);

  const { id: assignmentId } = useParams();

  const tabletMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const location = useLocation();

  const [assignment, setAssignment] = useState<APIResourceType<typeof AssignmentsAPI.getById>>();
  const [documents, setDocuments] = useState<any>([]);
  const [classrooms, setClassrooms] = useState<APIResourceType<typeof ClassroomsAPI.getAll>>([]);

  const loadData = async () => {
    const responseAssignment = await AssignmentsAPI.getById(assignmentId!);
    if (responseAssignment.status === 200) {
      setAssignment(responseAssignment.data.data);
    }
    const responseSubmissions = await AssignmentsAPI.getDocumentsSubmissions(assignmentId!);
    if (responseSubmissions.status === 200) {
      setDocuments(responseSubmissions.data.data);
    }
    const responseClassrooms = await ClassroomsAPI.getAll();
    if (responseClassrooms.status === 200) {
      setClassrooms(responseClassrooms.data.data);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (!user || user.role !== 'teacher') {
      navigate('/gallery', { state: { tab: 1 } });
      return () => { };
    }
    if (assignmentId) {
      loadData();
      interval = setInterval(loadData, Math.random() * 8000 + 2000);
    }
    return () => interval && clearTimeout(interval);
  }, [assignmentId]);

  const handleDeleteAssignment = async () => {
    const response = await AssignmentsAPI.deleteAssignment(assignment!.id);
    if (response.status === 200) {
      navigate('/gallery', { state: { tab: 1 } });
    }
  };

  // Create a list of documents. This is a view-model that unions
  // the actual student submissions with students who were assigned work
  // but haven't started.
  const entriesByStudentId: Record<string, any> = {};

  if (assignment) {
    // First create a blank entry for each student who was assigned directly.
    assignment.students.forEach((student) => {
      entriesByStudentId[student.id] = {
        ...assignment['base-document'],
        name: assignment.name,
        user: {
          ...student,
          email: '',
          role: 'student',
        },
        submission: {
          id: '',
          status: 'new',
          student_id: student.id,
          assignment_id: assignment.id,
          document_id: assignment['base-document']?.id || '',
        },
      };
    });

    // Then add students assigned through a classroom.
    assignment.classrooms.forEach((classroom) => {
      classrooms.find((c) => c.id === classroom.id)?.students.forEach((student) => {
        entriesByStudentId[student.id] = {
          ...assignment['base-document'],
          name: assignment.name,
          user: student,
          submission: {
            id: '',
            status: 'new',
            student_id: student.id,
            assignment_id: assignment.id,
            document_id: assignment['base-document']?.id || '',
          },
        };
      });
    });
  }

  // Then overwrite with the actual documents that have been started.
  documents.forEach((document: any) => {
    entriesByStudentId[document.user.id] = document;
  });

  const entries = Object.values(entriesByStudentId);
  entries.sort((a: any, b: any) => (
    (a.user.name || '').localeCompare(b.user.name || '')
  ));

  return (
    <>
      <GalleryTopBar tab="assignments" />
      <AssignmentPageContainer>
        {tabletMQ && !mobileMQ && (
          <BackButton {...BACK_BUTTON_BEHAVIOR} />
        )}
        {!tabletMQ && (
          <div className="left-column">
            <BackButton {...BACK_BUTTON_BEHAVIOR} />
            <div className="assignment-info">
              {assignment?.classrooms.map((classroom: { provider: string, name: string }) => (
                <div className="classroom-name-info">
                  <Description className="classroom-type">
                    {
                      classroom.provider === 'google' ? (
                        <>
                          <img src={GoogleClassroomImg} alt="google classroom" height={18} />
                          Google Classroom
                        </>
                      ) : 'Pressto'
                    }
                  </Description>
                  <Description className="classroom-name">
                    {classroom.name}
                  </Description>
                </div>
              ))}
              <div className="turned-in-assignment-info">
                <div>
                  <p>{assignment?.turnedin_count || 0}</p>
                  <p>{lang('assignment.assignment_tab.turned_in')}</p>
                </div>
                <div>
                  <p>
                    {entries.length}
                  </p>
                  <p>{lang('assignment.assignment_tab.assigned')}</p>
                </div>
              </div>
              <div style={{
                textAlign: 'center',
                padding: '50px',
              }}
              >
                {assignment && (
                  <RouterLink
                    to={`/assignment/${assignmentId}`}
                    state={{
                      referrer: location,
                      referrerLabel: 'Go back to assignment review',
                    }}
                    style={{
                      fontWeight: 'bold',
                      textDecoration: 'none',
                    }}
                  >
                    Edit Assignment Detail
                  </RouterLink>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="right-column">
          <h1>{assignment?.name}</h1>
          <div className="content">
            {entries.map((entry: PresstoDefinition) => (
              <AssignmentDocumentButton
                key={`${entry.id}-${entry.user.id}-${entry.user.name}`}
                pressto={entry}
                user={entry.user}
                status={entry.submission?.status}
              />
            ))}
          </div>
        </div>
        <Box
          className="delete-assignment"
          onClick={() => setIsDeleteConfirmationDialogOpen(true)}
        >
          {lang('assignment.assignment_tab.delete_assignment')}
        </Box>
      </AssignmentPageContainer>
      <DeleteConfirmationDialog
        title={lang('assignment.assignment_tab.delete_assignment_confirm.title')}
        description={lang('assignment.assignment_tab.delete_assignment_confirm.subtitle')}
        onSubmit={handleDeleteAssignment}
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
    </>
  );
}

export default AssignmentReview;

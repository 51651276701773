import { getBlockType } from 'src/configuration/blocks';
import { DocumentFormat } from 'src/types/DocumentSettings';
import {
  Block,
  BlockPage,
  Document, DocumentVersion,
  isBlockPage,
} from 'src/types/models';
import SynchronousDocumentOperation from './synchronous';

type BlockUpdate = Partial<Block> & {
  id: string
};

function generateCSS(style: Record<string, string | undefined>) {
  return Object.entries(style).map(
    ([key, value]) => `${key}: ${value || 'initial'}`,
  ).join('; ');
}

function generateHtml(document: Document, block: Block): string {
  // Assume that a fixed font-size has been set at the page level
  // and that text font sizes are specified relative that.
  // This HTML does not need to care about font size.
  const { properties } = block;
  const blockType = getBlockType(block);
  const blockStyle: Record<string, string | undefined> = {
    width: '100%',
    height: document.format === DocumentFormat.DOC ? 'auto' : '100%',
    overflow: 'hidden',
    padding: '.5em .6em',
    position: 'relative',
  };
  const textStyle = {
    'font-size': properties.fontSize || blockType.defaultProperties.fontSize || '1em',
    'font-family': properties.fontFamily || blockType.defaultProperties.fontFamily,

    // This causes the text to appear in front of the image because it comes
    // after it in the HTML.
    position: 'relative',
  };

  const innerTextHtml = (blockType.configuration.hasText && properties.htmlText) || '';
  let imageHtml = '';

  if (blockType.configuration.hasImage && block.properties.imageUrl) {
    const imgContainerStyle: Record<string, string> = {
      position: blockType.configuration.hasText ? 'absolute' : 'relative',
      top: '0',
      left: '0',
      height: document.format === DocumentFormat.DOC ? block.properties.preferredHeight || '100%' : '100%',
      width: '100%',
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
    };

    const imgStyle: Record<string, string> = {
      transform: `rotate(${properties.rotation || 0}deg) scale(${properties.zoom || 1}) translate(${properties.positionX || 0}%, ${properties.positionY || 0}%)`,
      [block.properties.orientation === 'portrait' ? 'width' : 'height']: '100%',
    };

    imageHtml = `<div style="${generateCSS(imgContainerStyle)}"><img style="${generateCSS(imgStyle)}" src="${block.properties.imageUrl}"/></div>`;
  }

  const textHtml = `<div style='${generateCSS(textStyle)}'>${innerTextHtml}</div>`;
  const html = `<div style="${generateCSS(blockStyle)}">${imageHtml}${textHtml}</div>`;
  return html;
}

function getPageContainingBlock(version: DocumentVersion, blockId: string): BlockPage | null {
  for (let i = 0; i < version.pages.length; i += 1) {
    const page = version.pages[i];
    if (!isBlockPage(page)) {
      return null;
    }

    for (let j = 0; j < page.grid.blocks.length; j += 1) {
      if (page.grid.blocks[j].id === blockId) {
        return page;
      }
    }
  }

  return null;
}

export default class SetBlockOperation extends SynchronousDocumentOperation {
  readonly type = 'set-block';

  block: BlockUpdate;

  constructor(documentId: string, block: BlockUpdate) {
    super(documentId);
    this.block = block;
  }

  mergeNext(next: SetBlockOperation): SetBlockOperation | false {
    if (
      // The operation is against the same block.
      next.block.id === this.block.id

      // Only text properties are changing.
      // TODO: All for partial property updates. The follow check isn't
      // quite right but it gets the job done.
      && next.block.properties?.plainText && this.block.properties?.plainText
    ) {
      return new SetBlockOperation(
        this.documentId,
        {
          ...this.block,
          properties: {
            ...this.block.properties,
            ...next.block.properties,
          },
        },
      );
    }

    return false;
  }

  apply(document: Document): Document {
    // Return a new document version with this block updated.
    // TODO: Commented until Page->Block relationship is defined.
    const { version } = document;
    const page = getPageContainingBlock(version, this.block.id);
    if (!page) {
      throw new Error(`Page for block ${this.block.id} does not exist`);
    }

    const oldBlock = page.grid.blocks.find((block) => block.id === this.block.id);
    if (!oldBlock) {
      throw new Error(`Block ${this.block.id} does not exist`);
    }

    const html = generateHtml(document, {
      ...oldBlock,
      ...this.block,
    });

    const pageIndex = version.pages.indexOf(page);
    const newPage = {
      ...page,
      grid: {
        ...page.grid,
        blocks: page.grid.blocks.map((block) => {
          if (block.id === this.block.id) {
            return {
              ...block,
              ...this.block,
              properties: {
                ...block.properties,
                ...this.block.properties,
                html,
              },
            };
          }
          return block;
        }),
      },
    };

    return {
      ...document,
      version: {
        ...version,
        pages: [
          ...version.pages.slice(0, pageIndex),
          newPage,
          ...version.pages.slice(pageIndex + 1),
        ],
      },
    };
  }
}

import { Toolbar } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { defaultStyleMap } from 'src/components/Block/Features/Text';
import {
  useApplyDocumentOperation,
  useBlockEditorState,
  useSelectedBlock,
} from 'src/hooks/document';
import { BlockProperties, Document } from 'src/types/models';
import { SetBlockOperation } from 'src/hooks/store/document/operations';
import { getBlockType } from 'src/configuration/blocks';
import TextBoxToolbar from '../../components/Block/Toolbar/TextBoxToolbar';
import ImageBoxToolbar from '../../components/Block/Toolbar/ImageBoxToolbar';

function stopPropagation(event: React.SyntheticEvent) {
  // This prevents the block selection from being cleared when this is clicked on.
  event.stopPropagation();
}

// TODO: The toolbar's position will need to fix at the top of the visible
// area. Alternatively, we'll need to find a different position. I think
// the toolbar is going to take some futzing. 1H

/**
 * Show this toolbar at the top of the artboard when a block is selected.
 */
export default function BlockToolbar(props: { document: Document }) {
  const { document } = props;
  const handleBackgroundColorChange = () => {};
  const selectedBlock = useSelectedBlock()[0];
  const blockType = selectedBlock && getBlockType(selectedBlock);
  const [isImageToolbarExpanded, setIsImageToolbarExpanded] = useState(false);
  const [editorState, setEditorState] = useBlockEditorState(selectedBlock?.id);

  const applyOperation = useApplyDocumentOperation();
  const onPropertiesChange = useCallback(
    (properties: BlockProperties) => {
      applyOperation(
        new SetBlockOperation(selectedBlock!.documentId, {
          id: selectedBlock!.id,
          properties: {
            ...selectedBlock!.properties,
            ...properties,
          },
        }),
      );
    },
    [selectedBlock],
  );

  useEffect(() => {
    // Expand the image toolbar by default if the block has an image but no text.
    if (
      blockType?.configuration.hasImage
      && !blockType?.configuration.hasText
    ) {
      setIsImageToolbarExpanded(true);
    } else {
      setIsImageToolbarExpanded(false);
    }
  }, [blockType?.configuration.hasText, blockType?.configuration.hasImage]);

  const showImageToolbar = selectedBlock && blockType?.configuration.hasImage;
  const showTextToolbar = selectedBlock
    && blockType?.configuration.hasText
    && editorState
    && !isImageToolbarExpanded;

  return (
    <Toolbar
      onMouseUp={stopPropagation}
      onKeyUp={stopPropagation}
      sx={{
        justifyContent: 'center',
        columnGap: 4,
        rowGap: 1,
        flexWrap: 'wrap',
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: showTextToolbar ? 15 : 0,
      }}
    >
      {showTextToolbar && (
        <TextBoxToolbar
          block={selectedBlock}
          document={document}
          editorState={editorState}
          setEditorState={setEditorState}
          isFocused
          handleBackgroundColorChange={handleBackgroundColorChange}
          handleContainerFontChange={(fontFamily) => {
            onPropertiesChange({
              fontFamily,
            });
          }}
          handleContainerFontSizeChange={(fontSize, fitFontSize) => {
            onPropertiesChange({
              fontSize,
              fitFontSize,
            });
          }}
          styleMap={defaultStyleMap}
        />
      )}
      {showImageToolbar && (
        <ImageBoxToolbar
          isExpanded={isImageToolbarExpanded}
          toggleImageToolbar={() => setIsImageToolbarExpanded(!isImageToolbarExpanded)}
          selectedBlock={selectedBlock}
          handleUploadImage={(imageUrl) => onPropertiesChange({ imageUrl })}
          handleFlipImage={(rotation) => onPropertiesChange({ rotation })}
          handleRemoveImage={() => onPropertiesChange({ imageUrl: '' })}
        />
      )}
    </Toolbar>
  );
}

import React from 'react';
import { DocumentFormat } from 'src/types/DocumentSettings';
import { Box } from '@mui/system';
import { DocumentFormatSettings } from 'src/configuration/documents';
import { DragDropContext } from 'react-beautiful-dnd';
import { useApplyDocumentOperation } from 'src/hooks/document';
import MoveBlockOperation from 'src/hooks/store/document/operations/move-block';
import * as models from '../../types/models';
import FixedGrid from './FixedGrid';
import FluidGrid from './FluidGrid';

export default function Page(props: {
  format: DocumentFormat;
  isEditable?: boolean;
  isThumbnail?: boolean;
  showBlockLabels?: boolean;
  page: models.Page | models.BlockPage;
  scale: number;
}) {
  const {
    format,
    isEditable = false,
    isThumbnail = false,
    showBlockLabels = false,
    page,
    scale,
  } = props;
  const formatSettings = DocumentFormatSettings[format];
  const applyOperation = useApplyDocumentOperation();

  // The a base font size is 48px unless adjusted by the format.
  // Within each page, relative units are based on this value.
  const baseFontSizePx = 48 * DocumentFormatSettings[format].scaleFactor;
  const pixelsPerEm = baseFontSizePx * scale;
  const pageStyle: React.CSSProperties = {
    position: 'relative',
    fontSize: `${pixelsPerEm}px`,
    width: `${formatSettings.width / baseFontSizePx}em`,
    minHeight: `${formatSettings.height / baseFontSizePx}em`,
    height: `${formatSettings.height / baseFontSizePx}em`,
    padding: `${formatSettings.pagePadding / baseFontSizePx}em`,
    backgroundColor: '#fff',
  };

  // View Mode (includes non-block documents)
  if (!models.isBlockPage(page)) {
    return (
      <Box
        sx={pageStyle}
        // TODO: There may be a script injection vulnerability here.
        // Look at how the API sanitizes incoming HTML.
        dangerouslySetInnerHTML={{ __html: page.html || '' }}
      />
    );
  }

  // Fluid grids can be any height. They grow with the content.
  if (page.grid.style === 'fluid') {
    delete pageStyle.height;
  }

  return (
    <DragDropContext
      onDragEnd={({ destination, source, draggableId }) => {
        if (
          destination
          && destination.index !== source.index
          && draggableId.startsWith('block-')
        ) {
          const blockId = draggableId.replace(/^block-/, '');
          const newPositionY = destination.index + 1;

          applyOperation(
            new MoveBlockOperation(
              page.documentId,
              blockId,
              page.id,
              1,
              newPositionY,
            ),
          );
        }
      }}
    >
      <Box sx={pageStyle}>
        {page.grid.style !== 'fluid' && (
          <FixedGrid
            grid={page.grid}
            isThumbnail={isThumbnail}
            isEditable={isEditable}
            showBlockLabels={showBlockLabels}
          />
        )}
        {page.grid.style === 'fluid' && (
          <FluidGrid
            page={page}
            grid={page.grid}
            isThumbnail={isThumbnail}
            isEditable={isEditable}
            pixelsPerEm={pixelsPerEm}
          />
        )}
      </Box>
    </DragDropContext>
  );
}

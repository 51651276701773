import React, { useMemo, useRef, useState } from 'react';

import IconButton from 'src/components/buttons/IconButton';
import {
  PrintImg, ShareImg,
} from 'src/assets/icons';
import DropdownMenu from 'src/components/DropdownMenu';
import { MenuRefType, MenuItemType } from 'src/components/DropdownMenu/types';

import * as DocumentsAPI from 'src/api/Documents';
import { lang } from 'src/lang';
import { useUserStore } from 'src/hooks/zustand/user';
import { Document } from 'src/types/models';
import PublishDialog from './dialogs/PublishDialog';

function ShareButton(props: {
  document: Document,
}) {
  const { document } = props;
  const user = useUserStore((state) => state.user);
  const isOwner = document.user?.id === user.id;

  const [isPrinting, setIsPrinting] = useState(false);
  const [timeMessage, setTimeMessage] = useState('');

  const menuRef = useRef<MenuRefType>(null);

  const [isPublishDialogOpen, setIsPublishDialogOpen] = useState(false);

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    menuRef.current?.open(e);
  };

  const getDocumentPdf = async () => {
    setTimeout(() => {
      setTimeMessage(lang('document.top_bar.export.take_a_while'));
    }, 5000);
    setIsPrinting(true);
    const { id, version, name } = document;
    const response = await DocumentsAPI.print(id, version.id);
    if (response.status >= 200 && response.status < 300) {
      const link = window.document.createElement('a');
      link.href = response.data.file;
      link.download = `${name}.pdf`;
      link.target = '_blank';
      link.click();
    }
    setIsPrinting(false);
    setTimeMessage('');
  };

  const handleDialogMenu = () => {
    menuRef.current?.close();
    setIsPublishDialogOpen(true);
  };

  const menuItems = useMemo<MenuItemType[]>(() => [
    {
      key: 'publish',
      label: lang('document.top_bar.export.publish'),
      disabled: isPrinting,
      onClick: handleDialogMenu,
    },
    {
      key: 'print',
      label: lang(`document.top_bar.export.${isPrinting ? 'printing' : 'print'}`),
      disabled: isPrinting,
      onClick: getDocumentPdf,
      description: timeMessage,
    },
  ], [handleDialogMenu, isPrinting]);

  return (
    <div>
      {isOwner ? (
        // Show sharing and printing when the user owns the document.
        <IconButton
          src={ShareImg}
          alt="share"
          onClick={handleOpenMenu}
          label={lang('document.top_bar.export.share')}
          // BUTTON PROPS
          id="share-menu"
          aria-controls={menuRef.current?.isOpen ? 'avatar-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuRef.current?.isOpen ? 'true' : undefined}
        />
      ) : (
        // If the user doesn't own the document, just show the print button.
        <IconButton
          src={PrintImg}
          alt="Print"
          onClick={getDocumentPdf}
          label={lang('document.top_bar.export.print')}
        />
      )}
      <DropdownMenu ref={menuRef} items={menuItems} />
      <PublishDialog
        isOpen={isPublishDialogOpen}
        onClose={() => setIsPublishDialogOpen(false)}
        document={document}
      />
    </div>
  );
}

export default ShareButton;

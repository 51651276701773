import React, {
  useLayoutEffect, useState,
} from 'react';
import {
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';

import {
  SideBar, TopBar, Artboard, FeedbackSideBar,
} from '../../components';
import { useEditor } from '../../hooks/useEditor';
import { useDocumentStore } from '../../hooks/zustand/documents';
import { useUserStore } from '../../hooks/zustand/user';
import { Container, Content } from './styles';
import * as AssignmentAPI from '../../api/Assignments';
import { getDemoDocument } from '../../configuration/layout-templates';

function DocumentScreen() {
  const { id: routeDocumentId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const currentDocument = useDocumentStore((state) => state.currentDocument);
  const setAssignmentStatus = useDocumentStore((state) => state.setAssignmentStatus);
  const getDocumentInfo = useDocumentStore((state) => state.currentDocumentInfo);
  const isLoadingDocument = useDocumentStore((state) => state.isLoadingDocument);
  const setTryItDocument = useDocumentStore((state) => state.setTryItDocument);
  const user = useUserStore((state) => state.user);

  const { toggleViewMode, clearData, isViewMode } = useEditor();

  const [isTryIt] = useState(pathname === '/try-it');
  const [isTurnedIn, setIsTurnedIn] = useState(false);
  const [isAssignment, setIsAssignment] = useState(false);

  const loadDocumentFromApi = async () => {
    if (routeDocumentId) {
      const response: any = await getDocumentInfo(routeDocumentId);

      if (response) {
        setIsTurnedIn(response.submission?.status === 'turnedin');
        setIsAssignment(!!response.submission);
        toggleViewMode(
          response.user.id !== user.id
          || response.submission?.status === 'turnedin',
        );
      } else {
        navigate('/');
      }
    }
  };

  const loadDemoDocument = () => {
    const format = searchParams.get('format')?.toLowerCase();
    if (format === 'booklet' || format === 'story') {
      setTryItDocument(getDemoDocument(format));
    } else {
      setTryItDocument(getDemoDocument('booklet'));
    }
  };

  const onUnsubmit = async () => {
    if (!currentDocument?.submission) {
      return;
    }

    const response = await AssignmentAPI.unsubmit(
      currentDocument.submission.assignment_id!,
      currentDocument.submission.id!,
    );

    if (response.status === 200) {
      setAssignmentStatus(response.data.data.status);
      setIsTurnedIn(false);
      if (currentDocument.isOwner) {
        toggleViewMode();
      }
    }
  };

  useLayoutEffect(() => {
    clearData();
    if (isTryIt) loadDemoDocument();
    else loadDocumentFromApi();
  }, [routeDocumentId]);

  return (
    <Container>
      {!isLoadingDocument && (
        <TopBar
          showBackButton={!isTurnedIn}
          viewOnlyMode={false}
          professorView={user.role === 'teacher'}
          isTurnedIn={isTurnedIn}
          isAssignment={isAssignment}
          onUnsubmit={onUnsubmit}
        />
      )}
      <Content>
        {!isLoadingDocument && !isViewMode && <SideBar />}
        {!isLoadingDocument && (!isViewMode || (
          !currentDocument?.isOwner && user.role === 'teacher')) && <FeedbackSideBar />}
        <Artboard isLoading={isLoadingDocument} animateLoading />
      </Content>
    </Container>
  );
}

export default DocumentScreen;

import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import {
  ArrowLeftImg,
  ExitLeft,
  Note as NoteIcon,
  PencilImg,
  PlayImg,
} from 'src/assets/icons';
import { Logo } from 'src/components';
import AlertBar from 'src/components/AlertBar';
import { Button, IconButton } from 'src/components/buttons';
import TopBar from 'src/components/shared/TopBar';
import NoteButton from 'src/components/shared/note/NoteButton';
import { CreatableNote } from 'src/components/shared/note/types';
import WritingPlans from 'src/configuration/writing-plans';
import { useAssignment } from 'src/hooks/assignment';
import { useDocumentStore } from 'src/hooks/store/document';
import {
  ReturnSubmission,
  TurnInSubmission,
  UnsubmitSubmission,
} from 'src/hooks/store/document/operations';
import { useAuthenticatedUser } from 'src/hooks/user';
import { lang } from 'src/lang';
import { DocumentTemplateSystem } from 'src/types/DocumentSettings';
import { Document, Note } from 'src/types/models';
import { teacherDisplayName } from 'src/utils';
import { Artboard, Panel } from 'src/views/document-v2/Artboard';
import {
  useApplyDocumentOperation,
  useDemoDocument,
  useDocument,
  useSelectedBlock,
} from '../../hooks/document';
import WritingPlanBlockTypes from '../assignment/WritingPlanBlockTypes';
import NotFound from '../notFound';
import BlocksList from './BlocksList';
import DocumentTitle from './DocumentTitle';
import FeedbackSideBar from './FeedbackSideBar';
import PageWrapper from './PageWrapper';
import ShareButton from './ShareButton';
import WritingBuddy from './WritingBuddy';
import * as actions from './actions';
import WritingPlanDialog from './dialogs/WritingPlanDialog';

export default function Edit(props: {
  documentId: string;
  isPublishedView?: boolean;
}) {
  const { documentId, isPublishedView = false } = props;
  const { pageIndex } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isTryIt = documentId === 'try-it';
  let document: Document | null | undefined;
  if (isTryIt) {
    document = useDemoDocument();
  } else {
    document = useDocument(documentId || '');
  }
  const applyOperation = useApplyDocumentOperation(isTryIt);
  const user = useAuthenticatedUser();

  // Get the assignment context in which this document is being edited, if any.
  // Teachers may hit this page from an assignment, in which case the assignment
  // (which may be a draft) is available. Otherwise, check to see if the
  // document is being edited as part of a submission.
  // Assignments share IDs with documents.
  const assignmentId = (
    searchParams.get('assignmentPreview')
    || searchParams.get('assignment')
    || document?.submission?.assignmentId
  );
  const isAssignmentPreview = !!searchParams.get('assignmentPreview');
  const [assignment] = useAssignment(assignmentId);

  // Users can edit the document if they are the person who created the document
  // or they teach a class to which the document was assigned.
  const isOwner = (
    // The document is owned by the current user.
    (document?.user?.id && (document.user.id === user?.id))
  );
  const canEdit = isOwner
    || assignment?.classrooms?.some((ac) => user?.teacherClassrooms?.find((tc) => ac.id === tc.id));

  // Check that the document, when loaded, uses the Blocks system.
  // Otherwise, redirect it to the old editor.
  useEffect(() => {
    if (document && document.templateSystem !== DocumentTemplateSystem.BLOCKS) {
      navigate(
        {
          pathname: `/${
            isPublishedView ? 'publish' : 'document'
          }-l/${documentId}`,
        },
        { replace: true },
      );
    }
  }, [document?.templateSystem]);

  // TODO: Don't access the store directly from components. Move this into
  // an operation or a hook.
  const currentOperationState = useDocumentStore(
    (state) => state.currentOperationState,
  );

  const onSaveTitle = useCallback(
    async (data: { name: string }) => {
      await actions.saveDocument(document!.id, data);
    },
    [document?.id],
  );

  // Manage the Notes associated with this document.
  // They can come from the version or the assignment.
  // Instructions used to be stored as notes. Return them that way for now.
  let instructionNote: Note | null = null;
  if (assignment?.instructions) {
    instructionNote = {
      id: 'instruction',
      text: assignment.instructions!,
      createdAt: assignment.createdAt,
      updatedAt: assignment.updatedAt,
      user: {
        ...assignment.teacher!,
        fullName: `${assignment.teacher!.name} ${assignment.teacher!.surname}`,
      },
      assignmentId: assignment.id,
      versionId: null,
    };
  }
  const notes = useMemo(
    () => [
      ...(instructionNote
        ? [instructionNote]
        : document?.submission?.assignment?.notes || []),
      ...(document?.version.notes || []),
    ],
    [
      document?.version.notes,
      document?.submission?.assignment?.notes,
      instructionNote,
    ],
  );

  const onSaveNote = useCallback(
    async (note: CreatableNote) => {
      await actions.saveNote(document!.id, note);
    },
    [document?.id],
  );

  // Case 1: No page is specified. Use the first page, if there is one.
  // Case 2: A valid page is specified. Use that one.
  // Case 3: A page is specified, but it is out of range. Use the first page.
  const selectedPage = document?.version.pages[Number(pageIndex) - 1];
  useEffect(() => {
    if (
      document?.id
      && !selectedPage
      // Layout legacy: Prevent redirect for non Block layouts
      && document.templateSystem === DocumentTemplateSystem.BLOCKS
    ) {
      // This is an invalid page. Redirect to the last page, if there is one.
      if (document.version.pages.length) {
        navigate(
          {
            ...location,
            pathname: `/document/${document.id}/pages/1`,
          },
          { replace: true },
        );
      }
      if (pageIndex !== undefined) {
        navigate(
          {
            ...location,
            pathname: `/document/${document.id}`,
          },
          { replace: true },
        );
      }
    }
  }, [
    document?.id,
    document?.templateSystem,
    pageIndex,
    navigate,
    selectedPage,
  ]);

  // Clear the block selection when certain interactions outside of
  // a block occur, such as clicking on the artboard background.
  const [selectedBlock, selectBlock] = useSelectedBlock();
  const clearBlockSelection = useCallback(() => {
    selectBlock(null);
  }, []);

  // Set the window name.
  useEffect(() => {
    if (document?.name) {
      window.document.title = document.name;
    }
  }, [document?.name]);

  const [isWritingPlanDialogOpen, setIsWritingPlanDialogOpen] = useState(false);

  if (document === null) {
    return <NotFound />;
  }

  if (document === undefined) {
    // Loading.
    return null;
  }

  const writingPlanId: string | null = document.meta.writingPlan || null;
  const writingPlan = WritingPlans.find((el) => el.id === writingPlanId);

  // The display mode determines which editor controls are visible.
  let displayMode: 'editing' | 'viewing' | 'previewing' | 'reviewing';
  if (canEdit || isTryIt) {
    // If the document is owned by the current user, then it will be in edit
    // mode (with two exceptions).
    if (searchParams.get('display') === 'preview') {
      // Exception 1: The user has put the document into preview mode.
      displayMode = 'previewing';
    } else if (document?.submission?.status === 'turnedin') {
      // Exception 2: The user is a student who has turned in an assignment.
      // In this case, the student can no longer edit the document until
      // it is returned to them for revision.
      displayMode = 'reviewing';
    } else {
      // Otherwise, the document is in edit mode.
      displayMode = 'editing';
    }
  } else if (user?.role === 'teacher' && document?.submission) {
    // The user is a teacher and teaches the class for which
    // this document is a submission.
    // TODO: The logic here is too permissive. There should also be a check
    // that the student is in the teacher's class. (This is enforced by the
    // API, so the problem is cosmetic.)
    displayMode = 'reviewing';
  } else if (isAssignmentPreview) {
    // The document is the base document for an assignment and the appearance
    // of the document to students is being previewed. Use 'reviewing' mode
    // because it shows all of the controls that a student would see but
    // is not editable. Note that the document is in editing mode if the
    // teacher owns the assignment and the document, per the above.
    displayMode = 'reviewing';
  } else {
    // With no special privileges, all other users are viewing.
    // The API limits this to documents that have been published.
    // This can also happen when the document is still loading.
    displayMode = 'viewing';
  }

  const canEditWritingPlan = displayMode === 'editing' && !document.submission;

  return (
    <Artboard
      backgroundColor={
        displayMode === 'previewing' || displayMode === 'viewing'
          ? '#41414E'
          : 'grey.A100'
      }
    >
      <TopBar>
        <TopBar.Section>
          {/* Navigation tools */}
          {(displayMode === 'editing' || displayMode === 'reviewing')
          && !isAssignmentPreview ? (
            // Show a back button when editing and reviewing.
            <IconButton
              src={ArrowLeftImg}
              alt="←"
              label="Go Back"
              onClick={() => {
                if (isAssignmentPreview) {
                  navigate(`/assignment/${assignmentId}`);
                } else if (
                  !isOwner
                  && displayMode === 'reviewing'
                  && document?.submission?.assignment?.id
                ) {
                  navigate(
                    `/assignment/${document.submission.assignment.id}/review`,
                  );
                } else {
                  navigate('/gallery');
                }
              }}
            />
            ) : (
            // When viewing, show the Pressto logo.
              <Logo height="2.25rem" variant="responsive" />
            )}
        </TopBar.Section>

        <TopBar.Section>
          {/* Document status tools */}
          <DocumentTitle
            document={document}
            state={
              displayMode === 'editing' && !isTryIt
                ? currentOperationState
                : undefined
            }
            onSubmit={onSaveTitle}
          />
        </TopBar.Section>

        <TopBar.Spacer />

        <TopBar.Section>
          {/* Assignment and review tools */}
          {(displayMode === 'reviewing' || displayMode === 'editing')
            && (document.submission || assignment) && (
              <NoteButton
                notes={notes}
                onSave={onSaveNote}
                showCreateForm={
                  displayMode === 'reviewing' && !isAssignmentPreview
                }
              />
          )}

          {isOwner && document.submission?.status === 'turnedin' && (
            // The work has been turned in and can be unsubmitted.
            <>
              <IconButton
                onClick={() => {
                  applyOperation(new UnsubmitSubmission(document!.id));
                }}
                label={lang('assignment.common.unsubmit')}
                style={{ height: '2.5rem', paddingLeft: '0.5rem' }}
                src=""
                alt=""
              />
              <Button
                disabled
                label={lang('assignment.common.turned_in')}
                style={{ height: '2.5rem' }}
              />
            </>
          )}

          {isOwner
            && (document.submission?.status === 'draft'
              || document.submission?.status === 'returned') && (
              // The work is assigned and can be turned in.
              <Button
                onClick={() => {
                  applyOperation(new TurnInSubmission(document!.id));
                }}
                label={lang('assignment.common.turnin')}
                style={{ height: '2.5rem' }}
              />
          )}

          {!isOwner
            && user?.role === 'teacher'
            && document.submission?.status === 'turnedin' && (
              // The work has been turned in and can be returned by the teacher.
              <Button
                onClick={() => {
                  applyOperation(new ReturnSubmission(document!.id));
                }}
                label={lang('assignment.common.return')}
                style={{ height: '2.5rem' }}
              />
          )}

          {!isOwner
            && user?.role === 'teacher'
            && document.submission?.status === 'returned' && (
              // The work has been turned in and can be returned by the teacher.
              <Button
                disabled
                label={lang('assignment.common.returned')}
                style={{ height: '2.5rem' }}
              />
          )}
        </TopBar.Section>

        <TopBar.Section>
          {isOwner && user.role === 'teacher' && !isAssignmentPreview && (
            <Button
              onClick={() => {
                // Navigate to the assignment creation page.
                navigate(`/assignment/new?document=${document?.id}`);
              }}
              label={lang('document.top_bar.export.assign')}
              style={{ height: '2.5rem' }}
            />
          )}

          {isAssignmentPreview && (
            <IconButton
              src={ExitLeft}
              alt=""
              label="Exit Student Preview"
              disabled={!document?.id}
              onClick={() => {
                if (isOwner) {
                  navigate(`/assignment/${assignmentId}`);
                } else {
                  navigate(`/templates/${assignmentId}`);
                }
              }}
            />
          )}
        </TopBar.Section>

        {!isAssignmentPreview && (
          <TopBar.Section>
            {/* Publishing tools */}
            {displayMode === 'editing' && (
              <IconButton
                src={PlayImg}
                label={lang('document.top_bar.export.view')}
                onClick={() => {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.set('display', 'preview');
                  setSearchParams(newParams);
                }}
                alt=""
              />
            )}
            {displayMode === 'previewing' && (
              <IconButton
                src={PencilImg}
                label={lang('document.top_bar.export.stop')}
                onClick={() => {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.delete('display');
                  setSearchParams(newParams);
                }}
                alt=""
              />
            )}

            <ShareButton document={document} />
          </TopBar.Section>
        )}
      </TopBar>

      {isAssignmentPreview && (
        <AlertBar>
          <b>Student Preview: </b>
          {isOwner
            ? 'A copy of this Starting Document is made for each student. Customize it by adding blocks, images, or text.'
            : 'You are viewing the Starting Document for this assignment template. Exit student preview and click “Adapt for my students” to customize it.'}
        </AlertBar>
      )}

      <Box onMouseUp={clearBlockSelection}>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'block',
            },
            position: 'fixed',
            width: {
              sm: '33%',
              md: '25%',
            },
          }}
        >
          {(displayMode === 'editing' || displayMode === 'reviewing') && (
            <Panel>
              {assignment?.instructions && (
                <Panel.Section
                  title="Instructions"
                  isExpandable
                  isExpandedByDefault
                >
                  <Box
                    sx={{
                      fontSize: '.95em',
                      lineHeight: '1.4em',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 1,
                      }}
                    >
                      <img src={NoteIcon} alt="Pushpin Icon" />
                      <b>{teacherDisplayName(assignment.teacher)}</b>
                    </Box>

                    <Markdown>{assignment.instructions}</Markdown>
                  </Box>
                </Panel.Section>
              )}

              <Panel.Section
                isExpandable
                isExpandedByDefault={false}
                title={(
                  <div>
                    <Typography variant="subtitle2" color="secondary.main">
                      Writing Plan
                      {canEditWritingPlan && (
                        // eslint-disable-next-line
                        <a
                          href="#"
                          onClick={() => {
                            setIsWritingPlanDialogOpen(true);
                          }}
                          style={{
                            color: '#163567',
                            textDecoration: 'none',
                            fontWeight: 'bold',
                            marginLeft: '1em',
                          }}
                        >
                          <small>{writingPlan ? 'Change' : 'Add'}</small>
                        </a>
                      )}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      color="secondary.main"
                    >
                      {writingPlan ? writingPlan.title : 'Not selected'}
                    </Typography>
                  </div>
                )}
              >
                {writingPlan && (
                  <WritingPlanBlockTypes
                    writingPlan={writingPlan}
                    isDraggable={displayMode === 'editing'}
                  />
                )}

                <WritingPlanDialog
                  open={isWritingPlanDialogOpen}
                  onClose={() => setIsWritingPlanDialogOpen(false)}
                  onAdd={(id: string) => {
                    actions.saveDocument(document!.id, {
                      meta: {
                        ...document!.meta,
                        writingPlan: id,
                      },
                    });
                    setIsWritingPlanDialogOpen(false);
                  }}
                  initialWritingPlan={writingPlanId || undefined}
                />
              </Panel.Section>

              <Panel.Section title="Blocks" isExpandable isExpandedByDefault>
                <BlocksList
                  document={document}
                  selectedPage={selectedPage}
                  isEditable={displayMode === 'editing'}
                />
              </Panel.Section>
            </Panel>
          )}
        </Box>

        <Box
          onMouseUp={(event) => {
            event.stopPropagation();
          }}
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
            width: '25%',
            position: 'fixed',
            right: 0,
            height: `calc(100vh - 64px - ${
              isAssignmentPreview ? '32px' : '0px'
            })`,
            maxHeight: `calc(100vh - 64px - ${
              isAssignmentPreview ? '32px' : '0px'
            })`,
            flexDirection: 'column',
          }}
        >
          {document.meta.hasWritingBuddy
            && (displayMode === 'editing' || displayMode === 'reviewing')
            && selectedPage && (
              <WritingBuddy
                document={document}
                selectedBlock={selectedBlock}
                selectedPage={selectedPage}
                assignment={assignment}
                isEditable={displayMode === 'editing'}
                // Teachers can use the writing buddy in assignment preview mode,
                // but messages are not saved.
                isPreview={isAssignmentPreview}
              />
          )}
          {document.meta.hasWritingBuddy && <div style={{ flexGrow: 1 }} />}
          {(displayMode === 'editing' || displayMode === 'reviewing') && (
            <FeedbackSideBar
              document={document}
              isEditable={canEditWritingPlan}
            />
          )}
        </Box>

        <Box
          sx={{
            marginLeft: {
              xs: 0,
              sm: '33%',
              md: '25%',
            },
            marginRight: {
              xs: 0,
              sm: 0,
              md: '25%',
            },
          }}
        >
          {selectedPage && (
            <PageWrapper
              page={selectedPage}
              document={document}
              isEditable={displayMode === 'editing'}
              showBlockLabels={
                displayMode === 'editing' || displayMode === 'reviewing'
              }
            />
          )}
        </Box>
      </Box>
    </Artboard>
  );
}

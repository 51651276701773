import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as SchoolAPI from '../../api/School';
import * as UserAPI from '../../api/User';
import { Form, Input, Logo } from '../../components';
import { Button } from '../../components/buttons';
import { Title, ErrorMessage } from '../../components/typography';
import { lang } from '../../lang';
import { useUserStore } from '../../hooks/zustand/user';

function SchoolForm() {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const user = useUserStore((state) => state.user);
  const authCheck = useUserStore((state) => state.authCheck);

  const navigate = useNavigate();

  const handleChangeName = (e: any) => setName(e.target.value);

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    try {
      if (user.id && user.role === 'unselected') {
        // The user is on this page because they are about to identify
        // as a teacher.
        await UserAPI.updateUser({
          userId: user.id,
          role: 'teacher',
        });
      }

      const response = await SchoolAPI.create({ name });
      if (response.status !== 201) {
        throw response.data;
      }
      await authCheck();
      navigate('/');
    } catch (error: any) {
      setErrorMessage(error.response.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (errorMessage) setErrorMessage('');
  }, [name]);

  return (
    <Grid container direction="column" alignItems="center" marginTop="100px">
      <Logo />

      <div style={{ marginTop: 50 }}>
        <Title>{lang('auth.signup.school.register_school_title')}</Title>
      </div>

      <Form onSubmit={handleSubmit} style={{ width: 310, marginTop: 50 }}>
        <Input
          placeholder={lang('auth.signup.school.name_placeholder')}
          required
          error={!!errorMessage}
          value={name}
          onChange={handleChangeName}
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <Button
          style={{ marginTop: 40 }}
          type="submit"
          label={lang('auth.signup.school.signup')}
          disabled={loading || !name}
          large
        />
      </Form>
    </Grid>
  );
}

export default SchoolForm;

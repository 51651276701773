import { useCallback } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticatedUser } from './user';

/**
 * Return a function that redirects to the login page. An optional URL
 * may be supplied to that function to remember which page should be seen
 * after login.
 */
export function useRedirectToLogin(options?: {
  /** If true, the user is not redirected if they are already logged in.
   * Defaults to true. */
  checkRegistration?: boolean,

  /** A different URL for the login page may be supplied if you want to force,
   * for example, the registration page as a starting point. Sends the user
   * to the login page, by default. */
  loginUrl?: To;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAuthenticatedUser();

  const {
    checkRegistration = true,
    loginUrl = '/',
  } = options || {};

  return useCallback((nextUrl: To = location) => {
    if (checkRegistration && user && user.role !== 'anonymous') {
      // The user is registered. That means they are logged in and are
      // not anonymous.
      return;
    }

    let postLoginState;
    try {
      postLoginState = JSON.parse(localStorage.getItem('postLoginState') || '{}');
    } catch (e) {
      postLoginState = {};
    }
    try {
      localStorage.setItem('postLoginState', JSON.stringify({
        ...postLoginState,
        next: nextUrl,
      }));
    } catch (e) {
      // Ignore error. Redirect after login will not happen.
    }

    navigate(loginUrl);
  }, [location, navigate, user, checkRegistration, loginUrl]);
}

import React, { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthenticatedUser } from 'src/hooks/user';
import { useDocumentStore } from '../../hooks/zustand/documents';
import { useUserStore } from '../../hooks/zustand/user';
import DropdownMenu from '../DropdownMenu';
import { MenuRefType } from '../DropdownMenu/types';
import { CleanButton } from './styles';

function AvatarMenu() {
  const user = useAuthenticatedUser();
  const logout = useUserStore((state) => state.logout);
  const resetDocuments = useDocumentStore((state) => state.resetDocuments);

  const MenuRef = useRef<MenuRefType>(null);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    MenuRef.current?.open(e);
  };

  const navigate = useNavigate();

  const menuItems = useMemo(() => {
    const profileItem = {
      key: 'profile',
      label: 'Profile',
      onClick: () => {
        navigate('/profile');
        MenuRef.current?.close();
      },
    };

    const helpCenterItem = {
      key: 'help',
      label: 'Help Center',
      onClick: () => window.open('https://help.joinpressto.com/en/', '_blank'),
    };

    const logoutItem = {
      key: 'logout',
      label: 'Logout',
      onClick: async () => {
        resetDocuments();
        await logout();
        navigate('/');
      },
    };

    if (!user) {
      return [];
    }

    if (user.role === 'teacher') {
      return [profileItem, helpCenterItem, logoutItem];
    }

    const joinCodeItem = {
      key: 'joinCode',
      label: 'Join a Class',
      onClick: () => {
        MenuRef.current?.close();
        navigate('/code');
      },
    };

    return [profileItem, joinCodeItem, helpCenterItem, logoutItem];
  }, [user, MenuRef, navigate]);

  return (
    <div>
      <CleanButton
        id="avatar-button"
        aria-controls={MenuRef.current?.isOpen ? 'avatar-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={MenuRef.current?.isOpen ? 'true' : undefined}
        onClick={handleOpen}
        sx={[
          user?.masqueradingAdminId ? {
            border: '3px solid #dd2222',
          } : null,
        ]}
      >
        <div id="avatar">
          {user?.name?.slice(0, 1)}
          {user?.surname?.slice(0, 1)}
        </div>
      </CleanButton>
      <DropdownMenu
        ref={MenuRef}
        items={menuItems}
      />
    </div>
  );
}

export default AvatarMenu;

import { styled } from '@mui/system';
import { theme } from '../../utils';

export const PresstoGrid = styled('div')(() => ({
  // Skip top bar and center.
  margin: '0 auto 80px auto',
  width: 'min-content',

  '.content': {
    display: 'grid',
    gap: '1.25rem',
    gridTemplateColumns: 'repeat(2, 1fr)',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(4, 12.5rem)',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(5, 12.5rem)',
    },

    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(6, 12.5rem)',
    },
  },

  // The templates variation acts as a carousel, allowing scroll from
  // side to side.
  '.templates': {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '1.25rem',
    alignItems: 'center',
    position: 'relative',
    borderBottom: '1px solid',
    paddingBottom: '2.5rem',
    borderColor: theme.palette.grey[300],
  },

  '.page-button': {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    padding: 0,
    minWidth: '40px',
    backgroundColor: theme.palette.grey[300],
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    transition: 'none',
  },

  '.filters': {
    marginTop: '1rem',
    display: 'flex',
    gap: '8px',
  },

  '.pressto-count-row': {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '.75rem',
    alignItems: 'center',
    margin: '2rem 0',
  },

  '.absolute': {
    position: 'absolute',
    top: '2rem',
    fontSize: '.75rem',
    color: '#2C2C2C',
  },

  '.pressto-count': {
    color: '#2C2C2C',
    fontSize: '.875rem',

    strong: {
      fontSize: '1.125rem',
    },
  },

  '.sorted-by': {
    display: 'flex',
    alignItems: 'center',

    '#sort-by-button': {
      border: '1px solid #596F93',
      backgroundColor: '#FFFFFF',
      color: '#163567',
      backgroundOpacity: 1,
      padding: '.5rem 1rem',
      textDecoration: 'none',
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
    },

    span: {
      marginRight: '1rem',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },

  '.pressto-button': {
    [theme.breakpoints.up('sm')]: {
      height: '17.5rem',
      width: '12.5rem',
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: '2rem',
      aspectRatio: '12.5 / 17.5',
    },

    overflow: 'hidden',
    position: 'relative',
    textDecoration: 'none',
    transition: 'all .15s ease-in-out',

    '&:hover': {
      background: '#FFFFFF',
    },
  },

  '.create': {
    // The create button has a plus sign and a label.
    // Otherwise, it has similar shape and coloring to the rest
    // of the objects in the grid.
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: '700',

    img: {
      marginBottom: '20px',
    },
  },

  '.assignment-button': {
    overflow: 'hidden',
    position: 'relative',

    '&:hover': {
      background: '#FFFFFF',
    },
  },

  '.show-assignments': {
    cursor: 'pointer',
    left: 'calc(50% + 1.75rem)',
    transform: 'translateX(-50%)',
    textDecoration: 'underline',
  },
}));

export const ClassroomGrid = styled(PresstoGrid)(() => ({
  '.classroom-button': {
    height: '12.5rem',
    width: '12.5rem',
    background: '#F6F6FA',
    overflow: 'hidden',
    position: 'relative',

    '&:hover': {
      background: '#FFFFFF',
    },
  },
}));

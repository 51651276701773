import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as UserAPI from '../../api/User';

import {
  FlexBox, Form, GoogleButton, GradientContainer, Input, Logo, VerificationEmailSent,
} from '../../components';
import PrivacyAndTerms from '../../components/PrivacyAndTerms';
import { Button } from '../../components/buttons';
import { Description, Link, Title } from '../../components/typography';
import { useUserStore } from '../../hooks/zustand/user';

import { lang } from '../../lang';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function StudentSignUp({ action }: { action?: string; }) {
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const [email, setEmail] = useState(user.email ?? '');
  const [emailValidation] = useState<any>(action === 'verify_email');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    const joinCode = searchParams.get('join_code');

    const form: any = {
      type: 'student',
      email: '',
      username: '',
      join_code: '',
    };
    if (email.match(EMAIL_REGEX)) {
      form.email = email;
      delete form.username;
    } else {
      form.username = email;
      delete form.email;
    }

    if (joinCode) form.join_code = joinCode;
    else delete form.join_code;

    try {
      const response = await UserAPI.signUp(form);
      if (response.status >= 200 && response.status < 300) {
        setUser(response.data.data);
      } else {
        throw response.data;
      }
    } catch (err: any) {
      setError(err.response.data?.message);

      let url = '';

      if (err.response.data?.message?.includes(lang('auth.errors.email_is_google_account'))) {
        url += '/google-email';
      } else if (err.response.data?.message.includes(lang('auth.errors.field_required', { field: 'birthdate' }))) {
        url += '/birthday-form';
      }

      const params = new URLSearchParams('');

      if (email.match(EMAIL_REGEX)) params.append('email', email);
      else params.append('username', email);

      if (joinCode) params.append('join_code', joinCode);

      params.append('userType', 'student');

      navigate(`${url}?${params}`);
    }
    setLoading(false);
  };

  const renderEmailCreation = () => (
    <>
      <FlexBox textAlign="center" width={310} justifySelf="center">
        <Title marginBottom={5}>{lang('auth.signup.start_signup')}</Title>

        <GoogleButton type="signUp" />

        <Description size="small">{lang('auth.commom.email_or_username_label')}</Description>
        <Form onSubmit={handleSubmit}>
          <Input
            required
            placeholder={lang('auth.commom.email_or_username_placeholder')}
            aria-label="email"
            value={email}
            error={!!error}
            invalidMessage={error}
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            aria-label="submit"
            label={lang('auth.signup.signup')}
            disabled={loading}
            large
          />
        </Form>
      </FlexBox>
      <FlexBox alignItems="center" marginTop={2}>
        <Description size="small">
          {lang('auth.signup.agree_to_terms')}
          {' '}
          <Link underline href="#terms">
            {lang('auth.commom.terms_of_use')}
          </Link>
        </Description>
        <Description size="small" marginTop={5} align="center">
          {lang('auth.signup.already_have_account')}
          {' '}
          <Link underline href="/">{lang('auth.signup.login')}</Link>
        </Description>
      </FlexBox>
    </>
  );

  return (
    <GradientContainer justifyContent="center">
      <Logo />

      <FlexBox marginTop={10} width="80%" alignItems="center" justifyContent="center">
        {emailValidation === true && <VerificationEmailSent email={email} />}

        {emailValidation === false && renderEmailCreation()}
      </FlexBox>

      <PrivacyAndTerms />
    </GradientContainer>
  );
}

StudentSignUp.defaultProps = {
  action: '',
};

export default StudentSignUp;

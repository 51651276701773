import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateDocument, useCreateDocumentFromTemplate, useDuplicateDocument } from '../../hooks/document';
import { DocumentFormat, isValidDocumentFormat } from '../../types/DocumentSettings';

export default function New() {
  const navigate = useNavigate();
  const createDocument = useCreateDocument();
  const createDocumentFromTemplate = useCreateDocumentFromTemplate();
  const duplicateDocument = useDuplicateDocument();
  // const authId = useAuthorizationId();
  const [isCreating, setIsCreating] = React.useState(false);
  const search = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (isCreating) {
      return;
    }
    setIsCreating(true);

    // Configuration for the document can be specified in the URL.
    const templateId = search.get('template') || undefined;
    const baseDocumentId = search.get('base') || undefined;
    const formatParam = search.get('format');
    const format = isValidDocumentFormat(formatParam) ? formatParam : DocumentFormat.BOOKLET;
    const importantWords = search.get('importantWords')?.trim().split(/,\s*/) || [];
    const writingPlan = search.get('writingPlan');

    if (templateId) {
      const name = search.get('name') || 'Untitled';
      createDocumentFromTemplate(
        name,
        templateId,
      ).then((document) => {
        navigate(`/document/${document.id}`, { replace: true });
      });
    } else if (baseDocumentId) {
      // Otherwise, attempt to duplicate the document from another document
      // that the user has access to.
      duplicateDocument(baseDocumentId).then((document) => {
        navigate(`/document/${document.id}`, { replace: true });
      });
    } else {
      // Cretae a new document from scratch.
      const name = search.get('name') || 'Untitled';
      createDocument(
        name,
        format,
        {
          writingPlan,
          importantWords,
          hasImportantWords: true,
          hasSignalWords: true,
          hasWordCount: true,
        },
      ).then((document) => {
        navigate(`/document/${document.id}`, { replace: true });
      });
    }
  }, [isCreating]);

  return <div />;
}

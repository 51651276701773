import { Box, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import React, {
  SyntheticEvent,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticatedUser } from 'src/hooks/user';
import { DocumentFormat, DocumentTemplateSystem } from 'src/types/DocumentSettings';
import * as AssignmentsAPI from '../../api/Assignments';
import * as DocumentsAPI from '../../api/Documents';
import { OptionsMenuImg, StarImg } from '../../assets/icons';
import { lang } from '../../lang';
import { AssignmentDefinition } from '../../types/Gallery';
import { getPresstoCardPredominantColor } from '../../utils';
import getPlaceholderDocumentByFormat from '../../utils/getPlaceholderDocumentByFormat';
import ContextMenu from '../ContextMenu';
import { ContextMenuItemProps, ContextMenuRef } from '../ContextMenu/types';
import Page from '../Page';
import StatusTag from '../StatusTag';
import DeleteConfirmationDialog from '../dialogs/DeleteConfirmationDialog';
import { Description } from '../typography';
import { ButtonContainer, CardDescription, CardNumber } from './styles';

function AssignmentButton(
  { assignment, onDeleted }: {
    assignment: AssignmentDefinition,
    onDeleted?: any,
  },
) {
  const {
    name, id,
  } = assignment;
  const user = useAuthenticatedUser();
  const navigate = useNavigate();
  const document = assignment['base-document'];
  const isDraft = !(assignment.classrooms?.length || assignment.students?.length);

  const predominantColor = useMemo(
    () => getPresstoCardPredominantColor(assignment['base-document']?.version),
    [assignment['base-document']?.version],
  );

  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);

  const contextMenuRef = useRef<ContextMenuRef>(null);
  const menuItems = useMemo<ContextMenuItemProps[]>(() => ([
    {
      label: 'Edit',
      icon: 'EDIT',
      onClick: () => {
        navigate(`/assignment/${assignment.id}`);
      },
    },
    {
      label: 'Make a copy',
      icon: 'DUPLICATE',
      onClick: () => {
        navigate(`/assignment/${assignment.id}/duplicate`);
      },
    },
    {
      label: 'Delete',
      icon: 'BIN',
      onClick: () => { setIsDeleteConfirmationDialogOpen(true); },
      style: { color: '#FF5252 !important' },
    },
  ]), []);

  const handleDropdownClick = (e: any) => {
    e.stopPropagation();

    contextMenuRef.current?.openMenu(e);
  };

  const handleDelete = async () => {
    if (user?.role === 'teacher') {
      const response = await AssignmentsAPI.deleteAssignment(id);
      if (response.status === 200) {
        onDeleted?.();
      }
    } else {
      const response = await DocumentsAPI.deleteDocument(assignment.submission.document_id);
      if (response.status === 200) {
        onDeleted?.();
      }
    }
  };

  const createSubmission = async (assignmentId: string) => {
    const response = await AssignmentsAPI.createSubmission(assignmentId);
    if (response.status === 201) {
      navigate(`/document/${response.data.data.document_id}?notes=true`);
    }
  };

  const handleOnError = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = getPlaceholderDocumentByFormat(
      assignment['base-document']?.format as string,
    );
  };

  const imageUrl = assignment['base-document']?.thumbnail_url || assignment.thumbnail_url || '';

  return (
    <>
      <ContextMenu menuItems={menuItems} ref={contextMenuRef}>
        <ButtonContainer
          elevation={16}
          onClick={() => {
            if (user?.role === 'teacher') {
              if (isDraft) {
                navigate(`/assignment/${assignment.id}`);
              } else {
                navigate(`/assignment/${assignment.id}/review`);
              }
            } else if (!assignment.submission?.id) {
              createSubmission(assignment.id);
            } else if (assignment?.submission?.document_id) {
              navigate(`/document/${assignment.submission.document_id}`);
            }
          }}
          isTeacher={user?.role === 'teacher'}
        >
          <div className="image-container" style={{ backgroundColor: predominantColor }}>
            {document?.template_system === DocumentTemplateSystem.BLOCKS ? (
              // Be extra-guarded here because so many Presstos are displayed
              // at once. Better to fail silently than to crash the gallery.
              document.version?.content?.pages?.length && (
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <Page
                    format={document.format as DocumentFormat || DocumentFormat.BOOKLET}
                    page={document.version.content.pages[0]}
                    scale={0.15}
                    isEditable={false}
                    isThumbnail
                  />
                </Box>
              )
            ) : (
              <img
                onError={handleOnError}
                src={imageUrl}
                alt={name}
              />
            )}
          </div>
          <div className="info-container">
            {user?.role === 'teacher'
              ? <StatusTag icon={!isDraft ? StarImg : undefined} status={isDraft ? 'Draft' : 'Assigned'} />
              : <StatusTag status={assignment.submission?.status || 'New'} />}
            <div>
              <Description className="title">
                {name || 'Untitled'}
              </Description>
              <Description size="small">{assignment.classrooms?.map((c) => c.name).join(', ')}</Description>
            </div>
            {user?.role === 'teacher' ? (
              <div className="row">
                <div>
                  <CardNumber>{assignment.turnedin_count || 0}</CardNumber>
                  <CardDescription>{lang('assignment.assignment_tab.turned_in')}</CardDescription>
                </div>
                <div>
                  <CardNumber>
                    {(assignment.assigned_count || 0) + (assignment.directly_assigned_count || 0)}
                  </CardNumber>
                  <CardDescription>{lang('assignment.assignment_tab.assigned')}</CardDescription>
                </div>
              </div>
            ) : (
              <div className="row">
                <Description size="small" className="alert-date">
                  {dayjs(assignment.updated_at).fromNow()}
                </Description>
                <Description size="small">
                  {assignment.submission?.classroom_name}
                </Description>
              </div>
            )}
          </div>
          {
            (!assignment.submission || assignment.submission.status !== 'new') && (
              <IconButton className="pressto-button-actions" onClick={handleDropdownClick}>
                <img src={OptionsMenuImg} alt="options menu" />
              </IconButton>
            )
          }
        </ButtonContainer>
      </ContextMenu>
      <DeleteConfirmationDialog
        title={
          // Since teacher can only access assignments through assignments tab,
          // only a student will see the delete document message
          user?.role === 'teacher'
            ? lang('assignment.assignment_tab.delete_assignment_confirm.title')
            : lang('gallery.pressto_button.delete_pressto')
        }
        description={
          user?.role === 'teacher'
            ? lang('assignment.assignment_tab.delete_assignment_confirm.subtitle')
            : undefined
        }
        onSubmit={() => { handleDelete(); }}
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
    </>
  );
}

export default AssignmentButton;

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import * as UUID from 'uuid';
import {
  Box, Button, Collapse, Fade,
} from '@mui/material';
import { getBlockType } from 'src/configuration/blocks';
import {
  Assignment,
  Block,
  BlockType,
  ChatMessage,
  Document,
  Page,
  WritingPlan,
} from 'src/types/models';
import * as AssistantApi from 'src/api/Assistant';
import { allBlocks } from 'src/utils/documents';
import { getWritingPlan } from 'src/configuration/writing-plans';
import { WritingBuddyOpen, WritingBuddyClosed } from 'src/assets/images';
import { Chevron } from 'src/assets/icons/variantComponents';
import { useApplyDocumentOperation } from 'src/hooks/document';
import { AddChatMessageOperation } from 'src/hooks/store/document/operations';
import { useUserStore } from 'src/hooks/zustand/user';
import InfoText from 'src/components/InfoText';
import { useTrackEvent } from 'src/hooks/analytics';

export default function WritingBuddy(props: {
  document: Document;
  isEditable?: boolean;
  isPreview?: boolean;
  selectedPage: Page;
  selectedBlock: Block | null;
  assignment?: Assignment | null;
}) {
  const {
    document,
    selectedBlock,
    selectedPage,
    assignment,
    isEditable = false,
    isPreview = false,
  } = props;

  const user = useUserStore((state) => state.user);
  const trackEvent = useTrackEvent();

  const [messages, setMessages] = useState(
    document.version.content?.messages || [],
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [canRequestHint, setCanRequestHint] = useState(isEditable);
  const containerRef = useRef<HTMLDivElement>(null);

  // Handle saving and displaying messages. When saving messages, apply
  // message operations to the global store. When messages aren't saved, we still
  // need to show them, so use local state by itself. Keep global and local state
  // in sync with an effect.
  const applyDocumentOperation = useApplyDocumentOperation();
  const saveMessage = useCallback(
    (data: Pick<ChatMessage, 'agent' | 'text' | 'userId'>) => {
      const message = {
        id: UUID.v4(),
        payload: {
          selectedBlockId: selectedBlock?.id,
          selectedPageId: selectedPage.id,
        },
        ...data,
      };
      if (isPreview) {
        // Save locally.
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            ...message,
            clientCreatedAt: new Date(),
            sequence: prevMessages.length + 1,
          },
        ]);
      } else {
        // Save globally.
        applyDocumentOperation(
          new AddChatMessageOperation(document.id, message),
        );
      }
    },
    [document.id, selectedBlock?.id, selectedPage.id, isPreview],
  );

  // Sync global with local state.
  useEffect(() => {
    setMessages(document.version.content?.messages || []);
  }, [document.version.content?.messages]);

  let writingPlan: WritingPlan | undefined;
  if (document.meta.writingPlan) {
    writingPlan = getWritingPlan(document.meta.writingPlan);
  }
  let blockType: BlockType | null = null;

  if (selectedBlock) {
    blockType = getBlockType(selectedBlock);
  }

  const requestHint = async () => {
    if (!selectedBlock || !blockType) {
      return;
    }

    setCanRequestHint(false);
    saveMessage({
      agent: 'user',
      userId: user.id!,
      text: 'Give me a hint',
    });

    const blocks = allBlocks(document.version);
    const timeout = setTimeout(() => setIsTyping(true), 200);
    try {
      const response = await AssistantApi.askWritingBuddy(
        blocks.map((block) => ({
          text: block.properties.plainText || '',
          blockName: getBlockType(block).label,
        })),
        getBlockType(selectedBlock).label,
        writingPlan?.title,

        writingPlan?.blockTypeIds.map(getBlockType).map((bt) => bt.title),

        assignment?.instructions,
      );

      if (response.status !== 200) {
        return;
      }

      const { text, entities } = response.data.data[0];
      trackEvent('hint-requested', {
        documentId: document.id,
        blockId: selectedBlock.id,
        blockType: blockType.title,
        hint: text,
        piiEntities: entities,
      });
      saveMessage({
        agent: 'writing-buddy',
        userId: null,
        text,
      });
    } finally {
      clearTimeout(timeout);
      setIsTyping(false);

      setTimeout(() => setCanRequestHint(true), 1000);
    }
  };

  // Smooth scroll to the bottom of the container when the messages change.
  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        behavior: 'smooth',
        top: containerRef.current.scrollHeight,
      });
    }
  }, [messages, isTyping, canRequestHint]);

  return (
    <div
      style={{
        margin: '16px',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          marginLeft: 'auto',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          borderBottom: isOpen ? '1px solid lightgray' : 'none',
          cursor: 'pointer',
          width: isOpen ? '100%' : '108px',
          transition: 'width 0.3s',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={isOpen ? WritingBuddyOpen : WritingBuddyClosed}
            alt="Writing Buddy Eyes"
            style={{
              height: '2rem',
            }}
          />
        </div>

        <Chevron
          direction={isOpen ? 'down' : 'right'}
          style={{
            backgroundColor: 'white',
            border: '1px solid lightgray',
            width: '28px',
            height: '28px',
          }}
        />
      </Box>

      <Collapse
        in={isOpen}
        ref={containerRef}
        sx={{
          overflowY: 'auto',
        }}
      >
        <Box sx={{ my: 2, mr: 1, minHeight: '180px' }}>
          {isPreview && (
            <InfoText>
              <b>Note:</b>
              {' '}
              Messages generated in Student Preview mode are not
              saved and will not be seen by your students.
            </InfoText>
          )}

          {messages.map((message) => (
            <Box
              key={message.id}
              sx={[
                {
                  borderRadius: '0px 15px 15px 15px',
                  border: '1px solid',
                  fontSize: '0.9rem',
                  py: 1,
                  px: 2,
                  mb: 2,
                  clear: 'both',
                  lineHeight: '1.4em',
                },
                message.agent === 'user' && {
                  backgroundColor: 'primary.main',
                  borderColor: 'primary.main',
                  color: 'white',
                  borderRadius: '15px 15px 0 15px',
                  display: 'inline-block',
                  float: 'right',
                },
                message.agent === 'writing-buddy' && {
                  backgroundColor: 'background.default',
                  borderColor: 'primary.light',
                  py: 1.5,
                },
              ]}
            >
              {message.text}
            </Box>
          ))}

          <div>
            <Fade in={isTyping} exit={false} mountOnEnter unmountOnExit>
              <Box
                sx={{
                  background: '#FFFFFF',
                  border: '1px solid',
                  borderColor: 'primary.light',
                  borderRadius: '0px 15px 15px 15px',
                  py: 1,
                  px: 2,
                  mb: 2,
                  display: 'inline-block',
                  float: 'left',
                  clear: 'both',
                }}
              >
                &hellip;
              </Box>
            </Fade>
          </div>

          {!isTyping && canRequestHint && (
            <Button
              disabled={!selectedBlock}
              onClick={requestHint}
              variant="outlined"
              sx={{
                borderRadius: '15px 15px 0 15px',
                backgroundColor: 'primary.main',
                color: 'white',
                float: 'right',

                '&:hover': {
                  backgroundColor: 'primary.dark',
                },

                '&:disabled': {
                  backgroundColor: 'primary.light',
                },

                textTransform: 'none',
              }}
            >
              Give me a hint
            </Button>
          )}
        </Box>
      </Collapse>
    </div>
  );
}

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticatedUser } from 'src/hooks/user';

/**
   * This effect runs when the user changes or is set for the first time.
   * If the user is registered (that is, the user has an ID and is not anonymous)
   * and there is a 'next' path in local storage, then navigate to that path
   * and remove it immediately from local storage. Any actions that need to be taken
   * take precedence over this.
   * (There is no central function that is called to log users in. In the future,
   * this check could be moved if one is defined. Putting it at the top of the router
   * is not an obvious place for it.)
  */
export default function RedirectAfterLogin() {
  const user = useAuthenticatedUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let postLoginPath: string | null = null;
    if (user?.id && user.role !== 'anonymous' && !user?.actions?.length) {
      try {
        const postLoginState = JSON.parse(localStorage.getItem('postLoginState') || '{}');
        postLoginPath = postLoginState?.next;
        if (postLoginPath) {
          delete postLoginState.next;
          localStorage.setItem('postLoginState', JSON.stringify(postLoginState));
          navigate(postLoginPath, { replace: true });
        }
      } catch (err) {
      }
    }
  }, [location]);

  return null;
}

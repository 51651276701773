import React, { useMemo } from 'react';
import { ClickAwayListener } from '@mui/base';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useEditor } from '../../hooks/useEditor';
import { EditorContainer } from './styles';
import { EditorComponentProps } from './types';
import { ImageBoxType, LabelType, TextBoxType } from '../../types/LayoutEditor';
import { generateStyleMap, getFontSizeMultiplier } from '../elements/TextBox/utils';
import { TextBox, ImageBox, Label } from '../elements';
import { useDocumentStore } from '../../hooks/zustand/documents';
import SliderContainer from '../SliderContainer';

function Editor({ handleUploadImage }: EditorComponentProps) {
  const {
    selectedPage,
    board,
    selectedEl,
    handleSelectEl,
    isRefreshing,
    content,
    selectedPageIndex,
    isBusy,
  } = useEditor();

  const prevPage = useDocumentStore((state) => state.prevPage);
  const nextPage = useDocumentStore((state) => state.nextPage);
  const currentDocument = useDocumentStore((state) => state.currentDocument);

  const handleBlur = () => {
    if (selectedEl) { handleSelectEl(null); }
  };

  const handleClick = (e: any) => {
    if (e.target.id === 'board') { handleSelectEl('board'); }
  };

  const { height, width, ref } = useResizeDetector();

  const styleMap = useMemo(() => {
    if (!board || !height || !width) return {};
    const fsm = getFontSizeMultiplier(board.height, height);

    return generateStyleMap(fsm);
  }, [board, height, width]);

  const renderBoardElements = () => {
    if (!board || !selectedPage?.content) return [];

    if (isRefreshing) {
      return <div />;
    }

    return content?.map(({ ref: _, ...el }) => {
      if (!height || !width) return null;
      const pWidth = el.width * (width / board.width);
      const pHeight = el.height * (height / board.height);
      const pX = el.x * (width / board.width);
      const pY = el.y * (height / board.height);

      switch (el.type) {
        case 'TextBox':
          return (
            <TextBox
              name={el.key}
              {...el as TextBoxType}
              height={pHeight}
              width={pWidth}
              x={pX}
              y={pY}
              styleMap={styleMap}
            />
          );
        case 'ImageBox':
          return (
            <ImageBox
              name={el.key}
              {...el as ImageBoxType}
              height={pHeight}
              width={pWidth}
              x={pX}
              y={pY}
              boardDims={{ height, width }}
              handleUploadImage={handleUploadImage}
            />
          );
        case 'Label':
          return (
            <Label
              name={el.key}
              {...el as LabelType}
              height={pHeight}
              width={pWidth}
              x={pX}
              y={pY}
              styleMap={styleMap}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <SliderContainer
      className="editor-wrapper"
      onSlideLeft={nextPage}
      onSlideRight={prevPage}
      disabled={isBusy}
      ignoreEventOnQuery={['.indiana-scroll-container > header']}
    >
      {board && (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleBlur}
        >
          <EditorContainer
            id="board"
            onClick={handleClick}
            height={board.height}
            width={board.width}
            backgroundColor={currentDocument?.version?.pages[selectedPageIndex]?.backgroundColor || '#fff'}
            ref={ref}
          >
            {content && renderBoardElements()}
          </EditorContainer>
        </ClickAwayListener>
      )}
    </SliderContainer>
  );
}

export default Editor;

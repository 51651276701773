import { FunctionComponent, SVGProps } from 'react';
import { SVGs } from 'src/assets/blocks';
import { DocumentFormat } from 'src/types/DocumentSettings';

const EMBEDDED_TEMPLATES: {
  format: DocumentFormat;
  name: string;
  writingPlanId: string;
  documentId: string;
  svg: FunctionComponent<SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }>;
  color: string;
  document: any;
}[] = [
  {
    format: DocumentFormat.BOOKLET,
    name: 'News Article',
    writingPlanId: 'news-article',
    documentId: 'template-booklet-news-article',
    svg: SVGs.QuoteSVG,
    color: '#EEA1A2',
    document: {
      templateSystem: 'blocks',
      user: {
        username: null,
        id: '7WwWWeqwqv',
        name: 'Kieran',
        surname: 'Sobel',
        role: 'teacher',
        email: 'kieran+teacher@joinpressto.com',
      },
      isPublic: true,
      id: 'template-booklet-news-article',
      isDemo: false,
      thumbnailUrl: null,
      meta: {
        importantWords: [],
        hasWritingBuddy: true,
        hasImportantWords: true,
        hasSignalWords: true,
        writingPlan: 'news-article',
        hasWordCount: true,
      },
      isTemplate: true,
      submission: null,
      updatedAt: '2024-04-17T19:42:00.763Z',
      createdAt: '2024-04-17T19:42:00.000Z',
      format: DocumentFormat.BOOKLET,
      name: 'Copy of News Article - Zine Template',
      version: {
        publishedAt: {
          seconds: 1713382920,
          nanoseconds: 419000000,
        },
        createdAt: {
          seconds: 1713382920,
          nanoseconds: 0,
        },
        documentId: 'gNjomz8JYD',
        content: {
          messages: [],
          pages: [
            {
              documentId: 'Vv41nGoj0A',
              position: 0,
              grid: {
                columns: 1,
                style: 'fixed',
                rows: 2,
                blocks: [
                  {
                    x: 1,
                    width: 1,
                    id: '2d8547e1-30b4-11ee-a0cb-3bf51732fff8',
                    height: 1,
                    typeId: 'Title',
                    properties: {
                      text: null,
                      plainText: null,
                      fontColor: 'Black',
                      fontSize: '2em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      maximumFontSize: '5em',
                      rawEditorContent: '{"blocks":[{"key":"b23rv","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: 'Alfa',
                    },
                    y: 1,
                    documentId: 'Vv41nGoj0A',
                  },
                  {
                    typeId: 'Image',
                    height: 1,
                    width: 1,
                    id: '36fc6060-30b4-11ee-a0cb-3bf51732fff8',
                    x: 1,
                    documentId: 'Vv41nGoj0A',
                    properties: {
                      text: null,
                      html: null,
                      preferredHeight: '10em',
                      rotation: 0,
                      positionX: 0,
                      positionY: 0,
                    },
                    y: 2,
                  },
                ],
              },
              id: '2d8547e0-30b4-11ee-a0cb-3bf51732fff8',
              meta: '{}',
            },
            {
              documentId: 'Vv41nGoj0A',
              position: 1,
              meta: '{}',
              id: '2d8547e2-30b4-11ee-a0cb-3bf51732fff8',
              grid: {
                style: 'fixed',
                rows: 2,
                columns: 1,
                blocks: [
                  {
                    y: 1,
                    width: 1,
                    x: 1,
                    documentId: 'Vv41nGoj0A',
                    id: '3895c510-30b4-11ee-a0cb-3bf51732fff8',
                    height: 1,
                    properties: {
                      htmlText: '<p > </p>',
                      fontColor: 'Black',
                      fontSize: '1em',
                      fontFamily: "'Open Sans'",
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"4fv4h","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      plainText: null,
                    },
                    typeId: 'Lead',
                  },
                  {
                    height: 1,
                    width: 1,
                    typeId: 'Image',
                    y: 2,
                    documentId: 'Vv41nGoj0A',
                    id: '3a9cb8a0-30b4-11ee-a0cb-3bf51732fff8',
                    x: 1,
                    properties: {
                      positionY: 0,
                      rotation: 0,
                      text: null,
                      html: null,
                      preferredHeight: '10em',
                      positionX: 0,
                    },
                  },
                ],
              },
            },
            {
              meta: '{}',
              id: '2d8547e4-30b4-11ee-a0cb-3bf51732fff8',
              position: 2,
              documentId: 'Vv41nGoj0A',
              grid: {
                blocks: [
                  {
                    id: '40a9a410-30b4-11ee-a0cb-3bf51732fff8',
                    x: 1,
                    typeId: 'MainIdea',
                    height: 2,
                    width: 1,
                    y: 1,
                    documentId: 'Vv41nGoj0A',
                    properties: {
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      maximumFontSize: '5em',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"dg0hk","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      plainText: null,
                    },
                  },
                ],
                style: 'fixed',
                rows: 2,
                columns: 1,
              },
            },
            {
              id: '2d8547e3-30b4-11ee-a0cb-3bf51732fff8',
              position: 3,
              meta: '{}',
              documentId: 'Vv41nGoj0A',
              grid: {
                style: 'fixed',
                columns: 1,
                rows: 2,
                blocks: [
                  {
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      text: null,
                      positionX: 0,
                      positionY: 0,
                      rotation: 0,
                      preferredHeight: '10em',
                    },
                    width: 1,
                    x: 1,
                    height: 1,
                    documentId: 'Vv41nGoj0A',
                    typeId: 'Image',
                    y: 1,
                    id: '5dd4d5a0-30b4-11ee-a0cb-3bf51732fff8',
                  },
                  {
                    documentId: 'Vv41nGoj0A',
                    properties: {
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"alhis","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      plainText: null,
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      fontStyle: 'Italic',
                      fontSize: '1em',
                      htmlText: '<p > </p>',
                    },
                    height: 1,
                    x: 1,
                    y: 2,
                    id: '3df0c5a0-30b4-11ee-a0cb-3bf51732fff8',
                    typeId: 'Details',
                    width: 1,
                  },
                ],
              },
            },
            {
              position: 4,
              id: '2d8547e5-30b4-11ee-a0cb-3bf51732fff8',
              documentId: 'Vv41nGoj0A',
              grid: {
                style: 'fixed',
                columns: 1,
                blocks: [
                  {
                    documentId: 'Vv41nGoj0A',
                    typeId: 'CallOut',
                    y: 1,
                    properties: {
                      fontStyle: 'Italic',
                      rawEditorContent: '{"blocks":[{"key":"9405c","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Source Serif 4'",
                      htmlText: '<p > </p>',
                      fontSize: '1.5em',
                      maximumFontSize: '5em',
                      plainText: null,
                      text: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1.5em; font-family: 'Source Serif 4'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                    },
                    width: 1,
                    height: 1,
                    x: 1,
                    id: '746c86a0-30b4-11ee-a0cb-3bf51732fff8',
                  },
                  {
                    typeId: 'Details',
                    height: 1,
                    x: 1,
                    documentId: 'Vv41nGoj0A',
                    y: 2,
                    width: 1,
                    properties: {
                      plainText: null,
                      maximumFontSize: '5em',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"8f2vh","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                    },
                    id: '44f70530-30b4-11ee-a0cb-3bf51732fff8',
                  },
                ],
                rows: 2,
              },
              meta: '{}',
            },
            {
              position: 5,
              documentId: 'Vv41nGoj0A',
              meta: '{}',
              grid: {
                style: 'fixed',
                columns: 1,
                rows: 2,
                blocks: [
                  {
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      fontSize: '1em',
                      fontColor: 'Black',
                      text: null,
                      fontFamily: "'Open Sans'",
                      rawEditorContent: '{"blocks":[{"key":"ano2m","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      plainText: null,
                      htmlText: '<p > </p>',
                    },
                    x: 1,
                    id: '472e0970-30b4-11ee-a0cb-3bf51732fff8',
                    typeId: 'Details',
                    documentId: 'Vv41nGoj0A',
                    height: 1,
                    y: 1,
                    width: 1,
                  },
                  {
                    width: 1,
                    y: 2,
                    height: 1,
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      rawEditorContent: '{"blocks":[{"key":"ekl3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                      maximumFontSize: '5em',
                      plainText: null,
                      text: null,
                      fontColor: 'Black',
                    },
                    x: 1,
                    documentId: 'Vv41nGoj0A',
                    typeId: 'Details',
                    id: '47ea2d80-30b4-11ee-a0cb-3bf51732fff8',
                  },
                ],
              },
              id: '2d8547e6-30b4-11ee-a0cb-3bf51732fff8',
            },
            {
              meta: '{}',
              position: 6,
              grid: {
                columns: 1,
                blocks: [
                  {
                    typeId: 'Image',
                    id: '4b03c990-30b4-11ee-a0cb-3bf51732fff8',
                    properties: {
                      positionY: 0,
                      rotation: 0,
                      preferredHeight: '10em',
                      positionX: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      text: null,
                    },
                    width: 1,
                    y: 1,
                    documentId: 'Vv41nGoj0A',
                    x: 1,
                    height: 2,
                  },
                ],
                rows: 2,
                style: 'fixed',
              },
              id: '2d8547e7-30b4-11ee-a0cb-3bf51732fff8',
              documentId: 'Vv41nGoj0A',
            },
            {
              id: '2d8547e8-30b4-11ee-a0cb-3bf51732fff8',
              position: 7,
              meta: '{}',
              grid: {
                style: 'fixed',
                rows: 2,
                blocks: [
                  {
                    properties: {
                      rawEditorContent: '{"blocks":[{"key":"ve86","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      plainText: null,
                      fontFamily: "'Open Sans'",
                    },
                    id: '533b3620-30b4-11ee-a0cb-3bf51732fff8',
                    height: 1,
                    documentId: 'Vv41nGoj0A',
                    width: 1,
                    y: 1,
                    typeId: 'Conclusion',
                    x: 1,
                  },
                  {
                    typeId: 'Image',
                    x: 1,
                    height: 1,
                    documentId: 'Vv41nGoj0A',
                    id: '549791d0-30b4-11ee-a0cb-3bf51732fff8',
                    width: 1,
                    properties: {
                      preferredHeight: '10em',
                      text: null,
                      positionY: 0,
                      positionX: 0,
                      rotation: 0,
                      html: null,
                    },
                    y: 2,
                  },
                ],
                columns: 1,
              },
              documentId: 'Vv41nGoj0A',
            },
          ],
          schemaVersion: 20230101,
        },
        notes: [],
        pages: [
          {
            meta: '{}',
            id: '2d8547e0-30b4-11ee-a0cb-3bf51732fff8',
            documentId: 'Vv41nGoj0A',
            grid: {
              style: 'fixed',
              blocks: [
                {
                  id: '2d8547e1-30b4-11ee-a0cb-3bf51732fff8',
                  height: 1,
                  x: 1,
                  typeId: 'Title',
                  documentId: 'Vv41nGoj0A',
                  y: 1,
                  properties: {
                    fontFamily: 'Alfa',
                    rawEditorContent: '{"blocks":[{"key":"b23rv","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    fontSize: '2em',
                    maximumFontSize: '5em',
                    plainText: null,
                    text: null,
                    htmlText: '<p > </p>',
                  },
                  width: 1,
                },
                {
                  id: '36fc6060-30b4-11ee-a0cb-3bf51732fff8',
                  typeId: 'Image',
                  y: 2,
                  x: 1,
                  height: 1,
                  documentId: 'Vv41nGoj0A',
                  properties: {
                    html: null,
                    text: null,
                    rotation: 0,
                    preferredHeight: '10em',
                    positionX: 0,
                    positionY: 0,
                  },
                  width: 1,
                },
              ],
              rows: 2,
              columns: 1,
            },
            position: 0,
          },
          {
            id: '2d8547e2-30b4-11ee-a0cb-3bf51732fff8',
            grid: {
              blocks: [
                {
                  width: 1,
                  y: 1,
                  typeId: 'Lead',
                  height: 1,
                  id: '3895c510-30b4-11ee-a0cb-3bf51732fff8',
                  documentId: 'Vv41nGoj0A',
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    text: null,
                    fontColor: 'Black',
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"4fv4h","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontSize: '1em',
                    maximumFontSize: '5em',
                  },
                  x: 1,
                },
                {
                  width: 1,
                  id: '3a9cb8a0-30b4-11ee-a0cb-3bf51732fff8',
                  y: 2,
                  typeId: 'Image',
                  properties: {
                    rotation: 0,
                    html: null,
                    text: null,
                    positionY: 0,
                    preferredHeight: '10em',
                    positionX: 0,
                  },
                  height: 1,
                  x: 1,
                  documentId: 'Vv41nGoj0A',
                },
              ],
              style: 'fixed',
              rows: 2,
              columns: 1,
            },
            position: 1,
            documentId: 'Vv41nGoj0A',
            meta: '{}',
          },
          {
            id: '2d8547e4-30b4-11ee-a0cb-3bf51732fff8',
            documentId: 'Vv41nGoj0A',
            meta: '{}',
            position: 2,
            grid: {
              columns: 1,
              style: 'fixed',
              blocks: [
                {
                  width: 1,
                  y: 1,
                  x: 1,
                  id: '40a9a410-30b4-11ee-a0cb-3bf51732fff8',
                  documentId: 'Vv41nGoj0A',
                  height: 2,
                  typeId: 'MainIdea',
                  properties: {
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"dg0hk","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                    maximumFontSize: '5em',
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontSize: '1em',
                    text: null,
                    fontColor: 'Black',
                  },
                },
              ],
              rows: 2,
            },
          },
          {
            meta: '{}',
            id: '2d8547e3-30b4-11ee-a0cb-3bf51732fff8',
            grid: {
              blocks: [
                {
                  height: 1,
                  properties: {
                    preferredHeight: '10em',
                    text: null,
                    positionY: 0,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    rotation: 0,
                    positionX: 0,
                  },
                  x: 1,
                  documentId: 'Vv41nGoj0A',
                  width: 1,
                  typeId: 'Image',
                  id: '5dd4d5a0-30b4-11ee-a0cb-3bf51732fff8',
                  y: 1,
                },
                {
                  id: '3df0c5a0-30b4-11ee-a0cb-3bf51732fff8',
                  documentId: 'Vv41nGoj0A',
                  properties: {
                    plainText: null,
                    text: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontStyle: 'Italic',
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"alhis","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    fontSize: '1em',
                  },
                  x: 1,
                  width: 1,
                  height: 1,
                  y: 2,
                  typeId: 'Details',
                },
              ],
              style: 'fixed',
              rows: 2,
              columns: 1,
            },
            documentId: 'Vv41nGoj0A',
            position: 3,
          },
          {
            documentId: 'Vv41nGoj0A',
            id: '2d8547e5-30b4-11ee-a0cb-3bf51732fff8',
            grid: {
              columns: 1,
              rows: 2,
              style: 'fixed',
              blocks: [
                {
                  documentId: 'Vv41nGoj0A',
                  width: 1,
                  id: '746c86a0-30b4-11ee-a0cb-3bf51732fff8',
                  typeId: 'CallOut',
                  x: 1,
                  height: 1,
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"9405c","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontSize: '1.5em',
                    text: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1.5em; font-family: 'Source Serif 4'; position: relative'><p > </p></div></div>",
                    fontStyle: 'Italic',
                    fontColor: 'Black',
                    fontFamily: "'Source Serif 4'",
                    htmlText: '<p > </p>',
                    plainText: null,
                    maximumFontSize: '5em',
                  },
                  y: 1,
                },
                {
                  y: 2,
                  typeId: 'Details',
                  height: 1,
                  x: 1,
                  id: '44f70530-30b4-11ee-a0cb-3bf51732fff8',
                  documentId: 'Vv41nGoj0A',
                  width: 1,
                  properties: {
                    plainText: null,
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"8f2vh","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    fontSize: '1em',
                    fontFamily: "'Open Sans'",
                    text: null,
                    maximumFontSize: '5em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                },
              ],
            },
            position: 4,
            meta: '{}',
          },
          {
            meta: '{}',
            documentId: 'Vv41nGoj0A',
            id: '2d8547e6-30b4-11ee-a0cb-3bf51732fff8',
            position: 5,
            grid: {
              rows: 2,
              style: 'fixed',
              columns: 1,
              blocks: [
                {
                  x: 1,
                  width: 1,
                  height: 1,
                  y: 1,
                  typeId: 'Details',
                  properties: {
                    maximumFontSize: '5em',
                    plainText: null,
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    text: null,
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"ano2m","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                  },
                  id: '472e0970-30b4-11ee-a0cb-3bf51732fff8',
                  documentId: 'Vv41nGoj0A',
                },
                {
                  documentId: 'Vv41nGoj0A',
                  y: 2,
                  typeId: 'Details',
                  x: 1,
                  height: 1,
                  id: '47ea2d80-30b4-11ee-a0cb-3bf51732fff8',
                  width: 1,
                  properties: {
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    plainText: null,
                    text: null,
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"ekl3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                },
              ],
            },
          },
          {
            documentId: 'Vv41nGoj0A',
            id: '2d8547e7-30b4-11ee-a0cb-3bf51732fff8',
            meta: '{}',
            grid: {
              rows: 2,
              columns: 1,
              blocks: [
                {
                  typeId: 'Image',
                  properties: {
                    rotation: 0,
                    preferredHeight: '10em',
                    text: null,
                    positionX: 0,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    positionY: 0,
                  },
                  height: 2,
                  x: 1,
                  y: 1,
                  documentId: 'Vv41nGoj0A',
                  width: 1,
                  id: '4b03c990-30b4-11ee-a0cb-3bf51732fff8',
                },
              ],
              style: 'fixed',
            },
            position: 6,
          },
          {
            documentId: 'Vv41nGoj0A',
            grid: {
              style: 'fixed',
              columns: 1,
              blocks: [
                {
                  width: 1,
                  id: '533b3620-30b4-11ee-a0cb-3bf51732fff8',
                  x: 1,
                  documentId: 'Vv41nGoj0A',
                  properties: {
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    fontFamily: "'Open Sans'",
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    maximumFontSize: '5em',
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"ve86","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                  },
                  y: 1,
                  typeId: 'Conclusion',
                  height: 1,
                },
                {
                  y: 2,
                  typeId: 'Image',
                  id: '549791d0-30b4-11ee-a0cb-3bf51732fff8',
                  documentId: 'Vv41nGoj0A',
                  properties: {
                    rotation: 0,
                    positionY: 0,
                    html: null,
                    positionX: 0,
                    text: null,
                    preferredHeight: '10em',
                  },
                  height: 1,
                  x: 1,
                  width: 1,
                },
              ],
              rows: 2,
            },
            id: '2d8547e8-30b4-11ee-a0cb-3bf51732fff8',
            position: 7,
            meta: '{}',
          },
        ],
        id: 'gNjomz8JYD',
        status: 'PUBLISHED',
      },
    },
  },
  {
    format: DocumentFormat.STORY,
    name: 'Cause and Effect',
    writingPlanId: 'cause-effect',
    documentId: 'template-story-cause-effect',
    svg: SVGs.EffectSVG,
    color: '#8DC9C3',
    document: {
      createdAt: '2024-04-17T19:42:00.000Z',
      version: {
        id: 'MK49meb408',
        createdAt: {
          seconds: 1713382920,
          nanoseconds: 0,
        },
        notes: [],
        publishedAt: {
          seconds: 1713382920,
          nanoseconds: 463000000,
        },
        documentId: 'MK49meb408',
        content: {
          schemaVersion: 20230101,
          pages: [
            {
              grid: {
                style: 'fixed',
                columns: 1,
                blocks: [
                  {
                    typeId: 'Title',
                    width: 1,
                    documentId: 'XKj78n8wa2',
                    height: 1,
                    id: '2b23d721-3247-11ee-abfd-5f9de2162030',
                    x: 1,
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      rawEditorContent: '{"blocks":[{"key":"cfjhc","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      plainText: null,
                      htmlText: '<p > </p>',
                      text: null,
                      fontSize: '2em',
                      fontFamily: 'Alfa',
                      fontColor: 'Black',
                    },
                    y: 1,
                  },
                  {
                    y: 2,
                    documentId: 'XKj78n8wa2',
                    typeId: 'Image',
                    id: '2b23d722-3247-11ee-abfd-5f9de2162030',
                    x: 1,
                    width: 1,
                    properties: {
                      rotation: 0,
                      positionX: 0,
                      positionY: 0,
                      html: null,
                      text: null,
                      preferredHeight: '10em',
                    },
                    height: 1,
                  },
                ],
                rows: 2,
              },
              position: 0,
              documentId: 'XKj78n8wa2',
              meta: '{}',
              id: '2b23d720-3247-11ee-abfd-5f9de2162030',
            },
            {
              grid: {
                blocks: [
                  {
                    id: '2b23d724-3247-11ee-abfd-5f9de2162030',
                    y: 1,
                    height: 2,
                    typeId: 'Introduction',
                    width: 1,
                    properties: {
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"6fs62","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      text: null,
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                    },
                    documentId: 'XKj78n8wa2',
                    x: 1,
                  },
                ],
                columns: 1,
                rows: 2,
                style: 'fixed',
              },
              documentId: 'XKj78n8wa2',
              meta: '{}',
              id: '2b23d723-3247-11ee-abfd-5f9de2162030',
              position: 1,
            },
            {
              id: '2b23d725-3247-11ee-abfd-5f9de2162030',
              grid: {
                style: 'fixed',
                columns: 1,
                blocks: [
                  {
                    documentId: 'XKj78n8wa2',
                    properties: {
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"8vfka","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      fontColor: 'Black',
                      maximumFontSize: '5em',
                      fontFamily: "'Open Sans'",
                      plainText: null,
                    },
                    x: 1,
                    id: '2b23d726-3247-11ee-abfd-5f9de2162030',
                    height: 1,
                    width: 1,
                    y: 1,
                    typeId: 'Cause',
                  },
                  {
                    y: 2,
                    width: 1,
                    documentId: 'XKj78n8wa2',
                    height: 1,
                    typeId: 'Image',
                    x: 1,
                    id: '46cf4680-3247-11ee-abfd-5f9de2162030',
                    properties: {
                      positionX: 0,
                      positionY: 0,
                      preferredHeight: '10em',
                      fontFamily: 'Roboto',
                      backgroundColor: 'Black',
                      text: null,
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"4p36l","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'White',
                      rotation: 0,
                      plainText: null,
                      fontSize: '0.75em',
                      htmlText: '<p > </p>',
                    },
                  },
                ],
                rows: 2,
              },
              position: 2,
              documentId: 'XKj78n8wa2',
              meta: '{}',
            },
            {
              id: '2b23d72a-3247-11ee-abfd-5f9de2162030',
              meta: '{}',
              position: 3,
              grid: {
                blocks: [
                  {
                    y: 1,
                    width: 1,
                    documentId: 'XKj78n8wa2',
                    id: '2b23d72b-3247-11ee-abfd-5f9de2162030',
                    typeId: 'Effect',
                    height: 1,
                    x: 1,
                    properties: {
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"9ivkq","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      fontSize: '1em',
                      htmlText: '<p > </p>',
                      fontColor: 'Black',
                      plainText: null,
                    },
                  },
                  {
                    documentId: 'XKj78n8wa2',
                    height: 1,
                    typeId: 'Image',
                    width: 1,
                    id: '49649760-3247-11ee-abfd-5f9de2162030',
                    x: 1,
                    properties: {
                      text: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      positionY: 0,
                      positionX: 0,
                      fontFamily: 'Roboto',
                      plainText: null,
                      backgroundColor: 'Black',
                      fontColor: 'White',
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      fontSize: '0.75em',
                      preferredHeight: '10em',
                      rotation: 0,
                      rawEditorContent: '{"blocks":[{"key":"1buoi","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    },
                    y: 2,
                  },
                ],
                columns: 1,
                rows: 2,
                style: 'fixed',
              },
              documentId: 'XKj78n8wa2',
            },
            {
              grid: {
                rows: 2,
                blocks: [
                  {
                    properties: {
                      plainText: null,
                      text: null,
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"569p6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                    },
                    width: 1,
                    height: 2,
                    documentId: 'XKj78n8wa2',
                    id: '2b23d730-3247-11ee-abfd-5f9de2162030',
                    x: 1,
                    y: 1,
                    typeId: 'Conclusion',
                  },
                ],
                columns: 1,
                style: 'fixed',
              },
              meta: '{}',
              id: '2b23d72f-3247-11ee-abfd-5f9de2162030',
              position: 4,
              documentId: 'XKj78n8wa2',
            },
          ],
          messages: [],
        },
        pages: [
          {
            meta: '{}',
            grid: {
              style: 'fixed',
              rows: 2,
              columns: 1,
              blocks: [
                {
                  documentId: 'XKj78n8wa2',
                  typeId: 'Title',
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"cfjhc","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                    fontFamily: 'Alfa',
                    fontSize: '2em',
                    plainText: null,
                    htmlText: '<p > </p>',
                    text: null,
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                  },
                  y: 1,
                  width: 1,
                  height: 1,
                  x: 1,
                  id: '2b23d721-3247-11ee-abfd-5f9de2162030',
                },
                {
                  width: 1,
                  x: 1,
                  y: 2,
                  height: 1,
                  typeId: 'Image',
                  id: '2b23d722-3247-11ee-abfd-5f9de2162030',
                  properties: {
                    positionY: 0,
                    positionX: 0,
                    preferredHeight: '10em',
                    rotation: 0,
                    text: null,
                    html: null,
                  },
                  documentId: 'XKj78n8wa2',
                },
              ],
            },
            id: '2b23d720-3247-11ee-abfd-5f9de2162030',
            documentId: 'XKj78n8wa2',
            position: 0,
          },
          {
            meta: '{}',
            position: 1,
            id: '2b23d723-3247-11ee-abfd-5f9de2162030',
            grid: {
              rows: 2,
              style: 'fixed',
              columns: 1,
              blocks: [
                {
                  y: 1,
                  id: '2b23d724-3247-11ee-abfd-5f9de2162030',
                  documentId: 'XKj78n8wa2',
                  typeId: 'Introduction',
                  width: 1,
                  x: 1,
                  properties: {
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"6fs62","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    text: null,
                    plainText: null,
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                  height: 2,
                },
              ],
            },
            documentId: 'XKj78n8wa2',
          },
          {
            id: '2b23d725-3247-11ee-abfd-5f9de2162030',
            meta: '{}',
            grid: {
              blocks: [
                {
                  id: '2b23d726-3247-11ee-abfd-5f9de2162030',
                  width: 1,
                  documentId: 'XKj78n8wa2',
                  x: 1,
                  typeId: 'Cause',
                  properties: {
                    text: null,
                    fontColor: 'Black',
                    maximumFontSize: '5em',
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"8vfka","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                  },
                  y: 1,
                  height: 1,
                },
                {
                  y: 2,
                  width: 1,
                  height: 1,
                  x: 1,
                  properties: {
                    fontColor: 'White',
                    fontSize: '0.75em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    text: null,
                    maximumFontSize: '5em',
                    rawEditorContent: '{"blocks":[{"key":"4p36l","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                    fontFamily: 'Roboto',
                    backgroundColor: 'Black',
                    rotation: 0,
                    positionY: 0,
                    positionX: 0,
                    htmlText: '<p > </p>',
                    preferredHeight: '10em',
                  },
                  typeId: 'Image',
                  documentId: 'XKj78n8wa2',
                  id: '46cf4680-3247-11ee-abfd-5f9de2162030',
                },
              ],
              style: 'fixed',
              rows: 2,
              columns: 1,
            },
            documentId: 'XKj78n8wa2',
            position: 2,
          },
          {
            position: 3,
            grid: {
              blocks: [
                {
                  typeId: 'Effect',
                  id: '2b23d72b-3247-11ee-abfd-5f9de2162030',
                  properties: {
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"9ivkq","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    text: null,
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    maximumFontSize: '5em',
                    plainText: null,
                    fontSize: '1em',
                  },
                  documentId: 'XKj78n8wa2',
                  y: 1,
                  x: 1,
                  height: 1,
                  width: 1,
                },
                {
                  typeId: 'Image',
                  x: 1,
                  height: 1,
                  properties: {
                    fontSize: '0.75em',
                    fontFamily: 'Roboto',
                    fontColor: 'White',
                    maximumFontSize: '5em',
                    rotation: 0,
                    positionX: 0,
                    rawEditorContent: '{"blocks":[{"key":"1buoi","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    backgroundColor: 'Black',
                    text: null,
                    plainText: null,
                    positionY: 0,
                    preferredHeight: '10em',
                    htmlText: '<p > </p>',
                  },
                  id: '49649760-3247-11ee-abfd-5f9de2162030',
                  width: 1,
                  y: 2,
                  documentId: 'XKj78n8wa2',
                },
              ],
              rows: 2,
              style: 'fixed',
              columns: 1,
            },
            documentId: 'XKj78n8wa2',
            meta: '{}',
            id: '2b23d72a-3247-11ee-abfd-5f9de2162030',
          },
          {
            documentId: 'XKj78n8wa2',
            meta: '{}',
            id: '2b23d72f-3247-11ee-abfd-5f9de2162030',
            grid: {
              rows: 2,
              columns: 1,
              style: 'fixed',
              blocks: [
                {
                  width: 1,
                  typeId: 'Conclusion',
                  y: 1,
                  height: 2,
                  id: '2b23d730-3247-11ee-abfd-5f9de2162030',
                  properties: {
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"569p6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    plainText: null,
                    htmlText: '<p > </p>',
                    fontColor: 'Black',
                    text: null,
                    fontFamily: "'Open Sans'",
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                  documentId: 'XKj78n8wa2',
                  x: 1,
                },
              ],
            },
            position: 4,
          },
        ],
        status: 'PUBLISHED',
      },
      thumbnailUrl: null,
      user: {
        username: null,
        name: 'Kieran',
        email: 'kieran+teacher@joinpressto.com',
        role: 'teacher',
        surname: 'Sobel',
        id: '7WwWWeqwqv',
      },
      submission: null,
      isPublic: true,
      updatedAt: '2024-04-17T19:42:00.834Z',
      format: DocumentFormat.STORY,
      isTemplate: true,
      templateSystem: 'blocks',
      meta: {
        hasImportantWords: true,
        hasSignalWords: true,
        writingPlan: 'cause-effect',
        importantWords: [],
        hasWordCount: true,
        hasWritingBuddy: true,
      },
      name: 'Copy of Cause and Effect - Story Template',
      id: 'template-story-cause-effect',
      isDemo: false,
    },
  },
  {
    format: DocumentFormat.DOC,
    name: 'Story',
    writingPlanId: 'story',
    documentId: 'template-doc-story',
    svg: SVGs.SettingCharacterSVG,
    color: '#FDAD4E',
    document: {
      thumbnailUrl: null,
      isTemplate: true,
      templateSystem: 'blocks',
      isDemo: false,
      updatedAt: '2024-04-17T19:42:00.900Z',
      meta: {
        importantWords: [],
        hasWritingBuddy: true,
        hasWordCount: true,
        hasSignalWords: true,
        hasImportantWords: true,
        writingPlan: 'story',
      },
      format: DocumentFormat.DOC,
      user: {
        name: 'Kieran',
        email: 'kieran+teacher@joinpressto.com',
        id: '7WwWWeqwqv',
        username: null,
        surname: 'Sobel',
        role: 'teacher',
      },
      submission: null,
      id: 'template-doc-story',
      version: {
        createdAt: {
          seconds: 1713382920,
          nanoseconds: 0,
        },
        publishedAt: {
          seconds: 1713382920,
          nanoseconds: 679000000,
        },
        notes: [],
        id: 'WKjeWLzJ6o',
        pages: [
          {
            id: 'acb2dd31-30ae-11ee-a0cb-3bf51732fff8',
            documentId: 'MOJBXWZj8N',
            grid: {
              columns: 1,
              rows: 11,
              style: 'fluid',
              blocks: [
                {
                  id: 'acb2dd32-30ae-11ee-a0cb-3bf51732fff8',
                  x: 1,
                  height: 1,
                  width: 1,
                  typeId: 'Title',
                  properties: {
                    fontFamily: 'Alfa',
                    text: null,
                    fontSize: '2em',
                    fontColor: 'Black',
                    html: null,
                  },
                  y: 1,
                  documentId: 'MOJBXWZj8N',
                },
                {
                  documentId: 'MOJBXWZj8N',
                  width: 1,
                  y: 2,
                  x: 1,
                  id: 'b01b83a0-30ae-11ee-a0cb-3bf51732fff8',
                  height: 1,
                  typeId: 'SettingAndCharacters',
                  properties: {
                    htmlText: '<p > </p>',
                    text: null,
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"6u4hg","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                  },
                },
                {
                  documentId: 'MOJBXWZj8N',
                  x: 1,
                  properties: {
                    positionY: 0,
                    preferredHeight: '10em',
                    text: null,
                    html: null,
                    positionX: 0,
                    rotation: 0,
                  },
                  typeId: 'Image',
                  id: 'b70d50d0-30ae-11ee-a0cb-3bf51732fff8',
                  y: 3,
                  height: 1,
                  width: 1,
                },
                {
                  id: 'b13d6e60-30ae-11ee-a0cb-3bf51732fff8',
                  width: 1,
                  x: 1,
                  y: 4,
                  properties: {
                    fontSize: '1em',
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"a35vo","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    plainText: null,
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    htmlText: '<p > </p>',
                    text: null,
                  },
                  documentId: 'MOJBXWZj8N',
                  typeId: 'ProblemOrGoal',
                  height: 1,
                },
                {
                  id: 'b224e830-30ae-11ee-a0cb-3bf51732fff8',
                  documentId: 'MOJBXWZj8N',
                  height: 1,
                  typeId: 'Attempts',
                  width: 1,
                  y: 5,
                  properties: {
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"9rgon","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    text: null,
                    fontFamily: "'Open Sans'",
                    plainText: null,
                  },
                  x: 1,
                },
                {
                  documentId: 'MOJBXWZj8N',
                  properties: {
                    preferredHeight: '10em',
                    html: null,
                    rotation: 0,
                    positionY: 0,
                    positionX: 0,
                    text: null,
                  },
                  id: 'ba521b90-30ae-11ee-a0cb-3bf51732fff8',
                  height: 1,
                  y: 6,
                  x: 1,
                  width: 1,
                  typeId: 'Image',
                },
                {
                  y: 7,
                  width: 1,
                  documentId: 'MOJBXWZj8N',
                  id: 'b318be10-30ae-11ee-a0cb-3bf51732fff8',
                  height: 1,
                  x: 1,
                  typeId: 'StorySolution',
                  properties: {
                    text: null,
                    plainText: null,
                    htmlText: '<p > </p>',
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"bkt84","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                },
                {
                  height: 1,
                  typeId: 'Consequence',
                  id: 'b3eed2c0-30ae-11ee-a0cb-3bf51732fff8',
                  y: 8,
                  x: 1,
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"6sg2t","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                    text: null,
                    fontColor: 'Black',
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                  },
                  width: 1,
                  documentId: 'MOJBXWZj8N',
                },
                {
                  width: 1,
                  y: 9,
                  height: 1,
                  id: 'be9a4c90-30ae-11ee-a0cb-3bf51732fff8',
                  typeId: 'Image',
                  x: 1,
                  properties: {
                    positionX: 0,
                    text: null,
                    rotation: 0,
                    positionY: 0,
                    html: null,
                    preferredHeight: '10em',
                  },
                  documentId: 'MOJBXWZj8N',
                },
                {
                  documentId: 'MOJBXWZj8N',
                  y: 10,
                  x: 1,
                  width: 1,
                  properties: {
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    plainText: null,
                    htmlText: '<p > </p>',
                    text: null,
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"99obs","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                  },
                  typeId: 'Resolution',
                  height: 1,
                  id: 'b4b297f0-30ae-11ee-a0cb-3bf51732fff8',
                },
                {
                  height: 1,
                  documentId: 'MOJBXWZj8N',
                  properties: {
                    preferredHeight: '10em',
                    positionY: 0,
                    rotation: 0,
                    html: null,
                    text: null,
                    positionX: 0,
                  },
                  typeId: 'Image',
                  y: 11,
                  x: 1,
                  width: 1,
                  id: 'c2e27d90-30ae-11ee-a0cb-3bf51732fff8',
                },
              ],
            },
            meta: '{}',
            position: 0,
          },
        ],
        documentId: 'WKjeWLzJ6o',
        content: {
          schemaVersion: 20230101,
          pages: [
            {
              position: 0,
              documentId: 'MOJBXWZj8N',
              meta: '{}',
              grid: {
                rows: 11,
                style: 'fluid',
                blocks: [
                  {
                    x: 1,
                    y: 1,
                    id: 'acb2dd32-30ae-11ee-a0cb-3bf51732fff8',
                    width: 1,
                    typeId: 'Title',
                    documentId: 'MOJBXWZj8N',
                    height: 1,
                    properties: {
                      html: null,
                      fontColor: 'Black',
                      fontFamily: 'Alfa',
                      text: null,
                      fontSize: '2em',
                    },
                  },
                  {
                    height: 1,
                    y: 2,
                    documentId: 'MOJBXWZj8N',
                    typeId: 'SettingAndCharacters',
                    id: 'b01b83a0-30ae-11ee-a0cb-3bf51732fff8',
                    width: 1,
                    properties: {
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"6u4hg","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      plainText: null,
                      fontColor: 'Black',
                      text: null,
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    },
                    x: 1,
                  },
                  {
                    y: 3,
                    width: 1,
                    id: 'b70d50d0-30ae-11ee-a0cb-3bf51732fff8',
                    documentId: 'MOJBXWZj8N',
                    properties: {
                      positionX: 0,
                      positionY: 0,
                      text: null,
                      preferredHeight: '10em',
                      rotation: 0,
                      html: null,
                    },
                    typeId: 'Image',
                    x: 1,
                    height: 1,
                  },
                  {
                    documentId: 'MOJBXWZj8N',
                    y: 4,
                    height: 1,
                    typeId: 'ProblemOrGoal',
                    width: 1,
                    x: 1,
                    properties: {
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontSize: '1em',
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      fontColor: 'Black',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"a35vo","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                    },
                    id: 'b13d6e60-30ae-11ee-a0cb-3bf51732fff8',
                  },
                  {
                    height: 1,
                    typeId: 'Attempts',
                    documentId: 'MOJBXWZj8N',
                    id: 'b224e830-30ae-11ee-a0cb-3bf51732fff8',
                    width: 1,
                    x: 1,
                    y: 5,
                    properties: {
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"9rgon","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      htmlText: '<p > </p>',
                      fontColor: 'Black',
                      plainText: null,
                    },
                  },
                  {
                    typeId: 'Image',
                    id: 'ba521b90-30ae-11ee-a0cb-3bf51732fff8',
                    height: 1,
                    properties: {
                      rotation: 0,
                      html: null,
                      preferredHeight: '10em',
                      text: null,
                      positionX: 0,
                      positionY: 0,
                    },
                    y: 6,
                    documentId: 'MOJBXWZj8N',
                    width: 1,
                    x: 1,
                  },
                  {
                    height: 1,
                    width: 1,
                    typeId: 'StorySolution',
                    properties: {
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"bkt84","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      plainText: null,
                      text: null,
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                    },
                    x: 1,
                    y: 7,
                    documentId: 'MOJBXWZj8N',
                    id: 'b318be10-30ae-11ee-a0cb-3bf51732fff8',
                  },
                  {
                    properties: {
                      plainText: null,
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"6sg2t","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      fontSize: '1em',
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                    },
                    typeId: 'Consequence',
                    y: 8,
                    documentId: 'MOJBXWZj8N',
                    id: 'b3eed2c0-30ae-11ee-a0cb-3bf51732fff8',
                    x: 1,
                    width: 1,
                    height: 1,
                  },
                  {
                    properties: {
                      html: null,
                      positionY: 0,
                      preferredHeight: '10em',
                      text: null,
                      positionX: 0,
                      rotation: 0,
                    },
                    height: 1,
                    y: 9,
                    typeId: 'Image',
                    id: 'be9a4c90-30ae-11ee-a0cb-3bf51732fff8',
                    documentId: 'MOJBXWZj8N',
                    x: 1,
                    width: 1,
                  },
                  {
                    y: 10,
                    height: 1,
                    id: 'b4b297f0-30ae-11ee-a0cb-3bf51732fff8',
                    documentId: 'MOJBXWZj8N',
                    typeId: 'Resolution',
                    properties: {
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"99obs","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                      text: null,
                      plainText: null,
                    },
                    width: 1,
                    x: 1,
                  },
                  {
                    width: 1,
                    x: 1,
                    properties: {
                      html: null,
                      text: null,
                      rotation: 0,
                      preferredHeight: '10em',
                      positionX: 0,
                      positionY: 0,
                    },
                    y: 11,
                    documentId: 'MOJBXWZj8N',
                    id: 'c2e27d90-30ae-11ee-a0cb-3bf51732fff8',
                    typeId: 'Image',
                    height: 1,
                  },
                ],
                columns: 1,
              },
              id: 'acb2dd31-30ae-11ee-a0cb-3bf51732fff8',
            },
          ],
          messages: [],
        },
        status: 'PUBLISHED',
      },
      name: 'Copy of Story - Docs Template',
      createdAt: '2024-04-17T19:42:00.000Z',
      isPublic: true,
    },
  },
  {
    format: DocumentFormat.STORY,
    name: 'Compare and Contrast',
    writingPlanId: 'compare-contrast',
    documentId: 'template-story-compare-contrast',
    svg: SVGs.ContrastSVG,
    color: '#D2D7D3',
    document: {
      createdAt: '2024-04-17T19:42:00.000Z',
      submission: null,
      isPublic: true,
      version: {
        publishedAt: {
          seconds: 1713382920,
          nanoseconds: 823000000,
        },
        documentId: 'MOJBVg0J8N',
        content: {
          pages: [
            {
              position: 0,
              grid: {
                columns: 1,
                blocks: [
                  {
                    height: 1,
                    properties: {
                      plainText: null,
                      rawEditorContent: '{"blocks":[{"key":"cfjhc","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                      text: null,
                      fontSize: '2em',
                      fontFamily: 'Alfa',
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                    },
                    width: 1,
                    x: 1,
                    id: 'ff0370f2-3242-11ee-abfd-5f9de2162030',
                    typeId: 'Title',
                    documentId: 'Z2jnM16498',
                    y: 1,
                  },
                  {
                    properties: {
                      text: null,
                      html: null,
                      rotation: 0,
                      positionX: 0,
                      positionY: 0,
                      preferredHeight: '10em',
                    },
                    width: 1,
                    y: 2,
                    x: 1,
                    id: '0665da40-3243-11ee-abfd-5f9de2162030',
                    height: 1,
                    typeId: 'Image',
                    documentId: 'Z2jnM16498',
                  },
                ],
                rows: 2,
                style: 'fixed',
              },
              documentId: 'Z2jnM16498',
              meta: '{}',
              id: 'ff0370f1-3242-11ee-abfd-5f9de2162030',
            },
            {
              grid: {
                columns: 1,
                blocks: [
                  {
                    typeId: 'Introduction',
                    width: 1,
                    y: 1,
                    id: '0c476ff0-3243-11ee-abfd-5f9de2162030',
                    x: 1,
                    documentId: 'Z2jnM16498',
                    properties: {
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                      text: null,
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"6fs62","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      plainText: null,
                    },
                    height: 2,
                  },
                ],
                style: 'fixed',
                rows: 2,
              },
              id: '06dd5431-3243-11ee-abfd-5f9de2162030',
              documentId: 'Z2jnM16498',
              position: 1,
              meta: '{}',
            },
            {
              documentId: 'Z2jnM16498',
              meta: '{}',
              grid: {
                style: 'fixed',
                blocks: [
                  {
                    x: 1,
                    height: 1,
                    id: '50dc7f10-3569-11ee-b38a-fb6fedb7c693',
                    typeId: 'Image',
                    documentId: 'Z2jnM16498',
                    y: 1,
                    width: 1,
                    properties: {
                      html: null,
                      rotation: 0,
                      positionX: 0,
                      preferredHeight: '10em',
                      positionY: 0,
                      text: null,
                    },
                  },
                  {
                    typeId: 'Compare',
                    documentId: 'Z2jnM16498',
                    height: 1,
                    y: 2,
                    properties: {
                      fontSize: '1em',
                      fontColor: 'Black',
                      plainText: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"8vfka","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                    },
                    width: 1,
                    id: '109211f0-3243-11ee-abfd-5f9de2162030',
                    x: 1,
                  },
                ],
                columns: 1,
                rows: 2,
              },
              id: '0f870a91-3243-11ee-abfd-5f9de2162030',
              position: 2,
            },
            {
              documentId: 'Z2jnM16498',
              meta: '{}',
              grid: {
                rows: 2,
                style: 'fixed',
                blocks: [
                  {
                    typeId: 'ImageCaption',
                    width: 1,
                    y: 1,
                    id: '138d2980-3243-11ee-abfd-5f9de2162030',
                    height: 2,
                    documentId: 'Z2jnM16498',
                    x: 1,
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 0.75em; font-family: Roboto; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      backgroundColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"5o99v","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      fontColor: 'White',
                      plainText: null,
                      fontFamily: 'Roboto',
                      text: null,
                      fontSize: '0.75em',
                    },
                  },
                ],
                columns: 1,
              },
              id: '122bebd1-3243-11ee-abfd-5f9de2162030',
              position: 3,
            },
            {
              grid: {
                style: 'fixed',
                rows: 2,
                columns: 1,
                blocks: [
                  {
                    typeId: 'Contrast',
                    height: 1,
                    id: '1640d7d0-3243-11ee-abfd-5f9de2162030',
                    properties: {
                      fontColor: 'Black',
                      maximumFontSize: '5em',
                      fontFamily: "'Open Sans'",
                      text: null,
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"9ivkq","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      plainText: null,
                    },
                    documentId: 'Z2jnM16498',
                    x: 1,
                    y: 1,
                    width: 1,
                  },
                  {
                    width: 1,
                    id: '53d3ed20-3569-11ee-b38a-fb6fedb7c693',
                    x: 1,
                    documentId: 'Z2jnM16498',
                    properties: {
                      html: null,
                      text: null,
                      positionX: 0,
                      preferredHeight: '10em',
                      positionY: 0,
                      rotation: 0,
                    },
                    y: 2,
                    typeId: 'Image',
                    height: 1,
                  },
                ],
              },
              id: '154d5011-3243-11ee-abfd-5f9de2162030',
              position: 4,
              documentId: 'Z2jnM16498',
              meta: '{}',
            },
            {
              position: 5,
              id: '185f4b01-3243-11ee-abfd-5f9de2162030',
              grid: {
                blocks: [
                  {
                    height: 2,
                    id: '199a14f0-3243-11ee-abfd-5f9de2162030',
                    width: 1,
                    x: 1,
                    y: 1,
                    properties: {
                      fontColor: 'White',
                      plainText: null,
                      fontSize: '0.75em',
                      maximumFontSize: '5em',
                      text: null,
                      htmlText: '<p > </p>',
                      fontFamily: 'Roboto',
                      rawEditorContent: '{"blocks":[{"key":"b2tnh","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      backgroundColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 0.75em; font-family: Roboto; position: relative'><p > </p></div></div>",
                    },
                    typeId: 'ImageCaption',
                    documentId: 'Z2jnM16498',
                  },
                ],
                style: 'fixed',
                columns: 1,
                rows: 2,
              },
              documentId: 'Z2jnM16498',
              meta: '{}',
            },
            {
              meta: '{}',
              position: 6,
              id: '1b522531-3243-11ee-abfd-5f9de2162030',
              documentId: 'Z2jnM16498',
              grid: {
                style: 'fixed',
                columns: 1,
                rows: 2,
                blocks: [
                  {
                    x: 1,
                    typeId: 'Conclusion',
                    properties: {
                      plainText: null,
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"569p6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                      text: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                    },
                    documentId: 'Z2jnM16498',
                    width: 1,
                    height: 2,
                    id: '1ca6b8b0-3243-11ee-abfd-5f9de2162030',
                    y: 1,
                  },
                ],
              },
            },
          ],
          schemaVersion: 20230101,
          messages: [],
        },
        id: 'MOJBVg0J8N',
        notes: [],
        status: 'PUBLISHED',
        pages: [
          {
            meta: '{}',
            id: 'ff0370f1-3242-11ee-abfd-5f9de2162030',
            grid: {
              style: 'fixed',
              columns: 1,
              blocks: [
                {
                  x: 1,
                  typeId: 'Title',
                  documentId: 'Z2jnM16498',
                  properties: {
                    text: null,
                    fontFamily: 'Alfa',
                    maximumFontSize: '5em',
                    plainText: null,
                    fontSize: '2em',
                    rawEditorContent: '{"blocks":[{"key":"cfjhc","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                  },
                  width: 1,
                  id: 'ff0370f2-3242-11ee-abfd-5f9de2162030',
                  height: 1,
                  y: 1,
                },
                {
                  width: 1,
                  properties: {
                    positionY: 0,
                    rotation: 0,
                    positionX: 0,
                    text: null,
                    html: null,
                    preferredHeight: '10em',
                  },
                  id: '0665da40-3243-11ee-abfd-5f9de2162030',
                  height: 1,
                  typeId: 'Image',
                  x: 1,
                  documentId: 'Z2jnM16498',
                  y: 2,
                },
              ],
              rows: 2,
            },
            documentId: 'Z2jnM16498',
            position: 0,
          },
          {
            id: '06dd5431-3243-11ee-abfd-5f9de2162030',
            position: 1,
            grid: {
              columns: 1,
              style: 'fixed',
              rows: 2,
              blocks: [
                {
                  y: 1,
                  width: 1,
                  id: '0c476ff0-3243-11ee-abfd-5f9de2162030',
                  x: 1,
                  documentId: 'Z2jnM16498',
                  properties: {
                    maximumFontSize: '5em',
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"6fs62","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    plainText: null,
                    text: null,
                  },
                  height: 2,
                  typeId: 'Introduction',
                },
              ],
            },
            meta: '{}',
            documentId: 'Z2jnM16498',
          },
          {
            grid: {
              blocks: [
                {
                  typeId: 'Image',
                  properties: {
                    html: null,
                    positionY: 0,
                    preferredHeight: '10em',
                    positionX: 0,
                    text: null,
                    rotation: 0,
                  },
                  height: 1,
                  width: 1,
                  documentId: 'Z2jnM16498',
                  id: '50dc7f10-3569-11ee-b38a-fb6fedb7c693',
                  y: 1,
                  x: 1,
                },
                {
                  height: 1,
                  id: '109211f0-3243-11ee-abfd-5f9de2162030',
                  documentId: 'Z2jnM16498',
                  properties: {
                    fontSize: '1em',
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"8vfka","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    text: null,
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                  },
                  x: 1,
                  y: 2,
                  width: 1,
                  typeId: 'Compare',
                },
              ],
              rows: 2,
              columns: 1,
              style: 'fixed',
            },
            id: '0f870a91-3243-11ee-abfd-5f9de2162030',
            position: 2,
            meta: '{}',
            documentId: 'Z2jnM16498',
          },
          {
            documentId: 'Z2jnM16498',
            grid: {
              blocks: [
                {
                  height: 2,
                  properties: {
                    backgroundColor: 'Black',
                    text: null,
                    maximumFontSize: '5em',
                    plainText: null,
                    fontColor: 'White',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 0.75em; font-family: Roboto; position: relative'><p > </p></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"5o99v","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontSize: '0.75em',
                    fontFamily: 'Roboto',
                    htmlText: '<p > </p>',
                  },
                  width: 1,
                  x: 1,
                  y: 1,
                  id: '138d2980-3243-11ee-abfd-5f9de2162030',
                  typeId: 'ImageCaption',
                  documentId: 'Z2jnM16498',
                },
              ],
              rows: 2,
              columns: 1,
              style: 'fixed',
            },
            position: 3,
            id: '122bebd1-3243-11ee-abfd-5f9de2162030',
            meta: '{}',
          },
          {
            id: '154d5011-3243-11ee-abfd-5f9de2162030',
            meta: '{}',
            position: 4,
            documentId: 'Z2jnM16498',
            grid: {
              blocks: [
                {
                  documentId: 'Z2jnM16498',
                  width: 1,
                  typeId: 'Contrast',
                  id: '1640d7d0-3243-11ee-abfd-5f9de2162030',
                  y: 1,
                  properties: {
                    htmlText: '<p > </p>',
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                    text: null,
                    maximumFontSize: '5em',
                    rawEditorContent: '{"blocks":[{"key":"9ivkq","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontSize: '1em',
                    fontFamily: "'Open Sans'",
                  },
                  height: 1,
                  x: 1,
                },
                {
                  documentId: 'Z2jnM16498',
                  properties: {
                    preferredHeight: '10em',
                    positionY: 0,
                    rotation: 0,
                    html: null,
                    positionX: 0,
                    text: null,
                  },
                  x: 1,
                  height: 1,
                  width: 1,
                  y: 2,
                  typeId: 'Image',
                  id: '53d3ed20-3569-11ee-b38a-fb6fedb7c693',
                },
              ],
              columns: 1,
              style: 'fixed',
              rows: 2,
            },
          },
          {
            meta: '{}',
            documentId: 'Z2jnM16498',
            id: '185f4b01-3243-11ee-abfd-5f9de2162030',
            grid: {
              style: 'fixed',
              rows: 2,
              blocks: [
                {
                  id: '199a14f0-3243-11ee-abfd-5f9de2162030',
                  x: 1,
                  height: 2,
                  documentId: 'Z2jnM16498',
                  typeId: 'ImageCaption',
                  width: 1,
                  properties: {
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    fontSize: '0.75em',
                    plainText: null,
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"b2tnh","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    backgroundColor: 'Black',
                    fontColor: 'White',
                    fontFamily: 'Roboto',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 0.75em; font-family: Roboto; position: relative'><p > </p></div></div>",
                  },
                  y: 1,
                },
              ],
              columns: 1,
            },
            position: 5,
          },
          {
            position: 6,
            meta: '{}',
            id: '1b522531-3243-11ee-abfd-5f9de2162030',
            grid: {
              blocks: [
                {
                  properties: {
                    htmlText: '<p > </p>',
                    text: null,
                    fontColor: 'Black',
                    fontFamily: "'Open Sans'",
                    plainText: null,
                    maximumFontSize: '5em',
                    rawEditorContent: '{"blocks":[{"key":"569p6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontSize: '1em',
                  },
                  id: '1ca6b8b0-3243-11ee-abfd-5f9de2162030',
                  x: 1,
                  typeId: 'Conclusion',
                  width: 1,
                  documentId: 'Z2jnM16498',
                  y: 1,
                  height: 2,
                },
              ],
              columns: 1,
              rows: 2,
              style: 'fixed',
            },
            documentId: 'Z2jnM16498',
          },
        ],
        createdAt: {
          seconds: 1713382920,
          nanoseconds: 0,
        },
      },
      name: 'Copy of Compare and Contrast - Story Template',
      updatedAt: '2024-04-17T19:42:00.986Z',
      isDemo: false,
      id: 'template-story-compare-contrast',
      user: {
        email: 'kieran+teacher@joinpressto.com',
        username: null,
        surname: 'Sobel',
        name: 'Kieran',
        role: 'teacher',
        id: '7WwWWeqwqv',
      },
      templateSystem: 'blocks',
      thumbnailUrl: null,
      meta: {
        writingPlan: 'compare-contrast',
        hasWordCount: true,
        hasSignalWords: true,
        hasWritingBuddy: true,
        hasImportantWords: true,
        importantWords: [],
      },
      isTemplate: true,
      format: DocumentFormat.STORY,
    },
  },
  {
    format: DocumentFormat.DOC,
    name: 'Problem and Solution',
    writingPlanId: 'problem-solution',
    documentId: 'template-doc-problem-solution',
    svg: SVGs.SolutionPuzzleSVG,
    color: '#52509A',
    document: {
      name: 'Copy of Problem and Solution - Docs Template',
      updatedAt: '2024-04-17T19:42:01.245Z',
      isDemo: false,
      thumbnailUrl: null,
      isTemplate: true,
      isPublic: true,
      meta: {
        hasImportantWords: true,
        writingPlan: 'problem-solution',
        hasWritingBuddy: true,
        hasWordCount: true,
        importantWords: [],
        hasSignalWords: true,
      },
      version: {
        content: {
          schemaVersion: 20230101,
          pages: [
            {
              position: 0,
              meta: '{}',
              id: '711760a0-3092-11ee-a7ee-1d0afbee0047',
              grid: {
                style: 'fluid',
                rows: 7,
                columns: 1,
                blocks: [
                  {
                    typeId: 'Title',
                    y: 1,
                    height: 1,
                    properties: {
                      html: null,
                      text: null,
                      fontSize: '2em',
                      fontFamily: 'Alfa',
                      fontColor: 'Black',
                    },
                    id: '711760a1-3092-11ee-a7ee-1d0afbee0047',
                    width: 1,
                    x: 1,
                    documentId: 'qKwEnY8Jmx',
                  },
                  {
                    y: 2,
                    height: 1,
                    properties: {
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontSize: '1em',
                      htmlText: '<p > </p>',
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"2eeb7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                      text: null,
                      plainText: null,
                    },
                    typeId: 'Introduction',
                    x: 1,
                    width: 1,
                    id: '711760a2-3092-11ee-a7ee-1d0afbee0047',
                    documentId: 'qKwEnY8Jmx',
                  },
                  {
                    properties: {
                      htmlText: '<p > </p>',
                      text: null,
                      fontSize: '1em',
                      plainText: null,
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"8q2ka","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    },
                    x: 1,
                    width: 1,
                    height: 1,
                    typeId: 'Problem',
                    documentId: 'qKwEnY8Jmx',
                    y: 3,
                    id: '711760a3-3092-11ee-a7ee-1d0afbee0047',
                  },
                  {
                    id: '711760a4-3092-11ee-a7ee-1d0afbee0047',
                    height: 1,
                    documentId: 'qKwEnY8Jmx',
                    x: 1,
                    y: 4,
                    properties: {
                      positionY: 0,
                      text: null,
                      positionX: 0,
                      preferredHeight: '10em',
                      rotation: 0,
                      html: null,
                    },
                    typeId: 'Image',
                    width: 1,
                  },
                  {
                    x: 1,
                    width: 1,
                    typeId: 'Solution',
                    documentId: 'qKwEnY8Jmx',
                    id: '589d3e90-3093-11ee-a7ee-1d0afbee0047',
                    properties: {
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"qne8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      fontSize: '1em',
                    },
                    y: 5,
                    height: 1,
                  },
                  {
                    id: '711760a8-3092-11ee-a7ee-1d0afbee0047',
                    y: 6,
                    documentId: 'qKwEnY8Jmx',
                    x: 1,
                    width: 1,
                    typeId: 'Image',
                    height: 1,
                    properties: {
                      positionX: 0,
                      html: null,
                      positionY: 0,
                      preferredHeight: '10em',
                      text: null,
                      rotation: 0,
                    },
                  },
                  {
                    y: 7,
                    properties: {
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"es923","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '1em',
                      text: null,
                      plainText: null,
                    },
                    id: '711760a9-3092-11ee-a7ee-1d0afbee0047',
                    typeId: 'Conclusion',
                    width: 1,
                    height: 1,
                    documentId: 'qKwEnY8Jmx',
                    x: 1,
                  },
                ],
              },
              documentId: 'qKwEnY8Jmx',
            },
          ],
          messages: [],
        },
        notes: [],
        createdAt: {
          seconds: 1713382921,
          nanoseconds: 0,
        },
        status: 'PUBLISHED',
        id: '51JqmZRJKA',
        pages: [
          {
            meta: '{}',
            grid: {
              columns: 1,
              rows: 7,
              style: 'fluid',
              blocks: [
                {
                  id: '711760a1-3092-11ee-a7ee-1d0afbee0047',
                  y: 1,
                  typeId: 'Title',
                  width: 1,
                  height: 1,
                  properties: {
                    fontColor: 'Black',
                    fontFamily: 'Alfa',
                    html: null,
                    fontSize: '2em',
                    text: null,
                  },
                  documentId: 'qKwEnY8Jmx',
                  x: 1,
                },
                {
                  x: 1,
                  documentId: 'qKwEnY8Jmx',
                  y: 2,
                  height: 1,
                  id: '711760a2-3092-11ee-a7ee-1d0afbee0047',
                  typeId: 'Introduction',
                  properties: {
                    plainText: null,
                    text: null,
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"2eeb7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                  },
                  width: 1,
                },
                {
                  typeId: 'Problem',
                  properties: {
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    fontColor: 'Black',
                    text: null,
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"8q2ka","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                  },
                  documentId: 'qKwEnY8Jmx',
                  width: 1,
                  height: 1,
                  x: 1,
                  id: '711760a3-3092-11ee-a7ee-1d0afbee0047',
                  y: 3,
                },
                {
                  x: 1,
                  height: 1,
                  typeId: 'Image',
                  properties: {
                    text: null,
                    positionY: 0,
                    html: null,
                    preferredHeight: '10em',
                    rotation: 0,
                    positionX: 0,
                  },
                  documentId: 'qKwEnY8Jmx',
                  width: 1,
                  id: '711760a4-3092-11ee-a7ee-1d0afbee0047',
                  y: 4,
                },
                {
                  x: 1,
                  id: '589d3e90-3093-11ee-a7ee-1d0afbee0047',
                  properties: {
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"qne8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    plainText: null,
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    text: null,
                    htmlText: '<p > </p>',
                  },
                  height: 1,
                  width: 1,
                  documentId: 'qKwEnY8Jmx',
                  y: 5,
                  typeId: 'Solution',
                },
                {
                  id: '711760a8-3092-11ee-a7ee-1d0afbee0047',
                  height: 1,
                  typeId: 'Image',
                  properties: {
                    html: null,
                    preferredHeight: '10em',
                    positionY: 0,
                    positionX: 0,
                    text: null,
                    rotation: 0,
                  },
                  x: 1,
                  y: 6,
                  documentId: 'qKwEnY8Jmx',
                  width: 1,
                },
                {
                  x: 1,
                  properties: {
                    plainText: null,
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"es923","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontSize: '1em',
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    text: null,
                  },
                  width: 1,
                  documentId: 'qKwEnY8Jmx',
                  height: 1,
                  id: '711760a9-3092-11ee-a7ee-1d0afbee0047',
                  y: 7,
                  typeId: 'Conclusion',
                },
              ],
            },
            position: 0,
            documentId: 'qKwEnY8Jmx',
            id: '711760a0-3092-11ee-a7ee-1d0afbee0047',
          },
        ],
        documentId: '51JqmZRJKA',
        publishedAt: {
          seconds: 1713382921,
          nanoseconds: 188000000,
        },
      },
      createdAt: '2024-04-17T19:42:01.000Z',
      user: {
        email: 'kieran+teacher@joinpressto.com',
        role: 'teacher',
        username: null,
        id: '7WwWWeqwqv',
        surname: 'Sobel',
        name: 'Kieran',
      },
      id: 'template-doc-problem-solution',
      templateSystem: 'blocks',
      format: DocumentFormat.DOC,
      submission: null,
    },
  },
  {
    format: DocumentFormat.DOC,
    name: 'Personal Narrative',
    writingPlanId: 'personal-narrative',
    documentId: 'template-doc-personal-narrative',
    svg: SVGs.IntroductionSVG,
    color: '#E84534',
    document: {
      templateSystem: 'blocks',
      isPublic: true,
      version: {
        pages: [
          {
            meta: '{}',
            documentId: 'bOJ0kynwzR',
            position: 0,
            grid: {
              blocks: [
                {
                  x: 1,
                  id: '013c2402-30b0-11ee-a0cb-3bf51732fff8',
                  width: 1,
                  properties: {
                    fontFamily: 'Alfa',
                    text: null,
                    fontColor: 'Black',
                    fontSize: '2em',
                    html: null,
                  },
                  y: 1,
                  documentId: 'bOJ0kynwzR',
                  typeId: 'Title',
                  height: 1,
                },
                {
                  documentId: 'bOJ0kynwzR',
                  y: 2,
                  id: '06b533e0-30b0-11ee-a0cb-3bf51732fff8',
                  typeId: 'Introduction',
                  x: 1,
                  height: 1,
                  width: 1,
                  properties: {
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"7tpu8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    text: null,
                  },
                },
                {
                  typeId: 'SettingAndCharacters',
                  height: 1,
                  x: 1,
                  documentId: 'bOJ0kynwzR',
                  y: 3,
                  width: 1,
                  properties: {
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"9gtmq","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    text: null,
                    fontFamily: "'Open Sans'",
                  },
                  id: '07e3efe0-30b0-11ee-a0cb-3bf51732fff8',
                },
                {
                  documentId: 'bOJ0kynwzR',
                  x: 1,
                  height: 1,
                  y: 4,
                  properties: {
                    html: null,
                    preferredHeight: '10em',
                    positionY: 0,
                    text: null,
                    rotation: 0,
                    positionX: 0,
                  },
                  id: '0c21e7b0-30b0-11ee-a0cb-3bf51732fff8',
                  width: 1,
                  typeId: 'Image',
                },
                {
                  y: 5,
                  x: 1,
                  typeId: 'Details',
                  documentId: 'bOJ0kynwzR',
                  height: 1,
                  width: 1,
                  id: '08be9870-30b0-11ee-a0cb-3bf51732fff8',
                  properties: {
                    plainText: null,
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    text: null,
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"4gdbd","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                },
                {
                  documentId: 'bOJ0kynwzR',
                  properties: {
                    positionY: 0,
                    html: null,
                    text: null,
                    rotation: 0,
                    preferredHeight: '10em',
                    positionX: 0,
                  },
                  width: 1,
                  x: 1,
                  y: 6,
                  id: '0f408cd0-30b0-11ee-a0cb-3bf51732fff8',
                  typeId: 'Image',
                  height: 1,
                },
                {
                  typeId: 'Conclusion',
                  properties: {
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontSize: '1em',
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    plainText: null,
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"1kjt4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  x: 1,
                  width: 1,
                  id: '0b2b2ba0-30b0-11ee-a0cb-3bf51732fff8',
                  height: 1,
                  documentId: 'bOJ0kynwzR',
                  y: 7,
                },
              ],
              columns: 1,
              style: 'fluid',
              rows: 7,
            },
            id: '013c2401-30b0-11ee-a0cb-3bf51732fff8',
          },
        ],
        status: 'PUBLISHED',
        notes: [],
        content: {
          pages: [
            {
              grid: {
                blocks: [
                  {
                    y: 1,
                    height: 1,
                    width: 1,
                    documentId: 'bOJ0kynwzR',
                    properties: {
                      text: null,
                      fontColor: 'Black',
                      fontSize: '2em',
                      fontFamily: 'Alfa',
                      html: null,
                    },
                    id: '013c2402-30b0-11ee-a0cb-3bf51732fff8',
                    x: 1,
                    typeId: 'Title',
                  },
                  {
                    id: '06b533e0-30b0-11ee-a0cb-3bf51732fff8',
                    height: 1,
                    properties: {
                      plainText: null,
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"7tpu8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    },
                    documentId: 'bOJ0kynwzR',
                    x: 1,
                    y: 2,
                    width: 1,
                    typeId: 'Introduction',
                  },
                  {
                    id: '07e3efe0-30b0-11ee-a0cb-3bf51732fff8',
                    x: 1,
                    width: 1,
                    documentId: 'bOJ0kynwzR',
                    properties: {
                      fontColor: 'Black',
                      plainText: null,
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"9gtmq","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      fontSize: '1em',
                      fontFamily: "'Open Sans'",
                    },
                    height: 1,
                    y: 3,
                    typeId: 'SettingAndCharacters',
                  },
                  {
                    height: 1,
                    documentId: 'bOJ0kynwzR',
                    width: 1,
                    x: 1,
                    y: 4,
                    properties: {
                      positionX: 0,
                      rotation: 0,
                      positionY: 0,
                      preferredHeight: '10em',
                      text: null,
                      html: null,
                    },
                    id: '0c21e7b0-30b0-11ee-a0cb-3bf51732fff8',
                    typeId: 'Image',
                  },
                  {
                    width: 1,
                    typeId: 'Details',
                    documentId: 'bOJ0kynwzR',
                    properties: {
                      plainText: null,
                      fontSize: '1em',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"4gdbd","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                    },
                    y: 5,
                    x: 1,
                    id: '08be9870-30b0-11ee-a0cb-3bf51732fff8',
                    height: 1,
                  },
                  {
                    properties: {
                      rotation: 0,
                      text: null,
                      html: null,
                      preferredHeight: '10em',
                      positionY: 0,
                      positionX: 0,
                    },
                    y: 6,
                    id: '0f408cd0-30b0-11ee-a0cb-3bf51732fff8',
                    width: 1,
                    x: 1,
                    documentId: 'bOJ0kynwzR',
                    height: 1,
                    typeId: 'Image',
                  },
                  {
                    width: 1,
                    typeId: 'Conclusion',
                    id: '0b2b2ba0-30b0-11ee-a0cb-3bf51732fff8',
                    height: 1,
                    y: 7,
                    x: 1,
                    documentId: 'bOJ0kynwzR',
                    properties: {
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      plainText: null,
                      rawEditorContent: '{"blocks":[{"key":"1kjt4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '1em',
                      text: null,
                      htmlText: '<p > </p>',
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                    },
                  },
                ],
                style: 'fluid',
                rows: 7,
                columns: 1,
              },
              documentId: 'bOJ0kynwzR',
              id: '013c2401-30b0-11ee-a0cb-3bf51732fff8',
              meta: '{}',
              position: 0,
            },
          ],
          messages: [],
          schemaVersion: 20230101,
        },
        createdAt: {
          seconds: 1713382921,
          nanoseconds: 0,
        },
        id: 'bOJ0RdpjzR',
        documentId: 'bOJ0RdpjzR',
        publishedAt: {
          seconds: 1713382921,
          nanoseconds: 253000000,
        },
      },
      submission: null,
      user: {
        id: '7WwWWeqwqv',
        email: 'kieran+teacher@joinpressto.com',
        name: 'Kieran',
        role: 'teacher',
        username: null,
        surname: 'Sobel',
      },
      id: 'template-doc-personal-narrative',
      meta: {
        hasWritingBuddy: true,
        writingPlan: 'personal-narrative',
        hasWordCount: true,
        importantWords: [],
        hasImportantWords: true,
        hasSignalWords: true,
      },
      updatedAt: '2024-04-17T19:42:01.324Z',
      format: DocumentFormat.DOC,
      name: 'Copy of Personal Narrative - Docs Template',
      isDemo: false,
      createdAt: '2024-04-17T19:42:01.000Z',
      thumbnailUrl: null,
      isTemplate: true,
    },
  },
  {
    format: DocumentFormat.STORY,
    name: 'News Article',
    writingPlanId: 'news-article',
    documentId: 'template-story-news-article',
    svg: SVGs.QuoteSVG,
    color: '#EEA1A2',
    document: {
      templateSystem: 'blocks',
      createdAt: '2024-04-17T19:42:01.000Z',
      version: {
        createdAt: {
          seconds: 1713382921,
          nanoseconds: 0,
        },
        content: {
          messages: [],
          pages: [
            {
              meta: '{}',
              grid: {
                blocks: [
                  {
                    documentId: 'VG48O8RJNv',
                    x: 1,
                    typeId: 'Title',
                    width: 1,
                    id: '00b12b81-3244-11ee-abfd-5f9de2162030',
                    properties: {
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"cna38","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      maximumFontSize: '5em',
                      fontSize: '2em',
                      plainText: null,
                      text: null,
                      fontFamily: 'Alfa',
                    },
                    height: 2,
                    y: 1,
                  },
                ],
                rows: 2,
                style: 'fixed',
                columns: 1,
              },
              position: 0,
              id: '00b12b80-3244-11ee-abfd-5f9de2162030',
              documentId: 'VG48O8RJNv',
            },
            {
              meta: '{}',
              documentId: 'VG48O8RJNv',
              id: '04ccce41-3244-11ee-abfd-5f9de2162030',
              position: 1,
              grid: {
                blocks: [
                  {
                    height: 1,
                    typeId: 'Lead',
                    width: 1,
                    x: 1,
                    y: 1,
                    properties: {
                      maximumFontSize: '5em',
                      rawEditorContent: '{"blocks":[{"key":"97la4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                      text: null,
                      plainText: null,
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                    },
                    id: '09961620-3244-11ee-abfd-5f9de2162030',
                    documentId: 'VG48O8RJNv',
                  },
                  {
                    documentId: 'VG48O8RJNv',
                    typeId: 'MainIdea',
                    width: 1,
                    y: 2,
                    height: 1,
                    x: 1,
                    id: '0a59b440-3244-11ee-abfd-5f9de2162030',
                    properties: {
                      fontFamily: "'Open Sans'",
                      rawEditorContent: '{"blocks":[{"key":"5elol","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                      plainText: null,
                      text: null,
                      maximumFontSize: '5em',
                      fontSize: '1em',
                    },
                  },
                ],
                style: 'fixed',
                columns: 1,
                rows: 2,
              },
            },
            {
              meta: '{}',
              grid: {
                style: 'fixed',
                columns: 1,
                blocks: [
                  {
                    width: 1,
                    id: '0c472c60-3244-11ee-abfd-5f9de2162030',
                    y: 1,
                    properties: {
                      text: null,
                      fontSize: '0.75em',
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      backgroundColor: 'Black',
                      plainText: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 0.75em; font-family: Roboto; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"avi95","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'White',
                      fontFamily: 'Roboto',
                    },
                    height: 2,
                    typeId: 'ImageCaption',
                    documentId: 'VG48O8RJNv',
                    x: 1,
                  },
                ],
                rows: 2,
              },
              position: 2,
              id: '0b356e41-3244-11ee-abfd-5f9de2162030',
              documentId: 'VG48O8RJNv',
            },
            {
              id: '11284201-3244-11ee-abfd-5f9de2162030',
              documentId: 'VG48O8RJNv',
              grid: {
                blocks: [
                  {
                    documentId: 'VG48O8RJNv',
                    y: 1,
                    properties: {
                      maximumFontSize: '5em',
                      rawEditorContent: '{"blocks":[{"key":"1vdm7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      plainText: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1.5em; font-family: 'Source Serif 4'; position: relative'><p > </p></div></div>",
                      fontSize: '1.5em',
                      fontFamily: "'Source Serif 4'",
                      fontColor: 'Black',
                      fontStyle: 'Italic',
                      htmlText: '<p > </p>',
                    },
                    typeId: 'CallOut',
                    width: 1,
                    id: '126fdd30-3244-11ee-abfd-5f9de2162030',
                    x: 1,
                    height: 1,
                  },
                  {
                    id: '13246020-3244-11ee-abfd-5f9de2162030',
                    typeId: 'Details',
                    properties: {
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"b4mku","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '1em',
                      fontFamily: "'Open Sans'",
                      text: null,
                      maximumFontSize: '5em',
                      plainText: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                    },
                    height: 1,
                    documentId: 'VG48O8RJNv',
                    width: 1,
                    y: 2,
                    x: 1,
                  },
                ],
                rows: 2,
                columns: 1,
                style: 'fixed',
              },
              meta: '{}',
              position: 3,
            },
            {
              grid: {
                blocks: [
                  {
                    width: 1,
                    typeId: 'ImageCaption',
                    height: 2,
                    properties: {
                      plainText: null,
                      backgroundColor: 'Black',
                      htmlText: '<p > </p>',
                      text: null,
                      fontColor: 'White',
                      rawEditorContent: '{"blocks":[{"key":"vv13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: 'Roboto',
                      maximumFontSize: '5em',
                      fontSize: '0.75em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 0.75em; font-family: Roboto; position: relative'><p > </p></div></div>",
                    },
                    documentId: 'VG48O8RJNv',
                    id: '151c3880-3244-11ee-abfd-5f9de2162030',
                    y: 1,
                    x: 1,
                  },
                ],
                style: 'fixed',
                rows: 2,
                columns: 1,
              },
              meta: '{}',
              id: '13f5e0f1-3244-11ee-abfd-5f9de2162030',
              position: 4,
              documentId: 'VG48O8RJNv',
            },
            {
              id: '16ee1251-3244-11ee-abfd-5f9de2162030',
              meta: '{}',
              documentId: 'VG48O8RJNv',
              position: 5,
              grid: {
                rows: 2,
                columns: 1,
                blocks: [
                  {
                    height: 1,
                    id: '198f7120-3244-11ee-abfd-5f9de2162030',
                    typeId: 'Image',
                    documentId: 'VG48O8RJNv',
                    properties: {
                      positionY: 0,
                      preferredHeight: '10em',
                      text: null,
                      positionX: 0,
                      rotation: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    },
                    y: 1,
                    width: 1,
                    x: 1,
                  },
                  {
                    properties: {
                      htmlText: '<p > </p>',
                      fontColor: 'Black',
                      maximumFontSize: '5em',
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"82as1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '1em',
                      text: null,
                    },
                    typeId: 'Conclusion',
                    height: 1,
                    width: 1,
                    x: 1,
                    id: '18333c80-3244-11ee-abfd-5f9de2162030',
                    documentId: 'VG48O8RJNv',
                    y: 2,
                  },
                ],
                style: 'fixed',
              },
            },
          ],
          schemaVersion: 20230101,
        },
        publishedAt: {
          seconds: 1713382921,
          nanoseconds: 630000000,
        },
        status: 'PUBLISHED',
        documentId: '5Dwmm66wOY',
        notes: [],
        pages: [
          {
            documentId: 'VG48O8RJNv',
            position: 0,
            grid: {
              columns: 1,
              blocks: [
                {
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"cna38","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    text: null,
                    htmlText: '<p > </p>',
                    plainText: null,
                    fontSize: '2em',
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                    fontFamily: 'Alfa',
                  },
                  typeId: 'Title',
                  id: '00b12b81-3244-11ee-abfd-5f9de2162030',
                  height: 2,
                  x: 1,
                  y: 1,
                  width: 1,
                  documentId: 'VG48O8RJNv',
                },
              ],
              rows: 2,
              style: 'fixed',
            },
            id: '00b12b80-3244-11ee-abfd-5f9de2162030',
            meta: '{}',
          },
          {
            documentId: 'VG48O8RJNv',
            meta: '{}',
            position: 1,
            id: '04ccce41-3244-11ee-abfd-5f9de2162030',
            grid: {
              columns: 1,
              rows: 2,
              blocks: [
                {
                  documentId: 'VG48O8RJNv',
                  id: '09961620-3244-11ee-abfd-5f9de2162030',
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"97la4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    text: null,
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    maximumFontSize: '5em',
                  },
                  height: 1,
                  x: 1,
                  y: 1,
                  width: 1,
                  typeId: 'Lead',
                },
                {
                  height: 1,
                  documentId: 'VG48O8RJNv',
                  id: '0a59b440-3244-11ee-abfd-5f9de2162030',
                  width: 1,
                  typeId: 'MainIdea',
                  x: 1,
                  y: 2,
                  properties: {
                    htmlText: '<p > </p>',
                    fontFamily: "'Open Sans'",
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    text: null,
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"5elol","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    fontSize: '1em',
                    fontColor: 'Black',
                  },
                },
              ],
              style: 'fixed',
            },
          },
          {
            grid: {
              rows: 2,
              blocks: [
                {
                  documentId: 'VG48O8RJNv',
                  properties: {
                    plainText: null,
                    text: null,
                    backgroundColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"avi95","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    fontSize: '0.75em',
                    fontFamily: 'Roboto',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 0.75em; font-family: Roboto; position: relative'><p > </p></div></div>",
                    fontColor: 'White',
                    maximumFontSize: '5em',
                  },
                  x: 1,
                  width: 1,
                  height: 2,
                  id: '0c472c60-3244-11ee-abfd-5f9de2162030',
                  typeId: 'ImageCaption',
                  y: 1,
                },
              ],
              style: 'fixed',
              columns: 1,
            },
            documentId: 'VG48O8RJNv',
            position: 2,
            meta: '{}',
            id: '0b356e41-3244-11ee-abfd-5f9de2162030',
          },
          {
            id: '11284201-3244-11ee-abfd-5f9de2162030',
            grid: {
              columns: 1,
              blocks: [
                {
                  id: '126fdd30-3244-11ee-abfd-5f9de2162030',
                  documentId: 'VG48O8RJNv',
                  properties: {
                    fontSize: '1.5em',
                    fontFamily: "'Source Serif 4'",
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1.5em; font-family: 'Source Serif 4'; position: relative'><p > </p></div></div>",
                    text: null,
                    htmlText: '<p > </p>',
                    fontStyle: 'Italic',
                    plainText: null,
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"1vdm7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                  },
                  y: 1,
                  height: 1,
                  x: 1,
                  width: 1,
                  typeId: 'CallOut',
                },
                {
                  documentId: 'VG48O8RJNv',
                  x: 1,
                  properties: {
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"b4mku","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    text: null,
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                  },
                  typeId: 'Details',
                  width: 1,
                  id: '13246020-3244-11ee-abfd-5f9de2162030',
                  y: 2,
                  height: 1,
                },
              ],
              style: 'fixed',
              rows: 2,
            },
            documentId: 'VG48O8RJNv',
            position: 3,
            meta: '{}',
          },
          {
            meta: '{}',
            documentId: 'VG48O8RJNv',
            grid: {
              style: 'fixed',
              columns: 1,
              rows: 2,
              blocks: [
                {
                  x: 1,
                  properties: {
                    plainText: null,
                    fontSize: '0.75em',
                    fontFamily: 'Roboto',
                    backgroundColor: 'Black',
                    fontColor: 'White',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 0.75em; font-family: Roboto; position: relative'><p > </p></div></div>",
                    text: null,
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"vv13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                  },
                  width: 1,
                  typeId: 'ImageCaption',
                  documentId: 'VG48O8RJNv',
                  id: '151c3880-3244-11ee-abfd-5f9de2162030',
                  y: 1,
                  height: 2,
                },
              ],
            },
            position: 4,
            id: '13f5e0f1-3244-11ee-abfd-5f9de2162030',
          },
          {
            position: 5,
            documentId: 'VG48O8RJNv',
            id: '16ee1251-3244-11ee-abfd-5f9de2162030',
            grid: {
              blocks: [
                {
                  documentId: 'VG48O8RJNv',
                  typeId: 'Image',
                  id: '198f7120-3244-11ee-abfd-5f9de2162030',
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    text: null,
                    positionX: 0,
                    positionY: 0,
                    rotation: 0,
                    preferredHeight: '10em',
                  },
                  height: 1,
                  width: 1,
                  y: 1,
                  x: 1,
                },
                {
                  x: 1,
                  documentId: 'VG48O8RJNv',
                  height: 1,
                  y: 2,
                  width: 1,
                  id: '18333c80-3244-11ee-abfd-5f9de2162030',
                  properties: {
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"82as1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    text: null,
                  },
                  typeId: 'Conclusion',
                },
              ],
              rows: 2,
              style: 'fixed',
              columns: 1,
            },
            meta: '{}',
          },
        ],
        id: '8zwVnDmw0N',
      },
      isDemo: false,
      format: DocumentFormat.STORY,
      isTemplate: true,
      meta: {
        importantWords: [],
        hasWordCount: true,
        writingPlan: 'news-article',
        hasSignalWords: true,
        hasImportantWords: true,
        hasWritingBuddy: true,
      },
      thumbnailUrl: null,
      updatedAt: '2024-04-17T19:42:01.692Z',
      submission: null,
      user: {
        name: 'Kieran',
        email: 'kieran+teacher@joinpressto.com',
        role: 'teacher',
        id: '7WwWWeqwqv',
        surname: 'Sobel',
        username: null,
      },
      isPublic: true,
      name: 'Copy of News Article - Story Template',
      id: 'template-story-news-article',
    },
  },
  {
    format: DocumentFormat.BOOKLET,
    name: 'Opinion',
    writingPlanId: 'op-ed',
    documentId: 'template-booklet-op-ed',
    svg: SVGs.ClaimSVG,
    color: '#288247',
    document: {
      name: 'Copy of Opinion - Zine Template',
      meta: {
        hasWordCount: true,
        importantWords: [],
        hasWritingBuddy: true,
        hasSignalWords: true,
        writingPlan: 'op-ed',
        hasImportantWords: true,
      },
      isTemplate: true,
      format: DocumentFormat.BOOKLET,
      version: {
        content: {
          messages: [],
          schemaVersion: 20230101,
          pages: [
            {
              id: '6162e5c1-30b1-11ee-a0cb-3bf51732fff8',
              grid: {
                style: 'fixed',
                blocks: [
                  {
                    id: '6162e5c2-30b1-11ee-a0cb-3bf51732fff8',
                    typeId: 'Title',
                    x: 1,
                    documentId: '8zwV6NOw0N',
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'></div></div>",
                      fontSize: '2em',
                      maximumFontSize: '5em',
                      text: null,
                      fontColor: 'Black',
                      fontFamily: 'Alfa',
                    },
                    width: 1,
                    height: 1,
                    y: 1,
                  },
                  {
                    width: 1,
                    y: 2,
                    documentId: '8zwV6NOw0N',
                    typeId: 'Image',
                    properties: {
                      positionX: 0,
                      text: null,
                      html: null,
                      positionY: 0,
                      preferredHeight: '10em',
                      rotation: 0,
                    },
                    id: '64c99060-30b1-11ee-a0cb-3bf51732fff8',
                    x: 1,
                    height: 1,
                  },
                ],
                rows: 2,
                columns: 1,
              },
              meta: '{}',
              position: 0,
              documentId: '8zwV6NOw0N',
            },
            {
              documentId: '8zwV6NOw0N',
              meta: '{}',
              grid: {
                blocks: [
                  {
                    x: 1,
                    width: 1,
                    y: 1,
                    id: '6a56eb90-30b1-11ee-a0cb-3bf51732fff8',
                    documentId: '8zwV6NOw0N',
                    height: 2,
                    typeId: 'Introduction',
                    properties: {
                      text: null,
                      fontSize: '1em',
                      plainText: null,
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"cmup5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                    },
                  },
                ],
                style: 'fixed',
                rows: 2,
                columns: 1,
              },
              id: '6162e5c3-30b1-11ee-a0cb-3bf51732fff8',
              position: 1,
            },
            {
              meta: '{}',
              documentId: '8zwV6NOw0N',
              position: 2,
              grid: {
                columns: 1,
                blocks: [
                  {
                    y: 1,
                    height: 1,
                    x: 1,
                    documentId: '8zwV6NOw0N',
                    properties: {
                      text: null,
                      plainText: null,
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"bpvk","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    },
                    id: '6c9590f0-30b1-11ee-a0cb-3bf51732fff8',
                    width: 1,
                    typeId: 'ClaimOrOpinion',
                  },
                  {
                    y: 2,
                    properties: {
                      positionY: 0,
                      preferredHeight: '10em',
                      positionX: 0,
                      html: null,
                      text: null,
                      rotation: 0,
                    },
                    typeId: 'Image',
                    documentId: '8zwV6NOw0N',
                    x: 1,
                    id: '6e7b8f00-30b1-11ee-a0cb-3bf51732fff8',
                    width: 1,
                    height: 1,
                  },
                ],
                style: 'fixed',
                rows: 2,
              },
              id: '6162e5c4-30b1-11ee-a0cb-3bf51732fff8',
            },
            {
              grid: {
                blocks: [
                  {
                    width: 1,
                    x: 1,
                    typeId: 'Evidence',
                    documentId: '8zwV6NOw0N',
                    id: '6fec3600-30b1-11ee-a0cb-3bf51732fff8',
                    y: 1,
                    properties: {
                      fontSize: '1em',
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"6ab96","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      plainText: null,
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                    },
                    height: 2,
                  },
                ],
                columns: 1,
                rows: 2,
                style: 'fixed',
              },
              meta: '{}',
              position: 3,
              documentId: '8zwV6NOw0N',
              id: '6162e5c5-30b1-11ee-a0cb-3bf51732fff8',
            },
            {
              documentId: '8zwV6NOw0N',
              position: 4,
              grid: {
                style: 'fixed',
                rows: 2,
                columns: 1,
                blocks: [
                  {
                    documentId: '8zwV6NOw0N',
                    properties: {
                      rawEditorContent: '{"blocks":[{"key":"85pm9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      text: null,
                      plainText: null,
                      fontSize: '1em',
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    },
                    y: 1,
                    typeId: 'Evidence',
                    width: 1,
                    height: 1,
                    id: '7c3644a0-30b1-11ee-a0cb-3bf51732fff8',
                    x: 1,
                  },
                  {
                    y: 2,
                    height: 1,
                    id: '7f1fa8f0-30b1-11ee-a0cb-3bf51732fff8',
                    typeId: 'Image',
                    x: 1,
                    properties: {
                      text: null,
                      positionY: 0,
                      rotation: 0,
                      html: null,
                      preferredHeight: '10em',
                      positionX: 0,
                    },
                    documentId: '8zwV6NOw0N',
                    width: 1,
                  },
                ],
              },
              meta: '{}',
              id: '61630cd2-30b1-11ee-a0cb-3bf51732fff8',
            },
            {
              meta: '{}',
              position: 5,
              grid: {
                blocks: [
                  {
                    height: 2,
                    width: 1,
                    x: 1,
                    documentId: '8zwV6NOw0N',
                    id: '72c65810-30b1-11ee-a0cb-3bf51732fff8',
                    typeId: 'Evidence',
                    properties: {
                      htmlText: '<p > </p>',
                      plainText: null,
                      maximumFontSize: '5em',
                      fontFamily: "'Open Sans'",
                      text: null,
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"3cuno","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '1em',
                    },
                    y: 1,
                  },
                ],
                columns: 1,
                style: 'fixed',
                rows: 2,
              },
              id: '61630cd0-30b1-11ee-a0cb-3bf51732fff8',
              documentId: '8zwV6NOw0N',
            },
            {
              grid: {
                rows: 2,
                style: 'fixed',
                blocks: [
                  {
                    height: 2,
                    properties: {
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                      text: null,
                      plainText: null,
                      maximumFontSize: '5em',
                      fontSize: '1em',
                      fontFamily: "'Open Sans'",
                      rawEditorContent: '{"blocks":[{"key":"8qu20","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    },
                    width: 1,
                    typeId: 'Conclusion',
                    y: 1,
                    documentId: '8zwV6NOw0N',
                    id: '866cdc90-30b1-11ee-a0cb-3bf51732fff8',
                    x: 1,
                  },
                ],
                columns: 1,
              },
              position: 6,
              id: '61630cd1-30b1-11ee-a0cb-3bf51732fff8',
              meta: '{}',
              documentId: '8zwV6NOw0N',
            },
            {
              id: '61630cd3-30b1-11ee-a0cb-3bf51732fff8',
              documentId: '8zwV6NOw0N',
              position: 7,
              grid: {
                style: 'fixed',
                columns: 1,
                rows: 2,
                blocks: [
                  {
                    id: '8d21c7d0-30b1-11ee-a0cb-3bf51732fff8',
                    x: 1,
                    documentId: '8zwV6NOw0N',
                    height: 2,
                    properties: {
                      preferredHeight: '10em',
                      positionX: 0,
                      positionY: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      text: null,
                      rotation: 0,
                    },
                    y: 1,
                    width: 1,
                    typeId: 'Image',
                  },
                ],
              },
              meta: '{}',
            },
          ],
        },
        publishedAt: {
          seconds: 1713382921,
          nanoseconds: 662000000,
        },
        id: '5Dwmm66wOY',
        notes: [],
        status: 'PUBLISHED',
        pages: [
          {
            grid: {
              blocks: [
                {
                  width: 1,
                  y: 1,
                  documentId: '8zwV6NOw0N',
                  id: '6162e5c2-30b1-11ee-a0cb-3bf51732fff8',
                  typeId: 'Title',
                  x: 1,
                  height: 1,
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'></div></div>",
                    fontColor: 'Black',
                    maximumFontSize: '5em',
                    fontFamily: 'Alfa',
                    fontSize: '2em',
                    text: null,
                  },
                },
                {
                  y: 2,
                  height: 1,
                  documentId: '8zwV6NOw0N',
                  width: 1,
                  typeId: 'Image',
                  properties: {
                    preferredHeight: '10em',
                    positionY: 0,
                    rotation: 0,
                    positionX: 0,
                    html: null,
                    text: null,
                  },
                  x: 1,
                  id: '64c99060-30b1-11ee-a0cb-3bf51732fff8',
                },
              ],
              rows: 2,
              style: 'fixed',
              columns: 1,
            },
            id: '6162e5c1-30b1-11ee-a0cb-3bf51732fff8',
            meta: '{}',
            position: 0,
            documentId: '8zwV6NOw0N',
          },
          {
            documentId: '8zwV6NOw0N',
            position: 1,
            grid: {
              columns: 1,
              blocks: [
                {
                  width: 1,
                  y: 1,
                  id: '6a56eb90-30b1-11ee-a0cb-3bf51732fff8',
                  typeId: 'Introduction',
                  documentId: '8zwV6NOw0N',
                  height: 2,
                  properties: {
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"cmup5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    text: null,
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                  x: 1,
                },
              ],
              style: 'fixed',
              rows: 2,
            },
            id: '6162e5c3-30b1-11ee-a0cb-3bf51732fff8',
            meta: '{}',
          },
          {
            documentId: '8zwV6NOw0N',
            grid: {
              rows: 2,
              blocks: [
                {
                  typeId: 'ClaimOrOpinion',
                  documentId: '8zwV6NOw0N',
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    maximumFontSize: '5em',
                    rawEditorContent: '{"blocks":[{"key":"bpvk","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    plainText: null,
                    text: null,
                    fontSize: '1em',
                  },
                  height: 1,
                  x: 1,
                  y: 1,
                  width: 1,
                  id: '6c9590f0-30b1-11ee-a0cb-3bf51732fff8',
                },
                {
                  x: 1,
                  y: 2,
                  width: 1,
                  height: 1,
                  id: '6e7b8f00-30b1-11ee-a0cb-3bf51732fff8',
                  documentId: '8zwV6NOw0N',
                  typeId: 'Image',
                  properties: {
                    rotation: 0,
                    positionY: 0,
                    positionX: 0,
                    text: null,
                    preferredHeight: '10em',
                    html: null,
                  },
                },
              ],
              columns: 1,
              style: 'fixed',
            },
            meta: '{}',
            id: '6162e5c4-30b1-11ee-a0cb-3bf51732fff8',
            position: 2,
          },
          {
            position: 3,
            id: '6162e5c5-30b1-11ee-a0cb-3bf51732fff8',
            meta: '{}',
            grid: {
              style: 'fixed',
              blocks: [
                {
                  width: 1,
                  properties: {
                    fontSize: '1em',
                    text: null,
                    maximumFontSize: '5em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"6ab96","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    plainText: null,
                  },
                  y: 1,
                  height: 2,
                  id: '6fec3600-30b1-11ee-a0cb-3bf51732fff8',
                  x: 1,
                  documentId: '8zwV6NOw0N',
                  typeId: 'Evidence',
                },
              ],
              rows: 2,
              columns: 1,
            },
            documentId: '8zwV6NOw0N',
          },
          {
            id: '61630cd2-30b1-11ee-a0cb-3bf51732fff8',
            position: 4,
            grid: {
              columns: 1,
              style: 'fixed',
              blocks: [
                {
                  typeId: 'Evidence',
                  documentId: '8zwV6NOw0N',
                  height: 1,
                  properties: {
                    plainText: null,
                    text: null,
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"85pm9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                    maximumFontSize: '5em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    htmlText: '<p > </p>',
                  },
                  id: '7c3644a0-30b1-11ee-a0cb-3bf51732fff8',
                  width: 1,
                  x: 1,
                  y: 1,
                },
                {
                  x: 1,
                  documentId: '8zwV6NOw0N',
                  id: '7f1fa8f0-30b1-11ee-a0cb-3bf51732fff8',
                  properties: {
                    positionX: 0,
                    rotation: 0,
                    preferredHeight: '10em',
                    positionY: 0,
                    html: null,
                    text: null,
                  },
                  height: 1,
                  width: 1,
                  y: 2,
                  typeId: 'Image',
                },
              ],
              rows: 2,
            },
            documentId: '8zwV6NOw0N',
            meta: '{}',
          },
          {
            grid: {
              columns: 1,
              blocks: [
                {
                  y: 1,
                  height: 2,
                  x: 1,
                  typeId: 'Evidence',
                  documentId: '8zwV6NOw0N',
                  width: 1,
                  id: '72c65810-30b1-11ee-a0cb-3bf51732fff8',
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"3cuno","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    text: null,
                    fontFamily: "'Open Sans'",
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                  },
                },
              ],
              style: 'fixed',
              rows: 2,
            },
            meta: '{}',
            documentId: '8zwV6NOw0N',
            position: 5,
            id: '61630cd0-30b1-11ee-a0cb-3bf51732fff8',
          },
          {
            meta: '{}',
            position: 6,
            id: '61630cd1-30b1-11ee-a0cb-3bf51732fff8',
            grid: {
              columns: 1,
              style: 'fixed',
              rows: 2,
              blocks: [
                {
                  y: 1,
                  height: 2,
                  documentId: '8zwV6NOw0N',
                  typeId: 'Conclusion',
                  properties: {
                    plainText: null,
                    fontColor: 'Black',
                    fontSize: '1em',
                    text: null,
                    htmlText: '<p > </p>',
                    fontFamily: "'Open Sans'",
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    maximumFontSize: '5em',
                    rawEditorContent: '{"blocks":[{"key":"8qu20","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  id: '866cdc90-30b1-11ee-a0cb-3bf51732fff8',
                  x: 1,
                  width: 1,
                },
              ],
            },
            documentId: '8zwV6NOw0N',
          },
          {
            id: '61630cd3-30b1-11ee-a0cb-3bf51732fff8',
            meta: '{}',
            documentId: '8zwV6NOw0N',
            position: 7,
            grid: {
              blocks: [
                {
                  x: 1,
                  y: 1,
                  documentId: '8zwV6NOw0N',
                  id: '8d21c7d0-30b1-11ee-a0cb-3bf51732fff8',
                  typeId: 'Image',
                  width: 1,
                  height: 2,
                  properties: {
                    preferredHeight: '10em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    positionX: 0,
                    positionY: 0,
                    text: null,
                    rotation: 0,
                  },
                },
              ],
              rows: 2,
              columns: 1,
              style: 'fixed',
            },
          },
        ],
        createdAt: {
          seconds: 1713382921,
          nanoseconds: 0,
        },
        documentId: '8zwVnDmw0N',
      },
      templateSystem: 'blocks',
      isDemo: false,
      isPublic: true,
      user: {
        surname: 'Sobel',
        id: '7WwWWeqwqv',
        role: 'teacher',
        email: 'kieran+teacher@joinpressto.com',
        name: 'Kieran',
        username: null,
      },
      submission: null,
      thumbnailUrl: null,
      updatedAt: '2024-04-17T19:42:01.777Z',
      createdAt: '2024-04-17T19:42:01.000Z',
      id: 'template-booklet-op-ed',
    },
  },
  {
    format: DocumentFormat.BOOKLET,
    name: 'Sequence',
    writingPlanId: 'sequence',
    documentId: 'template-booklet-sequence',
    svg: SVGs.EventSVG,
    color: '#F5D62F',
    document: {
      createdAt: '2024-04-17T19:42:01.000Z',
      id: 'template-booklet-sequence',
      thumbnailUrl: null,
      isTemplate: true,
      isPublic: true,
      isDemo: false,
      format: DocumentFormat.BOOKLET,
      name: 'Copy of Sequence - Zine Template',
      meta: {
        importantWords: [],
        writingPlan: 'sequence',
        hasWordCount: true,
        hasSignalWords: true,
        hasImportantWords: true,
        hasWritingBuddy: true,
      },
      version: {
        content: {
          schemaVersion: 20230101,
          messages: [],
          pages: [
            {
              position: 0,
              meta: '{}',
              id: 'f5030a71-3161-11ee-85a3-71a99429ba2d',
              grid: {
                rows: 2,
                blocks: [
                  {
                    height: 1,
                    documentId: 'gXJbERgwWD',
                    y: 1,
                    properties: {
                      rawEditorContent: '{"blocks":[{"key":"2fs1l","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                      plainText: null,
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      fontColor: 'Black',
                      fontFamily: 'Alfa',
                      fontSize: '2em',
                    },
                    typeId: 'Title',
                    width: 1,
                    id: 'f5030a72-3161-11ee-85a3-71a99429ba2d',
                    x: 1,
                  },
                  {
                    id: '02a6dcb0-3162-11ee-85a3-71a99429ba2d',
                    properties: {
                      text: null,
                      preferredHeight: '10em',
                      positionX: 0,
                      html: null,
                      rotation: 0,
                      positionY: 0,
                    },
                    documentId: 'gXJbERgwWD',
                    x: 1,
                    width: 1,
                    typeId: 'Image',
                    y: 2,
                    height: 1,
                  },
                ],
                columns: 1,
                style: 'fixed',
              },
              documentId: 'gXJbERgwWD',
            },
            {
              grid: {
                rows: 2,
                blocks: [
                  {
                    height: 1,
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      plainText: null,
                      htmlText: '<p > </p>',
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"7hurl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    },
                    width: 1,
                    id: '03fbbe50-3162-11ee-85a3-71a99429ba2d',
                    y: 1,
                    documentId: 'gXJbERgwWD',
                    x: 1,
                    typeId: 'Introduction',
                  },
                  {
                    height: 1,
                    x: 1,
                    y: 2,
                    documentId: 'gXJbERgwWD',
                    properties: {
                      maximumFontSize: '5em',
                      fontSize: '1em',
                      plainText: null,
                      rawEditorContent: '{"blocks":[{"key":"d57p5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                      text: null,
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    },
                    width: 1,
                    typeId: 'Event',
                    id: '418f7220-3162-11ee-85a3-71a99429ba2d',
                  },
                ],
                columns: 1,
                style: 'fixed',
              },
              position: 1,
              documentId: 'gXJbERgwWD',
              meta: '{}',
              id: 'f5030a73-3161-11ee-85a3-71a99429ba2d',
            },
            {
              id: 'f5030a74-3161-11ee-85a3-71a99429ba2d',
              grid: {
                style: 'fixed',
                rows: 2,
                blocks: [
                  {
                    id: '0998a9e0-3162-11ee-85a3-71a99429ba2d',
                    typeId: 'Image',
                    properties: {
                      rotation: 0,
                      positionY: 0,
                      preferredHeight: '10em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      positionX: 0,
                      text: null,
                    },
                    x: 1,
                    documentId: 'gXJbERgwWD',
                    width: 1,
                    y: 1,
                    height: 1,
                  },
                  {
                    properties: {
                      fontSize: '1em',
                      plainText: null,
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"4fqoe","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                    },
                    id: '064252f0-3162-11ee-85a3-71a99429ba2d',
                    typeId: 'Event',
                    width: 1,
                    x: 1,
                    height: 1,
                    documentId: 'gXJbERgwWD',
                    y: 2,
                  },
                ],
                columns: 1,
              },
              position: 2,
              documentId: 'gXJbERgwWD',
              meta: '{}',
            },
            {
              id: 'f5030a75-3161-11ee-85a3-71a99429ba2d',
              position: 3,
              documentId: 'gXJbERgwWD',
              grid: {
                blocks: [
                  {
                    y: 1,
                    width: 1,
                    typeId: 'Event',
                    id: '077358e0-3162-11ee-85a3-71a99429ba2d',
                    documentId: 'gXJbERgwWD',
                    height: 1,
                    x: 1,
                    properties: {
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      text: null,
                      plainText: null,
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"1um4c","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    },
                  },
                  {
                    width: 1,
                    properties: {
                      text: null,
                      html: null,
                      preferredHeight: '10em',
                      positionX: 0,
                      rotation: 0,
                      positionY: 0,
                    },
                    height: 1,
                    id: '0b588250-3162-11ee-85a3-71a99429ba2d',
                    typeId: 'Image',
                    x: 1,
                    y: 2,
                    documentId: 'gXJbERgwWD',
                  },
                ],
                rows: 2,
                columns: 1,
                style: 'fixed',
              },
              meta: '{}',
            },
            {
              meta: '{}',
              position: 4,
              id: 'f5030a76-3161-11ee-85a3-71a99429ba2d',
              documentId: 'gXJbERgwWD',
              grid: {
                columns: 1,
                rows: 2,
                blocks: [
                  {
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      positionY: 0,
                      preferredHeight: '10em',
                      positionX: 0,
                      text: null,
                      rotation: 0,
                    },
                    id: '0e1112a0-3162-11ee-85a3-71a99429ba2d',
                    documentId: 'gXJbERgwWD',
                    typeId: 'Image',
                    x: 1,
                    height: 1,
                    y: 1,
                    width: 1,
                  },
                  {
                    id: '0d3139f0-3162-11ee-85a3-71a99429ba2d',
                    typeId: 'Event',
                    width: 1,
                    height: 1,
                    properties: {
                      fontSize: '1em',
                      text: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"44ecg","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      plainText: null,
                      fontFamily: "'Open Sans'",
                    },
                    documentId: 'gXJbERgwWD',
                    x: 1,
                    y: 2,
                  },
                ],
                style: 'fixed',
              },
            },
            {
              grid: {
                rows: 2,
                style: 'fixed',
                blocks: [
                  {
                    id: '1038fbb0-3162-11ee-85a3-71a99429ba2d',
                    x: 1,
                    y: 1,
                    height: 1,
                    typeId: 'Event',
                    width: 1,
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      htmlText: '<p > </p>',
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      rawEditorContent: '{"blocks":[{"key":"fckkb","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '1em',
                      maximumFontSize: '5em',
                    },
                    documentId: 'gXJbERgwWD',
                  },
                  {
                    y: 2,
                    x: 1,
                    documentId: 'gXJbERgwWD',
                    height: 1,
                    id: '11813320-3162-11ee-85a3-71a99429ba2d',
                    width: 1,
                    properties: {
                      text: null,
                      html: null,
                      rotation: 0,
                      positionX: 0,
                      positionY: 0,
                      preferredHeight: '10em',
                    },
                    typeId: 'Image',
                  },
                ],
                columns: 1,
              },
              documentId: 'gXJbERgwWD',
              id: 'f5030a77-3161-11ee-85a3-71a99429ba2d',
              meta: '{}',
              position: 5,
            },
            {
              meta: '{}',
              documentId: 'gXJbERgwWD',
              grid: {
                style: 'fixed',
                blocks: [
                  {
                    properties: {
                      positionY: 0,
                      preferredHeight: '10em',
                      text: null,
                      rotation: 0,
                      positionX: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    },
                    height: 1,
                    id: '37aceda0-3162-11ee-85a3-71a99429ba2d',
                    documentId: 'gXJbERgwWD',
                    width: 1,
                    typeId: 'Image',
                    x: 1,
                    y: 1,
                  },
                  {
                    y: 2,
                    typeId: 'Event',
                    x: 1,
                    documentId: 'gXJbERgwWD',
                    height: 1,
                    id: '3610c9d0-3162-11ee-85a3-71a99429ba2d',
                    width: 1,
                    properties: {
                      fontSize: '1em',
                      maximumFontSize: '5em',
                      plainText: null,
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      rawEditorContent: '{"blocks":[{"key":"f48to","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                    },
                  },
                ],
                columns: 1,
                rows: 2,
              },
              position: 6,
              id: 'f5030a78-3161-11ee-85a3-71a99429ba2d',
            },
            {
              grid: {
                blocks: [
                  {
                    typeId: 'Conclusion',
                    height: 2,
                    id: '395d35b0-3162-11ee-85a3-71a99429ba2d',
                    properties: {
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      fontSize: '1em',
                      fontColor: 'Black',
                      maximumFontSize: '5em',
                      rawEditorContent: '{"blocks":[{"key":"2vaf0","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      text: null,
                    },
                    x: 1,
                    documentId: 'gXJbERgwWD',
                    width: 1,
                    y: 1,
                  },
                ],
                style: 'fixed',
                columns: 1,
                rows: 2,
              },
              meta: '{}',
              id: 'f5030a79-3161-11ee-85a3-71a99429ba2d',
              documentId: 'gXJbERgwWD',
              position: 7,
            },
          ],
        },
        status: 'PUBLISHED',
        documentId: 'oQj3QpYJYB',
        publishedAt: {
          seconds: 1713382921,
          nanoseconds: 715000000,
        },
        notes: [],
        id: 'oQj3QpYJYB',
        createdAt: {
          seconds: 1713382921,
          nanoseconds: 0,
        },
        pages: [
          {
            grid: {
              blocks: [
                {
                  id: 'f5030a72-3161-11ee-85a3-71a99429ba2d',
                  documentId: 'gXJbERgwWD',
                  width: 1,
                  x: 1,
                  y: 1,
                  typeId: 'Title',
                  height: 1,
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"2fs1l","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                    maximumFontSize: '5em',
                    fontFamily: 'Alfa',
                    text: null,
                    fontSize: '2em',
                    fontColor: 'Black',
                  },
                },
                {
                  x: 1,
                  typeId: 'Image',
                  height: 1,
                  documentId: 'gXJbERgwWD',
                  width: 1,
                  properties: {
                    html: null,
                    preferredHeight: '10em',
                    positionY: 0,
                    text: null,
                    rotation: 0,
                    positionX: 0,
                  },
                  id: '02a6dcb0-3162-11ee-85a3-71a99429ba2d',
                  y: 2,
                },
              ],
              style: 'fixed',
              columns: 1,
              rows: 2,
            },
            position: 0,
            meta: '{}',
            id: 'f5030a71-3161-11ee-85a3-71a99429ba2d',
            documentId: 'gXJbERgwWD',
          },
          {
            grid: {
              columns: 1,
              blocks: [
                {
                  width: 1,
                  height: 1,
                  x: 1,
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"7hurl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    plainText: null,
                    text: null,
                    fontSize: '1em',
                    maximumFontSize: '5em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    htmlText: '<p > </p>',
                    fontColor: 'Black',
                  },
                  documentId: 'gXJbERgwWD',
                  y: 1,
                  typeId: 'Introduction',
                  id: '03fbbe50-3162-11ee-85a3-71a99429ba2d',
                },
                {
                  x: 1,
                  height: 1,
                  width: 1,
                  documentId: 'gXJbERgwWD',
                  id: '418f7220-3162-11ee-85a3-71a99429ba2d',
                  typeId: 'Event',
                  properties: {
                    plainText: null,
                    maximumFontSize: '5em',
                    rawEditorContent: '{"blocks":[{"key":"d57p5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    fontFamily: "'Open Sans'",
                    text: null,
                    fontColor: 'Black',
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                  y: 2,
                },
              ],
              rows: 2,
              style: 'fixed',
            },
            documentId: 'gXJbERgwWD',
            position: 1,
            meta: '{}',
            id: 'f5030a73-3161-11ee-85a3-71a99429ba2d',
          },
          {
            meta: '{}',
            position: 2,
            documentId: 'gXJbERgwWD',
            grid: {
              style: 'fixed',
              blocks: [
                {
                  id: '0998a9e0-3162-11ee-85a3-71a99429ba2d',
                  x: 1,
                  documentId: 'gXJbERgwWD',
                  typeId: 'Image',
                  properties: {
                    positionX: 0,
                    text: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    rotation: 0,
                    preferredHeight: '10em',
                    positionY: 0,
                  },
                  width: 1,
                  y: 1,
                  height: 1,
                },
                {
                  id: '064252f0-3162-11ee-85a3-71a99429ba2d',
                  height: 1,
                  properties: {
                    fontFamily: "'Open Sans'",
                    text: null,
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"4fqoe","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    maximumFontSize: '5em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                  },
                  typeId: 'Event',
                  y: 2,
                  width: 1,
                  documentId: 'gXJbERgwWD',
                  x: 1,
                },
              ],
              columns: 1,
              rows: 2,
            },
            id: 'f5030a74-3161-11ee-85a3-71a99429ba2d',
          },
          {
            position: 3,
            meta: '{}',
            grid: {
              rows: 2,
              columns: 1,
              blocks: [
                {
                  width: 1,
                  x: 1,
                  id: '077358e0-3162-11ee-85a3-71a99429ba2d',
                  height: 1,
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"1um4c","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    text: null,
                    fontColor: 'Black',
                    fontSize: '1em',
                    maximumFontSize: '5em',
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                  y: 1,
                  typeId: 'Event',
                  documentId: 'gXJbERgwWD',
                },
                {
                  width: 1,
                  documentId: 'gXJbERgwWD',
                  id: '0b588250-3162-11ee-85a3-71a99429ba2d',
                  x: 1,
                  typeId: 'Image',
                  y: 2,
                  properties: {
                    positionY: 0,
                    rotation: 0,
                    html: null,
                    text: null,
                    preferredHeight: '10em',
                    positionX: 0,
                  },
                  height: 1,
                },
              ],
              style: 'fixed',
            },
            documentId: 'gXJbERgwWD',
            id: 'f5030a75-3161-11ee-85a3-71a99429ba2d',
          },
          {
            position: 4,
            id: 'f5030a76-3161-11ee-85a3-71a99429ba2d',
            meta: '{}',
            documentId: 'gXJbERgwWD',
            grid: {
              style: 'fixed',
              blocks: [
                {
                  properties: {
                    positionY: 0,
                    text: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    positionX: 0,
                    rotation: 0,
                    preferredHeight: '10em',
                  },
                  height: 1,
                  width: 1,
                  typeId: 'Image',
                  x: 1,
                  id: '0e1112a0-3162-11ee-85a3-71a99429ba2d',
                  documentId: 'gXJbERgwWD',
                  y: 1,
                },
                {
                  typeId: 'Event',
                  x: 1,
                  id: '0d3139f0-3162-11ee-85a3-71a99429ba2d',
                  width: 1,
                  properties: {
                    fontSize: '1em',
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    maximumFontSize: '5em',
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"44ecg","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                  },
                  y: 2,
                  height: 1,
                  documentId: 'gXJbERgwWD',
                },
              ],
              columns: 1,
              rows: 2,
            },
          },
          {
            position: 5,
            grid: {
              rows: 2,
              columns: 1,
              blocks: [
                {
                  id: '1038fbb0-3162-11ee-85a3-71a99429ba2d',
                  typeId: 'Event',
                  height: 1,
                  x: 1,
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"fckkb","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    fontSize: '1em',
                    text: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    maximumFontSize: '5em',
                    plainText: null,
                    htmlText: '<p > </p>',
                  },
                  width: 1,
                  y: 1,
                  documentId: 'gXJbERgwWD',
                },
                {
                  id: '11813320-3162-11ee-85a3-71a99429ba2d',
                  documentId: 'gXJbERgwWD',
                  height: 1,
                  properties: {
                    positionY: 0,
                    html: null,
                    rotation: 0,
                    text: null,
                    positionX: 0,
                    preferredHeight: '10em',
                  },
                  x: 1,
                  width: 1,
                  typeId: 'Image',
                  y: 2,
                },
              ],
              style: 'fixed',
            },
            meta: '{}',
            documentId: 'gXJbERgwWD',
            id: 'f5030a77-3161-11ee-85a3-71a99429ba2d',
          },
          {
            documentId: 'gXJbERgwWD',
            position: 6,
            grid: {
              style: 'fixed',
              columns: 1,
              blocks: [
                {
                  y: 1,
                  typeId: 'Image',
                  width: 1,
                  height: 1,
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    rotation: 0,
                    positionX: 0,
                    positionY: 0,
                    preferredHeight: '10em',
                    text: null,
                  },
                  id: '37aceda0-3162-11ee-85a3-71a99429ba2d',
                  documentId: 'gXJbERgwWD',
                  x: 1,
                },
                {
                  width: 1,
                  id: '3610c9d0-3162-11ee-85a3-71a99429ba2d',
                  y: 2,
                  properties: {
                    htmlText: '<p > </p>',
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"f48to","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    maximumFontSize: '5em',
                    fontSize: '1em',
                    text: null,
                  },
                  x: 1,
                  typeId: 'Event',
                  documentId: 'gXJbERgwWD',
                  height: 1,
                },
              ],
              rows: 2,
            },
            id: 'f5030a78-3161-11ee-85a3-71a99429ba2d',
            meta: '{}',
          },
          {
            position: 7,
            documentId: 'gXJbERgwWD',
            meta: '{}',
            grid: {
              style: 'fixed',
              blocks: [
                {
                  properties: {
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"2vaf0","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    fontFamily: "'Open Sans'",
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    fontSize: '1em',
                  },
                  id: '395d35b0-3162-11ee-85a3-71a99429ba2d',
                  documentId: 'gXJbERgwWD',
                  width: 1,
                  x: 1,
                  height: 2,
                  typeId: 'Conclusion',
                  y: 1,
                },
              ],
              rows: 2,
              columns: 1,
            },
            id: 'f5030a79-3161-11ee-85a3-71a99429ba2d',
          },
        ],
      },
      user: {
        surname: 'Sobel',
        role: 'teacher',
        name: 'Kieran',
        username: null,
        email: 'kieran+teacher@joinpressto.com',
        id: '7WwWWeqwqv',
      },
      templateSystem: 'blocks',
      submission: null,
      updatedAt: '2024-04-17T19:42:01.865Z',
    },
  },
  {
    format: DocumentFormat.DOC,
    name: 'Description',
    writingPlanId: 'description',
    documentId: 'template-doc-description',
    svg: SVGs.MainIdeaSVG,
    color: '#9EC264',
    document: {
      version: {
        notes: [],
        id: 'Vv416ZBj0A',
        createdAt: {
          seconds: 1713382921,
          nanoseconds: 0,
        },
        pages: [
          {
            documentId: 'mRjDkg240r',
            position: 0,
            meta: '{}',
            id: '492cb940-30ac-11ee-a90b-6d4c9c084595',
            grid: {
              style: 'fluid',
              blocks: [
                {
                  typeId: 'Title',
                  properties: {
                    html: null,
                    text: null,
                    fontSize: '2em',
                    fontColor: 'Black',
                    fontFamily: 'Alfa',
                  },
                  x: 1,
                  height: 1,
                  documentId: 'mRjDkg240r',
                  id: '492cb941-30ac-11ee-a90b-6d4c9c084595',
                  y: 1,
                  width: 1,
                },
                {
                  height: 1,
                  x: 1,
                  typeId: 'Image',
                  documentId: 'mRjDkg240r',
                  id: '5b002b70-30ac-11ee-a90b-6d4c9c084595',
                  width: 1,
                  properties: {
                    rotation: 0,
                    positionX: 0,
                    text: null,
                    preferredHeight: '10em',
                    html: null,
                    positionY: 0,
                  },
                  y: 2,
                },
                {
                  typeId: 'MainIdea',
                  documentId: 'mRjDkg240r',
                  x: 1,
                  id: '492cb943-30ac-11ee-a90b-6d4c9c084595',
                  height: 1,
                  y: 3,
                  width: 1,
                  properties: {
                    plainText: null,
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                    text: null,
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"27btk","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                  },
                },
                {
                  width: 1,
                  documentId: 'mRjDkg240r',
                  id: '492cb944-30ac-11ee-a90b-6d4c9c084595',
                  typeId: 'SupportingDetails',
                  x: 1,
                  properties: {
                    fontColor: 'Black',
                    fontSize: '1em',
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"ckc8s","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    text: null,
                    htmlText: '<p > </p>',
                    plainText: null,
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                  },
                  y: 4,
                  height: 1,
                },
                {
                  y: 5,
                  id: '492cb947-30ac-11ee-a90b-6d4c9c084595',
                  documentId: 'mRjDkg240r',
                  x: 1,
                  height: 1,
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"es923","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    text: null,
                    plainText: null,
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                  },
                  width: 1,
                  typeId: 'Conclusion',
                },
              ],
              columns: 1,
              rows: 5,
            },
          },
        ],
        publishedAt: {
          seconds: 1713382922,
          nanoseconds: 66000000,
        },
        documentId: 'Vv416ZBj0A',
        content: {
          messages: [],
          pages: [
            {
              id: '492cb940-30ac-11ee-a90b-6d4c9c084595',
              meta: '{}',
              documentId: 'mRjDkg240r',
              position: 0,
              grid: {
                columns: 1,
                rows: 6,
                style: 'fluid',
                blocks: [
                  {
                    typeId: 'Title',
                    width: 1,
                    x: 1,
                    height: 1,
                    id: '492cb941-30ac-11ee-a90b-6d4c9c084595',
                    documentId: 'mRjDkg240r',
                    y: 1,
                    properties: {
                      fontSize: '2em',
                      fontColor: 'Black',
                      text: null,
                      html: null,
                      fontFamily: 'Alfa',
                    },
                  },
                  {
                    typeId: 'Image',
                    properties: {
                      rotation: 0,
                      preferredHeight: '10em',
                      html: null,
                      positionX: 0,
                      text: null,
                      positionY: 0,
                    },
                    y: 2,
                    x: 1,
                    id: '5b002b70-30ac-11ee-a90b-6d4c9c084595',
                    height: 1,
                    documentId: 'mRjDkg240r',
                    width: 1,
                  },
                  {
                    typeId: 'Introduction',
                    width: 1,
                    x: 1,
                    y: 3,
                    id: '492cb942-30ac-11ee-a90b-6d4c9c084595',
                    properties: {
                      fontColor: 'Black',
                      plainText: null,
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"2eeb7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    },
                    height: 1,
                    documentId: 'mRjDkg240r',
                  },
                  {
                    height: 1,
                    y: 4,
                    id: '492cb943-30ac-11ee-a90b-6d4c9c084595',
                    x: 1,
                    width: 1,
                    documentId: 'mRjDkg240r',
                    typeId: 'MainIdea',
                    properties: {
                      rawEditorContent: '{"blocks":[{"key":"27btk","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                      plainText: null,
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                      text: null,
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                    },
                  },
                  {
                    width: 1,
                    x: 1,
                    height: 1,
                    y: 5,
                    id: '492cb944-30ac-11ee-a90b-6d4c9c084595',
                    typeId: 'SupportingDetails',
                    documentId: 'mRjDkg240r',
                    properties: {
                      htmlText: '<p > </p>',
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                      fontColor: 'Black',
                      plainText: null,
                      fontSize: '1em',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"ckc8s","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    },
                  },
                  {
                    height: 1,
                    x: 1,
                    documentId: 'mRjDkg240r',
                    y: 6,
                    width: 1,
                    id: '492cb947-30ac-11ee-a90b-6d4c9c084595',
                    typeId: 'Conclusion',
                    properties: {
                      rawEditorContent: '{"blocks":[{"key":"es923","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                      text: null,
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                      fontColor: 'Black',
                      fontSize: '1em',
                      plainText: null,
                    },
                  },
                ],
              },
            },
          ],
          schemaVersion: 20230101,
        },
        status: 'PUBLISHED',
      },
      isDemo: false,
      user: {
        name: 'Kieran',
        email: 'kieran+teacher@joinpressto.com',
        username: null,
        surname: 'Sobel',
        id: '7WwWWeqwqv',
        role: 'teacher',
      },
      id: 'template-doc-description',
      thumbnailUrl: null,
      name: 'Copy of Description - Docs Template',
      templateSystem: 'blocks',
      submission: null,
      isTemplate: true,
      isPublic: true,
      meta: {
        writingPlan: 'description',
        hasWordCount: true,
        hasSignalWords: true,
        hasImportantWords: true,
        importantWords: [],
        hasWritingBuddy: true,
      },
      format: DocumentFormat.DOC,
      createdAt: '2024-04-17T19:42:01.000Z',
      updatedAt: '2024-04-17T19:42:02.122Z',
    },
  },
  {
    format: DocumentFormat.DOC,
    name: 'News Article',
    writingPlanId: 'news-article',
    documentId: 'template-doc-news-article',
    svg: SVGs.QuoteSVG,
    color: '#EEA1A2',
    document: {
      id: 'template-doc-news-article',
      updatedAt: '2024-04-17T19:42:02.271Z',
      version: {
        createdAt: {
          seconds: 1713382922,
          nanoseconds: 0,
        },
        publishedAt: {
          seconds: 1713382922,
          nanoseconds: 215000000,
        },
        notes: [],
        status: 'PUBLISHED',
        id: 'Y7jQgZ6JD1',
        documentId: 'Y7jQgZ6JD1',
        pages: [
          {
            position: 0,
            meta: '{}',
            id: 'ed2c5dc0-30ac-11ee-a90b-6d4c9c084595',
            grid: {
              rows: 7,
              style: 'fluid',
              columns: 1,
              blocks: [
                {
                  typeId: 'Title',
                  x: 1,
                  documentId: 'gNjo6R34YD',
                  height: 1,
                  y: 1,
                  id: 'ed2c5dc1-30ac-11ee-a90b-6d4c9c084595',
                  properties: {
                    html: null,
                    text: null,
                    fontSize: '2em',
                    fontColor: 'Black',
                    fontFamily: 'Alfa',
                  },
                  width: 1,
                },
                {
                  height: 1,
                  id: '1aea6b30-30ad-11ee-a90b-6d4c9c084595',
                  width: 1,
                  documentId: 'gNjo6R34YD',
                  typeId: 'Image',
                  properties: {
                    html: null,
                    preferredHeight: '10em',
                    text: null,
                    positionY: 0,
                    positionX: 0,
                    rotation: 0,
                  },
                  y: 2,
                  x: 1,
                },
                {
                  x: 1,
                  documentId: 'gNjo6R34YD',
                  width: 1,
                  y: 3,
                  id: '08736600-30ad-11ee-a90b-6d4c9c084595',
                  properties: {
                    plainText: null,
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"2b0fs","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                    fontColor: 'Black',
                    text: null,
                  },
                  height: 1,
                  typeId: 'Lead',
                },
                {
                  properties: {
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"3b2kn","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    plainText: null,
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontSize: '1em',
                    text: null,
                  },
                  typeId: 'MainIdea',
                  y: 4,
                  x: 1,
                  id: '0a6ee7e0-30ad-11ee-a90b-6d4c9c084595',
                  documentId: 'gNjo6R34YD',
                  height: 1,
                  width: 1,
                },
                {
                  documentId: 'gNjo6R34YD',
                  typeId: 'Details',
                  properties: {
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"2mim9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontSize: '1em',
                    text: null,
                    htmlText: '<p > </p>',
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    plainText: null,
                  },
                  id: '0b8bc990-30ad-11ee-a90b-6d4c9c084595',
                  height: 1,
                  y: 5,
                  x: 1,
                  width: 1,
                },
                {
                  width: 1,
                  typeId: 'Image',
                  documentId: 'gNjo6R34YD',
                  x: 1,
                  id: '68a0ace0-30ad-11ee-a90b-6d4c9c084595',
                  y: 6,
                  height: 1,
                  properties: {
                    html: null,
                    preferredHeight: '10em',
                    positionX: 0,
                    positionY: 0,
                    text: null,
                    rotation: 0,
                  },
                },
                {
                  documentId: 'gNjo6R34YD',
                  typeId: 'Conclusion',
                  y: 7,
                  height: 1,
                  properties: {
                    fontFamily: "'Open Sans'",
                    plainText: null,
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"5bvug","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontSize: '1em',
                    fontColor: 'Black',
                  },
                  id: '0ccef7f0-30ad-11ee-a90b-6d4c9c084595',
                  x: 1,
                  width: 1,
                },
              ],
            },
            documentId: 'gNjo6R34YD',
          },
        ],
        content: {
          pages: [
            {
              meta: '{}',
              id: 'ed2c5dc0-30ac-11ee-a90b-6d4c9c084595',
              grid: {
                rows: 7,
                blocks: [
                  {
                    typeId: 'Title',
                    properties: {
                      html: null,
                      fontFamily: 'Alfa',
                      text: null,
                      fontColor: 'Black',
                      fontSize: '2em',
                    },
                    documentId: 'gNjo6R34YD',
                    x: 1,
                    y: 1,
                    height: 1,
                    id: 'ed2c5dc1-30ac-11ee-a90b-6d4c9c084595',
                    width: 1,
                  },
                  {
                    x: 1,
                    documentId: 'gNjo6R34YD',
                    properties: {
                      positionY: 0,
                      text: null,
                      positionX: 0,
                      rotation: 0,
                      preferredHeight: '10em',
                      html: null,
                    },
                    y: 2,
                    height: 1,
                    typeId: 'Image',
                    width: 1,
                    id: '1aea6b30-30ad-11ee-a90b-6d4c9c084595',
                  },
                  {
                    properties: {
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"2b0fs","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      plainText: null,
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                    },
                    typeId: 'Lead',
                    x: 1,
                    height: 1,
                    id: '08736600-30ad-11ee-a90b-6d4c9c084595',
                    y: 3,
                    documentId: 'gNjo6R34YD',
                    width: 1,
                  },
                  {
                    id: '0a6ee7e0-30ad-11ee-a90b-6d4c9c084595',
                    properties: {
                      plainText: null,
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"3b2kn","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                      text: null,
                      fontSize: '1em',
                    },
                    typeId: 'MainIdea',
                    documentId: 'gNjo6R34YD',
                    y: 4,
                    height: 1,
                    width: 1,
                    x: 1,
                  },
                  {
                    id: '0b8bc990-30ad-11ee-a90b-6d4c9c084595',
                    width: 1,
                    y: 5,
                    typeId: 'Details',
                    height: 1,
                    x: 1,
                    documentId: 'gNjo6R34YD',
                    properties: {
                      plainText: null,
                      htmlText: '<p > </p>',
                      text: null,
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"2mim9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    },
                  },
                  {
                    height: 1,
                    properties: {
                      positionY: 0,
                      html: null,
                      preferredHeight: '10em',
                      positionX: 0,
                      rotation: 0,
                      text: null,
                    },
                    documentId: 'gNjo6R34YD',
                    id: '68a0ace0-30ad-11ee-a90b-6d4c9c084595',
                    width: 1,
                    y: 6,
                    typeId: 'Image',
                    x: 1,
                  },
                  {
                    width: 1,
                    x: 1,
                    documentId: 'gNjo6R34YD',
                    typeId: 'Conclusion',
                    properties: {
                      text: null,
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"5bvug","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      plainText: null,
                      fontFamily: "'Open Sans'",
                    },
                    height: 1,
                    id: '0ccef7f0-30ad-11ee-a90b-6d4c9c084595',
                    y: 7,
                  },
                ],
                columns: 1,
                style: 'fluid',
              },
              position: 0,
              documentId: 'gNjo6R34YD',
            },
          ],
          schemaVersion: 20230101,
          messages: [],
        },
      },
      templateSystem: 'blocks',
      user: {
        id: '7WwWWeqwqv',
        surname: 'Sobel',
        role: 'teacher',
        name: 'Kieran',
        username: null,
        email: 'kieran+teacher@joinpressto.com',
      },
      meta: {
        hasWritingBuddy: true,
        hasSignalWords: true,
        hasWordCount: true,
        importantWords: [],
        hasImportantWords: true,
        writingPlan: 'news-article',
      },
      isPublic: true,
      format: DocumentFormat.DOC,
      isDemo: false,
      isTemplate: true,
      name: 'Copy of News Article - Docs Template',
      thumbnailUrl: null,
      createdAt: '2024-04-17T19:42:02.000Z',
      submission: null,
    },
  },
  {
    format: DocumentFormat.BOOKLET,
    name: 'Personal Narrative',
    writingPlanId: 'personal-narrative',
    documentId: 'template-booklet-personal-narrative',
    svg: SVGs.IntroductionSVG,
    color: '#E84534',
    document: {
      name: 'Copy of Personal Narrative - Zine Template',
      format: DocumentFormat.BOOKLET,
      updatedAt: '2024-04-17T19:42:02.584Z',
      templateSystem: 'blocks',
      isDemo: false,
      submission: null,
      isPublic: true,
      createdAt: '2024-04-17T19:42:02.000Z',
      id: 'template-booklet-personal-narrative',
      isTemplate: true,
      meta: {
        hasWritingBuddy: true,
        hasImportantWords: true,
        hasSignalWords: true,
        hasWordCount: true,
        importantWords: [],
        writingPlan: 'personal-narrative',
      },
      user: {
        name: 'Kieran',
        id: '7WwWWeqwqv',
        email: 'kieran+teacher@joinpressto.com',
        surname: 'Sobel',
        username: null,
        role: 'teacher',
      },
      thumbnailUrl: null,
      version: {
        documentId: 'qMJRl2aja9',
        createdAt: {
          seconds: 1713382922,
          nanoseconds: 0,
        },
        publishedAt: {
          seconds: 1713382922,
          nanoseconds: 518000000,
        },
        status: 'PUBLISHED',
        id: 'qMJRl2aja9',
        notes: [],
        pages: [
          {
            documentId: 'XKj78Ymwa2',
            id: '96365210-316e-11ee-9a95-45bacf07edbc',
            position: 0,
            grid: {
              rows: 2,
              blocks: [
                {
                  documentId: 'XKj78Ymwa2',
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    maximumFontSize: '5em',
                    fontFamily: 'Alfa',
                    text: null,
                    plainText: null,
                    fontSize: '2em',
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"8vqf8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  id: '96365211-316e-11ee-9a95-45bacf07edbc',
                  typeId: 'Title',
                  x: 1,
                  height: 1,
                  width: 1,
                  y: 1,
                },
                {
                  width: 1,
                  documentId: 'XKj78Ymwa2',
                  id: '9d7d4420-316e-11ee-9a95-45bacf07edbc',
                  height: 1,
                  y: 2,
                  properties: {
                    positionX: 0,
                    positionY: 0,
                    rotation: 0,
                    preferredHeight: '10em',
                    html: null,
                    text: null,
                  },
                  x: 1,
                  typeId: 'Image',
                },
              ],
              columns: 1,
              style: 'fixed',
            },
            meta: '{}',
          },
          {
            position: 1,
            documentId: 'XKj78Ymwa2',
            meta: '{}',
            id: '96365212-316e-11ee-9a95-45bacf07edbc',
            grid: {
              columns: 1,
              rows: 2,
              blocks: [
                {
                  y: 1,
                  height: 2,
                  x: 1,
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"38c2q","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    plainText: null,
                    text: null,
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    fontColor: 'Black',
                    fontFamily: "'Open Sans'",
                  },
                  width: 1,
                  typeId: 'Introduction',
                  id: '9ef0aa40-316e-11ee-9a95-45bacf07edbc',
                  documentId: 'XKj78Ymwa2',
                },
              ],
              style: 'fixed',
            },
          },
          {
            id: '96365213-316e-11ee-9a95-45bacf07edbc',
            documentId: 'XKj78Ymwa2',
            grid: {
              style: 'fixed',
              blocks: [
                {
                  y: 1,
                  documentId: 'XKj78Ymwa2',
                  typeId: 'SettingAndCharacters',
                  height: 1,
                  id: 'a0ec2c20-316e-11ee-9a95-45bacf07edbc',
                  x: 1,
                  width: 1,
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    maximumFontSize: '5em',
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"c8v5p","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                },
                {
                  y: 2,
                  width: 1,
                  documentId: 'XKj78Ymwa2',
                  typeId: 'Image',
                  id: 'a207ae40-316e-11ee-9a95-45bacf07edbc',
                  x: 1,
                  height: 1,
                  properties: {
                    positionX: 0,
                    positionY: 0,
                    rotation: 0,
                    text: null,
                    html: null,
                    preferredHeight: '10em',
                  },
                },
              ],
              columns: 1,
              rows: 2,
            },
            position: 2,
            meta: '{}',
          },
          {
            id: '96365214-316e-11ee-9a95-45bacf07edbc',
            documentId: 'XKj78Ymwa2',
            position: 3,
            meta: '{}',
            grid: {
              style: 'fixed',
              rows: 2,
              blocks: [
                {
                  y: 1,
                  typeId: 'Details',
                  id: 'a35c8fe0-316e-11ee-9a95-45bacf07edbc',
                  documentId: 'XKj78Ymwa2',
                  height: 2,
                  properties: {
                    text: null,
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    fontSize: '1em',
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"31h4h","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                  },
                  x: 1,
                  width: 1,
                },
              ],
              columns: 1,
            },
          },
          {
            grid: {
              rows: 2,
              blocks: [
                {
                  x: 1,
                  id: 'bc2b4520-316e-11ee-9a95-45bacf07edbc',
                  y: 1,
                  documentId: 'XKj78Ymwa2',
                  width: 1,
                  height: 1,
                  properties: {
                    preferredHeight: '10em',
                    text: null,
                    positionY: 0,
                    positionX: 0,
                    rotation: 0,
                    html: null,
                  },
                  typeId: 'Image',
                },
                {
                  x: 1,
                  properties: {
                    fontSize: '1em',
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    text: null,
                    maximumFontSize: '5em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"600ik","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  typeId: 'Details',
                  id: 'a4a47930-316e-11ee-9a95-45bacf07edbc',
                  documentId: 'XKj78Ymwa2',
                  width: 1,
                  y: 2,
                  height: 1,
                },
              ],
              columns: 1,
              style: 'fixed',
            },
            documentId: 'XKj78Ymwa2',
            position: 4,
            meta: '{}',
            id: '96365215-316e-11ee-9a95-45bacf07edbc',
          },
          {
            position: 5,
            id: '96365216-316e-11ee-9a95-45bacf07edbc',
            grid: {
              blocks: [
                {
                  x: 1,
                  height: 1,
                  typeId: 'Details',
                  width: 1,
                  documentId: 'XKj78Ymwa2',
                  id: 'a5ea1890-316e-11ee-9a95-45bacf07edbc',
                  y: 1,
                  properties: {
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"f98v5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    text: null,
                    fontFamily: "'Open Sans'",
                  },
                },
                {
                  typeId: 'Image',
                  properties: {
                    positionX: 0,
                    html: null,
                    preferredHeight: '10em',
                    text: null,
                    rotation: 0,
                    positionY: 0,
                  },
                  width: 1,
                  y: 2,
                  height: 1,
                  x: 1,
                  id: 'c27cb3a0-316e-11ee-9a95-45bacf07edbc',
                  documentId: 'XKj78Ymwa2',
                },
              ],
              style: 'fixed',
              columns: 1,
              rows: 2,
            },
            documentId: 'XKj78Ymwa2',
            meta: '{}',
          },
          {
            grid: {
              columns: 1,
              rows: 2,
              blocks: [
                {
                  documentId: 'XKj78Ymwa2',
                  x: 1,
                  width: 1,
                  id: 'a95a1200-316e-11ee-9a95-45bacf07edbc',
                  typeId: 'Details',
                  properties: {
                    maximumFontSize: '5em',
                    text: null,
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"d6unp","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                    plainText: null,
                  },
                  height: 2,
                  y: 1,
                },
              ],
              style: 'fixed',
            },
            id: '96365217-316e-11ee-9a95-45bacf07edbc',
            documentId: 'XKj78Ymwa2',
            position: 6,
            meta: '{}',
          },
          {
            grid: {
              blocks: [
                {
                  documentId: 'XKj78Ymwa2',
                  height: 1,
                  typeId: 'Image',
                  x: 1,
                  y: 1,
                  id: 'ab172b50-316e-11ee-9a95-45bacf07edbc',
                  width: 1,
                  properties: {
                    rotation: 0,
                    positionX: 0,
                    preferredHeight: '10em',
                    imageUrl: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    orientation: 'portrait',
                    text: null,
                    positionY: 0,
                    zoom: 1,
                  },
                },
                {
                  typeId: 'Conclusion',
                  properties: {
                    fontFamily: "'Open Sans'",
                    text: null,
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"l189","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                  },
                  height: 1,
                  x: 1,
                  y: 2,
                  documentId: 'XKj78Ymwa2',
                  id: 'ae93cef0-316e-11ee-9a95-45bacf07edbc',
                  width: 1,
                },
              ],
              columns: 1,
              style: 'fixed',
              rows: 2,
            },
            id: '96365218-316e-11ee-9a95-45bacf07edbc',
            meta: '{}',
            documentId: 'XKj78Ymwa2',
            position: 7,
          },
        ],
        content: {
          schemaVersion: 20230101,
          pages: [
            {
              grid: {
                columns: 1,
                rows: 2,
                style: 'fixed',
                blocks: [
                  {
                    typeId: 'Title',
                    id: '96365211-316e-11ee-9a95-45bacf07edbc',
                    width: 1,
                    x: 1,
                    documentId: 'XKj78Ymwa2',
                    height: 1,
                    y: 1,
                    properties: {
                      fontSize: '2em',
                      fontFamily: 'Alfa',
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"8vqf8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      plainText: null,
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      text: null,
                    },
                  },
                  {
                    id: '9d7d4420-316e-11ee-9a95-45bacf07edbc',
                    height: 1,
                    documentId: 'XKj78Ymwa2',
                    properties: {
                      rotation: 0,
                      text: null,
                      html: null,
                      preferredHeight: '10em',
                      positionY: 0,
                      positionX: 0,
                    },
                    width: 1,
                    x: 1,
                    typeId: 'Image',
                    y: 2,
                  },
                ],
              },
              documentId: 'XKj78Ymwa2',
              id: '96365210-316e-11ee-9a95-45bacf07edbc',
              position: 0,
              meta: '{}',
            },
            {
              documentId: 'XKj78Ymwa2',
              position: 1,
              grid: {
                columns: 1,
                blocks: [
                  {
                    properties: {
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      plainText: null,
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                      text: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      rawEditorContent: '{"blocks":[{"key":"38c2q","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    },
                    typeId: 'Introduction',
                    height: 2,
                    y: 1,
                    id: '9ef0aa40-316e-11ee-9a95-45bacf07edbc',
                    documentId: 'XKj78Ymwa2',
                    width: 1,
                    x: 1,
                  },
                ],
                rows: 2,
                style: 'fixed',
              },
              meta: '{}',
              id: '96365212-316e-11ee-9a95-45bacf07edbc',
            },
            {
              documentId: 'XKj78Ymwa2',
              grid: {
                columns: 1,
                style: 'fixed',
                blocks: [
                  {
                    typeId: 'SettingAndCharacters',
                    x: 1,
                    properties: {
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"c8v5p","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      plainText: null,
                      maximumFontSize: '5em',
                    },
                    y: 1,
                    width: 1,
                    documentId: 'XKj78Ymwa2',
                    id: 'a0ec2c20-316e-11ee-9a95-45bacf07edbc',
                    height: 1,
                  },
                  {
                    properties: {
                      positionX: 0,
                      positionY: 0,
                      rotation: 0,
                      preferredHeight: '10em',
                      text: null,
                      html: null,
                    },
                    documentId: 'XKj78Ymwa2',
                    x: 1,
                    height: 1,
                    typeId: 'Image',
                    id: 'a207ae40-316e-11ee-9a95-45bacf07edbc',
                    width: 1,
                    y: 2,
                  },
                ],
                rows: 2,
              },
              position: 2,
              id: '96365213-316e-11ee-9a95-45bacf07edbc',
              meta: '{}',
            },
            {
              documentId: 'XKj78Ymwa2',
              id: '96365214-316e-11ee-9a95-45bacf07edbc',
              position: 3,
              grid: {
                columns: 1,
                blocks: [
                  {
                    y: 1,
                    id: 'a35c8fe0-316e-11ee-9a95-45bacf07edbc',
                    properties: {
                      fontFamily: "'Open Sans'",
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                      plainText: null,
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"31h4h","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                    },
                    typeId: 'Details',
                    height: 2,
                    x: 1,
                    width: 1,
                    documentId: 'XKj78Ymwa2',
                  },
                ],
                style: 'fixed',
                rows: 2,
              },
              meta: '{}',
            },
            {
              position: 4,
              documentId: 'XKj78Ymwa2',
              grid: {
                style: 'fixed',
                blocks: [
                  {
                    properties: {
                      positionY: 0,
                      preferredHeight: '10em',
                      html: null,
                      text: null,
                      rotation: 0,
                      positionX: 0,
                    },
                    height: 1,
                    id: 'bc2b4520-316e-11ee-9a95-45bacf07edbc',
                    x: 1,
                    typeId: 'Image',
                    width: 1,
                    y: 1,
                    documentId: 'XKj78Ymwa2',
                  },
                  {
                    x: 1,
                    width: 1,
                    documentId: 'XKj78Ymwa2',
                    properties: {
                      maximumFontSize: '5em',
                      plainText: null,
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"600ik","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                    },
                    y: 2,
                    typeId: 'Details',
                    id: 'a4a47930-316e-11ee-9a95-45bacf07edbc',
                    height: 1,
                  },
                ],
                rows: 2,
                columns: 1,
              },
              meta: '{}',
              id: '96365215-316e-11ee-9a95-45bacf07edbc',
            },
            {
              id: '96365216-316e-11ee-9a95-45bacf07edbc',
              meta: '{}',
              grid: {
                rows: 2,
                style: 'fixed',
                blocks: [
                  {
                    properties: {
                      fontFamily: "'Open Sans'",
                      text: null,
                      plainText: null,
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"f98v5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                    },
                    y: 1,
                    x: 1,
                    documentId: 'XKj78Ymwa2',
                    typeId: 'Details',
                    width: 1,
                    height: 1,
                    id: 'a5ea1890-316e-11ee-9a95-45bacf07edbc',
                  },
                  {
                    y: 2,
                    height: 1,
                    width: 1,
                    properties: {
                      text: null,
                      positionX: 0,
                      preferredHeight: '10em',
                      positionY: 0,
                      html: null,
                      rotation: 0,
                    },
                    id: 'c27cb3a0-316e-11ee-9a95-45bacf07edbc',
                    documentId: 'XKj78Ymwa2',
                    x: 1,
                    typeId: 'Image',
                  },
                ],
                columns: 1,
              },
              position: 5,
              documentId: 'XKj78Ymwa2',
            },
            {
              grid: {
                columns: 1,
                style: 'fixed',
                blocks: [
                  {
                    documentId: 'XKj78Ymwa2',
                    x: 1,
                    properties: {
                      maximumFontSize: '5em',
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"d6unp","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      plainText: null,
                      htmlText: '<p > </p>',
                      text: null,
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                    },
                    height: 2,
                    typeId: 'Details',
                    width: 1,
                    y: 1,
                    id: 'a95a1200-316e-11ee-9a95-45bacf07edbc',
                  },
                ],
                rows: 2,
              },
              meta: '{}',
              id: '96365217-316e-11ee-9a95-45bacf07edbc',
              position: 6,
              documentId: 'XKj78Ymwa2',
            },
            {
              documentId: 'XKj78Ymwa2',
              meta: '{}',
              grid: {
                style: 'fixed',
                rows: 2,
                blocks: [
                  {
                    width: 1,
                    height: 1,
                    y: 1,
                    id: 'ab172b50-316e-11ee-9a95-45bacf07edbc',
                    documentId: 'XKj78Ymwa2',
                    x: 1,
                    properties: {
                      positionY: 0,
                      zoom: 1,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      orientation: 'portrait',
                      positionX: 0,
                      preferredHeight: '10em',
                      rotation: 0,
                      imageUrl: null,
                      text: null,
                    },
                    typeId: 'Image',
                  },
                  {
                    x: 1,
                    width: 1,
                    properties: {
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"l189","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      htmlText: '<p > </p>',
                      fontFamily: "'Open Sans'",
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                      plainText: null,
                    },
                    y: 2,
                    id: 'ae93cef0-316e-11ee-9a95-45bacf07edbc',
                    documentId: 'XKj78Ymwa2',
                    typeId: 'Conclusion',
                    height: 1,
                  },
                ],
                columns: 1,
              },
              id: '96365218-316e-11ee-9a95-45bacf07edbc',
              position: 7,
            },
          ],
          messages: [],
        },
      },
    },
  },
  {
    format: DocumentFormat.DOC,
    name: 'Sequence',
    writingPlanId: 'sequence',
    documentId: 'template-doc-sequence',
    svg: SVGs.EventSVG,
    color: '#F5D62F',
    document: {
      meta: {
        hasWritingBuddy: true,
        writingPlan: 'sequence',
        hasImportantWords: true,
        importantWords: [],
        hasWordCount: true,
        hasSignalWords: true,
      },
      updatedAt: '2024-04-17T19:42:02.726Z',
      version: {
        createdAt: {
          seconds: 1713382922,
          nanoseconds: 0,
        },
        notes: [],
        status: 'PUBLISHED',
        id: 'D8jKny64XW',
        documentId: 'D8jKny64XW',
        pages: [
          {
            grid: {
              columns: 1,
              style: 'fluid',
              blocks: [
                {
                  id: '7049f851-30af-11ee-a0cb-3bf51732fff8',
                  height: 1,
                  properties: {
                    text: null,
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                    fontFamily: 'Alfa',
                    rawEditorContent: '{"blocks":[{"key":"1taog","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    fontSize: '2em',
                    plainText: null,
                    fontColor: 'Black',
                  },
                  documentId: '51JqdR5wKA',
                  x: 1,
                  y: 1,
                  typeId: 'Title',
                  width: 1,
                },
                {
                  id: '7f73a740-30af-11ee-a0cb-3bf51732fff8',
                  y: 2,
                  properties: {
                    preferredHeight: '10em',
                    rotation: 0,
                    text: null,
                    positionY: 0,
                    positionX: 0,
                    html: null,
                  },
                  width: 1,
                  height: 1,
                  x: 1,
                  documentId: '51JqdR5wKA',
                  typeId: 'Image',
                },
                {
                  height: 1,
                  y: 3,
                  x: 1,
                  documentId: '51JqdR5wKA',
                  typeId: 'Introduction',
                  width: 1,
                  id: '7452fd70-30af-11ee-a0cb-3bf51732fff8',
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"3jg6i","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    plainText: null,
                    text: null,
                    fontFamily: "'Open Sans'",
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                  },
                },
                {
                  documentId: '51JqdR5wKA',
                  height: 1,
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"83tt3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    text: null,
                    plainText: null,
                  },
                  typeId: 'Event',
                  x: 1,
                  width: 1,
                  id: '77acfde0-30af-11ee-a0cb-3bf51732fff8',
                  y: 4,
                },
                {
                  y: 5,
                  properties: {
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"ahe4m","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    text: null,
                    plainText: null,
                    html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                  },
                  id: '797dc640-30af-11ee-a0cb-3bf51732fff8',
                  width: 1,
                  documentId: '51JqdR5wKA',
                  x: 1,
                  typeId: 'Conclusion',
                  height: 1,
                },
              ],
              rows: 5,
            },
            id: '7049f850-30af-11ee-a0cb-3bf51732fff8',
            documentId: '51JqdR5wKA',
            meta: '{}',
            position: 0,
          },
        ],
        publishedAt: {
          seconds: 1713382922,
          nanoseconds: 670000000,
        },
        content: {
          messages: [],
          pages: [
            {
              id: '7049f850-30af-11ee-a0cb-3bf51732fff8',
              position: 0,
              documentId: '51JqdR5wKA',
              meta: '{}',
              grid: {
                blocks: [
                  {
                    x: 1,
                    properties: {
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      plainText: null,
                      fontColor: 'Black',
                      fontFamily: 'Alfa',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"1taog","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '2em',
                    },
                    y: 1,
                    typeId: 'Title',
                    height: 1,
                    id: '7049f851-30af-11ee-a0cb-3bf51732fff8',
                    documentId: '51JqdR5wKA',
                    width: 1,
                  },
                  {
                    x: 1,
                    properties: {
                      text: null,
                      rotation: 0,
                      preferredHeight: '10em',
                      html: null,
                      positionX: 0,
                      positionY: 0,
                    },
                    documentId: '51JqdR5wKA',
                    width: 1,
                    height: 1,
                    typeId: 'Image',
                    y: 2,
                    id: '7f73a740-30af-11ee-a0cb-3bf51732fff8',
                  },
                  {
                    id: '7452fd70-30af-11ee-a0cb-3bf51732fff8',
                    y: 3,
                    typeId: 'Introduction',
                    properties: {
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"3jg6i","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      text: null,
                      fontSize: '1em',
                      plainText: null,
                    },
                    documentId: '51JqdR5wKA',
                    x: 1,
                    height: 1,
                    width: 1,
                  },
                  {
                    height: 1,
                    x: 1,
                    typeId: 'Event',
                    properties: {
                      text: null,
                      fontColor: 'Black',
                      plainText: null,
                      fontSize: '1em',
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"83tt3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    },
                    y: 4,
                    documentId: '51JqdR5wKA',
                    id: '77acfde0-30af-11ee-a0cb-3bf51732fff8',
                    width: 1,
                  },
                  {
                    typeId: 'Conclusion',
                    properties: {
                      plainText: null,
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: auto; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"ahe4m","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '1em',
                    },
                    documentId: '51JqdR5wKA',
                    height: 1,
                    id: '797dc640-30af-11ee-a0cb-3bf51732fff8',
                    y: 5,
                    x: 1,
                    width: 1,
                  },
                ],
                rows: 5,
                columns: 1,
                style: 'fluid',
              },
            },
          ],
          schemaVersion: 20230101,
        },
      },
      submission: null,
      thumbnailUrl: null,
      isDemo: false,
      name: 'Copy of Sequence - Docs Template',
      format: DocumentFormat.DOC,
      id: 'template-doc-sequence',
      user: {
        username: null,
        role: 'teacher',
        email: 'kieran+teacher@joinpressto.com',
        id: '7WwWWeqwqv',
        surname: 'Sobel',
        name: 'Kieran',
      },
      templateSystem: 'blocks',
      isPublic: true,
      createdAt: '2024-04-17T19:42:02.000Z',
      isTemplate: true,
    },
  },
  {
    format: DocumentFormat.BOOKLET,
    name: 'Story',
    writingPlanId: 'story',
    documentId: 'template-booklet-story',
    svg: SVGs.SettingCharacterSVG,
    color: '#FDAD4E',
    document: {
      templateSystem: 'blocks',
      id: 'template-booklet-story',
      format: DocumentFormat.BOOKLET,
      thumbnailUrl: null,
      name: 'Copy of Story - Zine Template',
      user: {
        surname: 'Sobel',
        username: null,
        email: 'kieran+teacher@joinpressto.com',
        name: 'Kieran',
        role: 'teacher',
        id: '7WwWWeqwqv',
      },
      isPublic: true,
      updatedAt: '2024-04-17T19:42:02.832Z',
      createdAt: '2024-04-17T19:42:02.000Z',
      meta: {
        hasSignalWords: true,
        hasWritingBuddy: true,
        hasWordCount: true,
        importantWords: [],
        hasImportantWords: true,
        writingPlan: 'story',
      },
      isDemo: false,
      submission: null,
      isTemplate: true,
      version: {
        documentId: '7N4AVMEjRe',
        id: '7N4AVMEjRe',
        createdAt: {
          seconds: 1713382922,
          nanoseconds: 0,
        },
        content: {
          pages: [
            {
              meta: '{}',
              documentId: 'Ld4O56lwO5',
              position: 0,
              id: 'd18eb150-316d-11ee-9a95-45bacf07edbc',
              grid: {
                rows: 2,
                columns: 1,
                blocks: [
                  {
                    x: 1,
                    id: 'd18eb151-316d-11ee-9a95-45bacf07edbc',
                    documentId: 'Ld4O56lwO5',
                    width: 1,
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'></div></div>",
                      maximumFontSize: '5em',
                      text: null,
                      fontFamily: 'Alfa',
                      fontSize: '2em',
                      fontColor: 'Black',
                    },
                    height: 1,
                    y: 1,
                    typeId: 'Title',
                  },
                  {
                    width: 1,
                    y: 2,
                    documentId: 'Ld4O56lwO5',
                    height: 1,
                    properties: {
                      positionY: 0,
                      text: null,
                      preferredHeight: '10em',
                      positionX: 0,
                      rotation: 0,
                      html: null,
                    },
                    id: 'd7a27a90-316d-11ee-9a95-45bacf07edbc',
                    typeId: 'Image',
                    x: 1,
                  },
                ],
                style: 'fixed',
              },
            },
            {
              documentId: 'Ld4O56lwO5',
              id: 'd18eb152-316d-11ee-9a95-45bacf07edbc',
              grid: {
                columns: 1,
                rows: 2,
                blocks: [
                  {
                    documentId: 'Ld4O56lwO5',
                    height: 1,
                    width: 1,
                    properties: {
                      plainText: null,
                      htmlText: '<p > </p>',
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"9u71r","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      fontSize: '1em',
                    },
                    typeId: 'SettingAndCharacters',
                    y: 1,
                    x: 1,
                    id: 'db5c0020-316d-11ee-9a95-45bacf07edbc',
                  },
                  {
                    typeId: 'Image',
                    width: 1,
                    properties: {
                      text: null,
                      rotation: 0,
                      positionX: 0,
                      html: null,
                      preferredHeight: '10em',
                      positionY: 0,
                    },
                    height: 1,
                    y: 2,
                    x: 1,
                    id: 'e6c68430-316d-11ee-9a95-45bacf07edbc',
                    documentId: 'Ld4O56lwO5',
                  },
                ],
                style: 'fixed',
              },
              meta: '{}',
              position: 1,
            },
            {
              meta: '{}',
              id: 'd18eb158-316d-11ee-9a95-45bacf07edbc',
              documentId: 'Ld4O56lwO5',
              grid: {
                style: 'fixed',
                rows: 2,
                columns: 1,
                blocks: [
                  {
                    width: 1,
                    id: 'e3a2d600-316d-11ee-9a95-45bacf07edbc',
                    typeId: 'Image',
                    height: 2,
                    documentId: 'Ld4O56lwO5',
                    properties: {
                      text: null,
                      rotation: 0,
                      positionY: 0,
                      positionX: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      preferredHeight: '10em',
                    },
                    y: 1,
                    x: 1,
                  },
                ],
              },
              position: 2,
            },
            {
              position: 3,
              id: 'd18eb153-316d-11ee-9a95-45bacf07edbc',
              grid: {
                rows: 2,
                blocks: [
                  {
                    height: 1,
                    width: 1,
                    id: 'dc6c85c0-316d-11ee-9a95-45bacf07edbc',
                    x: 1,
                    typeId: 'ProblemOrGoal',
                    documentId: 'Ld4O56lwO5',
                    properties: {
                      plainText: null,
                      fontSize: '1em',
                      text: null,
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"6l1ln","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                    },
                    y: 1,
                  },
                  {
                    properties: {
                      positionX: 0,
                      text: null,
                      html: null,
                      preferredHeight: '10em',
                      rotation: 0,
                      positionY: 0,
                    },
                    x: 1,
                    typeId: 'Image',
                    width: 1,
                    y: 2,
                    id: 'e81b17b0-316d-11ee-9a95-45bacf07edbc',
                    height: 1,
                    documentId: 'Ld4O56lwO5',
                  },
                ],
                columns: 1,
                style: 'fixed',
              },
              meta: '{}',
              documentId: 'Ld4O56lwO5',
            },
            {
              position: 4,
              documentId: 'Ld4O56lwO5',
              grid: {
                rows: 2,
                style: 'fixed',
                columns: 1,
                blocks: [
                  {
                    typeId: 'Image',
                    x: 1,
                    documentId: 'Ld4O56lwO5',
                    height: 1,
                    y: 1,
                    width: 1,
                    properties: {
                      positionX: 0,
                      preferredHeight: '10em',
                      text: null,
                      rotation: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      positionY: 0,
                    },
                    id: 'e944f1b0-316d-11ee-9a95-45bacf07edbc',
                  },
                  {
                    height: 1,
                    id: 'dd9611a0-316d-11ee-9a95-45bacf07edbc',
                    documentId: 'Ld4O56lwO5',
                    x: 1,
                    width: 1,
                    typeId: 'Attempts',
                    y: 2,
                    properties: {
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      rawEditorContent: '{"blocks":[{"key":"5b1nd","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '1em',
                      text: null,
                      htmlText: '<p > </p>',
                    },
                  },
                ],
              },
              id: 'd18eb154-316d-11ee-9a95-45bacf07edbc',
              meta: '{}',
            },
            {
              meta: '{}',
              documentId: 'Ld4O56lwO5',
              position: 5,
              id: 'd18eb155-316d-11ee-9a95-45bacf07edbc',
              grid: {
                style: 'fixed',
                rows: 2,
                blocks: [
                  {
                    x: 1,
                    width: 1,
                    id: 'df4659b0-316d-11ee-9a95-45bacf07edbc',
                    typeId: 'StorySolution',
                    documentId: 'Ld4O56lwO5',
                    properties: {
                      fontFamily: "'Open Sans'",
                      maximumFontSize: '5em',
                      plainText: null,
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontSize: '1em',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"fuv0v","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                    },
                    y: 1,
                    height: 1,
                  },
                  {
                    y: 2,
                    typeId: 'Image',
                    x: 1,
                    id: 'eabff8f0-316d-11ee-9a95-45bacf07edbc',
                    properties: {
                      text: null,
                      preferredHeight: '10em',
                      html: null,
                      positionX: 0,
                      rotation: 0,
                      positionY: 0,
                    },
                    documentId: 'Ld4O56lwO5',
                    width: 1,
                    height: 1,
                  },
                ],
                columns: 1,
              },
            },
            {
              id: 'd18eb156-316d-11ee-9a95-45bacf07edbc',
              grid: {
                style: 'fixed',
                columns: 1,
                rows: 2,
                blocks: [
                  {
                    documentId: 'Ld4O56lwO5',
                    y: 1,
                    height: 1,
                    id: 'e0c8db00-316d-11ee-9a95-45bacf07edbc',
                    properties: {
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                      maximumFontSize: '5em',
                      fontSize: '1em',
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      plainText: null,
                      rawEditorContent: '{"blocks":[{"key":"7l05f","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                    },
                    x: 1,
                    typeId: 'Consequence',
                    width: 1,
                  },
                  {
                    documentId: 'Ld4O56lwO5',
                    width: 1,
                    id: 'ec728af0-316d-11ee-9a95-45bacf07edbc',
                    height: 1,
                    properties: {
                      rawEditorContent: '{"blocks":[{"key":"en79k","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      rotation: 0,
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      htmlText: '<p > </p>',
                      maximumFontSize: '5em',
                      text: null,
                      positionX: 0,
                      fontSize: '1em',
                      fontColor: 'Black',
                      positionY: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      preferredHeight: '10em',
                    },
                    x: 1,
                    y: 2,
                    typeId: 'Resolution',
                  },
                ],
              },
              position: 6,
              documentId: 'Ld4O56lwO5',
              meta: '{}',
            },
            {
              documentId: 'Ld4O56lwO5',
              position: 7,
              meta: '{}',
              id: 'd18eb157-316d-11ee-9a95-45bacf07edbc',
              grid: {
                blocks: [
                  {
                    documentId: 'Ld4O56lwO5',
                    y: 1,
                    properties: {
                      text: null,
                      positionY: 0,
                      positionX: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      rotation: 0,
                      preferredHeight: '10em',
                    },
                    width: 1,
                    id: 'edc26380-316d-11ee-9a95-45bacf07edbc',
                    typeId: 'Image',
                    x: 1,
                    height: 2,
                  },
                ],
                columns: 1,
                style: 'fixed',
                rows: 2,
              },
            },
          ],
          messages: [],
          schemaVersion: 20230101,
        },
        publishedAt: {
          seconds: 1713382922,
          nanoseconds: 765000000,
        },
        notes: [],
        pages: [
          {
            position: 0,
            meta: '{}',
            grid: {
              blocks: [
                {
                  properties: {
                    fontSize: '2em',
                    text: null,
                    fontFamily: 'Alfa',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'></div></div>",
                    fontColor: 'Black',
                    maximumFontSize: '5em',
                  },
                  documentId: 'Ld4O56lwO5',
                  id: 'd18eb151-316d-11ee-9a95-45bacf07edbc',
                  y: 1,
                  typeId: 'Title',
                  width: 1,
                  height: 1,
                  x: 1,
                },
                {
                  id: 'd7a27a90-316d-11ee-9a95-45bacf07edbc',
                  properties: {
                    text: null,
                    rotation: 0,
                    positionX: 0,
                    preferredHeight: '10em',
                    positionY: 0,
                    html: null,
                  },
                  x: 1,
                  height: 1,
                  typeId: 'Image',
                  width: 1,
                  y: 2,
                  documentId: 'Ld4O56lwO5',
                },
              ],
              rows: 2,
              columns: 1,
              style: 'fixed',
            },
            id: 'd18eb150-316d-11ee-9a95-45bacf07edbc',
            documentId: 'Ld4O56lwO5',
          },
          {
            grid: {
              style: 'fixed',
              columns: 1,
              blocks: [
                {
                  id: 'db5c0020-316d-11ee-9a95-45bacf07edbc',
                  x: 1,
                  typeId: 'SettingAndCharacters',
                  height: 1,
                  y: 1,
                  documentId: 'Ld4O56lwO5',
                  width: 1,
                  properties: {
                    fontColor: 'Black',
                    text: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"9u71r","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    plainText: null,
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                  },
                },
                {
                  typeId: 'Image',
                  documentId: 'Ld4O56lwO5',
                  properties: {
                    positionX: 0,
                    rotation: 0,
                    html: null,
                    positionY: 0,
                    preferredHeight: '10em',
                    text: null,
                  },
                  x: 1,
                  y: 2,
                  id: 'e6c68430-316d-11ee-9a95-45bacf07edbc',
                  width: 1,
                  height: 1,
                },
              ],
              rows: 2,
            },
            meta: '{}',
            documentId: 'Ld4O56lwO5',
            id: 'd18eb152-316d-11ee-9a95-45bacf07edbc',
            position: 1,
          },
          {
            id: 'd18eb158-316d-11ee-9a95-45bacf07edbc',
            meta: '{}',
            position: 2,
            documentId: 'Ld4O56lwO5',
            grid: {
              blocks: [
                {
                  id: 'e3a2d600-316d-11ee-9a95-45bacf07edbc',
                  x: 1,
                  y: 1,
                  typeId: 'Image',
                  width: 1,
                  documentId: 'Ld4O56lwO5',
                  height: 2,
                  properties: {
                    preferredHeight: '10em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    positionX: 0,
                    text: null,
                    positionY: 0,
                    rotation: 0,
                  },
                },
              ],
              rows: 2,
              style: 'fixed',
              columns: 1,
            },
          },
          {
            id: 'd18eb153-316d-11ee-9a95-45bacf07edbc',
            documentId: 'Ld4O56lwO5',
            meta: '{}',
            grid: {
              rows: 2,
              style: 'fixed',
              blocks: [
                {
                  y: 1,
                  height: 1,
                  width: 1,
                  properties: {
                    maximumFontSize: '5em',
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"6l1ln","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    text: null,
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                  },
                  typeId: 'ProblemOrGoal',
                  id: 'dc6c85c0-316d-11ee-9a95-45bacf07edbc',
                  x: 1,
                  documentId: 'Ld4O56lwO5',
                },
                {
                  y: 2,
                  x: 1,
                  height: 1,
                  width: 1,
                  id: 'e81b17b0-316d-11ee-9a95-45bacf07edbc',
                  properties: {
                    html: null,
                    rotation: 0,
                    positionX: 0,
                    preferredHeight: '10em',
                    text: null,
                    positionY: 0,
                  },
                  documentId: 'Ld4O56lwO5',
                  typeId: 'Image',
                },
              ],
              columns: 1,
            },
            position: 3,
          },
          {
            documentId: 'Ld4O56lwO5',
            meta: '{}',
            position: 4,
            id: 'd18eb154-316d-11ee-9a95-45bacf07edbc',
            grid: {
              rows: 2,
              style: 'fixed',
              columns: 1,
              blocks: [
                {
                  x: 1,
                  id: 'e944f1b0-316d-11ee-9a95-45bacf07edbc',
                  typeId: 'Image',
                  properties: {
                    rotation: 0,
                    positionY: 0,
                    positionX: 0,
                    preferredHeight: '10em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    text: null,
                  },
                  y: 1,
                  height: 1,
                  width: 1,
                  documentId: 'Ld4O56lwO5',
                },
                {
                  id: 'dd9611a0-316d-11ee-9a95-45bacf07edbc',
                  documentId: 'Ld4O56lwO5',
                  x: 1,
                  width: 1,
                  properties: {
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"5b1nd","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    maximumFontSize: '5em',
                    text: null,
                  },
                  typeId: 'Attempts',
                  y: 2,
                  height: 1,
                },
              ],
            },
          },
          {
            position: 5,
            grid: {
              blocks: [
                {
                  properties: {
                    text: null,
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    maximumFontSize: '5em',
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"fuv0v","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                  width: 1,
                  typeId: 'StorySolution',
                  id: 'df4659b0-316d-11ee-9a95-45bacf07edbc',
                  y: 1,
                  x: 1,
                  documentId: 'Ld4O56lwO5',
                  height: 1,
                },
                {
                  typeId: 'Image',
                  height: 1,
                  y: 2,
                  properties: {
                    positionY: 0,
                    text: null,
                    preferredHeight: '10em',
                    rotation: 0,
                    positionX: 0,
                    html: null,
                  },
                  width: 1,
                  documentId: 'Ld4O56lwO5',
                  x: 1,
                  id: 'eabff8f0-316d-11ee-9a95-45bacf07edbc',
                },
              ],
              style: 'fixed',
              columns: 1,
              rows: 2,
            },
            meta: '{}',
            documentId: 'Ld4O56lwO5',
            id: 'd18eb155-316d-11ee-9a95-45bacf07edbc',
          },
          {
            id: 'd18eb156-316d-11ee-9a95-45bacf07edbc',
            grid: {
              rows: 2,
              blocks: [
                {
                  id: 'e0c8db00-316d-11ee-9a95-45bacf07edbc',
                  height: 1,
                  typeId: 'Consequence',
                  properties: {
                    text: null,
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontSize: '1em',
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"7l05f","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                  },
                  width: 1,
                  x: 1,
                  documentId: 'Ld4O56lwO5',
                  y: 1,
                },
                {
                  id: 'ec728af0-316d-11ee-9a95-45bacf07edbc',
                  typeId: 'Resolution',
                  y: 2,
                  documentId: 'Ld4O56lwO5',
                  x: 1,
                  height: 1,
                  properties: {
                    positionY: 0,
                    positionX: 0,
                    rotation: 0,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    htmlText: '<p > </p>',
                    plainText: null,
                    fontSize: '1em',
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"en79k","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    text: null,
                    maximumFontSize: '5em',
                    fontFamily: "'Open Sans'",
                    preferredHeight: '10em',
                  },
                  width: 1,
                },
              ],
              columns: 1,
              style: 'fixed',
            },
            documentId: 'Ld4O56lwO5',
            meta: '{}',
            position: 6,
          },
          {
            grid: {
              style: 'fixed',
              columns: 1,
              rows: 2,
              blocks: [
                {
                  documentId: 'Ld4O56lwO5',
                  x: 1,
                  typeId: 'Image',
                  height: 2,
                  width: 1,
                  id: 'edc26380-316d-11ee-9a95-45bacf07edbc',
                  y: 1,
                  properties: {
                    preferredHeight: '10em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    positionY: 0,
                    rotation: 0,
                    text: null,
                    positionX: 0,
                  },
                },
              ],
            },
            documentId: 'Ld4O56lwO5',
            meta: '{}',
            position: 7,
            id: 'd18eb157-316d-11ee-9a95-45bacf07edbc',
          },
        ],
        status: 'PUBLISHED',
      },
    },
  },
  {
    format: DocumentFormat.BOOKLET,
    name: 'Description',
    writingPlanId: 'description',
    documentId: 'template-booklet-description',
    svg: SVGs.MainIdeaSVG,
    color: '#9EC264',
    document: {
      updatedAt: '2024-04-17T19:42:02.944Z',
      isTemplate: true,
      name: 'Copy of Description - Zine Template',
      isDemo: false,
      submission: null,
      isPublic: true,
      createdAt: '2024-04-17T19:42:02.000Z',
      meta: {
        hasWritingBuddy: true,
        writingPlan: 'description',
        importantWords: [],
        hasImportantWords: true,
        hasWordCount: true,
        hasSignalWords: true,
      },
      thumbnailUrl: null,
      user: {
        name: 'Kieran',
        email: 'kieran+teacher@joinpressto.com',
        id: '7WwWWeqwqv',
        surname: 'Sobel',
        username: null,
        role: 'teacher',
      },
      version: {
        documentId: 'M5jY7eNJl2',
        status: 'PUBLISHED',
        createdAt: {
          seconds: 1713382922,
          nanoseconds: 0,
        },
        content: {
          pages: [
            {
              meta: '{}',
              grid: {
                blocks: [
                  {
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'></div></div>",
                      maximumFontSize: '5em',
                      text: null,
                      fontSize: '2em',
                      fontFamily: 'Alfa',
                      fontColor: 'Black',
                    },
                    documentId: 'VG48OpKJNv',
                    id: '3335f232-3167-11ee-9a95-45bacf07edbc',
                    y: 1,
                    typeId: 'Title',
                    width: 1,
                    height: 1,
                    x: 1,
                  },
                  {
                    properties: {
                      preferredHeight: '10em',
                      text: null,
                      rotation: 0,
                      positionX: 0,
                      positionY: 0,
                      html: null,
                    },
                    typeId: 'Image',
                    id: '37b7d0d0-3167-11ee-9a95-45bacf07edbc',
                    width: 1,
                    documentId: 'VG48OpKJNv',
                    height: 1,
                    y: 2,
                    x: 1,
                  },
                ],
                style: 'fixed',
                rows: 2,
                columns: 1,
              },
              id: '3335f231-3167-11ee-9a95-45bacf07edbc',
              documentId: 'VG48OpKJNv',
              position: 0,
            },
            {
              position: 1,
              documentId: 'VG48OpKJNv',
              id: '3335f233-3167-11ee-9a95-45bacf07edbc',
              meta: '{}',
              grid: {
                rows: 2,
                style: 'fixed',
                columns: 1,
                blocks: [
                  {
                    x: 1,
                    height: 2,
                    y: 1,
                    typeId: 'MainIdea',
                    width: 1,
                    id: '39421a50-3167-11ee-9a95-45bacf07edbc',
                    properties: {
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"5q893","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      plainText: null,
                      text: null,
                    },
                    documentId: 'VG48OpKJNv',
                  },
                ],
              },
            },
            {
              documentId: 'VG48OpKJNv',
              grid: {
                rows: 2,
                blocks: [
                  {
                    y: 1,
                    width: 1,
                    x: 1,
                    id: '3c149b40-3167-11ee-9a95-45bacf07edbc',
                    typeId: 'Image',
                    properties: {
                      positionX: 0,
                      rotation: 0,
                      positionY: 0,
                      html: null,
                      text: null,
                      preferredHeight: '10em',
                    },
                    height: 1,
                    documentId: 'VG48OpKJNv',
                  },
                  {
                    properties: {
                      plainText: null,
                      rawEditorContent: '{"blocks":[{"key":"5p9q6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      htmlText: '<p > </p>',
                    },
                    typeId: 'SupportingDetails',
                    y: 2,
                    width: 1,
                    x: 1,
                    documentId: 'VG48OpKJNv',
                    id: '3d6955d0-3167-11ee-9a95-45bacf07edbc',
                    height: 1,
                  },
                ],
                style: 'fixed',
                columns: 1,
              },
              meta: '{}',
              position: 2,
              id: '3335f234-3167-11ee-9a95-45bacf07edbc',
            },
            {
              documentId: 'VG48OpKJNv',
              grid: {
                style: 'fixed',
                blocks: [
                  {
                    x: 1,
                    width: 1,
                    documentId: 'VG48OpKJNv',
                    typeId: 'SupportingDetails',
                    height: 1,
                    id: '3e9db720-3167-11ee-9a95-45bacf07edbc',
                    y: 1,
                    properties: {
                      plainText: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      maximumFontSize: '5em',
                      rawEditorContent: '{"blocks":[{"key":"abogo","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '1em',
                      htmlText: '<p > </p>',
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                    },
                  },
                  {
                    id: '3f65e920-3167-11ee-9a95-45bacf07edbc',
                    x: 1,
                    typeId: 'SupportingDetails',
                    width: 1,
                    height: 1,
                    properties: {
                      fontFamily: "'Open Sans'",
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"22uur","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      plainText: null,
                      text: null,
                    },
                    y: 2,
                    documentId: 'VG48OpKJNv',
                  },
                ],
                columns: 1,
                rows: 2,
              },
              id: '3335f235-3167-11ee-9a95-45bacf07edbc',
              meta: '{}',
              position: 3,
            },
            {
              meta: '{}',
              id: '3335f236-3167-11ee-9a95-45bacf07edbc',
              documentId: 'VG48OpKJNv',
              grid: {
                columns: 1,
                style: 'fixed',
                blocks: [
                  {
                    x: 1,
                    properties: {
                      fontFamily: "'Open Sans'",
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      plainText: null,
                      text: null,
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"dsvr6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                    },
                    height: 1,
                    typeId: 'SupportingDetails',
                    id: '40c0e540-3167-11ee-9a95-45bacf07edbc',
                    y: 1,
                    documentId: 'VG48OpKJNv',
                    width: 1,
                  },
                  {
                    width: 1,
                    y: 2,
                    properties: {
                      preferredHeight: '10em',
                      html: null,
                      positionY: 0,
                      rotation: 0,
                      positionX: 0,
                      text: null,
                    },
                    documentId: 'VG48OpKJNv',
                    height: 1,
                    id: '420f8550-3167-11ee-9a95-45bacf07edbc',
                    typeId: 'Image',
                    x: 1,
                  },
                ],
                rows: 2,
              },
              position: 4,
            },
            {
              position: 5,
              grid: {
                columns: 1,
                rows: 2,
                blocks: [
                  {
                    typeId: 'Image',
                    y: 1,
                    properties: {
                      text: null,
                      positionY: 0,
                      preferredHeight: '10em',
                      rotation: 0,
                      positionX: 0,
                      html: null,
                    },
                    id: '45a0c260-3167-11ee-9a95-45bacf07edbc',
                    documentId: 'VG48OpKJNv',
                    width: 1,
                    x: 1,
                    height: 1,
                  },
                  {
                    documentId: 'VG48OpKJNv',
                    typeId: 'SupportingDetails',
                    id: '46528630-3167-11ee-9a95-45bacf07edbc',
                    width: 1,
                    y: 2,
                    height: 1,
                    x: 1,
                    properties: {
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      fontSize: '1em',
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"17rl8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      htmlText: '<p > </p>',
                      maximumFontSize: '5em',
                    },
                  },
                ],
                style: 'fixed',
              },
              documentId: 'VG48OpKJNv',
              id: '3335f237-3167-11ee-9a95-45bacf07edbc',
              meta: '{}',
            },
            {
              grid: {
                blocks: [
                  {
                    id: '4819d8b0-3167-11ee-9a95-45bacf07edbc',
                    documentId: 'VG48OpKJNv',
                    x: 1,
                    properties: {
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"cu58o","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    },
                    height: 2,
                    y: 1,
                    typeId: 'Conclusion',
                    width: 1,
                  },
                ],
                columns: 1,
                rows: 2,
                style: 'fixed',
              },
              meta: '{}',
              id: '33361940-3167-11ee-9a95-45bacf07edbc',
              documentId: 'VG48OpKJNv',
              position: 6,
            },
            {
              grid: {
                columns: 1,
                rows: 2,
                blocks: [
                  {
                    typeId: 'Image',
                    width: 1,
                    x: 1,
                    height: 2,
                    y: 1,
                    id: '4fa1cb60-3167-11ee-9a95-45bacf07edbc',
                    properties: {
                      positionY: 0,
                      preferredHeight: '10em',
                      rotation: 0,
                      text: null,
                      positionX: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    },
                    documentId: 'VG48OpKJNv',
                  },
                ],
                style: 'fixed',
              },
              meta: '{}',
              position: 7,
              id: '33361941-3167-11ee-9a95-45bacf07edbc',
              documentId: 'VG48OpKJNv',
            },
          ],
          schemaVersion: 20230101,
          messages: [],
        },
        publishedAt: {
          seconds: 1713382922,
          nanoseconds: 878000000,
        },
        id: 'M5jY7eNJl2',
        pages: [
          {
            id: '3335f231-3167-11ee-9a95-45bacf07edbc',
            position: 0,
            documentId: 'VG48OpKJNv',
            grid: {
              columns: 1,
              style: 'fixed',
              blocks: [
                {
                  y: 1,
                  typeId: 'Title',
                  height: 1,
                  id: '3335f232-3167-11ee-9a95-45bacf07edbc',
                  documentId: 'VG48OpKJNv',
                  properties: {
                    text: null,
                    fontSize: '2em',
                    maximumFontSize: '5em',
                    fontFamily: 'Alfa',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'></div></div>",
                    fontColor: 'Black',
                  },
                  x: 1,
                  width: 1,
                },
                {
                  id: '37b7d0d0-3167-11ee-9a95-45bacf07edbc',
                  height: 1,
                  typeId: 'Image',
                  width: 1,
                  y: 2,
                  x: 1,
                  properties: {
                    preferredHeight: '10em',
                    positionY: 0,
                    text: null,
                    rotation: 0,
                    html: null,
                    positionX: 0,
                  },
                  documentId: 'VG48OpKJNv',
                },
              ],
              rows: 2,
            },
            meta: '{}',
          },
          {
            grid: {
              style: 'fixed',
              rows: 2,
              blocks: [
                {
                  x: 1,
                  id: '39421a50-3167-11ee-9a95-45bacf07edbc',
                  properties: {
                    text: null,
                    fontSize: '1em',
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"5q893","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    htmlText: '<p > </p>',
                  },
                  y: 1,
                  documentId: 'VG48OpKJNv',
                  height: 2,
                  width: 1,
                  typeId: 'MainIdea',
                },
              ],
              columns: 1,
            },
            position: 1,
            id: '3335f233-3167-11ee-9a95-45bacf07edbc',
            documentId: 'VG48OpKJNv',
            meta: '{}',
          },
          {
            meta: '{}',
            position: 2,
            grid: {
              columns: 1,
              blocks: [
                {
                  x: 1,
                  typeId: 'Image',
                  height: 1,
                  properties: {
                    html: null,
                    preferredHeight: '10em',
                    text: null,
                    positionY: 0,
                    positionX: 0,
                    rotation: 0,
                  },
                  width: 1,
                  y: 1,
                  documentId: 'VG48OpKJNv',
                  id: '3c149b40-3167-11ee-9a95-45bacf07edbc',
                },
                {
                  id: '3d6955d0-3167-11ee-9a95-45bacf07edbc',
                  documentId: 'VG48OpKJNv',
                  typeId: 'SupportingDetails',
                  y: 2,
                  width: 1,
                  x: 1,
                  properties: {
                    text: null,
                    maximumFontSize: '5em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"5p9q6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    fontColor: 'Black',
                    fontSize: '1em',
                  },
                  height: 1,
                },
              ],
              style: 'fixed',
              rows: 2,
            },
            id: '3335f234-3167-11ee-9a95-45bacf07edbc',
            documentId: 'VG48OpKJNv',
          },
          {
            position: 3,
            documentId: 'VG48OpKJNv',
            id: '3335f235-3167-11ee-9a95-45bacf07edbc',
            meta: '{}',
            grid: {
              columns: 1,
              style: 'fixed',
              blocks: [
                {
                  id: '3e9db720-3167-11ee-9a95-45bacf07edbc',
                  width: 1,
                  properties: {
                    fontColor: 'Black',
                    fontFamily: "'Open Sans'",
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    rawEditorContent: '{"blocks":[{"key":"abogo","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    text: null,
                  },
                  height: 1,
                  y: 1,
                  x: 1,
                  typeId: 'SupportingDetails',
                  documentId: 'VG48OpKJNv',
                },
                {
                  typeId: 'SupportingDetails',
                  x: 1,
                  id: '3f65e920-3167-11ee-9a95-45bacf07edbc',
                  documentId: 'VG48OpKJNv',
                  properties: {
                    maximumFontSize: '5em',
                    fontFamily: "'Open Sans'",
                    plainText: null,
                    text: null,
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"22uur","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontSize: '1em',
                  },
                  y: 2,
                  height: 1,
                  width: 1,
                },
              ],
              rows: 2,
            },
          },
          {
            documentId: 'VG48OpKJNv',
            id: '3335f236-3167-11ee-9a95-45bacf07edbc',
            meta: '{}',
            position: 4,
            grid: {
              columns: 1,
              blocks: [
                {
                  documentId: 'VG48OpKJNv',
                  y: 1,
                  id: '40c0e540-3167-11ee-9a95-45bacf07edbc',
                  x: 1,
                  properties: {
                    fontSize: '1em',
                    text: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"dsvr6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                  },
                  width: 1,
                  typeId: 'SupportingDetails',
                  height: 1,
                },
                {
                  height: 1,
                  documentId: 'VG48OpKJNv',
                  properties: {
                    preferredHeight: '10em',
                    positionX: 0,
                    text: null,
                    rotation: 0,
                    positionY: 0,
                    html: null,
                  },
                  typeId: 'Image',
                  width: 1,
                  x: 1,
                  y: 2,
                  id: '420f8550-3167-11ee-9a95-45bacf07edbc',
                },
              ],
              style: 'fixed',
              rows: 2,
            },
          },
          {
            documentId: 'VG48OpKJNv',
            id: '3335f237-3167-11ee-9a95-45bacf07edbc',
            meta: '{}',
            position: 5,
            grid: {
              columns: 1,
              style: 'fixed',
              rows: 2,
              blocks: [
                {
                  width: 1,
                  id: '45a0c260-3167-11ee-9a95-45bacf07edbc',
                  documentId: 'VG48OpKJNv',
                  typeId: 'Image',
                  x: 1,
                  y: 1,
                  properties: {
                    positionX: 0,
                    preferredHeight: '10em',
                    rotation: 0,
                    positionY: 0,
                    html: null,
                    text: null,
                  },
                  height: 1,
                },
                {
                  documentId: 'VG48OpKJNv',
                  x: 1,
                  y: 2,
                  typeId: 'SupportingDetails',
                  id: '46528630-3167-11ee-9a95-45bacf07edbc',
                  properties: {
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"17rl8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    plainText: null,
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    text: null,
                  },
                  height: 1,
                  width: 1,
                },
              ],
            },
          },
          {
            grid: {
              style: 'fixed',
              columns: 1,
              rows: 2,
              blocks: [
                {
                  properties: {
                    fontSize: '1em',
                    plainText: null,
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"cu58o","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    fontFamily: "'Open Sans'",
                    text: null,
                  },
                  height: 2,
                  typeId: 'Conclusion',
                  documentId: 'VG48OpKJNv',
                  width: 1,
                  id: '4819d8b0-3167-11ee-9a95-45bacf07edbc',
                  y: 1,
                  x: 1,
                },
              ],
            },
            documentId: 'VG48OpKJNv',
            position: 6,
            meta: '{}',
            id: '33361940-3167-11ee-9a95-45bacf07edbc',
          },
          {
            meta: '{}',
            id: '33361941-3167-11ee-9a95-45bacf07edbc',
            position: 7,
            documentId: 'VG48OpKJNv',
            grid: {
              columns: 1,
              style: 'fixed',
              rows: 2,
              blocks: [
                {
                  y: 1,
                  typeId: 'Image',
                  height: 2,
                  id: '4fa1cb60-3167-11ee-9a95-45bacf07edbc',
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    positionX: 0,
                    rotation: 0,
                    text: null,
                    preferredHeight: '10em',
                    positionY: 0,
                  },
                  x: 1,
                  width: 1,
                  documentId: 'VG48OpKJNv',
                },
              ],
            },
          },
        ],
        notes: [],
      },
      format: DocumentFormat.BOOKLET,
      templateSystem: 'blocks',
      id: 'template-booklet-description',
    },
  },
  {
    format: DocumentFormat.BOOKLET,
    name: 'Compare and Contrast',
    writingPlanId: 'compare-contrast',
    documentId: 'template-booklet-compare-contrast',
    svg: SVGs.ContrastSVG,
    color: '#D2D7D3',
    document: {
      isDemo: false,
      templateSystem: 'blocks',
      id: 'template-booklet-compare-contrast',
      isTemplate: true,
      thumbnailUrl: null,
      version: {
        id: 'gXJb8gljWD',
        content: {
          schemaVersion: 20230101,
          messages: [],
          pages: [
            {
              id: '2d014041-30b3-11ee-a0cb-3bf51732fff8',
              grid: {
                style: 'fixed',
                columns: 1,
                rows: 2,
                blocks: [
                  {
                    documentId: '5DwmXAejOY',
                    width: 1,
                    typeId: 'Title',
                    y: 1,
                    properties: {
                      fontFamily: 'Alfa',
                      fontSize: '2em',
                      rawEditorContent: '{"blocks":[{"key":"1c4ir","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      text: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      plainText: null,
                      htmlText: '<p > </p>',
                    },
                    id: '2d014042-30b3-11ee-a0cb-3bf51732fff8',
                    height: 1,
                    x: 1,
                  },
                  {
                    height: 1,
                    properties: {
                      html: null,
                      positionX: 0,
                      rotation: 0,
                      positionY: 0,
                      preferredHeight: '10em',
                      text: null,
                    },
                    x: 1,
                    y: 2,
                    documentId: '5DwmXAejOY',
                    id: '31f1bf30-30b3-11ee-a0cb-3bf51732fff8',
                    width: 1,
                    typeId: 'Image',
                  },
                ],
              },
              meta: '{}',
              documentId: '5DwmXAejOY',
              position: 0,
            },
            {
              id: '2d014043-30b3-11ee-a0cb-3bf51732fff8',
              meta: '{}',
              grid: {
                columns: 1,
                style: 'fixed',
                blocks: [
                  {
                    y: 1,
                    properties: {
                      fontSize: '1em',
                      plainText: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                      text: null,
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      fontFamily: "'Open Sans'",
                      rawEditorContent: '{"blocks":[{"key":"8tcvh","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                    },
                    height: 2,
                    id: '4780b2c0-30b3-11ee-a0cb-3bf51732fff8',
                    width: 1,
                    x: 1,
                    typeId: 'Introduction',
                    documentId: '5DwmXAejOY',
                  },
                ],
                rows: 2,
              },
              documentId: '5DwmXAejOY',
              position: 1,
            },
            {
              grid: {
                style: 'fixed',
                rows: 2,
                blocks: [
                  {
                    y: 1,
                    id: '4e6b05e0-30b3-11ee-a0cb-3bf51732fff8',
                    properties: {
                      fontSize: '1em',
                      rotation: 0,
                      positionY: 0,
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      positionX: 0,
                      htmlText: '<p > </p>',
                      preferredHeight: '10em',
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"5rc5u","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                    },
                    height: 2,
                    x: 1,
                    typeId: 'Compare',
                    documentId: '5DwmXAejOY',
                    width: 1,
                  },
                ],
                columns: 1,
              },
              meta: '{}',
              documentId: '5DwmXAejOY',
              position: 2,
              id: '2d014045-30b3-11ee-a0cb-3bf51732fff8',
            },
            {
              grid: {
                style: 'fixed',
                blocks: [
                  {
                    documentId: '5DwmXAejOY',
                    y: 1,
                    height: 1,
                    typeId: 'Compare',
                    properties: {
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                      maximumFontSize: '5em',
                      fontFamily: "'Open Sans'",
                      rawEditorContent: '{"blocks":[{"key":"fhbqh","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      plainText: null,
                      text: null,
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    },
                    x: 1,
                    width: 1,
                    id: '4a1b5ad0-30b3-11ee-a0cb-3bf51732fff8',
                  },
                  {
                    width: 1,
                    y: 2,
                    documentId: '5DwmXAejOY',
                    properties: {
                      positionY: 0,
                      html: null,
                      positionX: 0,
                      preferredHeight: '10em',
                      rotation: 0,
                      text: null,
                    },
                    id: '66cc2c40-30b3-11ee-a0cb-3bf51732fff8',
                    height: 1,
                    x: 1,
                    typeId: 'Image',
                  },
                ],
                columns: 1,
                rows: 2,
              },
              meta: '{}',
              id: '2d014044-30b3-11ee-a0cb-3bf51732fff8',
              documentId: '5DwmXAejOY',
              position: 3,
            },
            {
              id: '2d014046-30b3-11ee-a0cb-3bf51732fff8',
              documentId: '5DwmXAejOY',
              grid: {
                style: 'fixed',
                columns: 1,
                blocks: [
                  {
                    width: 1,
                    height: 2,
                    typeId: 'Contrast',
                    y: 1,
                    properties: {
                      plainText: null,
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      text: null,
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"7npj2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                    },
                    id: '2d557300-354e-11ee-85d9-f3941bab7f6d',
                    documentId: '5DwmXAejOY',
                    x: 1,
                  },
                ],
                rows: 2,
              },
              position: 4,
              meta: '{}',
            },
            {
              documentId: '5DwmXAejOY',
              position: 5,
              grid: {
                columns: 1,
                style: 'fixed',
                rows: 2,
                blocks: [
                  {
                    documentId: '5DwmXAejOY',
                    typeId: 'Contrast',
                    height: 1,
                    x: 1,
                    properties: {
                      maximumFontSize: '5em',
                      rawEditorContent: '{"blocks":[{"key":"5tbks","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '1em',
                      plainText: null,
                      fontColor: 'Black',
                      text: null,
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    },
                    width: 1,
                    y: 1,
                    id: '52d90e60-30b3-11ee-a0cb-3bf51732fff8',
                  },
                  {
                    id: '63086d80-30b3-11ee-a0cb-3bf51732fff8',
                    y: 2,
                    documentId: '5DwmXAejOY',
                    x: 1,
                    typeId: 'Image',
                    properties: {
                      preferredHeight: '10em',
                      positionX: 0,
                      text: null,
                      html: null,
                      rotation: 0,
                      positionY: 0,
                    },
                    width: 1,
                    height: 1,
                  },
                ],
              },
              id: '2d014047-30b3-11ee-a0cb-3bf51732fff8',
              meta: '{}',
            },
            {
              meta: '{}',
              position: 6,
              id: '2d014049-30b3-11ee-a0cb-3bf51732fff8',
              documentId: '5DwmXAejOY',
              grid: {
                rows: 2,
                style: 'fixed',
                columns: 1,
                blocks: [
                  {
                    width: 1,
                    properties: {
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      plainText: null,
                      rawEditorContent: '{"blocks":[{"key":"7n21f","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      fontSize: '1em',
                      text: null,
                    },
                    id: '573ae1e0-30b3-11ee-a0cb-3bf51732fff8',
                    typeId: 'Conclusion',
                    y: 1,
                    height: 2,
                    x: 1,
                    documentId: '5DwmXAejOY',
                  },
                ],
              },
            },
            {
              id: '2d014048-30b3-11ee-a0cb-3bf51732fff8',
              meta: '{}',
              documentId: '5DwmXAejOY',
              grid: {
                style: 'fixed',
                rows: 2,
                columns: 1,
                blocks: [
                  {
                    typeId: 'Image',
                    id: '5508bfa0-30b3-11ee-a0cb-3bf51732fff8',
                    width: 1,
                    height: 2,
                    y: 1,
                    documentId: '5DwmXAejOY',
                    properties: {
                      preferredHeight: '10em',
                      text: null,
                      positionY: 0,
                      positionX: 0,
                      rotation: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    },
                    x: 1,
                  },
                ],
              },
              position: 7,
            },
          ],
        },
        publishedAt: {
          seconds: 1713382922,
          nanoseconds: 902000000,
        },
        pages: [
          {
            position: 0,
            id: '2d014041-30b3-11ee-a0cb-3bf51732fff8',
            meta: '{}',
            documentId: '5DwmXAejOY',
            grid: {
              style: 'fixed',
              rows: 2,
              columns: 1,
              blocks: [
                {
                  documentId: '5DwmXAejOY',
                  id: '2d014042-30b3-11ee-a0cb-3bf51732fff8',
                  y: 1,
                  typeId: 'Title',
                  width: 1,
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                    fontFamily: 'Alfa',
                    fontSize: '2em',
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    rawEditorContent: '{"blocks":[{"key":"1c4ir","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    plainText: null,
                    text: null,
                  },
                  height: 1,
                  x: 1,
                },
                {
                  width: 1,
                  y: 2,
                  id: '31f1bf30-30b3-11ee-a0cb-3bf51732fff8',
                  properties: {
                    text: null,
                    positionX: 0,
                    preferredHeight: '10em',
                    positionY: 0,
                    rotation: 0,
                    html: null,
                  },
                  typeId: 'Image',
                  height: 1,
                  documentId: '5DwmXAejOY',
                  x: 1,
                },
              ],
            },
          },
          {
            grid: {
              style: 'fixed',
              blocks: [
                {
                  typeId: 'Introduction',
                  height: 2,
                  properties: {
                    maximumFontSize: '5em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"8tcvh","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                    htmlText: '<p > </p>',
                    fontColor: 'Black',
                    text: null,
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                  },
                  width: 1,
                  id: '4780b2c0-30b3-11ee-a0cb-3bf51732fff8',
                  y: 1,
                  x: 1,
                  documentId: '5DwmXAejOY',
                },
              ],
              columns: 1,
              rows: 2,
            },
            documentId: '5DwmXAejOY',
            meta: '{}',
            id: '2d014043-30b3-11ee-a0cb-3bf51732fff8',
            position: 1,
          },
          {
            grid: {
              columns: 1,
              rows: 2,
              blocks: [
                {
                  x: 1,
                  documentId: '5DwmXAejOY',
                  properties: {
                    rotation: 0,
                    rawEditorContent: '{"blocks":[{"key":"5rc5u","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    text: null,
                    positionX: 0,
                    positionY: 0,
                    preferredHeight: '10em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    maximumFontSize: '5em',
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                    fontColor: 'Black',
                    plainText: null,
                    fontFamily: "'Open Sans'",
                  },
                  width: 1,
                  typeId: 'Compare',
                  y: 1,
                  id: '4e6b05e0-30b3-11ee-a0cb-3bf51732fff8',
                  height: 2,
                },
              ],
              style: 'fixed',
            },
            position: 2,
            meta: '{}',
            documentId: '5DwmXAejOY',
            id: '2d014045-30b3-11ee-a0cb-3bf51732fff8',
          },
          {
            documentId: '5DwmXAejOY',
            id: '2d014044-30b3-11ee-a0cb-3bf51732fff8',
            meta: '{}',
            grid: {
              columns: 1,
              blocks: [
                {
                  properties: {
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"fhbqh","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    text: null,
                    maximumFontSize: '5em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                  documentId: '5DwmXAejOY',
                  height: 1,
                  typeId: 'Compare',
                  id: '4a1b5ad0-30b3-11ee-a0cb-3bf51732fff8',
                  x: 1,
                  y: 1,
                  width: 1,
                },
                {
                  properties: {
                    positionY: 0,
                    rotation: 0,
                    text: null,
                    positionX: 0,
                    preferredHeight: '10em',
                    html: null,
                  },
                  x: 1,
                  height: 1,
                  id: '66cc2c40-30b3-11ee-a0cb-3bf51732fff8',
                  typeId: 'Image',
                  width: 1,
                  documentId: '5DwmXAejOY',
                  y: 2,
                },
              ],
              style: 'fixed',
              rows: 2,
            },
            position: 3,
          },
          {
            grid: {
              rows: 2,
              columns: 1,
              blocks: [
                {
                  typeId: 'Contrast',
                  height: 2,
                  id: '2d557300-354e-11ee-85d9-f3941bab7f6d',
                  x: 1,
                  documentId: '5DwmXAejOY',
                  y: 1,
                  width: 1,
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    text: null,
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"7npj2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    plainText: null,
                    fontColor: 'Black',
                    maximumFontSize: '5em',
                  },
                },
              ],
              style: 'fixed',
            },
            documentId: '5DwmXAejOY',
            position: 4,
            id: '2d014046-30b3-11ee-a0cb-3bf51732fff8',
            meta: '{}',
          },
          {
            meta: '{}',
            position: 5,
            documentId: '5DwmXAejOY',
            id: '2d014047-30b3-11ee-a0cb-3bf51732fff8',
            grid: {
              rows: 2,
              blocks: [
                {
                  documentId: '5DwmXAejOY',
                  typeId: 'Contrast',
                  properties: {
                    text: null,
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"5tbks","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                  },
                  width: 1,
                  y: 1,
                  x: 1,
                  height: 1,
                  id: '52d90e60-30b3-11ee-a0cb-3bf51732fff8',
                },
                {
                  width: 1,
                  id: '63086d80-30b3-11ee-a0cb-3bf51732fff8',
                  height: 1,
                  typeId: 'Image',
                  documentId: '5DwmXAejOY',
                  properties: {
                    preferredHeight: '10em',
                    text: null,
                    html: null,
                    rotation: 0,
                    positionX: 0,
                    positionY: 0,
                  },
                  y: 2,
                  x: 1,
                },
              ],
              style: 'fixed',
              columns: 1,
            },
          },
          {
            grid: {
              style: 'fixed',
              blocks: [
                {
                  properties: {
                    fontSize: '1em',
                    maximumFontSize: '5em',
                    rawEditorContent: '{"blocks":[{"key":"7n21f","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    text: null,
                  },
                  typeId: 'Conclusion',
                  id: '573ae1e0-30b3-11ee-a0cb-3bf51732fff8',
                  height: 2,
                  x: 1,
                  documentId: '5DwmXAejOY',
                  width: 1,
                  y: 1,
                },
              ],
              columns: 1,
              rows: 2,
            },
            position: 6,
            documentId: '5DwmXAejOY',
            id: '2d014049-30b3-11ee-a0cb-3bf51732fff8',
            meta: '{}',
          },
          {
            position: 7,
            meta: '{}',
            id: '2d014048-30b3-11ee-a0cb-3bf51732fff8',
            documentId: '5DwmXAejOY',
            grid: {
              rows: 2,
              style: 'fixed',
              columns: 1,
              blocks: [
                {
                  properties: {
                    text: null,
                    preferredHeight: '10em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    rotation: 0,
                    positionY: 0,
                    positionX: 0,
                  },
                  id: '5508bfa0-30b3-11ee-a0cb-3bf51732fff8',
                  width: 1,
                  documentId: '5DwmXAejOY',
                  x: 1,
                  height: 2,
                  y: 1,
                  typeId: 'Image',
                },
              ],
            },
          },
        ],
        status: 'PUBLISHED',
        notes: [],
        documentId: 'gXJb8gljWD',
        createdAt: {
          seconds: 1713382922,
          nanoseconds: 0,
        },
      },
      updatedAt: '2024-04-17T19:42:03.030Z',
      user: {
        id: '7WwWWeqwqv',
        surname: 'Sobel',
        username: null,
        email: 'kieran+teacher@joinpressto.com',
        name: 'Kieran',
        role: 'teacher',
      },
      createdAt: '2024-04-17T19:42:02.000Z',
      meta: {
        writingPlan: 'compare-contrast',
        importantWords: [],
        hasImportantWords: true,
        hasWritingBuddy: true,
        hasSignalWords: true,
        hasWordCount: true,
      },
      name: 'Copy of Compare and Contrast - Zine Template',
      submission: null,
      isPublic: true,
      format: DocumentFormat.BOOKLET,
    },
  },
  {
    format: DocumentFormat.BOOKLET,
    name: 'Problem and Solution',
    writingPlanId: 'problem-solution',
    documentId: 'template-booklet-problem-solution',
    svg: SVGs.SolutionPuzzleSVG,
    color: '#52509A',
    document: {
      id: 'template-booklet-problem-solution',
      createdAt: '2024-04-17T19:42:02.000Z',
      thumbnailUrl: null,
      isPublic: true,
      version: {
        publishedAt: {
          seconds: 1713382922,
          nanoseconds: 980000000,
        },
        status: 'PUBLISHED',
        createdAt: {
          seconds: 1713382922,
          nanoseconds: 0,
        },
        content: {
          messages: [],
          pages: [
            {
              position: 0,
              meta: '{}',
              documentId: 'Z2jnMRp498',
              id: 'ce2aca00-3166-11ee-9a95-45bacf07edbc',
              grid: {
                style: 'fixed',
                columns: 1,
                blocks: [
                  {
                    height: 1,
                    documentId: 'Z2jnMRp498',
                    width: 1,
                    typeId: 'Title',
                    properties: {
                      fontColor: 'Black',
                      maximumFontSize: '5em',
                      plainText: null,
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"13ff8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      fontSize: '2em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                      fontFamily: 'Alfa',
                    },
                    id: 'ce2aca01-3166-11ee-9a95-45bacf07edbc',
                    x: 1,
                    y: 1,
                  },
                  {
                    properties: {
                      text: null,
                      positionX: 0,
                      positionY: 0,
                      rotation: 0,
                      preferredHeight: '10em',
                      html: null,
                    },
                    width: 1,
                    height: 1,
                    x: 1,
                    typeId: 'Image',
                    documentId: 'Z2jnMRp498',
                    id: '2b14a5b0-3167-11ee-9a95-45bacf07edbc',
                    y: 2,
                  },
                ],
                rows: 2,
              },
            },
            {
              meta: '{}',
              documentId: 'Z2jnMRp498',
              grid: {
                rows: 2,
                style: 'fixed',
                columns: 1,
                blocks: [
                  {
                    id: 'eb3f8d60-3166-11ee-9a95-45bacf07edbc',
                    x: 1,
                    typeId: 'Introduction',
                    properties: {
                      htmlText: '<p > </p>',
                      text: null,
                      fontColor: 'Black',
                      fontSize: '1em',
                      plainText: null,
                      rawEditorContent: '{"blocks":[{"key":"3pvg3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    },
                    documentId: 'Z2jnMRp498',
                    width: 1,
                    height: 2,
                    y: 1,
                  },
                ],
              },
              id: 'ce2aca02-3166-11ee-9a95-45bacf07edbc',
              position: 1,
            },
            {
              meta: '{}',
              id: 'ce2aca03-3166-11ee-9a95-45bacf07edbc',
              documentId: 'Z2jnMRp498',
              grid: {
                blocks: [
                  {
                    width: 1,
                    properties: {
                      plainText: null,
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"afuu6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                    },
                    y: 1,
                    x: 1,
                    id: 'ef785510-3166-11ee-9a95-45bacf07edbc',
                    height: 2,
                    documentId: 'Z2jnMRp498',
                    typeId: 'Problem',
                  },
                ],
                style: 'fixed',
                columns: 1,
                rows: 2,
              },
              position: 2,
            },
            {
              documentId: 'Z2jnMRp498',
              meta: '{}',
              id: 'ce2aca04-3166-11ee-9a95-45bacf07edbc',
              position: 3,
              grid: {
                columns: 1,
                blocks: [
                  {
                    x: 1,
                    typeId: 'Problem',
                    y: 1,
                    documentId: 'Z2jnMRp498',
                    properties: {
                      fontSize: '1em',
                      plainText: null,
                      htmlText: '<p > </p>',
                      fontFamily: "'Open Sans'",
                      rawEditorContent: '{"blocks":[{"key":"25ek","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                    },
                    height: 1,
                    id: '1b9f20b0-3167-11ee-9a95-45bacf07edbc',
                    width: 1,
                  },
                  {
                    height: 1,
                    id: '1c90d3b0-3167-11ee-9a95-45bacf07edbc',
                    width: 1,
                    typeId: 'Image',
                    documentId: 'Z2jnMRp498',
                    y: 2,
                    properties: {
                      htmlText: '<p > </p>',
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      maximumFontSize: '5em',
                      positionX: 0,
                      text: null,
                      positionY: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      plainText: null,
                      preferredHeight: '10em',
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"7uh81","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      rotation: 0,
                    },
                    x: 1,
                  },
                ],
                style: 'fixed',
                rows: 2,
              },
            },
            {
              meta: '{}',
              id: 'ce2aca05-3166-11ee-9a95-45bacf07edbc',
              position: 4,
              grid: {
                blocks: [
                  {
                    id: 'fb393680-3166-11ee-9a95-45bacf07edbc',
                    typeId: 'Solution',
                    y: 1,
                    width: 1,
                    height: 2,
                    properties: {
                      text: null,
                      fontFamily: "'Open Sans'",
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                      plainText: null,
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"fp45f","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    },
                    x: 1,
                    documentId: 'Z2jnMRp498',
                  },
                ],
                rows: 2,
                columns: 1,
                style: 'fixed',
              },
              documentId: 'Z2jnMRp498',
            },
            {
              meta: '{}',
              documentId: 'Z2jnMRp498',
              position: 5,
              grid: {
                rows: 2,
                columns: 1,
                blocks: [
                  {
                    width: 1,
                    properties: {
                      positionY: 0,
                      rotation: 0,
                      positionX: 0,
                      text: null,
                      preferredHeight: '10em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    },
                    y: 1,
                    x: 1,
                    typeId: 'Image',
                    height: 1,
                    id: '05cb8300-3167-11ee-9a95-45bacf07edbc',
                    documentId: 'Z2jnMRp498',
                  },
                  {
                    documentId: 'Z2jnMRp498',
                    id: 'fd3af9f0-3166-11ee-9a95-45bacf07edbc',
                    height: 1,
                    x: 1,
                    width: 1,
                    typeId: 'Solution',
                    properties: {
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"a0dil","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                      plainText: null,
                      fontFamily: "'Open Sans'",
                      maximumFontSize: '5em',
                      text: null,
                    },
                    y: 2,
                  },
                ],
                style: 'fixed',
              },
              id: 'ce2aca06-3166-11ee-9a95-45bacf07edbc',
            },
            {
              grid: {
                style: 'fixed',
                rows: 2,
                blocks: [
                  {
                    typeId: 'Solution',
                    width: 1,
                    height: 1,
                    x: 1,
                    y: 1,
                    documentId: 'Z2jnMRp498',
                    id: '0019fe00-3167-11ee-9a95-45bacf07edbc',
                    properties: {
                      text: null,
                      htmlText: '<p > </p>',
                      plainText: null,
                      fontColor: 'Black',
                      fontFamily: "'Open Sans'",
                      fontSize: '1em',
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      rawEditorContent: '{"blocks":[{"key":"5o3bb","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    },
                  },
                  {
                    y: 2,
                    documentId: 'Z2jnMRp498',
                    typeId: 'Image',
                    width: 1,
                    properties: {
                      rotation: 0,
                      positionY: 0,
                      preferredHeight: '10em',
                      html: null,
                      positionX: 0,
                      text: null,
                    },
                    height: 1,
                    x: 1,
                    id: '0131d6a0-3167-11ee-9a95-45bacf07edbc',
                  },
                ],
                columns: 1,
              },
              meta: '{}',
              id: 'ce2aca07-3166-11ee-9a95-45bacf07edbc',
              position: 6,
              documentId: 'Z2jnMRp498',
            },
            {
              id: 'ce2aca08-3166-11ee-9a95-45bacf07edbc',
              position: 7,
              grid: {
                blocks: [
                  {
                    height: 2,
                    x: 1,
                    id: '12b88860-3167-11ee-9a95-45bacf07edbc',
                    properties: {
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"9gfsm","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                      plainText: null,
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                      text: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                    },
                    documentId: 'Z2jnMRp498',
                    y: 1,
                    width: 1,
                    typeId: 'Conclusion',
                  },
                ],
                style: 'fixed',
                rows: 2,
                columns: 1,
              },
              meta: '{}',
              documentId: 'Z2jnMRp498',
            },
          ],
          schemaVersion: 20230101,
        },
        documentId: '9RjkmyQJry',
        pages: [
          {
            id: 'ce2aca00-3166-11ee-9a95-45bacf07edbc',
            documentId: 'Z2jnMRp498',
            position: 0,
            meta: '{}',
            grid: {
              rows: 2,
              columns: 1,
              style: 'fixed',
              blocks: [
                {
                  documentId: 'Z2jnMRp498',
                  properties: {
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    fontFamily: 'Alfa',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                    htmlText: '<p > </p>',
                    plainText: null,
                    fontSize: '2em',
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"13ff8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  x: 1,
                  typeId: 'Title',
                  y: 1,
                  id: 'ce2aca01-3166-11ee-9a95-45bacf07edbc',
                  height: 1,
                  width: 1,
                },
                {
                  x: 1,
                  typeId: 'Image',
                  properties: {
                    preferredHeight: '10em',
                    positionX: 0,
                    positionY: 0,
                    rotation: 0,
                    html: null,
                    text: null,
                  },
                  documentId: 'Z2jnMRp498',
                  height: 1,
                  y: 2,
                  id: '2b14a5b0-3167-11ee-9a95-45bacf07edbc',
                  width: 1,
                },
              ],
            },
          },
          {
            position: 1,
            documentId: 'Z2jnMRp498',
            grid: {
              rows: 2,
              style: 'fixed',
              columns: 1,
              blocks: [
                {
                  x: 1,
                  documentId: 'Z2jnMRp498',
                  typeId: 'Introduction',
                  y: 1,
                  id: 'eb3f8d60-3166-11ee-9a95-45bacf07edbc',
                  width: 1,
                  height: 2,
                  properties: {
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"3pvg3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontSize: '1em',
                    text: null,
                    fontColor: 'Black',
                  },
                },
              ],
            },
            meta: '{}',
            id: 'ce2aca02-3166-11ee-9a95-45bacf07edbc',
          },
          {
            meta: '{}',
            id: 'ce2aca03-3166-11ee-9a95-45bacf07edbc',
            position: 2,
            documentId: 'Z2jnMRp498',
            grid: {
              style: 'fixed',
              rows: 2,
              blocks: [
                {
                  id: 'ef785510-3166-11ee-9a95-45bacf07edbc',
                  height: 2,
                  documentId: 'Z2jnMRp498',
                  typeId: 'Problem',
                  properties: {
                    fontColor: 'Black',
                    fontSize: '1em',
                    text: null,
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"afuu6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontFamily: "'Open Sans'",
                    maximumFontSize: '5em',
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                  width: 1,
                  x: 1,
                  y: 1,
                },
              ],
              columns: 1,
            },
          },
          {
            id: 'ce2aca04-3166-11ee-9a95-45bacf07edbc',
            meta: '{}',
            grid: {
              style: 'fixed',
              columns: 1,
              rows: 2,
              blocks: [
                {
                  height: 1,
                  x: 1,
                  properties: {
                    plainText: null,
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    text: null,
                    maximumFontSize: '5em',
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"25ek","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  documentId: 'Z2jnMRp498',
                  typeId: 'Problem',
                  y: 1,
                  id: '1b9f20b0-3167-11ee-9a95-45bacf07edbc',
                  width: 1,
                },
                {
                  width: 1,
                  height: 1,
                  x: 1,
                  y: 2,
                  typeId: 'Image',
                  properties: {
                    plainText: null,
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    htmlText: '<p > </p>',
                    positionX: 0,
                    text: null,
                    rotation: 0,
                    maximumFontSize: '5em',
                    preferredHeight: '10em',
                    fontFamily: "'Open Sans'",
                    positionY: 0,
                    rawEditorContent: '{"blocks":[{"key":"7uh81","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                  },
                  id: '1c90d3b0-3167-11ee-9a95-45bacf07edbc',
                  documentId: 'Z2jnMRp498',
                },
              ],
            },
            position: 3,
            documentId: 'Z2jnMRp498',
          },
          {
            meta: '{}',
            documentId: 'Z2jnMRp498',
            grid: {
              columns: 1,
              rows: 2,
              blocks: [
                {
                  documentId: 'Z2jnMRp498',
                  properties: {
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"fp45f","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    fontFamily: "'Open Sans'",
                    text: null,
                    maximumFontSize: '5em',
                    plainText: null,
                  },
                  typeId: 'Solution',
                  y: 1,
                  id: 'fb393680-3166-11ee-9a95-45bacf07edbc',
                  x: 1,
                  height: 2,
                  width: 1,
                },
              ],
              style: 'fixed',
            },
            position: 4,
            id: 'ce2aca05-3166-11ee-9a95-45bacf07edbc',
          },
          {
            position: 5,
            meta: '{}',
            id: 'ce2aca06-3166-11ee-9a95-45bacf07edbc',
            documentId: 'Z2jnMRp498',
            grid: {
              style: 'fixed',
              columns: 1,
              blocks: [
                {
                  height: 1,
                  documentId: 'Z2jnMRp498',
                  x: 1,
                  width: 1,
                  id: '05cb8300-3167-11ee-9a95-45bacf07edbc',
                  typeId: 'Image',
                  y: 1,
                  properties: {
                    positionY: 0,
                    preferredHeight: '10em',
                    rotation: 0,
                    text: null,
                    positionX: 0,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                  },
                },
                {
                  id: 'fd3af9f0-3166-11ee-9a95-45bacf07edbc',
                  x: 1,
                  height: 1,
                  documentId: 'Z2jnMRp498',
                  typeId: 'Solution',
                  properties: {
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"a0dil","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    plainText: null,
                    text: null,
                  },
                  y: 2,
                  width: 1,
                },
              ],
              rows: 2,
            },
          },
          {
            position: 6,
            grid: {
              columns: 1,
              blocks: [
                {
                  typeId: 'Solution',
                  documentId: 'Z2jnMRp498',
                  height: 1,
                  id: '0019fe00-3167-11ee-9a95-45bacf07edbc',
                  x: 1,
                  y: 1,
                  width: 1,
                  properties: {
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"5o3bb","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    text: null,
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                  },
                },
                {
                  typeId: 'Image',
                  id: '0131d6a0-3167-11ee-9a95-45bacf07edbc',
                  x: 1,
                  width: 1,
                  y: 2,
                  properties: {
                    html: null,
                    positionY: 0,
                    text: null,
                    rotation: 0,
                    preferredHeight: '10em',
                    positionX: 0,
                  },
                  documentId: 'Z2jnMRp498',
                  height: 1,
                },
              ],
              style: 'fixed',
              rows: 2,
            },
            meta: '{}',
            id: 'ce2aca07-3166-11ee-9a95-45bacf07edbc',
            documentId: 'Z2jnMRp498',
          },
          {
            position: 7,
            documentId: 'Z2jnMRp498',
            grid: {
              columns: 1,
              blocks: [
                {
                  y: 1,
                  width: 1,
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    text: null,
                    htmlText: '<p > </p>',
                    plainText: null,
                    maximumFontSize: '5em',
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"9gfsm","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  x: 1,
                  documentId: 'Z2jnMRp498',
                  id: '12b88860-3167-11ee-9a95-45bacf07edbc',
                  height: 2,
                  typeId: 'Conclusion',
                },
              ],
              rows: 2,
              style: 'fixed',
            },
            meta: '{}',
            id: 'ce2aca08-3166-11ee-9a95-45bacf07edbc',
          },
        ],
        notes: [],
        id: '9RjkmyQJry',
      },
      name: 'Copy of Problem and Solution - Zine Template',
      meta: {
        hasWritingBuddy: true,
        hasWordCount: true,
        hasSignalWords: true,
        importantWords: [],
        hasImportantWords: true,
        writingPlan: 'problem-solution',
      },
      isTemplate: true,
      updatedAt: '2024-04-17T19:42:03.116Z',
      submission: null,
      templateSystem: 'blocks',
      isDemo: false,
      format: DocumentFormat.BOOKLET,
      user: {
        username: null,
        name: 'Kieran',
        surname: 'Sobel',
        email: 'kieran+teacher@joinpressto.com',
        id: '7WwWWeqwqv',
        role: 'teacher',
      },
    },
  },
  {
    format: DocumentFormat.BOOKLET,
    name: 'Cause and Effect',
    writingPlanId: 'cause-effect',
    documentId: 'template-booklet-cause-effect',
    svg: SVGs.EffectSVG,
    color: '#8DC9C3',
    document: {
      createdAt: '2024-04-17T19:42:03.000Z',
      isTemplate: true,
      submission: null,
      templateSystem: 'blocks',
      updatedAt: '2024-04-17T19:42:03.230Z',
      id: 'template-booklet-cause-effect',
      meta: {
        hasWritingBuddy: true,
        hasWordCount: true,
        hasImportantWords: true,
        importantWords: [],
        writingPlan: 'cause-effect',
        hasSignalWords: true,
      },
      isPublic: true,
      version: {
        status: 'PUBLISHED',
        createdAt: {
          seconds: 1713382923,
          nanoseconds: 0,
        },
        notes: [],
        id: 'Z2jnmEK498',
        documentId: 'Z2jnmEK498',
        pages: [
          {
            grid: {
              columns: 1,
              blocks: [
                {
                  id: 'c8d70752-3164-11ee-85a3-71a99429ba2d',
                  properties: {
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'></div></div>",
                    fontFamily: 'Alfa',
                    maximumFontSize: '5em',
                    text: null,
                    fontSize: '2em',
                  },
                  typeId: 'Title',
                  y: 1,
                  x: 1,
                  height: 1,
                  width: 1,
                  documentId: '9RjkzRW4ry',
                },
                {
                  width: 1,
                  height: 1,
                  y: 2,
                  id: 'd742bd70-3164-11ee-85a3-71a99429ba2d',
                  properties: {
                    positionX: 0,
                    html: null,
                    text: null,
                    preferredHeight: '10em',
                    rotation: 0,
                    positionY: 0,
                  },
                  documentId: '9RjkzRW4ry',
                  x: 1,
                  typeId: 'Image',
                },
              ],
              rows: 2,
              style: 'fixed',
            },
            meta: '{}',
            id: 'c8d70751-3164-11ee-85a3-71a99429ba2d',
            documentId: '9RjkzRW4ry',
            position: 0,
          },
          {
            position: 1,
            id: 'c8d70753-3164-11ee-85a3-71a99429ba2d',
            grid: {
              blocks: [
                {
                  height: 2,
                  id: 'e15886f0-3164-11ee-85a3-71a99429ba2d',
                  typeId: 'Introduction',
                  y: 1,
                  width: 1,
                  x: 1,
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    fontSize: '1em',
                    text: null,
                    htmlText: '<p > </p>',
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"2i41j","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  documentId: '9RjkzRW4ry',
                },
              ],
              columns: 1,
              rows: 2,
              style: 'fixed',
            },
            documentId: '9RjkzRW4ry',
            meta: '{}',
          },
          {
            documentId: '9RjkzRW4ry',
            id: 'c8d70754-3164-11ee-85a3-71a99429ba2d',
            position: 2,
            meta: '{}',
            grid: {
              columns: 1,
              rows: 2,
              blocks: [
                {
                  documentId: '9RjkzRW4ry',
                  height: 2,
                  y: 1,
                  typeId: 'Cause',
                  x: 1,
                  width: 1,
                  properties: {
                    maximumFontSize: '5em',
                    fontSize: '1em',
                    plainText: null,
                    rawEditorContent: '{"blocks":[{"key":"2o7bl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    fontFamily: "'Open Sans'",
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    text: null,
                  },
                  id: 'e476ddf0-3164-11ee-85a3-71a99429ba2d',
                },
              ],
              style: 'fixed',
            },
          },
          {
            id: 'c8d70755-3164-11ee-85a3-71a99429ba2d',
            position: 3,
            meta: '{}',
            documentId: '9RjkzRW4ry',
            grid: {
              style: 'fixed',
              blocks: [
                {
                  x: 1,
                  width: 1,
                  properties: {
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"8kc9a","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    htmlText: '<p > </p>',
                    fontFamily: "'Open Sans'",
                    fontSize: '1em',
                  },
                  documentId: '9RjkzRW4ry',
                  typeId: 'Cause',
                  id: 'eb2e3a30-3164-11ee-85a3-71a99429ba2d',
                  y: 1,
                  height: 1,
                },
                {
                  properties: {
                    text: null,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    rotation: 0,
                    preferredHeight: '10em',
                    positionX: 0,
                    positionY: 0,
                  },
                  width: 1,
                  typeId: 'Image',
                  documentId: '9RjkzRW4ry',
                  y: 2,
                  id: 'e792eb00-3164-11ee-85a3-71a99429ba2d',
                  x: 1,
                  height: 1,
                },
              ],
              rows: 2,
              columns: 1,
            },
          },
          {
            position: 4,
            meta: '{}',
            grid: {
              columns: 1,
              style: 'fixed',
              rows: 2,
              blocks: [
                {
                  x: 1,
                  height: 2,
                  documentId: '9RjkzRW4ry',
                  id: 'eddf7780-3164-11ee-85a3-71a99429ba2d',
                  width: 1,
                  properties: {
                    maximumFontSize: '5em',
                    text: null,
                    plainText: null,
                    fontSize: '1em',
                    fontFamily: "'Open Sans'",
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"74m","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  y: 1,
                  typeId: 'Effect',
                },
              ],
            },
            documentId: '9RjkzRW4ry',
            id: 'c8d70756-3164-11ee-85a3-71a99429ba2d',
          },
          {
            documentId: '9RjkzRW4ry',
            id: 'c8d70757-3164-11ee-85a3-71a99429ba2d',
            grid: {
              rows: 2,
              blocks: [
                {
                  y: 1,
                  typeId: 'Effect',
                  width: 1,
                  id: 'f095bde0-3164-11ee-85a3-71a99429ba2d',
                  height: 1,
                  documentId: '9RjkzRW4ry',
                  x: 1,
                  properties: {
                    fontColor: 'Black',
                    maximumFontSize: '5em',
                    fontFamily: "'Open Sans'",
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"dlenp","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontSize: '1em',
                    htmlText: '<p > </p>',
                    text: null,
                    plainText: null,
                  },
                },
                {
                  id: 'f19e5440-3164-11ee-85a3-71a99429ba2d',
                  width: 1,
                  height: 1,
                  properties: {
                    fontFamily: "'Open Sans'",
                    positionY: 0,
                    rotation: 0,
                    plainText: null,
                    fontSize: '1em',
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    preferredHeight: '10em',
                    positionX: 0,
                    rawEditorContent: '{"blocks":[{"key":"3igpr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    text: null,
                  },
                  y: 2,
                  typeId: 'Image',
                  x: 1,
                  documentId: '9RjkzRW4ry',
                },
              ],
              columns: 1,
              style: 'fixed',
            },
            meta: '{}',
            position: 5,
          },
          {
            id: 'c8d70758-3164-11ee-85a3-71a99429ba2d',
            meta: '{}',
            grid: {
              rows: 2,
              blocks: [
                {
                  y: 1,
                  typeId: 'Image',
                  properties: {
                    rotation: 0,
                    preferredHeight: '10em',
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    rawEditorContent: '{"blocks":[{"key":"eduta","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                    positionY: 0,
                    positionX: 0,
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                    text: null,
                    maximumFontSize: '5em',
                    fontFamily: "'Open Sans'",
                  },
                  documentId: '9RjkzRW4ry',
                  x: 1,
                  width: 1,
                  height: 1,
                  id: 'f3816c20-3164-11ee-85a3-71a99429ba2d',
                },
                {
                  id: 'a45fd570-3176-11ee-abfd-5f9de2162030',
                  typeId: 'Effect',
                  documentId: '9RjkzRW4ry',
                  x: 1,
                  y: 2,
                  properties: {
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"2a7sg","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  width: 1,
                  height: 1,
                },
              ],
              style: 'fixed',
              columns: 1,
            },
            documentId: '9RjkzRW4ry',
            position: 6,
          },
          {
            grid: {
              rows: 2,
              columns: 1,
              style: 'fixed',
              blocks: [
                {
                  typeId: 'Conclusion',
                  y: 1,
                  height: 2,
                  documentId: '9RjkzRW4ry',
                  properties: {
                    rawEditorContent: '{"blocks":[{"key":"di7v9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    fontColor: 'Black',
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    maximumFontSize: '5em',
                    text: null,
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    htmlText: '<p > </p>',
                  },
                  id: 'fc9b7080-3164-11ee-85a3-71a99429ba2d',
                  width: 1,
                  x: 1,
                },
              ],
            },
            meta: '{}',
            documentId: '9RjkzRW4ry',
            position: 7,
            id: 'c8d70759-3164-11ee-85a3-71a99429ba2d',
          },
        ],
        publishedAt: {
          seconds: 1713382923,
          nanoseconds: 163000000,
        },
        content: {
          messages: [],
          schemaVersion: 20230101,
          pages: [
            {
              id: 'c8d70751-3164-11ee-85a3-71a99429ba2d',
              grid: {
                columns: 1,
                blocks: [
                  {
                    properties: {
                      text: null,
                      fontSize: '2em',
                      fontColor: 'Black',
                      maximumFontSize: '5em',
                      fontFamily: 'Alfa',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'></div></div>",
                    },
                    height: 1,
                    typeId: 'Title',
                    y: 1,
                    x: 1,
                    width: 1,
                    documentId: '9RjkzRW4ry',
                    id: 'c8d70752-3164-11ee-85a3-71a99429ba2d',
                  },
                  {
                    width: 1,
                    properties: {
                      positionY: 0,
                      preferredHeight: '10em',
                      html: null,
                      rotation: 0,
                      text: null,
                      positionX: 0,
                    },
                    documentId: '9RjkzRW4ry',
                    id: 'd742bd70-3164-11ee-85a3-71a99429ba2d',
                    typeId: 'Image',
                    x: 1,
                    height: 1,
                    y: 2,
                  },
                ],
                rows: 2,
                style: 'fixed',
              },
              position: 0,
              meta: '{}',
              documentId: '9RjkzRW4ry',
            },
            {
              documentId: '9RjkzRW4ry',
              grid: {
                blocks: [
                  {
                    y: 1,
                    typeId: 'Introduction',
                    height: 2,
                    documentId: '9RjkzRW4ry',
                    width: 1,
                    id: 'e15886f0-3164-11ee-85a3-71a99429ba2d',
                    properties: {
                      plainText: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      maximumFontSize: '5em',
                      text: null,
                      rawEditorContent: '{"blocks":[{"key":"2i41j","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                      fontColor: 'Black',
                    },
                    x: 1,
                  },
                ],
                rows: 2,
                style: 'fixed',
                columns: 1,
              },
              position: 1,
              id: 'c8d70753-3164-11ee-85a3-71a99429ba2d',
              meta: '{}',
            },
            {
              position: 2,
              documentId: '9RjkzRW4ry',
              meta: '{}',
              grid: {
                style: 'fixed',
                rows: 2,
                blocks: [
                  {
                    height: 2,
                    typeId: 'Cause',
                    documentId: '9RjkzRW4ry',
                    x: 1,
                    properties: {
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"2o7bl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      plainText: null,
                      text: null,
                    },
                    width: 1,
                    id: 'e476ddf0-3164-11ee-85a3-71a99429ba2d',
                    y: 1,
                  },
                ],
                columns: 1,
              },
              id: 'c8d70754-3164-11ee-85a3-71a99429ba2d',
            },
            {
              position: 3,
              id: 'c8d70755-3164-11ee-85a3-71a99429ba2d',
              meta: '{}',
              documentId: '9RjkzRW4ry',
              grid: {
                style: 'fixed',
                blocks: [
                  {
                    properties: {
                      rawEditorContent: '{"blocks":[{"key":"8kc9a","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      fontSize: '1em',
                      htmlText: '<p > </p>',
                      plainText: null,
                    },
                    width: 1,
                    documentId: '9RjkzRW4ry',
                    typeId: 'Cause',
                    y: 1,
                    id: 'eb2e3a30-3164-11ee-85a3-71a99429ba2d',
                    x: 1,
                    height: 1,
                  },
                  {
                    width: 1,
                    x: 1,
                    properties: {
                      positionX: 0,
                      preferredHeight: '10em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      text: null,
                      positionY: 0,
                      rotation: 0,
                    },
                    id: 'e792eb00-3164-11ee-85a3-71a99429ba2d',
                    height: 1,
                    y: 2,
                    documentId: '9RjkzRW4ry',
                    typeId: 'Image',
                  },
                ],
                columns: 1,
                rows: 2,
              },
            },
            {
              documentId: '9RjkzRW4ry',
              position: 4,
              id: 'c8d70756-3164-11ee-85a3-71a99429ba2d',
              grid: {
                blocks: [
                  {
                    documentId: '9RjkzRW4ry',
                    id: 'eddf7780-3164-11ee-85a3-71a99429ba2d',
                    x: 1,
                    width: 1,
                    properties: {
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                      text: null,
                      htmlText: '<p > </p>',
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"74m","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                    },
                    typeId: 'Effect',
                    height: 2,
                    y: 1,
                  },
                ],
                rows: 2,
                columns: 1,
                style: 'fixed',
              },
              meta: '{}',
            },
            {
              position: 5,
              documentId: '9RjkzRW4ry',
              id: 'c8d70757-3164-11ee-85a3-71a99429ba2d',
              meta: '{}',
              grid: {
                columns: 1,
                style: 'fixed',
                blocks: [
                  {
                    x: 1,
                    height: 1,
                    y: 1,
                    documentId: '9RjkzRW4ry',
                    id: 'f095bde0-3164-11ee-85a3-71a99429ba2d',
                    properties: {
                      maximumFontSize: '5em',
                      plainText: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                      rawEditorContent: '{"blocks":[{"key":"dlenp","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      fontSize: '1em',
                    },
                    width: 1,
                    typeId: 'Effect',
                  },
                  {
                    x: 1,
                    properties: {
                      rotation: 0,
                      preferredHeight: '10em',
                      fontColor: 'Black',
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      positionY: 0,
                      rawEditorContent: '{"blocks":[{"key":"3igpr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      plainText: null,
                      positionX: 0,
                      fontFamily: "'Open Sans'",
                      text: null,
                    },
                    width: 1,
                    id: 'f19e5440-3164-11ee-85a3-71a99429ba2d',
                    documentId: '9RjkzRW4ry',
                    typeId: 'Image',
                    y: 2,
                    height: 1,
                  },
                ],
                rows: 2,
              },
            },
            {
              id: 'c8d70758-3164-11ee-85a3-71a99429ba2d',
              grid: {
                rows: 2,
                columns: 1,
                style: 'fixed',
                blocks: [
                  {
                    properties: {
                      rawEditorContent: '{"blocks":[{"key":"eduta","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'></div></div>",
                      preferredHeight: '10em',
                      positionX: 0,
                      text: null,
                      fontSize: '1em',
                      plainText: null,
                      htmlText: '<p > </p>',
                      rotation: 0,
                      fontFamily: "'Open Sans'",
                      maximumFontSize: '5em',
                      positionY: 0,
                    },
                    id: 'f3816c20-3164-11ee-85a3-71a99429ba2d',
                    x: 1,
                    typeId: 'Image',
                    documentId: '9RjkzRW4ry',
                    height: 1,
                    y: 1,
                    width: 1,
                  },
                  {
                    height: 1,
                    documentId: '9RjkzRW4ry',
                    properties: {
                      fontSize: '1em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                      htmlText: '<p > </p>',
                      fontColor: 'Black',
                      plainText: null,
                      rawEditorContent: '{"blocks":[{"key":"2a7sg","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      fontFamily: "'Open Sans'",
                    },
                    x: 1,
                    typeId: 'Effect',
                    id: 'a45fd570-3176-11ee-abfd-5f9de2162030',
                    y: 2,
                    width: 1,
                  },
                ],
              },
              position: 6,
              meta: '{}',
              documentId: '9RjkzRW4ry',
            },
            {
              position: 7,
              id: 'c8d70759-3164-11ee-85a3-71a99429ba2d',
              grid: {
                rows: 2,
                blocks: [
                  {
                    properties: {
                      maximumFontSize: '5em',
                      fontColor: 'Black',
                      htmlText: '<p > </p>',
                      fontFamily: "'Open Sans'",
                      plainText: null,
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"di7v9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      text: null,
                    },
                    y: 1,
                    x: 1,
                    id: 'fc9b7080-3164-11ee-85a3-71a99429ba2d',
                    documentId: '9RjkzRW4ry',
                    typeId: 'Conclusion',
                    width: 1,
                    height: 2,
                  },
                ],
                columns: 1,
                style: 'fixed',
              },
              meta: '{}',
              documentId: '9RjkzRW4ry',
            },
          ],
        },
      },
      format: DocumentFormat.BOOKLET,
      thumbnailUrl: null,
      user: {
        name: 'Kieran',
        username: null,
        id: '7WwWWeqwqv',
        email: 'kieran+teacher@joinpressto.com',
        surname: 'Sobel',
        role: 'teacher',
      },
      name: 'Copy of Cause and Effect - Zine Template',
      isDemo: false,
    },
  },
  {
    format: DocumentFormat.STORY,
    name: 'Opinion',
    writingPlanId: 'op-ed',
    documentId: 'template-story-op-ed',
    svg: SVGs.ClaimSVG,
    color: '#288247',
    document: {
      isTemplate: true,
      thumbnailUrl: null,
      format: DocumentFormat.STORY,
      isDemo: false,
      submission: null,
      templateSystem: 'blocks',
      user: {
        id: '7WwWWeqwqv',
        role: 'teacher',
        email: 'kieran+teacher@joinpressto.com',
        surname: 'Sobel',
        name: 'Kieran',
        username: null,
      },
      id: 'template-story-op-ed',
      meta: {
        hasImportantWords: true,
        hasWritingBuddy: true,
        hasWordCount: true,
        writingPlan: 'op-ed',
        hasSignalWords: true,
        importantWords: [],
      },
      updatedAt: '2024-04-17T19:42:03.312Z',
      name: 'Copy of Opinion - Story Template',
      isPublic: true,
      version: {
        publishedAt: {
          seconds: 1713382923,
          nanoseconds: 212000000,
        },
        notes: [],
        documentId: 'VG48G9NJNv',
        createdAt: {
          seconds: 1713382923,
          nanoseconds: 0,
        },
        content: {
          pages: [
            {
              meta: '{}',
              id: '7362eea1-3250-11ee-abfd-5f9de2162030',
              documentId: 'X8JPxvXJ9W',
              grid: {
                blocks: [
                  {
                    properties: {
                      htmlText: '<p > </p>',
                      maximumFontSize: '5em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                      fontSize: '2em',
                      plainText: null,
                      rawEditorContent: '{"blocks":[{"key":"11pfs","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: 'Alfa',
                      text: null,
                      fontColor: 'Black',
                    },
                    id: '7362eea2-3250-11ee-abfd-5f9de2162030',
                    documentId: 'X8JPxvXJ9W',
                    x: 1,
                    y: 1,
                    typeId: 'Title',
                    width: 1,
                    height: 2,
                  },
                ],
                rows: 2,
                style: 'fixed',
                columns: 1,
              },
              position: 0,
            },
            {
              id: '74361d21-3250-11ee-abfd-5f9de2162030',
              meta: '{}',
              grid: {
                rows: 2,
                columns: 1,
                style: 'fixed',
                blocks: [
                  {
                    height: 1,
                    y: 1,
                    width: 1,
                    properties: {
                      text: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      plainText: null,
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"9je9k","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      maximumFontSize: '5em',
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      fontColor: 'Black',
                    },
                    id: '7dfae070-3250-11ee-abfd-5f9de2162030',
                    documentId: 'X8JPxvXJ9W',
                    x: 1,
                    typeId: 'Introduction',
                  },
                  {
                    y: 2,
                    id: '7fe85890-3250-11ee-abfd-5f9de2162030',
                    documentId: 'X8JPxvXJ9W',
                    width: 1,
                    typeId: 'Image',
                    height: 1,
                    x: 1,
                    properties: {
                      rotation: 0,
                      positionY: 0,
                      text: null,
                      positionX: 0,
                      preferredHeight: '10em',
                      html: null,
                    },
                  },
                ],
              },
              position: 1,
              documentId: 'X8JPxvXJ9W',
            },
            {
              documentId: 'X8JPxvXJ9W',
              grid: {
                columns: 1,
                rows: 2,
                style: 'fixed',
                blocks: [
                  {
                    id: '814eed70-3250-11ee-abfd-5f9de2162030',
                    typeId: 'ClaimOrOpinion',
                    x: 1,
                    properties: {
                      fontFamily: "'Open Sans'",
                      fontColor: 'Black',
                      fitFontSize: false,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2.33em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      rawEditorContent: '{"blocks":[{"key":"cqr6h","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                      plainText: null,
                      text: null,
                      fontSize: '2.33em',
                    },
                    y: 1,
                    height: 2,
                    width: 1,
                    documentId: 'X8JPxvXJ9W',
                  },
                ],
              },
              meta: '{}',
              id: '80a789e1-3250-11ee-abfd-5f9de2162030',
              position: 2,
            },
            {
              meta: '{}',
              id: '870e2e11-3250-11ee-abfd-5f9de2162030',
              position: 3,
              documentId: 'X8JPxvXJ9W',
              grid: {
                columns: 1,
                style: 'fixed',
                blocks: [
                  {
                    height: 1,
                    typeId: 'Image',
                    width: 1,
                    properties: {
                      rotation: 0,
                      preferredHeight: '10em',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      positionX: 0,
                      text: null,
                      positionY: 0,
                    },
                    x: 1,
                    id: '892acc80-3250-11ee-abfd-5f9de2162030',
                    y: 1,
                    documentId: 'X8JPxvXJ9W',
                  },
                  {
                    x: 1,
                    documentId: 'X8JPxvXJ9W',
                    typeId: 'Evidence',
                    height: 1,
                    width: 1,
                    id: '884352b0-3250-11ee-abfd-5f9de2162030',
                    y: 2,
                    properties: {
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"ec2qk","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      text: null,
                      plainText: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      maximumFontSize: '5em',
                      fontSize: '1em',
                    },
                  },
                ],
                rows: 2,
              },
            },
            {
              documentId: 'X8JPxvXJ9W',
              grid: {
                blocks: [
                  {
                    properties: {
                      rotation: 0,
                      positionX: 0,
                      positionY: 0,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                      preferredHeight: '10em',
                      text: null,
                    },
                    documentId: 'X8JPxvXJ9W',
                    height: 1,
                    y: 1,
                    typeId: 'Image',
                    id: '8ba6c900-3250-11ee-abfd-5f9de2162030',
                    x: 1,
                    width: 1,
                  },
                  {
                    documentId: 'X8JPxvXJ9W',
                    y: 2,
                    height: 1,
                    x: 1,
                    typeId: 'Evidence',
                    properties: {
                      fontSize: '1em',
                      rawEditorContent: '{"blocks":[{"key":"7kvnb","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontColor: 'Black',
                      plainText: null,
                      text: null,
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontFamily: "'Open Sans'",
                      htmlText: '<p > </p>',
                      maximumFontSize: '5em',
                    },
                    id: '8af21f00-3250-11ee-abfd-5f9de2162030',
                    width: 1,
                  },
                ],
                columns: 1,
                style: 'fixed',
                rows: 2,
              },
              id: '8a386bf1-3250-11ee-abfd-5f9de2162030',
              meta: '{}',
              position: 4,
            },
            {
              documentId: 'X8JPxvXJ9W',
              meta: '{}',
              grid: {
                style: 'fixed',
                rows: 2,
                blocks: [
                  {
                    x: 1,
                    height: 1,
                    id: '209004c0-354c-11ee-85d9-f3941bab7f6d',
                    documentId: 'X8JPxvXJ9W',
                    width: 1,
                    properties: {
                      rotation: 0,
                      preferredHeight: '10em',
                      text: null,
                      positionY: 0,
                      html: null,
                      positionX: 0,
                    },
                    typeId: 'Image',
                    y: 1,
                  },
                  {
                    height: 1,
                    typeId: 'Evidence',
                    documentId: 'X8JPxvXJ9W',
                    x: 1,
                    id: '21722760-354c-11ee-85d9-f3941bab7f6d',
                    properties: {
                      plainText: null,
                      maximumFontSize: '5em',
                      rawEditorContent: '{"blocks":[{"key":"ahmj7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      htmlText: '<p > </p>',
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      fontColor: 'Black',
                      fontSize: '1em',
                      text: null,
                      fontFamily: "'Open Sans'",
                    },
                    y: 2,
                    width: 1,
                  },
                ],
                columns: 1,
              },
              id: '1d951441-354c-11ee-85d9-f3941bab7f6d',
              position: 5,
            },
            {
              id: '8c4504d1-3250-11ee-abfd-5f9de2162030',
              documentId: 'X8JPxvXJ9W',
              meta: '{}',
              position: 6,
              grid: {
                blocks: [
                  {
                    x: 1,
                    width: 1,
                    typeId: 'Conclusion',
                    documentId: 'X8JPxvXJ9W',
                    id: '8d30c460-3250-11ee-abfd-5f9de2162030',
                    properties: {
                      html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                      maximumFontSize: '5em',
                      htmlText: '<p > </p>',
                      plainText: null,
                      fontColor: 'Black',
                      rawEditorContent: '{"blocks":[{"key":"atmna","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                      fontFamily: "'Open Sans'",
                      text: null,
                      fontSize: '1em',
                    },
                    height: 2,
                    y: 1,
                  },
                ],
                rows: 2,
                style: 'fixed',
                columns: 1,
              },
            },
          ],
          schemaVersion: 20230101,
          messages: [],
        },
        id: 'VG48G9NJNv',
        status: 'PUBLISHED',
        pages: [
          {
            meta: '{}',
            grid: {
              style: 'fixed',
              rows: 2,
              columns: 1,
              blocks: [
                {
                  y: 1,
                  documentId: 'X8JPxvXJ9W',
                  properties: {
                    fontFamily: 'Alfa',
                    maximumFontSize: '5em',
                    fontColor: 'Black',
                    rawEditorContent: '{"blocks":[{"key":"11pfs","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    htmlText: '<p > </p>',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2em; font-family: Alfa; position: relative'><p > </p></div></div>",
                    plainText: null,
                    text: null,
                    fontSize: '2em',
                  },
                  typeId: 'Title',
                  height: 2,
                  x: 1,
                  id: '7362eea2-3250-11ee-abfd-5f9de2162030',
                  width: 1,
                },
              ],
            },
            documentId: 'X8JPxvXJ9W',
            position: 0,
            id: '7362eea1-3250-11ee-abfd-5f9de2162030',
          },
          {
            meta: '{}',
            documentId: 'X8JPxvXJ9W',
            grid: {
              blocks: [
                {
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontSize: '1em',
                    rawEditorContent: '{"blocks":[{"key":"9je9k","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    plainText: null,
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    fontFamily: "'Open Sans'",
                    text: null,
                  },
                  typeId: 'Introduction',
                  x: 1,
                  y: 1,
                  id: '7dfae070-3250-11ee-abfd-5f9de2162030',
                  width: 1,
                  height: 1,
                  documentId: 'X8JPxvXJ9W',
                },
                {
                  id: '7fe85890-3250-11ee-abfd-5f9de2162030',
                  typeId: 'Image',
                  documentId: 'X8JPxvXJ9W',
                  y: 2,
                  width: 1,
                  x: 1,
                  properties: {
                    preferredHeight: '10em',
                    rotation: 0,
                    positionX: 0,
                    html: null,
                    text: null,
                    positionY: 0,
                  },
                  height: 1,
                },
              ],
              columns: 1,
              style: 'fixed',
              rows: 2,
            },
            id: '74361d21-3250-11ee-abfd-5f9de2162030',
            position: 1,
          },
          {
            documentId: 'X8JPxvXJ9W',
            grid: {
              rows: 2,
              blocks: [
                {
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 2.33em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    plainText: null,
                    text: null,
                    fitFontSize: false,
                    fontSize: '2.33em',
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    rawEditorContent: '{"blocks":[{"key":"cqr6h","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  x: 1,
                  y: 1,
                  height: 2,
                  documentId: 'X8JPxvXJ9W',
                  width: 1,
                  id: '814eed70-3250-11ee-abfd-5f9de2162030',
                  typeId: 'ClaimOrOpinion',
                },
              ],
              columns: 1,
              style: 'fixed',
            },
            id: '80a789e1-3250-11ee-abfd-5f9de2162030',
            meta: '{}',
            position: 2,
          },
          {
            grid: {
              blocks: [
                {
                  properties: {
                    positionY: 0,
                    preferredHeight: '10em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    rotation: 0,
                    positionX: 0,
                    text: null,
                  },
                  y: 1,
                  documentId: 'X8JPxvXJ9W',
                  x: 1,
                  id: '892acc80-3250-11ee-abfd-5f9de2162030',
                  typeId: 'Image',
                  width: 1,
                  height: 1,
                },
                {
                  width: 1,
                  y: 2,
                  height: 1,
                  properties: {
                    fontFamily: "'Open Sans'",
                    fontColor: 'Black',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    rawEditorContent: '{"blocks":[{"key":"ec2qk","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    plainText: null,
                    htmlText: '<p > </p>',
                    fontSize: '1em',
                    maximumFontSize: '5em',
                    text: null,
                  },
                  x: 1,
                  typeId: 'Evidence',
                  id: '884352b0-3250-11ee-abfd-5f9de2162030',
                  documentId: 'X8JPxvXJ9W',
                },
              ],
              rows: 2,
              style: 'fixed',
              columns: 1,
            },
            meta: '{}',
            id: '870e2e11-3250-11ee-abfd-5f9de2162030',
            documentId: 'X8JPxvXJ9W',
            position: 3,
          },
          {
            meta: '{}',
            grid: {
              rows: 2,
              blocks: [
                {
                  typeId: 'Image',
                  documentId: 'X8JPxvXJ9W',
                  height: 1,
                  properties: {
                    rotation: 0,
                    positionY: 0,
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: initial; position: relative'></div></div>",
                    text: null,
                    positionX: 0,
                    preferredHeight: '10em',
                  },
                  id: '8ba6c900-3250-11ee-abfd-5f9de2162030',
                  width: 1,
                  y: 1,
                  x: 1,
                },
                {
                  typeId: 'Evidence',
                  documentId: 'X8JPxvXJ9W',
                  width: 1,
                  y: 2,
                  x: 1,
                  height: 1,
                  id: '8af21f00-3250-11ee-abfd-5f9de2162030',
                  properties: {
                    text: null,
                    fontSize: '1em',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    fontColor: 'Black',
                    fontFamily: "'Open Sans'",
                    rawEditorContent: '{"blocks":[{"key":"7kvnb","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    maximumFontSize: '5em',
                    htmlText: '<p > </p>',
                    plainText: null,
                  },
                },
              ],
              columns: 1,
              style: 'fixed',
            },
            id: '8a386bf1-3250-11ee-abfd-5f9de2162030',
            position: 4,
            documentId: 'X8JPxvXJ9W',
          },
          {
            documentId: 'X8JPxvXJ9W',
            meta: '{}',
            position: 5,
            grid: {
              style: 'fixed',
              rows: 2,
              columns: 1,
              blocks: [
                {
                  y: 1,
                  width: 1,
                  id: '209004c0-354c-11ee-85d9-f3941bab7f6d',
                  properties: {
                    positionY: 0,
                    rotation: 0,
                    positionX: 0,
                    text: null,
                    html: null,
                    preferredHeight: '10em',
                  },
                  x: 1,
                  typeId: 'Image',
                  height: 1,
                  documentId: 'X8JPxvXJ9W',
                },
                {
                  x: 1,
                  y: 2,
                  width: 1,
                  id: '21722760-354c-11ee-85d9-f3941bab7f6d',
                  documentId: 'X8JPxvXJ9W',
                  properties: {
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                    maximumFontSize: '5em',
                    fontSize: '1em',
                    fontColor: 'Black',
                    htmlText: '<p > </p>',
                    plainText: null,
                    fontFamily: "'Open Sans'",
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"ahmj7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                  },
                  height: 1,
                  typeId: 'Evidence',
                },
              ],
            },
            id: '1d951441-354c-11ee-85d9-f3941bab7f6d',
          },
          {
            documentId: 'X8JPxvXJ9W',
            position: 6,
            meta: '{}',
            id: '8c4504d1-3250-11ee-abfd-5f9de2162030',
            grid: {
              blocks: [
                {
                  width: 1,
                  y: 1,
                  x: 1,
                  typeId: 'Conclusion',
                  properties: {
                    fontColor: 'Black',
                    fontSize: '1em',
                    fontFamily: "'Open Sans'",
                    htmlText: '<p > </p>',
                    maximumFontSize: '5em',
                    plainText: null,
                    text: null,
                    rawEditorContent: '{"blocks":[{"key":"atmna","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    html: "<div style=\"width: 100%; height: 100%; overflow: hidden; padding: .5em .6em; position: relative\"><div style='font-size: 1em; font-family: 'Open Sans'; position: relative'><p > </p></div></div>",
                  },
                  documentId: 'X8JPxvXJ9W',
                  id: '8d30c460-3250-11ee-abfd-5f9de2162030',
                  height: 2,
                },
              ],
              rows: 2,
              style: 'fixed',
              columns: 1,
            },
          },
        ],
      },
      createdAt: '2024-04-17T19:42:03.000Z',
    },
  },
];

export const DOCUMENT_TEMPLATES = EMBEDDED_TEMPLATES;

import { Box } from '@mui/material';
import React from 'react';
import { DocumentFormatSettings } from 'src/configuration/documents';
import { DocumentTemplateSystem } from 'src/types/DocumentSettings';
import { Document } from 'src/types/models';
import Page from '../Page';

/**
 * Embed a preview of a document somewhere.
 * Currently, the first page of the document is displayed as a thumbnail.
 *
 * For legacy documents that use the layout template system, an image that
 * is generated on the server is displayed. For documents that use
 * the block template system, the content of the first page is rendered to scale.
 */
export default function DocumentThumbnail(props: {
  document: Pick<Document, 'format' | 'thumbnailUrl' | 'templateSystem' | 'version'>
  scale?: number,
}) {
  const { document, scale = 0.13 } = props;
  const formatSettings = DocumentFormatSettings[document.format];

  return (
    <div
      className="image-container"
    >
      {document.templateSystem === DocumentTemplateSystem.BLOCKS ? (
        // Be extra-guarded here because so many Presstos are displayed
        // at once. Better to fail silently than to crash the gallery.
        document.version?.pages?.length && (
        <Box
          sx={{
            width: '100%',
            border: '1px solid #eee',
            maxHeight: `${formatSettings.height * scale + 4}px`,
          }}
        >
          <Page
            format={document.format}
            page={document.version.pages[0]}
            scale={scale}
            isEditable={false}
            isThumbnail
          />
        </Box>
        )
      ) : (
        <img src={document.thumbnailUrl || ''} alt="Preview" />
      )}
    </div>
  );
}
